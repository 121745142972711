import React, { useEffect} from "react";
import {  Card, Col, Container , Row } from "react-bootstrap";
import userimg from "../asset/Ellipse 30.webp";
import {  FaLock,   FaRegUser } from "react-icons/fa";
import "./UserProfile.css";
import {   useNavigate, useSearchParams } from "react-router-dom";
import { BsBellFill, BsFillBarChartLineFill, BsFillWalletFill } from "react-icons/bs";
import { BsGeoAltFill } from "react-icons/bs";
import { useAuth } from "../../hooks";
import { getFirstWordCap } from "../../hooks/useCustomHelpingMethod";
import { BiPhone } from "react-icons/bi";
import { Avatar, Chip } from "@mui/material";
// import { BiSolidBriefcase } from "react-icons/bi";

const ProfileAside = () => {
    const { authInfo } = useAuth();
    const navigate = useNavigate()
    const [searchParems] = useSearchParams()


    const handlechange = (val) => {
        // setMystayle(styles);
        // setLearnVisivility(val || false)
        // setSideMenu(val);
        navigate('/profile?profile=' + val)

    }
    useEffect(() => {
        // console.log(sideMenu);
    })
    return (
        <>
            <Container fluid className="" >
                <Row>
                    <Col className="mb-3 mb-md-0">
                        <Card id="aside" className="border-none">
                            <Card.Body className="pt-0 ps-2 pe-2">
                                <Container fluid className="ps-1 pe-1">
                                    <Row className="">
                                        <Col id="img_container" className="pb-3 ">
                                            <Row className="mt-4">
                                                <Col className=" text-center">
                                                    <Avatar sx={{ width: 70, height: 70 }} alt="Remy Sharp"
                                                        src={authInfo?.profile?.url_profile || userimg}
                                                        className="mx-auto" />
                                                </Col>
                                            </Row>
                                            <Row id="user_id">
                                                <Col className="text-center">
                                                    <span className="fw-bold">{authInfo?.profile?.name}</span> <br />
                                                    <BiPhone /><small
                                                        className="">+{authInfo?.profile?.mobile_code || 91} {authInfo?.profile?.mobile}</small><br />
                                                    <Chip
                                                        label={getFirstWordCap(authInfo?.profile?.user_type || "Client")}
                                                        color={'warning'} size="small" variant="outlined" /><br />
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row onClick={() => handlechange('profile-detail')} style={{ cursor: "pointer" }} className={"mb-2 pt-2 pb-2 " + (searchParems.get('profile') === 'profile-detail' ? "border-left-profile" : "border-left-profile-hover")}>
                                        <Col className={"  fw-bold "}>
                                            <FaRegUser size={30} className="pe-2" /> Profile Information
                                        </Col>
                                    </Row>
                                    <Row onClick={() => handlechange('notification')} style={{ cursor: "pointer" }} className={"mb-2 pt-2 pb-2 " + (searchParems.get('profile') === 'notification' ? "border-left-profile" : "border-left-profile-hover")}>
                                        <Col className={" fw-bold "}>
                                            <BsBellFill size={30} className="pe-2" /> Notification
                                        </Col>
                                    </Row>
                                    <Row onClick={() => handlechange('change-password')} style={{ cursor: "pointer" }} className={"mb-2 pt-2 pb-2 " + (searchParems.get('profile') === 'change-password' ? "border-left-profile" : "border-left-profile-hover")}>
                                        <Col className={"  fw-bold "}>

                                            <FaLock size={30} className="pe-2" /> Change Password
                                        </Col>
                                    </Row>

                                    <Row onClick={() => handlechange('address')} style={{ cursor: "pointer" }} className={"mb-2 pt-2 pb-2 " + (searchParems.get('profile') === 'address' ? "border-left-profile" : "border-left-profile-hover")}>
                                        <Col className={"  fw-bold "}>
                                            <BsGeoAltFill size={30} className="pe-2" /> Address
                                        </Col>
                                    </Row>

                                    {authInfo?.profile?.user_type === 'client' ? <></> : <Row onClick={() => handlechange('business-detail')} style={{ cursor: "pointer" }} className={"mb-2 pt-2 pb-2 " + (searchParems.get('profile') === 'business-detail' ? "border-left-profile" : "border-left-profile-hover")}>
                                        <Col className={"  fw-bold "}>
                                            <BsFillBarChartLineFill size={30} className="pe-2" /> Business Detail
                                        </Col>
                                    </Row>}

                                    <Row onClick={() => handlechange('wallet')} style={{ cursor: "pointer" }} className={"mb-2 pt-2 pb-2 " + (searchParems.get('profile') === 'wallet' ? "border-left-profile" : "border-left-profile-hover")}>
                                        <Col className={"  fw-bold "}>
                                            <BsFillWalletFill size={30} className="pe-2" />Wallet
                                        </Col>
                                    </Row>

                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default ProfileAside;