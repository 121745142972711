import React from 'react'
import {Card, Col, Row} from "react-bootstrap";
import {CButton, CCardBody} from "@coreui/react";
import {getFirstWordCap} from "../../hooks/useCustomHelpingMethod";

export default function ({dataInfo}) {
    const style = {color: "var(--primary)", fontSize: "1.2em"}
    return (
        <Card className={'shadow  w-100'}>
            <CCardBody>
                <Row>
                    {/*<Col>*/}
                    <h5 className={''}>Payment Info</h5>
                    {/*</Col>
                    <Col>
                        <CButton size={'sm'} className={'text-dark shadow float-end'} disabled color="warning"
                                 variant="outline"><b
                            className={'color-primary'}>{getFirstWordCap(dataInfo?.status_payment || "")}</b></CButton>
                    </Col>*/}
                </Row>
                <Row className={'g-2 mt-2 figure-caption'}>
                    <Col xs={4} className={'text-muted'}>Status</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold text-uppercase"}>{dataInfo?.status_payment || "-"}</small></Col>

                    <Col xs={4} className={'text-muted'}>Method</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold text-uppercase"}>{dataInfo?.payment_mode || "-"}</small></Col>
                    {/* {dataInfo?.amount_delivery_charge ? <>
                        <Col xs={4} className={'text-muted'}>Delivery Charge</Col>
                        <Col xs={8}><small
                            className={"float-end fw-bold"}>{dataInfo?.amount_currency_symbol || "₹"} {dataInfo?.amount_delivery_charge || "0"}</small></Col>
                    </> : ""}*/}

                    <Col xs={4} className={'text-muted'}>Total Amount</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{dataInfo?.amount_currency_symbol || "₹"} {dataInfo?.amount_total || "0"}</small></Col>

                    <Col xs={4} className={'text-muted'}>Paid Amount</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{dataInfo?.amount_currency_symbol || "₹"} {dataInfo?.amount_paid || "0"}</small></Col>
                    <Col xs={4} className={'text-muted'}>Pending Amount</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{dataInfo?.amount_currency_symbol || "₹"} {dataInfo?.amount_pending || "0"}</small></Col>

                    <Col xs={4} className={'text-muted'}>Payment Reference</Col>
                    <Col xs={8}><small className={"float-end fw-bold"}>{dataInfo?.payment_ref || "-"}</small></Col>

                    <Col xs={4} className={'text-muted'}>Payment Date</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{dataInfo?.payment_at || "-"}</small></Col>

                </Row>

            </CCardBody>
        </Card>
    );
}
