import React, {useEffect, useState} from "react";
import {cart, cartCheckout} from "../../api/cart";
import {useAuth, useNotification} from "../../hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getUser} from "../../api/auth";
import {Card, Col, Row} from "react-bootstrap";
import {
    Alert,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Skeleton,
} from "@mui/material";
import Radio from '@mui/material/Radio';
import {formatMoney} from "../../hooks/useCustomHelpingMethod";
import {isAppTempDown} from "../../api/client";
import IntegrationRazorPay from "./IntrigationRazorpay";
import "../Cart/Cart.css"
import {ApiCall} from "../../api/HelpingMethod";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import handlePaymentTransaction from "./handel_payment_razorpay";
import useRazorpay from "react-razorpay";
import ModalAddressAdd from "./ModalAddressAdd";
import IncludeCartSummery from "./IncludeCartSummery";


export default function CartCheckOut() {
    const [isAddressSelect, setIsAddressSelect] = useState(false)
    const [isCodAvailable, setIsCodAvailable] = useState(false)
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState({});
    const [cart_details, setCart_details] = useState([]);
    const [whole_details, setWhole_details] = useState([]);
    const [cartAddress, setCartAddress] = useState([])
    const [coupon, setCoupon] = useState()
    const [couponset, setCouponset] = useState()
    const [showRemove, setShowRemove] = useState(false)
    const [updated, setUpdated] = useState(false);
    const {showError, showSuccess} = useNotification();
    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const [productNotAvailable, setProductNotAvailable] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [Razorpay] = useRazorpay();

    const [addressBody, setAddressBody] = useState({
        "login_user_id": authInfo?.profile?.id,
        "login_user_type": authInfo?.profile?.user_type,
        "limit": authInfo?.profile?.user_type === 'client' ? 3 : 1,
        "offset": 0
    })

    const [paymentMode, setPaymentMode] = useState("wallet");
    const [searchParams, setSearchParams] = useSearchParams();
    const [tokenPart, setTokenPart] = useState(searchParams.get("token_part") || null);

    const [amountDeliveryCharge, setAmountDeliveryCharge] = useState(0);
    const [symbolCurrency, setSymbolCurrency] = useState("₹");

    useEffect(() => {
        document.title = 'Payment Confirm | iService - Innovation Service';
    }, [])

    const getData = async ({token_part, coupon, couponTrue}) => {
        let inpDt = {
            id: authInfo?.profile.id,
            user_type: authInfo?.profile.user_type,
            coupon: coupon,
            couponTrue: couponTrue
        }
        if (token_part) {
            inpDt.token_part = token_part;
        }
        const {data} = await cart(inpDt);
        // console.log("datadata--", data);
        if (data.error) {
            showError(data.message);
        } else {
            if (data?.data?.parts) await setCart_details(data?.data?.parts);
            if (data?.data) await setWhole_details(data?.data);
            setSymbolCurrency(data?.data?.amount_currency_symbol || data?.data?.amount_currency || symbolCurrency || "₹");
            if (authInfo?.profile?.user_type === 'dealer') {
                setAmountDeliveryCharge(data?.data?.amount_delivery_charge_dealer || data?.data?.amount_delivery_charge || 0)
            } else {
                setAmountDeliveryCharge(data?.data?.amount_delivery_charge_customer || data?.data?.amount_delivery_charge || 0)
            }
        }
        return data;
    };
    // const getAddress = async () => {
    //     const {data} = await getUser({
    //         login_user_id: authInfo?.profile.id,
    //         login_user_type: authInfo?.profile.user_type
    //     });
    //     // setAddress(data?.address);
    //     // setIsAddressSelect(data?.address?.id || 0)
    //     onSelectAddress(data?.address);
    // };


    const [notAvOpen, setNotAvOpen] = useState(false);

    const handelCheckout = async (paymentResponse) => {
        if (isAppTempDown) {
            setNotAvOpen(true);
            return;
        }
        setLoadingBtn(true);

        let inputData = {
            userId: authInfo.profile.id,
            userType: authInfo.profile.user_type,
            coupon: couponset,
            couponTrue: showRemove,
            payment_mode: paymentMode,
        }

        if (paymentResponse) {
            inputData.token_payment = paymentResponse.token;
            inputData.payment_id = paymentResponse.id;
        }

        inputData = {...inputData, addressId: isAddressSelect}

        if (tokenPart && tokenPart !== "") {
            inputData.token_part = tokenPart;
        }
        if (!inputData?.addressId) {
            showError("Please Select an address to continue");
            setLoadingBtn(false);
            return
        }
        // console.log(inputData);

        const {data} = await cartCheckout(inputData);
        // console.log("Order insert", data);
        if (data.error) {
            showError(data.message);
            setLoadingBtn(false);
        } else {
            showSuccess(data.message);
            if (paymentMode === 'wallet' && data?.data?.pending_transaction) {
                const response = await handlePaymentTransaction(
                    {
                        navigate: navigate,
                        transaction: data?.data?.pending_transaction,
                        Razorpay: Razorpay,
                        setNotAvOpen: setNotAvOpen,
                        paymentMode: paymentMode,
                        order: data?.data || null,
                        user: authInfo.profile,
                        amount_total: data?.data?.pending_transaction?.amount || 0,
                        amount_currency: data?.data?.pending_transaction?.amount_currency || "INR",
                    });
                // console.log(response);
                if (response?.error) {
                    showError(response?.message);
                    setLoadingBtn(false);
                    // successRedirect(data.data.token, data?.data?.order_parts[0]?.part?.token_brand);
                } else {
                    // showError(response?.message);
                    //  successRedirect(data.data.token, data?.data?.order_parts[0]?.part?.token_brand);
                }
                setLoadingBtn(false);
            } else {
                // showSuccess(data?.message);
                navigate("/order-complete?order=" + data.data.token, {
                    state: {id: data?.data?.order_parts[0]?.part?.token_brand},
                    replace: true,
                });
            }
        }
    };

    const applyCoupon = async () => {
        setLoading(true);
        if (showRemove === true) {
            setCoupon("")
            setLoading(false);
            return showError("Only one coupon can be applied at a time")
        }
        if (coupon === "") {
            setLoading(false);
            return showError("Please Enter Coupon Code")
        }

        const data = await cart({
            id: authInfo.profile.id,
            user_type: authInfo.profile.user_type,
            coupon: coupon
        });

        if (data.data.error) {
            setCoupon("")
            showError(data.data.message);
        } else {
            const data = await getData({coupon: coupon, couponTrue: true});
            if (data.error) {
                showError(data?.message || "Success");
            } else {
                setShowRemove(true)
                // showSuccess("Coupon Applied Successfully");
                setCouponset(coupon)
            }
        }
        setLoading(false);
    };

    const couponRemove = async () => {
        setLoading(true);
        const data = await cart({
            id: authInfo.profile.id,
            user_type: authInfo.profile.user_type,

        });
        if (data.data.error) {
            showError(data.data.message);
        } else {
            getData({coupon: "", couponTrue: false});
            setCouponset("")
            setShowRemove(false)
            // showSuccess("Coupon Removed Successfully");
        }
        setLoading(false);
    };

    const handleChange = ({target}) => {
        const {value, name} = target;
        setCoupon(value);
    };

    const getCartAddress = async (e) => {
        setLoading(true);
        const result = await ApiCall("/dealer/address", addressBody)
        // console.log("address---", result)
        setCartAddress(result?.data);
        if (result?.data && Array.isArray(result?.data) && result?.data?.length > 0) {
            onSelectAddress(result?.data[0])
        }
        setLoading(false);
        setUpdated(false);
    }
    useEffect(() => {
        getData({token_part: tokenPart, coupon: "", couponTrue: false}).then(r => r);
        getCartAddress().then(r => r);
        // getAddress().then(r => r);
    }, [updated, tokenPart]);


    const onSelectAddress = (address) => {
        // console.log(id);
        setIsAddressSelect(address?.id || 0);
        setAddress(address);
        if (address?.is_cod_available === "yes") {
            setIsCodAvailable(true);
        } else {
            setPaymentMode("wallet");
            setIsCodAvailable(false);
        }
    }
    const handlePaymentModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMode(event.target.value);
        // setHelperText(' ');
        // setError(false);
    };

    return (
        <div>
            <div className="ourservices">
                <h3 className=" mx-5 servicesHeading">Confirm Payment</h3><p className="mx-5 subHeading"/>
            </div>
            <section>
                <div className="container py-md-3 py-sm-2  py-0">
                    {loading &&
                    <Row>
                        <b className="product-info-heading col-lg-3"><Skeleton height={40}/></b>
                        <div className="col-lg-12"/>
                        <Col lg={8}>
                            <Row>
                                <SkeletonFileGeneral height={90} total={4} col={12}/>
                            </Row>
                        </Col>
                        <Col lg={4}>
                            <Row>
                                <SkeletonFileGeneral height={200} total={2} col={12}/>
                            </Row>
                        </Col>
                    </Row>
                    }
                    {!loading && (
                        <div className="row d-flex justify-content-center mb-4">

                            <div className="col-lg-8 py-2" style={{maxHeight: "450px", overflow: "auto"}}>
                                {!cart_details || !Array.isArray(cart_details) || !cart_details.length ? "" : <Row>
                                    <Col><h5 className="mb-0 mx-3 product-info-heading">User Addresses</h5></Col>
                                    <Col>{authInfo?.profile?.user_type === 'client' ?
                                        <ModalAddressAdd reloadData={updated} setReloadData={setUpdated}/> : ""}</Col>
                                </Row>}

                                <FormControl sx={{m: 1}} error={false} variant="standard">
                                    <RadioGroup aria-labelledby="demo-error-radios" name="address_id"
                                                value={isAddressSelect || 0}>
                                        {cartAddress && Array.isArray(cartAddress) && cartAddress.map((address, index) => {
                                            return (
                                                <Row className={'mb-2'} key={address?.token || address?.id || index}>
                                                    <Col xs={12}>
                                                        <Card style={{cursor: "pointer", width: "100%"}}
                                                              onClick={() => onSelectAddress(address)}
                                                              className={isAddressSelect === address?.id ? "select-address" : ""}>
                                                            <Card.Body>
                                                                <FormControlLabel value={address?.id}
                                                                                  control={<Radio color={"warning"}/>}
                                                                                  label={<>
                                                                                      {/*<b>{address?.name || ""} | {address?.mobile || ""} | {address?.email || ""}</b>*/}
                                                                                      <span>{address?.full_address || "No Address"}</span>
                                                                                  </>}/>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )
                                        })}

                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="col-lg-4 my-3">
                                {cart_details && Array.isArray(cart_details) && cart_details.length ? (
                                    <>
                                        <IncludeCartSummery user={authInfo?.profile} data={whole_details}
                                                            amountDeliveryCharge={amountDeliveryCharge}
                                                            symbolCurrency={symbolCurrency}/>

                                        {authInfo?.profile?.user_type === 'client' && (
                                            <>
                                                <div className="card mb-3 cart-summary">
                                                    <div className="pt-3">
                                                        <h5 className="mb-0 mx-3 ">Have coupon?</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="form-group input-test">
                                                            {" "}
                                                            <div className="input-group mb-2">
                                                                {" "}
                                                                <input
                                                                    type="text"
                                                                    className="form-control coupon"
                                                                    name="coupon"
                                                                    value={coupon}
                                                                    onChange={handleChange}
                                                                    placeholder="Coupon code"
                                                                />{" "}
                                                                <span className="input-group-append">
                                                            {" "}
                                                                    <button onClick={applyCoupon}
                                                                            className="apply-button mx-2">Apply</button>
                                                                    {" "}
                                                        </span>{" "}
                                                            </div>
                                                        </div>

                                                        {showRemove && <div className="d-flex">
                                                            <div className="coupon-x" onClick={couponRemove}>❌</div>
                                                            <h6 className="mx-1 my-1"> {couponset} <span
                                                                className="">is applied</span>
                                                            </h6></div>}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {<div className="card mb-1 cart-summary">
                                            <div className="pt-3">
                                                <h5 className="mb-0 mx-3 ">Payment Method</h5>
                                            </div>
                                            <div className="card-body pb-2">
                                                <FormControl sx={{m: 1}} error={false} variant="standard">
                                                    <RadioGroup aria-labelledby="demo-error-radios" name="payment_mode"
                                                                value={paymentMode} onChange={handlePaymentModeChange}>
                                                        <FormControlLabel value="wallet"
                                                                          control={<Radio color={"warning"}/>}
                                                                          label="Online Payment"/>
                                                        {isCodAvailable && <FormControlLabel value="cod"
                                                                                             control={<Radio
                                                                                                 color={"warning"}/>}
                                                                                             label="Cash On Delivery"/>}
                                                    </RadioGroup>
                                                </FormControl>
                                                <IntegrationRazorPay
                                                    // loadingMain={loading}
                                                    addresId={isAddressSelect}
                                                    setNotAvOpen={setNotAvOpen}
                                                    locationInfo={address}
                                                    // setPayment={setPayment}
                                                    handelCheckout={handelCheckout}
                                                    paymentMode={paymentMode}
                                                    amount_total={(authInfo?.profile?.user_type === 'dealer' ? whole_details.amount_grand_total_dealer : whole_details.amount_grand_total_customer)}
                                                    amount_currency={"INR"}
                                                    order={whole_details}
                                                    user={authInfo?.profile}/>
                                                {!isCodAvailable &&
                                                <Alert severity="warning">COD is not available on selected
                                                    address</Alert>}
                                                {isAppTempDown &&
                                                <Alert severity="warning">Sorry! This service is temporally not
                                                    available</Alert>
                                                }

                                                {productNotAvailable && (
                                                    <>
                                                        <Alert severity="warning">
                                                            Some products were not available. If you are going to
                                                            proceed,
                                                            then these will not be checking out.
                                                        </Alert>
                                                    </>
                                                )}
                                            </div>
                                        </div>}
                                    </>) : null}
                            </div>
                        </div>
                    )}
                </div>
            </section>
            {/* <DialogTempNotAvailable open={notAvOpen} setOpen={setNotAvOpen} /> */}
        </div>
    );
}
