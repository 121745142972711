
import { Avatar, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BsArrowDown, BsArrowUp, BsClock,  BsXCircle } from "react-icons/bs";
import { useAuth } from "../../hooks";
import { ApiCall } from "../../api/HelpingMethod";
import { formatMoney, get_date_formatted } from "../../hooks/useCustomHelpingMethod";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import ModalTopUp from "./ModalTopUp";

const WalletTransition = () => {
    const { authInfo } = useAuth();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [offset, setOffset] = useState(0);
    const [isMoreData, setIsMoreData] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    // const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [transitionData, setTransitionData] = useState([]);

    const [transitionBody] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "limit": 10,
        "offset": 0
    })
    const getWalletData = async () => {
        setLoading(true)
        const response = await ApiCall("dealer/wallet", {
            "login_user_id": authInfo.profile.id,
            "login_user_type": authInfo.profile.user_type,
        })
        if (response?.error === false) {
            setData(response?.data)
        } else {
            setData([])
        }
        setLoading(false)
    }




    const getData = async () => {
        setLoading(true)
        setOffset(0)
        const inputData = {
            offset: 0
        }

        const response = await ApiCall("dealer/wallet-transaction", { ...transitionBody, ...inputData })
     //   console.log("response---", response)


        if (response?.error === false) {
            setTransitionData(response?.data)
            if (response.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
        } else {
            setTransitionData([])
        }
        setLoading(false)
    }


    useEffect(() => {
        getWalletData().then(r => r)
        getData().then(r => r)
    }, []);



    const nextData = async () => {
        setBtnLoading(true);
        // setIsLoadingMore(true);

        const inputData = {
            offset: offset || 0
        }

        const response = await ApiCall("dealer/wallet-transaction", { ...transitionBody, ...inputData })
       // console.log("responsenext---", response)


        if (response?.error === false) {
            // setTransitionData(response?.data)
            if (response.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
            if (response.data.length) {
                setTransitionData((prev) => [...new Set([...prev, ...response.data])]);
            }
        } else {
            setTransitionData([])
        }
        // setIsLoadingMore(false);
        setBtnLoading(false);
    }
    useEffect(() => {
        if (offset !== 0) {
            nextData();
        }
    }, [offset]);

    const handelInfiniteScroll = async () => {
        setOffset(offset + 1);
    };

    return (
        <>
            <Container className="ps-0 pe-0">
                <Row className="h5 ">Wallet</Row>

                <Row>
                    {loading && <SkeletonFileGeneral height={140} col={6} total={1} />}
                    {!loading && <Col lg={6}>
                        <Card className="wallet-card">
                            <Card.Body>
                                <Container className="ps-0 pe-0">
                                    <Row className="h6 ms-1 "><Col>Total Amount</Col></Row>
                                    <Row className="mt-2 h2 ms-1 fw-bold "><Col>
                                        {(data?.amount_currency_symbol || "₹") + " " + (formatMoney(data?.amount_balance || 0))}
                                    </Col></Row>
                                    <Row className="  text-center ">
                                        <Col>

                                            <ModalTopUp />

                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>}
                    {loading && <SkeletonFileGeneral height={140} col={6} total={1} />}
                    {!loading && <Col lg={6} className="mt-lg-0 mt-3">
                        <Card className="wallet-card">
                            <Card.Body>
                                <Container className="ps-0 pe-0 pb-4 pt-2">
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6} >
                                            <Card className="shadow">
                                                <Card.Body className="ps-2 pe-2 pt-2 pb-2">
                                                    <Row>
                                                        <Col xs={8} sm={8}>
                                                            <Row className="h6 m-0">
                                                                {(data?.amount_currency_symbol || "₹") + " " + (formatMoney(data?.amount_total_credit || 0))}
                                                            </Row>
                                                            <Row className="m-0 d-flex">Total Credit</Row>
                                                        </Col>
                                                        <Col xs={4} sm={4} className="d-flex align-items-center justify-content-end ">
                                                            <Avatar className="wallet-credit" variant="rounded">
                                                                <BsArrowUp color="#ffffff" size={20} />
                                                            </Avatar>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} sm={6} className="mt-sm-0 mt-2">
                                            <Card className="shadow">
                                                <Card.Body className="ps-2 pe-2 pt-2 pb-2">
                                                    <Row>
                                                        <Col xs={8} sm={8}>
                                                            <Row className="h6 m-0">
                                                                {(data?.amount_currency_symbol || "₹") + " " + (formatMoney(data?.amount_total_debit || 0))}
                                                            </Row>
                                                            <Row className="m-0 d-flex">Total Debit</Row>
                                                        </Col>
                                                        <Col xs={4} sm={4} className="d-flex align-items-center justify-content-end ">
                                                            <Avatar className="wallet-debit" variant="rounded">
                                                                <BsArrowDown color="#ffffff" size={20} />
                                                            </Avatar>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>}
                </Row>

                <Row className="mt-4"><hr /></Row>

                <Row className="mt-2">
                    <Card>
                        <Card.Body>
                            <Container className="ps-0 pe-0">
                                <Row className="h6">Recent Transition</Row>
                                {loading && <Row className="mt-3"> <SkeletonFileGeneral height={50} col={6} total={8} /> </Row>}
                                <Row>
                                    {!loading &&
                                        transitionData && Array.isArray(transitionData) && transitionData.map((data, index) => {
                                            return (
                                                <Col lg={6} key={index} className="mt-2">
                                                    <Card className="shadow">
                                                        <Card.Body>
                                                            <Row>
                                                                {/* <Col xs={12} sm={2} className="d-flex align-items-center ">
                                                                    {data?.type === "credit" ?
                                                                        <Avatar className={data?.status === "pending" ? "transition-pending" : "transition-credit"} variant="rounded">
                                                                            <BsArrowUp color={data?.status === "pending" ? "#000000" : "#ffffff"} size={20} />
                                                                        </Avatar>
                                                                        : <Avatar className={data?.status === "pending" ? "transition-pending" : "transition-debit"} variant="rounded">
                                                                            <BsArrowDown color={data?.status === "pending" ? "#000000" : "#ffffff"} size={20} />
                                                                        </Avatar>}
                                                                </Col> */}


                                                                <Col xs={12} sm={2} className="d-flex align-items-center ">
                                                                    {data?.status === "pending" &&
                                                                        <Avatar className={"transition-status-pending"} variant="rounded">
                                                                            <BsClock size={20} />
                                                                        </Avatar>}

                                                                    {data?.status === "success" && <>
                                                                        {data?.type === "credit" ? <Avatar
                                                                            className={"transition-credit"} variant="rounded">
                                                                            <BsArrowUp   size={20} />
                                                                        </Avatar> : <Avatar
                                                                            className={"transition-debit"} variant="rounded">
                                                                            <BsArrowDown   size={20} />
                                                                        </Avatar>
                                                                        }
                                                                    </>}

                                                                    {(data?.status === "cancelled" ||data?.status === "rejected" ) &&
                                                                        <Avatar className={"transition-status-cancelled"} variant="rounded">
                                                                        <BsXCircle  size={20} />
                                                                    </Avatar>}

                                                                </Col>



                                                                <Col xs={7} sm={6}>
                                                                    <Row className="h6 m-0">Amount {data?.type}</Row>
                                                                    <Row className="m-0 d-flex">  {get_date_formatted(data?.created_at)}</Row>
                                                                </Col>
                                                                <Col className="text-end " xs={5} sm={4}>
                                                                    <div className="fw-bold">{(data?.amount_currency_symbol || "₹") + " " + (formatMoney(data?.amount || 0))}</div>
                                                                    {
                                                                        data?.status === "pending" ? <div>{data?.status}</div> : <></>
                                                                    }

                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }

                                </Row>
                                {!transitionData.length && !loading && <Row>
                                    <Col className="fw-bold text-danger">No React Transition</Col>
                                </Row>}
                                {transitionData.length && isMoreData && !loading && (<Row className="mt-3">
                                    <Col>
                                        <div className="text-center">
                                            <Button disabled={btnLoading} variant="contained"
                                                color="warning"
                                                className={'sign-up-button'}
                                                onClick={handelInfiniteScroll}>{btnLoading &&
                                                    <CircularProgress size={16} color={"inherit"} />}&nbsp; Load More</Button>


                                        </div>
                                    </Col>
                                </Row>)}
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>




            </Container>

        </>
    )
}
export default WalletTransition;