import React, {useEffect, useState} from "react";

export default function ProductDescription({description}) {
    const [isOverFlow, setIsOverFlow] = useState(false);
    const [isReadMore, setIsReadMore] = useState(false);
    const containerStyleOverflow = {
        height: "350px",
        overflow: "hidden",
        // transition: "height 0.25s linear",
        transition: "height 1050ms linear 0s",
        // transition: "smooth"
    };
    const containerStyleOverflowNot = {
        // height: "200px",
        overflow: "hidden",
        // transition: "height 0.25s linear",
        transition: "height 1000ms linear 0s",
        // transition: "smooth"
    };

    const handleReadMore = () => {
        setIsReadMore(!isReadMore);
    }

    useEffect(() => {
        const length = description?.length || 0;
        if (length > 1000) {
            setIsOverFlow(true);
        } else {
            setIsOverFlow(false);
        }
        // console.log(description);
    }, [description])

    return (
        <>
            {description ? <> <div style={isOverFlow && !isReadMore ? containerStyleOverflow : containerStyleOverflowNot} >
                <div dangerouslySetInnerHTML={{__html: description ?? "Information not available"}}/>
            </div>
            <span>{isOverFlow && (!isReadMore ? (<a className={'btn btn-link'} onClick={handleReadMore}>Read More</a>) : (
                <a className={'btn btn-link'} onClick={handleReadMore}>Read Less</a>))}</span> </> : <div className="h6 text-danger">This Part Description Is Not Available </div>}
        </>
    );
}
