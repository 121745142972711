import React from "react"
import {useNavigate} from "react-router-dom"
import banner from "../asset/banner.webp"
import {Card, Image} from "react-bootstrap";
import {styled, Tooltip, tooltipClasses, Zoom} from "@mui/material";
import { useAuth, useNotification } from "../../hooks";

export default function ByCard({title, image, slug, baseUrl}) {
    const { setShowLoginModel } = useNotification();
    const { authInfo } = useAuth();

    // let nextUrl = "/shop";
    let nextUrl = "/product";
    if (baseUrl) {
        nextUrl = baseUrl;
    }

    const placeholderImage = {banner}

    const onImageError = (e) => {
        e.target.src = banner
    }

    const navigate = useNavigate();

    const handleOnClick = async (event) => {

        if (slug) {
            if (baseUrl) {
                navigate(nextUrl + slug);
            } else {
                navigate(nextUrl + "?brand-slug=" + slug);
            }
        } else {
            navigate(nextUrl);
        }
    };

    const LightTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}} arrow TransitionComponent={Zoom}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    return (
        <>

            {/*<div onClick={handelOnclick} className='card  buy-card-logo '>
                <div className='card-body overflow-hidden'>
                    <img className="brand-image my-auto" src={image && checkIfImageExists(image) ? image : banner}
                         onError={onImageError}/>
                </div>
            </div>*/}
            <Card className={'card-custom card-link my-2'} onClick={authInfo.profile.id ? handleOnClick : () => setShowLoginModel(true)}>
                <Card.Body>
                    <LightTooltip title={title ? title : ""} placement="top">
                        <Image className="brand-image my-auto " rounded
                               src={image /*&& checkIfImageExists(image) */ ? image : banner}
                               onError={onImageError}/>
                    </LightTooltip>
                </Card.Body>

            </Card>
        </>
    )
}
