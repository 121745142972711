import * as React from 'react';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useEffect, useState} from "react";
// import banner from "../asset/banner.webp";
// import {get_data_by_url} from "../../api/general";
import {useAuth, useNotification} from "../../hooks";
import {Rating} from "@mui/material";
import {Col,  Row} from 'react-bootstrap';
import img from "../asset/noimage.webp"
import {Link} from 'react-router-dom';
import MoreButton from '../HomePage/MoreButton';
import {ApiCall} from '../../api/HelpingMethod';
import {BsPersonCircle} from 'react-icons/bs';
import SkeletonFileGeneral from '../../hooks/SkeletonFileGeneral';
import {get_datetime_formatted} from "../../hooks/useCustomHelpingMethod";

export default function IncludeReviewsByList({tokenPart, idPart}) {
    const [data, setData] = useState([]);
    // const [offset, setOffset] = useState(1);
    // const [sn, setSn] = useState(1);
    // const {showError, showSuccess} = useNotification();
    // const [value, setValue] = useState(0);
    const {authInfo} = useAuth();
    const [loading, setLoading] = useState(false)
    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "ref_type": "part",
        "token_ref": tokenPart,
        "limit": 6,
        "offset": 0
    })

    const titleMaxLength = 70;
    const limitTitleLength = (title, maxLength) => {
        if (title.length > maxLength) {
            return title.substring(0, maxLength) + "...";
        }
        return title;
    };


    const getReview = async () => {
        setLoading(true)
        const response = await ApiCall("dealer/user_review", bodyData)
        if (response?.error === false) {
            setData(response?.data || [])
        } else {
            setData([])
        }
        setLoading(false)
    }

    useEffect(() => {
        getReview()
    }, []);

    const styleUserImageIcon = {
        width: "100%",
        height: "100%",
        borderRadius: "30px"
    }

    return (
        <div>
            {/*<hr/>*/}
            {loading && <Row className="mt-3"> <SkeletonFileGeneral height={50} col={12} total={3}/> </Row>}
            {!loading &&
            data?.length >= 0 ? data && data.map((review) => {
                return (
                    <>
                        <Row className="mt-5">
                            <Col xs={1} className="text-end pe-0">
                                <div style={{height: "30px", width: "30px"}} className="ms-auto">
                                    <BsPersonCircle src={img} style={styleUserImageIcon}/>
                                </div>
                            </Col>
                            <Col xs={11} className="px-3">
                                <Row>
                                    <Col>{review?.user_name}</Col>
                                    <Col
                                        className="text-end">{review?.date_add_period || get_datetime_formatted(review?.created_at || "")}</Col>
                                </Row>
                                <Row xs={{cols: 1}}>
                                    <Col>
                                        <Rating style={{fontSize: "10px"}} size="small" readOnly
                                                name="simple-controlled"
                                                value={review?.count_rating}
                                                defaultValue={review?.count_rating || 0}
                                                precision={0.5}/>
                                    </Col>
                                    <Col>
                                        {limitTitleLength(review?.message, titleMaxLength)}
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </>
                )
            }) : loading ? <></> : <b className={'text-danger'}>No reviews available for this product</b>
            }


            {data?.length >= 6 ? <div className=" my-4 d-flex justify-content-center align-item-center">
                <Link to={"/review/" + tokenPart} className='text-decoration-none'>
                    <MoreButton>View All</MoreButton>
                </Link>
            </div> : <></>}
        </div>
    );
}