import {CButton, CCardBody} from "@coreui/react";
import {Card, Col, Row} from "react-bootstrap";
import {get_status_formatted} from "../../../hooks/useCustomHelpingMethod";
import banner from "../../asset/banner.webp";
import {BsTelephone} from "react-icons/bs";
import React from "react";
import {FaMapMarkerAlt} from "react-icons/fa";

export function BookingDetailCard({dataInfo, clientInfo, orderAddress}) {
    const style = {color: "var(--primary)", fontSize: "1.2em"}
    return (
        <Card className={'shadow w-100'}>
            <CCardBody>
                <Row xs={{cols: 1}} className={'g-2'}>
                    <Col>
                        <Row>
                            <Col>
                                <h5 className={''}>{/*Order*/} <span
                                    className="text-uppercase">#{dataInfo?.token}</span></h5>
                            </Col>
                            <Col>
                                <CButton size={'sm'} className={'text-dark shadow float-end'} disabled color="warning"
                                         variant="outline">
                                    Status: <b className={'color-primary'}>
                                    {dataInfo?.status === 'pending' && dataInfo?.status_payment === 'success' ?
                                        <>Processing (Paid)</> :
                                        get_status_formatted(dataInfo?.status || "")
                                    }
                                </b>
                                </CButton>
                            </Col>
                        </Row>
                    </Col>

                    <Col> <span className="clientDetail">Customer Details</span></Col>
                    <Col>
                        <div className={'d-flex flex-row'}>
                            <div><img className={"rounded-circle mx-2"}
                                      src={clientInfo?.url_profile /*&& checkIfImageExists(clientInfo.url_profile)*/ ? clientInfo.url_profile : banner}
                                      alt={clientInfo?.name} width={"50px"} height={"50px"} onError={(e) => e.target.src = banner}/></div>
                            <div className="customerDetail">
                                <span
                                    className="names fw-bold">{orderAddress?.name || clientInfo?.name || "Customer"}</span>
                                <div className="phoneNumber">
                                    <small><b>+91 {orderAddress?.mobile || clientInfo?.mobile}</b></small>
                                </div>

                            </div>
                            <div>
                                <a href={"tel:" + (orderAddress?.mobile || "")} className={''}>
                                    <BsTelephone style={style} className={'align-items-center justify-content-center'}/>
                                </a>
                            </div>

                        </div>
                    </Col>
                    <Col>
                        <div className={'d-flex flex-row px-2 align-items-center'}>
                            <p><FaMapMarkerAlt style={style} className={'align-items-center justify-content-center'}/>
                                <small
                                    className={"px-2"}>{orderAddress?.main_address || orderAddress?.main_address || ""}</small>
                            </p>
                        </div>
                    </Col>
                </Row>
            </CCardBody>
        </Card>
    );
}