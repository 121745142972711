import client from "./client";
import axios from "axios";

const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYjQxYjcyN2VkOTY5NGMzODQ1ZjM3ZDFmMTkwMjAyYzMxODY4YmU2MTc3OGI2ZjJlOGMwNjY2MzZkZjhhMGE5NzE0ZWE4ZGE2MTBlMjkxYWMiLCJpYXQiOjE2Nzc4MjI2MTEuMTk0MDYxLCJuYmYiOjE2Nzc4MjI2MTEuMTk0MDcsImV4cCI6MzMyMzQ3MzE0MTEuMTYwNjYsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.zTZvF547MhKLOm9sAfqs1Y5hRTpSziPc8TEAV_N_XnukXUoi3KxBHLl6J2avQm2vw_IvIw9Wmvjun8jZjF2xxRICx157cL12lw7TMruNqkynWhZ5k0V_FnP6KrcUO9mTOMR9gNj_mASpHDeMqFeFIJChPWxvd_1lslPaJ91om5FHFnhm8OzzRZXQweSFM9J0UO5t4UfixkWoG0xatubyeFkVp4GsYusoG4UGYFSGCj5aM2KqPmg6LvMIh6skJraV9ufSdj7mWMXCLoA8feSl40bvpHttfdaI709gb3ajyXMtksKt6fGILD8_kESxvP4N1HYdMGO49M2y8ASOHBV5d7OPzYl0PoiTtWSTnmhiJbflFsbczI8OGfYG0cubb8nSQDxRJQ51QReIwO7UCuxnEnDG8Gz7IKOg8ahnarcmBrW2zxr0hM1UmOeAsry9gB-Q8RABcB7fUPBMZN45r6oJqF5inr6qYKouS8_sd78ebUY-jAyYvSFyCL_uZ3lTDic4qXTtICf981GwSa6uGKcdR8UYrq0xbiU0N7RAAua2TF0aVfA6waMT8aCfjuvQA-ypk64YyydkfPr8QOUrqByuNA6OWgkkcdsKSUrD8V6dJvg3ZLEukS_lOTi5SZcvIpjaIQnVKpCgNcLVyqEz-e7ENEr81F0zepDfROlxCeehCpg"
const fetchHeader = {
    Authorization: "Bearer " + authToken,
    accept: "application/json",
    AccessControlAllowOrigin: "*"
};
const requiredInput = {
    device_type: "web",
    istoken: "ISABCDEFGH",
    device_token: localStorage.getItem('device_token') || "ABCDEFGH",
};
let ajaxRequest = null;

export const book_service = async ({
                                       category_id,
                                       brand_id,
                                       model_id,
                                       id,
                                       user_type,
                                       device_serial_imei,
                                       token_order,
                                       step,
                                       expected_visit_date,
                                       expected_visit_time
                                   }) => {
    const requestParams = {
        login_user_id: id,
        login_user_type: user_type || "client",
        category_id: category_id,
        brand_id: brand_id,
        model_id: model_id,
        device_serial_imei: device_serial_imei
    }
    const details = {...requiredInput, ...requestParams};

    // console.log(details)
    if (token_order) {
        details.token_order = token_order
    }
    if (step) {
        details.step = step
    }
    if (expected_visit_date) {
        details.expected_visit_date = expected_visit_date
    }
    if (expected_visit_time) {
        details.expected_visit_time = expected_visit_time
    }
    // console.log(details);
    try {
        return await client.post("/dealer/order/book-service-add", details, {
            headers: {...fetchHeader},
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};

export const book_service_step = async ({details, isImage = false}) => {
    let headerImport = {...fetchHeader};
    let sendData = details;
    if (isImage) {
        headerImport = {
            Authorization: "Bearer " + authToken,
            'Content-Type': 'multipart/form-data'
        }
    }
    if (isImage) {
        sendData.append('device_type', requiredInput.device_type);
        sendData.append('istoken', requiredInput.istoken);
    } else {
        sendData = {...details, ...requiredInput};
    }

    // console.log(details);
    // console.log(sendData);
    // console.log("is image" + isImage);
    try {
        return await client.post("/dealer/order/book-service-add", sendData, {
            headers: {...headerImport},
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};

export const book_service_step_image_upload = async (formData) => {
    try {
        formData.append('device_type', requiredInput.device_type);
        formData.append('istoken', requiredInput.istoken);

        // console.log(formData)
        const {data, message, error} = await client.post("/dealer/order/book-service-add", formData, {
            headers: {
                Authorization: "Bearer " + authToken,
                'Content-Type': 'multipart/form-data'
            },
        });
        // console.log("Form upload");
        // console.log(data);
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const get_bookings = async ({id, search_text, status, token_order, user_type, limit, offset}) => {
    // console.log("Booking")

    let requestParams = {
        login_user_id: id,
        login_user_type: user_type || "client"
    }
    if (token_order) {
        requestParams = {...requestParams, token_booking: token_order}
    } else {
        requestParams = {...requestParams, limit: limit, offset: offset}
    }
    if (status && status !== "") {
        requestParams.status = status;
    }
    if (search_text && search_text !== "") {
        requestParams.search_text = search_text;
    }
    const details = {...requiredInput, ...requestParams};
    // console.log(details);
    try {
        if (ajaxRequest) {
            ajaxRequest.cancel();
        }
        ajaxRequest = axios.CancelToken.source();
        return await client.post("/dealer/order/book-service", details, {
            headers: {...fetchHeader},
            cancelToken: ajaxRequest.token
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};
export const get_booking_dashboard = async ({id, user_type}) => {
    // console.log("Dashboard");

    let requestParams = {
        login_user_id: id,
        login_user_type: user_type || "client"
    }

    const details = {...requiredInput, ...requestParams};
    // console.log(details);
    try {
        const {data} = await client.post("/dealer/front-booking-dashboard", details, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};
export const get_order_part = async ({id, search_text, status, token_order, user_type, limit, offset}) => {
    // console.log("Order Part")

    let requestParams = {
        login_user_id: id,
        login_user_type: user_type || "client"
    }
    if (token_order) {
        requestParams = {...requestParams, token_order: token_order}
    } else {
        requestParams = {...requestParams, limit: limit, offset: offset}
    }
    if (status && status !== "") {
        requestParams.status = status;
    }
    if (search_text && search_text !== "") {
        requestParams.search_text = search_text;
    }


    const details = {...requiredInput, ...requestParams};
    // console.log(details, search_text, status);
    try {
        if (ajaxRequest) {
            ajaxRequest.cancel();
        }
        ajaxRequest = axios.CancelToken.source();

        return await client.post("/dealer/order/purchase", details, {
            headers: {...fetchHeader},
            cancelToken: ajaxRequest.token
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        // if (error.message === 'cancelled') return {error: ""};
        return {error: error.message || error};
    }
};

export const booking_cancel = async ({id, user_type, token_booking, remark}) => {
    // console.log("Booking Cancel")

    const requestParams = {
        token_order: token_booking,
        action_type: 'cancel_order',
        remark: remark || "",
        login_user_id: id,
        login_user_type: user_type || "client"
    }
    const details = {...requiredInput, ...requestParams};
    // console.log(details);
    try {
        const data = await client.post("/dealer/order/book-service-action", details, {
            headers: {
                Authorization: "Bearer " + authToken,
                accept: "application/json",
            },
        }).then((r) => r.data);
        // console.log(data);
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};

export const jobsheet_action = async (requestParams) => {
    // console.log("Booking Cancel")

    const details = {...requiredInput, ...requestParams};
    // console.log(details);
    try {
        const data = await client.post("/dealer/order/job-sheet-action", details, {
            headers: {
                Authorization: "Bearer " + authToken,
                accept: "application/json",
            },
        }).then((r) => r.data).catch((e) => {
            // console.log(e)
            if (e?.response?.data) {
                return e.response.data;
            } else {
                return {
                    "error": true,
                    "message": e.message + ". Please try after sometime.",
                    "data": null
                }
            }
        });
        // console.log(data);
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response?.data;

        return {
            error: true,
            data: null,
            message: error.message || error
        };
    }
};