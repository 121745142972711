import { React, useEffect, useState } from "react";
import { useAuth, useNotification } from "../../hooks";
import { InputField, InputFieldPassword } from "../InputFields/InputFieldHelper";
import { Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useCountdown } from "../../hooks/useCountdown";
// import CountdownTimer, {ShowCounter} from "../../hooks/CountdownTimer";
import { Link, useNavigate } from "react-router-dom";
import CountdownTimer, { ShowCounter } from "../../hooks/CountdownTimer";
import { sendOtp } from "../../api/auth";
import { isAppTempDown, isAppOTP } from "../../api/client";
import { CircularProgress } from "@mui/material";

const validateUserInfo = (mobile) => {
    if (!mobile.trim()) return { ok: false, error: "Mobile number is missing!" };
    // if (!isValidEmail(email)) return {ok: false, error: "Invalid email!"};
    if (!isValidMobile(mobile)) return { ok: false, error: "Invalid mobile! Mobile number must be 10 digit number" };

    return { ok: true };
};
const basicInput = {
    device_type: "web",
    istoken: "ISABCDEFGH",
    device_token: localStorage.getItem('device_token') || "ABCDEFGH",
}

export default function NavigatePage() {
    const [userInfo, setUserInfo] = useState({
        username: "",
        mobile: "",
        email: "",
        otp: "",
        reason: "",
    });

    const { verifyUser, authInfo, sendUserOtp } = useAuth();
    const [otp, setOtp] = useState();
    const [otpVisible, setOtpVisible] = useState(false);
    const [btnText, setBtnText] = useState("Verify Mobile");
    const [btnTextPrev, setBtnTextPrev] = useState("Verify Mobile");
    const [countTime, setCountTime] = useState((1000 * 60 * 3));
    const [loading, setLoading] = useState(false)

    const [dateCounterEnd, setDateCounterEnd] = useState(Date.now());
    console.log("sessiondata--", JSON.parse(localStorage.getItem("user-register")))
    const { showError, showSuccess } = useNotification();

    const handleChange = async ({ target }) => {
        const { value, name } = target;
        setUserInfo({ ...userInfo, [name]: value });
    };
    const resendOtp = async (e) => {
        setBtnText("Verifying...");
        setLoading(true)
        const { sendStatus, data } = await sendUserOtp({ ...basicInput, ...userInfo });
        // console.log(data);

        const timeSet = new Date().getTime() + countTime;
        // console.log(data);
        await setDateCounterEnd(timeSet);
        if (sendStatus) {
            if (isAppOTP) {
                await setUserInfo({ ...basicInput, ...userInfo, otp: data?.otp });
                setOtp(data.otp);
            }
            if (data?.type && userInfo.username) {
                if (data.type === 'email') {
                    await setUserInfo({ ...basicInput, ...userInfo, email: data?.username });
                } else {
                    await setUserInfo({ ...basicInput, ...userInfo, mobile: data?.username });
                }
            }
        }

        await setBtnText("Verify OTP");
        await setBtnTextPrev("Verify OTP");
        await setOtpVisible(true);

        setLoading(false)
    }
    useEffect(() => {
        setUserInfo({ ...basicInput, ...userInfo, otp: otp });
    }, [otp])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setBtnTextPrev(btnText);
        setBtnText("Verifying...");
        setLoading(true)

        if (!otpVisible) {
            // console.log("Basic input",basicInput)
            const { ok, error } = validateUserInfo(userInfo.username);
            // console.log("Verify User", ok, error);
            if (!ok) {
                setBtnText(btnTextPrev);
                return showError(error);
            }
            // setBtnText(btnTextPrev);
            const { notVerified, data } = await verifyUser({ ...basicInput, ...userInfo });
            // console.log("Verify User")
            // console.log(data);
            setBtnText(btnTextPrev);
            if (notVerified) {
                await resendOtp(e);
            }
        }
        if (otpVisible) {
            const { ok, error } = validateUserInfo(userInfo.username);

            if (!ok) {
                setBtnText(btnTextPrev);
                return showError(error);
            }
            var sendData = { ...basicInput, ...userInfo };
            // console.log(sendData)
            await sendUserOtp(sendData, "verification-before-registration");
        }
        setLoading(false)
        setBtnText(btnTextPrev);
    };
    // useEffect(() => {
    //     // console.log(userInfo);
    //     // console.log(localStorage.getItem("prevLocation"))
    // });

    return (
        <section className="section">
            <div className="col-12 col-md-8 col-lg-6 col-xl-7 temp2">
                <div className="card shadow-2-strong auth-card ">
                    <div className="card-body temp">
                        <p className=" text-center auth-heading">
                            Log/Sign in to your Account
                        </p>
                        <p className="text-center mb-5">
                            Book A Repair, purchase parts and get the best offers.
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <InputField type={'number'} minLength={10} name={'username'}
                                label={'Mobile Number'} value={userInfo?.username}
                                onChange={handleChange} disabled={otpVisible || false}
                                appendIcon={otpVisible ? <FaEdit /> : ""}
                                appendUrl={'/auth'} setOtpVisible={setOtpVisible} />

                            {otpVisible && (

                                <>

                                    <InputFieldPassword name={'otp'} label={'OTP'} value={userInfo?.otp || otp || ""}
                                        minLength={4}
                                        onChange={handleChange} placeholder={'Valid OTP'} />
                                    <CountdownTimer targetDate={dateCounterEnd} resendOtp={resendOtp} />
                                </>
                            )}
                            <button disabled={loading} type="submit" className="btn btn-primary1 my-3 "> {loading && <CircularProgress size={16} color={"inherit"} />}&nbsp; Verify Mobile
                                {/* {btnText} */}
                            </button>
                        </Form>
                        <p className="text-center">
                            Back to home?
                            <Link to={"/"} style={{ color: "#EE610F" }}> Home</Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export const isValidEmail = (email) => {
    const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return isValid.test(email);
};
export const isValidMobile = (mobile) => {
    return mobile.length === 10;
};
