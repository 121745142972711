import React, {useEffect} from "react";
import { Card, Col, Container, Row} from "react-bootstrap";
import img from "../../components/asset/noimage.webp"
// import {
//     Box,
//     CircularProgress,
//     IconButton,
//     InputBase,
//     Paper,
// } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import {useState} from "react";
// import {BsFillEnvelopeFill, BsFillGeoAltFill, BsFillStopwatchFill, BsTelephone, BsTelephoneFill} from "react-icons/bs";
import {useAuth, useNotification} from "../../hooks";
import {ApiCall} from "../../api/HelpingMethod";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import noitem from "../asset/Comp 2_00000.jpeg"
import {HelmetCustomInclude} from "../HelmetCustomInclude";
import {showAdBanners} from "../../api/client";
import {useParams} from "react-router";
import {CCardBody} from "@coreui/react";


const FranchiseDetail = () => {
    const [data, setData] = useState([])
    const {authInfo} = useAuth();
    const [loading, setLoading] = useState(true)
    const {slugFranchise} = useParams();
    const {showError} = useNotification();

    const [bodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "slug_franchise": slugFranchise
    })

    const getData = async () => {
        setLoading(true)
        const response = await ApiCall("dealer/franchise", {...bodyData})

        if (response?.error === false) {
            setData(response?.data)
        } else {
            showError(response?.error)
        }
        setLoading(false)
    }

    useEffect(() => {
        getData();
        getBannerAdCall();
    }, []);


    const [bannerTop, setBannerTop] = useState(null);
    const [bannerBottom, setBannerBottom] = useState(null);

    const getBannerAdCall = async () => {
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_franchise_detail_top_page_banner"
        }).then((r) => {
            setBannerTop(r?.data);
        });
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_franchise_detail_bottom_page_banner"
        }).then((r) => {
            setBannerBottom(r?.data);
        });
    }
    const redirectBanner = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (
        <>
            <HelmetCustomInclude url={"franchise/"} title={"Franchise Detail"} image={null}/>
            <div className="ourservices">
                <h3 className=" mx-lg-5 servicesHeading">Franchise: {data?.name}, {data?.name_city}</h3>
                <p className="mx-lg-5 subHeading">

                </p>
            </div>
            <Container className={"my-5"}>
                {showAdBanners && bannerTop?.url_image && <Row className='m-0'>
                    <Col>
                        <img onClick={() => redirectBanner(bannerTop?.url_redirect)}
                             src={bannerTop?.url_image || noitem} className='w-100 banner-image pointer'
                             alt={'no advertisement'}/>
                    </Col>
                </Row>}

                {loading &&
                <Row className="mt-2 ms-0 me-0"> <SkeletonFileGeneral height={350} col={6} total={2}/>
                </Row>}
                {!loading && <>
                    <Row>
                        <Col sm={12} md={6}>
                            <Card className={'shadow w-100 mb-5'}>
                                <CCardBody>
                                    <Row xs={{cols: 1}} className={'g-3'}>
                                        <Col> <span className="clientDetail">{data?.name}</span></Col>
                                        <Col><b>Mobile Number: </b> <span
                                            className={'text-end float-end'}>+91 {data?.mobile}</span></Col>
                                        <Col><b>Email Address: </b> <span
                                            className={'text-end float-end'}>{data?.email}</span></Col>
                                        <Col><b>Open Days: </b> <span
                                            className={'text-end float-end'}>{data?.open_day}</span></Col>
                                        <Col><b>Open Hours: </b> <span
                                            className={'text-end float-end'}>{data?.open_hour}</span></Col>
                                        <Col><b>State: </b> <span
                                            className={'text-end float-end'}>{data?.name_state}</span></Col>
                                        <Col><b>City: </b> <span
                                            className={'text-end float-end'}>{data?.name_city} {data?.pincode}</span></Col>
                                        <Col><b>Address: </b> <span
                                            className={'text-end float-end'}>{data?.address}</span></Col>
                                    </Row>
                                </CCardBody>
                            </Card>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className="mapouter mb-5">
                                <div className="gmap_canvas">
                                    <iframe width="100%" height="350" id="gmap_canvas"
                                            src="https://maps.google.com/maps?q=iService%3A+Mobile%2C+Laptop%2C+TV%2C+Camera%2C+Drone%2C+Printer%2C+Gaming+Consoles+Repair%2C+A%2C+Jyoti+Talkies+Shopping+Complex%2C+Jyoti+Talkies%2C+Main+Rd+1%2C+Zone-I%2C+Maharana+Pratap+Nagar%2C+Bhopal%2C+Madhya+Pradesh+462011&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                            frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>}

                {showAdBanners && bannerBottom?.url_image && <Row className='mt-3 ms-0 me-0 ms-sm-5 me-sm-5'>
                    <Col>
                        <img onClick={() => redirectBanner(bannerBottom?.url_redirect)}
                             src={bannerBottom?.url_image || noitem} className='w-100 banner-image pointer'
                             alt={'no advertisement'}/>
                    </Col>
                </Row>}


            </Container>

        </>
    )
}

export default FranchiseDetail;