import {useCountdown} from "./useCountdown";

const DateTimeDisplay = ({value, type, isDanger}) => {
    let div = <div className={isDanger ? 'countdown danger' : 'countdown'}>
        <b>{value.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })}</b> {/*<span>{type}</span>*/}
    </div>;
    return div;
};
const ExpiredNotice = ({resendOtp}) => {

    return (
        <div className="expired-notice">
            <span className={'color-primary'} style={{cursor: "pointer"}} onClick={resendOtp}  >Resend OTP</span>
        </div>
    );
};

const ShowCounter = ({days, hours, minutes, seconds}) => {
    return (
        <div className="show-counter d-flex flex-row ">
            <p className={'px-2'}>OTP Resend in</p>
            {days > 0 && (
                <>
                    <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3}/>:
                </>
            )}
            {hours > 0 && (
                <>
                    <DateTimeDisplay value={hours} type={'Hours'} isDanger={hours <= 3}/>:
                </>
            )}
            <DateTimeDisplay value={minutes} type={'Minutes'} isDanger={minutes <= 3}/>:
            <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false}/>
        </div>
    );
};

const CountdownTimer = ({targetDate, resendOtp}) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice resendOtp={resendOtp}/>;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};
export {ShowCounter};
export default CountdownTimer;