import React, {useState, useEffect} from "react";
import ServiceSection from "../HomePage/ServiceSection";
import BrandsRepairSection from "./BrandsRepairSection";
import Header from "./Header";
import {useLocation, useSearchParams} from "react-router-dom";
import {category_slug} from "../../api/general";
import {Container} from "@mui/material";
import {cropText, getFullPageTitle} from "../../hooks/useCustomHelpingMethod";
import {Helmet} from "react-helmet";
import {appFooterLogo} from "../../api/constant";
import {useParams} from "react-router";
import {useNotification} from "../../hooks";
import {HelmetCustomInclude} from "../HelmetCustomInclude";


export default function AboutRepair() {
    const [pageTitle, setPageTitle] = useState("Repair");
    const [metaKeywords, setMetaKeywords] = useState(null);
    const [metaDescription, setMetaDescription] = useState(null);
    const [metaImageUrl, setMetaImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // const locationTemp = useLocation();
    // if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
    //     localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
    // }
    const [categoryDetails, setCategoryDetails] = useState([]);
    const {slugCategory} = useParams();
    const {showError, showSuccess} = useNotification();
    const getData = async () => {
        setIsLoading(true);
        const {data} = await category_slug({category_slug: slugCategory});
        if (data?.data) {
            if (data?.data?.title) setPageTitle(data?.data?.title);
            if (data?.data?.page_keywords) setMetaKeywords(data?.data?.page_keywords);
            if (data?.data?.page_description) setMetaDescription(data?.data?.page_description);
            setCategoryDetails(data.data);
        } else {
            showError(data?.message);
        }
        setIsLoading(false); 
    };
    useEffect(() => {
        getData().then(r => r);
    }, [slugCategory]);

    return (
        <>
            {/* <Helmet>
                <title>{getFullPageTitle(pageTitle || "")}</title>
                <meta property="og:title" content={getFullPageTitle(pageTitle || "")}/>
                <meta property="og:url" content={window.location.href}/>
                <meta name="keywords" content={metaKeywords || ""}/>
                <meta name="description" content={cropText(metaDescription || "", 200)}/>
                <meta property="og:description" content={cropText(metaDescription || "", 200)}/>
                <meta property="og:image" content={metaImageUrl || appFooterLogo}/>
            </Helmet>*/}
            <HelmetCustomInclude url={"product/category/" + slugCategory} description={metaDescription}
                                 keywords={metaKeywords} title={pageTitle} image={metaImageUrl}/>

            <div className={'pb-5'}>

                <div className="ourservices">
                    <Container>
                        <h3 className=" servicesHeading">{categoryDetails.title} Repair</h3>
                        <p className=" subHeading">
                            {/*We at iService believe in giving our best to our customers. That's the reason we don't compromise on quality*/}
                            Experience a high-quality repair with an experienced technician in the comfort of your own
                            home
                            or office.

                        </p>
                    </Container>
                </div>
                <Container className={'my-2'}>
                    <ServiceSection category_id={categoryDetails.id} title={categoryDetails.title} isLoading={isLoading}
                                    basePath={'/book-repair?category-slug=' + (categoryDetails?.slug) + '&brand-slug='}/>

                    <Header title={categoryDetails.title} description={categoryDetails.description}
                            img={categoryDetails.url_icon} isLoading={isLoading}/>


                </Container>
            </div>
        </>
    );
}
