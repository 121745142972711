import React from 'react'

export default function ServiceCategories() {
  return (
    <div className="card service-categories-card ">
    <img className="card service-categories-card-image" src="banner2.webp" alt="Card cap"/>
      
     <div className="service-categories-card-body">
         Camera Repair
      </div>
    </div>
  )
}
