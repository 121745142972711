import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


// import "../mainContainer.css";
import { Location } from "./Success";
import { ProblemPageForm } from "./ProblemPageForm";
import BookingBody from "./JobSheetBody";
import JobSheetBody from "./JobSheetBody";
import { NeedService } from "./NeedService";
import { DevicePageForm } from "./DevicePageForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth, useNotification } from "../../hooks";
import { get_job_sheet } from "../../api/jobsheet";
import { Col, Container, Row } from "react-bootstrap";


const steps = ["Client", "Device", "Problem", "Success"];

export default function HorizontalBookLine() {
    const [searchParems] = useSearchParams()

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [token, setToken] = useState(searchParems.get('token') || "");
    const [data, setData] = useState();
    const [brand, setBrand] = useState();
    const [model, setModel] = useState();
    const [iemi, setIemi] = useState();
    const navigate = useNavigate();
    const { showError, showSuccess } = useNotification();
    const { authInfo } = useAuth();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
    }, [activeStep])

    const isStepOptional = (step) => {
        return step === 1;
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const getJobsheetData = async (orderToken) => {
        if (orderToken) {
            const { data, error } = await get_job_sheet({
                id: authInfo.profile?.id,
                user_type: authInfo.profile?.user_type,
                token_order: orderToken
            });
            if (error) return showError(error)
            setData(data.data)
            updateActiveStep(data?.data?.step || '')
        }
    }
    useEffect(() => {
        if (token && token !== "") {
            getJobsheetData(token);
        }


    }, [token])


    const updateActiveStep = (step) => {
        if (step === 'completed') {
            setActiveStep(3);
        } else if (step === 'client-info') {
            setActiveStep(1);
        } else if (step === 'device-problem') {
            setActiveStep(2);
        } else {
            setActiveStep(0);
        }
    }
    return (
        <Container>
            <Row>
                <Col >
                    <Box className="boxContainer mt-sm-5 mt-1  pt-3">
                        <Stepper activeStep={activeStep} className="stepLabel">
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = <Typography variant="caption" />;
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }

                                return (
                                    <Step key={label} {...stepProps} className="steplabel">
                                        <StepLabel {...labelProps}><span className="d-sm-flex d-none">{label}</span></StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Box>
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {activeStep === 0 &&
                                    <JobSheetBody token={token} data={data} handeNext={handleNext} setToken={setToken}
                                        setData={setData} />}
                                {activeStep === 1 &&
                                    <DevicePageForm setData={setData} setToken={setToken} token={token} data={data} handleBack={handleBack} handeNext={handleNext}
                                        setModel={setModel} setBrand={setBrand} iemi={iemi} setIemi={setIemi} />}
                                {activeStep === 2 &&
                                    <ProblemPageForm setToken={setToken} handeNext={handleNext} handleBack={handleBack} token={token} model={model}
                                        brand={brand} iemi={iemi} data={data} />}
                                {activeStep === 3 &&
                                    <Location token={token} model={model} brand={brand} iemi={iemi} handeNext={handleNext} />}
                                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                                <Box
                                    className="buttonFootercontainer"
                                // sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                                >
                                    <div className="container  mt-2  d-flex justify-content-center align-items-center">
                                        <div className="btn-container">
                                            <Box />
                                        </div>
                                    </div>
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                </Col>
            </Row>
        </Container>

    );
}
