import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { cropText } from "../hooks/useCustomHelpingMethod";

const ReadMore = ({ data, defaultsize }) => {
    const [textLength, setTextLenght] = useState(defaultsize)
    const [isExpend, setIsExpend] = useState(false)
    const [isReadMore, setIsReadMore] = useState(false)

    const readMore = (size, value) => {
        setTextLenght(size)
        setIsExpend(value)
    }

    useEffect(() => {
        if (data) {
            if (data?.length > defaultsize) {
                setIsReadMore(true);
            }
        }
    }, []);

    return (
        <>
            {cropText(data, textLength)} {isReadMore && (!isExpend ? <div className="colorprimary pointer text-decoration-underline" onClick={() => readMore(data?.length, true)}> <small><b>read more</b></small></div> : <div className="colorprimary pointer text-decoration-underline" onClick={() => readMore(defaultsize, false)}><small><b>read less</b></small></div>)}
        </>
    )
}
export default ReadMore