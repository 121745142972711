import { CBadge, CCard, CCardBody } from "@coreui/react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { get_color_by_status, get_date_formatted, get_status_formatted } from "../../hooks/useCustomHelpingMethod";

export function BookingListCard({ data, link }) {
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) navigate(link);
        else navigate("/booking");
    }
    const colorBadge = get_color_by_status(data?.status);

    console.log("data----", data)

    return (
        <>
            <CCard className={'m-1 shadow card-link'} onClick={handleClick}>
                <CCardBody>
                    <Row className={'g-2 overflow-hidden'}>
                        <Col sm={8}>
                            <h6 className={"text-uppercase"}>#{data?.token}</h6>
                            <small
                                className={'mt-2'}>{data?.device_info?.title_model || data?.product_title}</small>
                            <small
                                className={'d-block text-muted '}>{data?.device_info?.title_brand} {data?.device_info?.title_category ? ", " + data?.device_info?.title_category : ""}</small>
                        </Col>
                        <Col sm={4} className={'text-end'}>
                            <CBadge color={colorBadge} style={{
                                border: "1px solid",
                                borderColor: colorBadge,
                                color: colorBadge
                            }}>{get_status_formatted(data?.status)}</CBadge><br />
                            {/* <small className={'d-block mt-2'}>{get_date_formatted(data?.created_at)}</small> */}
                            <p className={'d-block  m-0 mt-2 fw-medium'}>{data?.datetime_formatted_long}</p>
                            {data?.tracking_url ? <small className="colorprimary text-decoration-underline " onClick={() => window.open(data?.tracking_url, "_blank")} >Track Order</small> : <></>}


                        </Col>
                    </Row>
                </CCardBody>
            </CCard>
        </>
    );
}