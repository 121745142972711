import {Box, Paper, Tab, Tabs, Typography} from "@mui/material";
import {React, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import PropTypes from 'prop-types';


function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}







export function StepZeroSelectUserType({ showError, showSuccess, regStep, setRegStep, regUserType, setRegUserType }) {
    const [userType, setUserType] = useState(regUserType || "client");
    const [btnText, setBtnText] = useState("Next");
    const [value, setValue] = useState(0);


    function handleSubmit(e) {
        e.preventDefault();
        setBtnText("Processing...");
        if (!userType) {
            setBtnText("Next");
            return showError("Please select user type");
        } else {
            setRegUserType(userType);
            setRegStep(1);
            showSuccess("You are registering with us as " + regUserType);
        }
    }

    function handleSelect(e) {
        setUserType(e.target.value);
    }

    const handleChangeTab = (e, index) => {
        console.log("valll1----", e)
        setUserType(e);
        setValue(index);
    };

    const userTypes = [
        {
            id: 1,
            title: "Customer",
            value: 'client'
        }, {
            id: 1,
            title: "Dealer",
            value: 'dealer'
        }
    ];

    return (
        <>
            <Container className={""}>
                <Form onSubmit={handleSubmit}>
                    <Row xs={{ cols: 1 }}>
                        <Col className={'align-items-center text-center'}>
                            <h4 className={'mb-4'}>You want to register with us as : </h4>
                            <div className="row">
                                {/* {userTypes.map((element, idx) => {
                                    return (
                                        <button key={idx} onClick={handleSelect} value={element.value} type="button"
                                            className={`Selectionbtn col m-2 ${element.value === userType ?
                                                'buttonStyle2' : ''}`}> {element.title}</button>
                                    );
                                })} */}


                                <Col className="login-user-type-tabs">
                                    <Tabs className="login-switch" textColor="white" variant="fullWidth" value={value} aria-label="basic tabs example" centered>
                                        {
                                            userTypes.map((user, index) => {
                                                return (
                                                    <Tab
                                                        onClick={() => handleChangeTab(user?.value, index)}
                                                        // sx={userType === user?.value ? { backgroundColor: 'var(--primary)' } : { backgroundColor: "none" }}
                                                        label={user?.title}
                                                        className={userType === user?.value ? '  user-type-active-tab' : 'ms-2 text-dark '} />
                                                )
                                            })
                                        }
                                        {/* <Tab
                                            sx={value == 0 ? { backgroundColor: 'var(--primary)' } : { backgroundColor: "none" }}
                                            label="Maker" {...a11yProps(0)}
                                            className={value == 0 ? 'text-light' : 'ms-2 text-dark '} />
                                        <Tab sx={value == 1 ? { backgroundColor: 'var(--primary)' } : { backgroundColor: "none" }}
                                            label="Submitter"
                                            {...a11yProps(1)} className={value == 1 ? 'text-light' : 'ms-2 text-dark '} /> */}
                                    </Tabs>
                                </Col>

                            </div>
                        </Col>

                        <Col className="my-2">
                            <CustomTabPanel value={value} index={0}>
                                <Paper variant="outlined" elevation={3}>
                                    <Row xs={{cols: 1}} className="p-3">
                                        <Col>
                                            <p><small>This registration is exclusively for end users or customers who want to
                                                book service and buy parts directly from us.</small></p>
                                            <p> <small>This website requires location permission from your side to have the
                                                best experience with us. If you blocked location permission then please
                                                allow it.</small></p>
                                        </Col>
                                    </Row>
                                </Paper>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Paper variant="outlined" elevation={3}>
                                    <Row xs={{cols: 1}} className="p-3">
                                        <Col>
                                            <p><small>This registration is exclusively for technicians, resellers, and bulk
                                                buyers. <br/>
                                                To register for wholesale, <strong className={'color-primary'}>dealers need to upload any two of the
                                                    following documents</strong>:</small>
                                                <br/>
                                                <br/>
                                                {/*<br/>
                                                <b><small>Business Proof Required*:</small></b>
                                                <br/>
                                                <br/>*/}
                                                <small><ul>
                                                    <li>Shop / Business Photo</li>
                                                    <li>Shop / Business / Visiting Card Photo</li>
                                                    <li>Gumasta / Trade Licence</li>
                                                    <li>GST Certificate</li>
                                                    <li>Bill Book Photo</li>
                                                </ul></small>
                                                <small>Once verified, your dealer account will be activated, and you will be able to log in.</small> <br/>
                                                {/*This website required location permission from your side for your best
                                                experience with us. If you blocked location permission then please allow
                                                it.*/}</p>
                                        </Col>
                                    </Row>
                                </Paper>
                            </CustomTabPanel>

                        </Col>
                        <Col className="text-end">
                            <button type="submit" className="btn btn-primary1 my-4 mx-auto" name={'action'}
                                value={"Next"}>{btnText}</button>
                        </Col>
                    </Row>


                </Form>
            </Container>
        </>
    );
}