import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import HorizontalLine from "./HorizontalBookLine";
import HorizontalBookLine from "./HorizontalBookLine";

function JobSheetMain() {
    const locationTemp = useLocation();
   // if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
   //     localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
   // }
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Dealer Jobsheet | iService - Innovation Service';
    }, [])
    return (
        <>


            <HorizontalBookLine/>

            {/* <FooterNext prev="/" next="/BookingDetail1" /> */}

        </>
    );
}

export default JobSheetMain;