import {
    FormControl,
    InputLabel,
    MenuItem,
    Rating,
    Select
} from '@mui/material';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import img from "../asset/noimage.webp"
import { useState } from 'react';
import { useAuth } from '../../hooks';
import { ApiCall } from '../../api/HelpingMethod';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BsPersonCircle } from "react-icons/bs";
import SkeletonFileGeneral from '../../hooks/SkeletonFileGeneral';



export default function ReviewDetail() {
    const { authInfo } = useAuth();
    const { tokenpart } = useParams();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)


    const [bodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "ref_type": "part",
        "token_ref": tokenpart,
        "limit": 10,
        "offset": 0
    })


    const getReview = async () => {
        setLoading(true)
        const response = await ApiCall("dealer/user_review", bodyData)
        if (response?.error === false) {
            setData(response?.data)
        } else {
            setData()
        }
        setLoading(false)
    }

    useEffect(() => {
        getReview()
    }, []);


    const [review, setReview] = useState('');

    const handleChange = (event) => {
        setReview(event.target.value);
    };


    return (
        <div className=''>


            <Container className='mb-5 mt-5'>
                <Row className="text-center mt-4 h3">
                    <Col> Reviewes </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6} sm={7} md={9} lg={10}>
                        <span className="underline-text">
                            All Reviews
                        </span>
                    </Col>
                    <Col xs={6} sm={5} md={3} lg={2} className=''>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={review}
                                label="Sort By"
                                onChange={handleChange}
                                defaultValue='dfdfdfdfdf' >
                                <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                                <MenuItem value={'Positive First'}>Positive First</MenuItem>
                                <MenuItem value={'Nagitive First'}>Nagitive First</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                {loading && <Row className="mt-3"> <SkeletonFileGeneral height={50} col={12} total={5} /> </Row>}

                {!loading &&
                    data?.length >= 0 ? data && data.map((data) => {
                        return (
                            <>
                                <Row className="mt-5">
                                    <Col xs={1} className="text-end pe-0">
                                        <div style={{ height: "40px", width: "40px" }} className="ms-auto">
                                            <BsPersonCircle src={img} style={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50px"
                                            }} />
                                        </div>
                                    </Col>
                                    <Col xs={11} className="ps-sm-0 ps-3">
                                        <Container className='ps-0'>
                                            <Row>
                                                <Col>
                                                    <Container>
                                                        <Row>
                                                            <Col>{data?.user_name}</Col>
                                                            <Col
                                                                className="text-end">{data?.created_at}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className='review-list'>
                                                                <Rating
                                                                    name="simple-controlled"
                                                                    value={data?.count_rating} />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col>{data?.message}</Col>
                                                        </Row>
                                                    </Container>
                                                </Col>

                                            </Row>
                                        </Container>
                                    </Col>

                                </Row>
                            </>
                        )
                    }) : loading ? <> </> :<b className={'text-danger'}>No reviews available for this product</b>

                }


            </Container>
        </div>
    )


}