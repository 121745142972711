import React from 'react'
import banner from "../asset/banner.webp";

export default function HowCard({item}) {
    return (
        <div className="card howItWorks">
            <div className="card-body overflow-hidden">
                <img
                    onError={(e) => e.target.src = banner}
                    src={item.url_icon}
                    alt={item.title}
                    loading="lazy"
                    style={{maxWidth: "100%"}}
                />
                <hr/>
                {/*<div className="dot mx-1" style={{textAlign: "center"}}>{item?.sn || 1}</div>*/}
                <h4 className="card-title my-2 text-center">
                    <a>{item?.title || ""}</a>
                </h4>
                <h5 className={"text-muted text-uppercase my-2 text-center"}>Step {item?.sn || 1}</h5>
                {/*<p className="card-text ">*/}
                {/*    {item?.description || "Please provide the necessary details for book service for you. like select category, brand, model and imei number. and then provide address info."}*/}
                {/*</p>*/}
            </div>
        </div>
    )
}
