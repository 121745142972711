import React from 'react'

export default function FooterFeaturedBlockCard({icon, title, titleSub}) {
    return (
        <div className="card card-custom bg-color-secondary">
            <div className="card-body ">
                <div className='d-flex align-items-center'>
                    <span>
                        {icon || ""}
                    </span>
                    <p/>
                    <div className='mx-2 my-2'>
                        <h6>{title}</h6>
                        <small className='text-muted'>{titleSub}</small>
                    </div>
                </div>

            </div>
        </div>
    )
}
