import { React, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth, useNotification } from "../../hooks";

export default function ResetPassword() {
  const { state } = useLocation();
  const [valueInfo, setValueInfo] = useState ({
    password:"",
    confirmPassword:"",
  });

  const [userInfo, setUserInfo] = useState({
    device_type: "web",
    istoken: "ISABCDEFGH",
    id: state.id,
    values: {
      ...valueInfo
    },
  });

  const {  resetpassword } = useAuth();

  const { showError, showSuccess } = useNotification();
  const [btnText, setBtnText] = useState("Get Started");

  const handleChange = ({ target }) => {
    const { value, name } = target;
    setValueInfo({ ...valueInfo, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if(valueInfo.password = !valueInfo.confirmPassword){
    //     return showError("Confirm Password Mismatch")
    // }
 
        userInfo.values.password = valueInfo.password
        userInfo.values.confirmPassword = valueInfo.confirmPassword
        // console.log(userInfo)
        await resetpassword(userInfo)
    
  };

  return (
    <section className="section">
      <div className="col-12 col-md-8 col-lg-6 col-xl-7 temp2">
        <div className="card shadow-2-strong auth-card ">
          <div className="card-body temp">
            <p className=" text-center auth-heading">Change Password</p>
            <p className="text-center mb-5">
              Book A Repair, purchase parts and get the best offers.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="form-outline my-3">
                <label
                  className="form-label fw-bold auth-input-heading"
                  htmlFor="typePasswordX-2"
                >
                  Password
                </label>
                <input
                  onChange={handleChange}
                  value={valueInfo.password}
                  name="password"
                  type="password"
                  id="typePasswordX-2"
                  className="form-control form-control-lg"
                />
                <div className="d-flex justify-content-between"></div>
              </div>

              <div className="form-outline my-3">
                <label
                  className="form-label fw-bold auth-input-heading"
                  htmlFor="typePasswordX-5"
                >
                  Confirm Password
                </label>
                <input
                  onChange={handleChange}
                  value={valueInfo.confirmPassword}
                  name="confirmPassword"
                  type="password"
                  id="typePasswordX-5"
                  className="form-control form-control-lg"
                />
              </div>

              <button type="submit" className="btn btn-primary1 my-3 ">
                {btnText}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export const isValidEmail = (email) => {
  const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return isValid.test(email);
};
