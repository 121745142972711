import React, {useEffect, useState} from "react";
import Crouser from "./Crouser";
import {category_list} from "../../api/general";
import Crouser2 from "./Crouser2";
import {BeatLoader} from "react-spinners";
import {Row} from "react-bootstrap";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";

export default function Brands() {
    const [product_details, setProduct_details] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const {data} = await category_list({is_service: "yes"});
            setLoading(false);
            setProduct_details(data.data);
        };
        getData();
    }, []);

    return <div>

        {loading && <div className=" justify-content-center align-items-center px-5  "><Row><SkeletonFileGeneral
            height={140} total={5} col={2} mx={'auto'}/></Row></div>}

        {/* {loading && <div className="d-flex justify-content-center align-items-center p-5 mt-3">
         <BeatLoader color="#ee610f" size={25}/>
       </div>
    }*/}
        {!loading && <Crouser2 brands={product_details} basePath={'/book-repair?category-slug='} id={29}/>}
    </div>
}
