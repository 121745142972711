import { React, useEffect, useState } from "react";
import { useAuth } from "../../../hooks";
import { isValidEmail } from "../SignUpForm";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { InputField } from "../../InputFields/InputFieldHelper";
import { isValidMobile } from "../NavigatePage";
import { address_state, category_list } from "../../../api/general";
import ModalChangeAddress from "./ModalChangeAddress";
import { ApiCall } from "../../../api/HelpingMethod";
import { Autocomplete, TextField } from "@mui/material";
import ModalPickAddress from "./ModalPickAddress";

const validateUserInfo = ({ name, email, mobile, password }) => {
    const isValidName = /^[a-z A-Z]+$/;

    if (!name.trim()) return { ok: false, errorMessage: "Name is missing!" };
    // if (!isValidName.test(name)) return {ok: false, error: "Invalid name!"};

    if (email) {
        if (!email.trim()) return { ok: false, errorMessage: "Email is missing!" };
        if (!isValidEmail(email)) return { ok: false, errorMessage: "Invalid email!" };
    }
    if (mobile) {
        if (!mobile.trim()) return { ok: false, errorMessage: "mobile is missing!" };
        if (!isValidMobile(mobile)) return { ok: false, errorMessage: "Invalid mobile!" };
    }

    return { ok: true };
};

export function StepTwoBusinessDetail({
    basicData,
    setRegUserToken,
    regUserToken,
    handleNext,
    pickLocation,
    setPicLocation,
    previousData,
    setPreviousData,
    showError,
    showSuccess,
    user_id,
    regUserType,
    setActiveStep,
    setRegStep
}) {
    const { createUser, sendUserOtp, authInfo } = useAuth();
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Next')
    const [addressStates, setAddressStates] = useState([]);
    const [addressCities, setAddressCities] = useState([]);
    const [addressData, setAddressData] = useState(null);
    const [category_details, setCategory_details] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([])


    const [userInfo, setUserInfo] = useState({
        token_user: regUserToken,
        step: 'business',
        categery: "",
        name: previousData?.business?.name || "",
        name_state: previousData?.business?.name_state || "",
        name_city: previousData?.business?.name_city || "",
        address: previousData?.business?.address || "",
        pincode: previousData?.business?.pincode || "",
        step: "business",
        latitude: "27.30",
        longitude: "72.34",
    });
    useEffect(() => {
        setUserInfo({
            name: userInfo.name || previousData?.business?.name_city,
            name_state: addressData?.name_state || previousData?.business?.name_state,
            name_city: addressData?.name_city || previousData?.business?.name_city,
            address: addressData?.address || previousData?.business?.address,
            pincode: addressData?.pincode || previousData?.business?.pincode,
            latitude: addressData?.device_latitude || previousData?.business?.latitude,
            longitude: addressData?.device_longitude || previousData?.business?.longitude,
            step: "business",
            token_user: regUserToken,
        })
    }, [addressData])

    const handleChange = ({ target }) => {
        const { value, name } = target;
        if (name === 'name_state') {
            getCityName(value);
        }
        setUserInfo({ ...userInfo, [name]: value });

    };

    const handleChangeCategory = (e, categery) => {
        let catAR = categery.map((cat) => cat?.title || "");
        setSelectedCategory(catAR)
    }
    const getCityName = async (stateName) => {
        const data = await address_state({
            login_user_id: authInfo.profile.id || 0,
            login_user_type: authInfo.profile.user_type || "client",
            type: "name_city",
            name_state: stateName || "",
            offset: 0,
            limit: 10000
        }).then((response) => response.data);
        if (data?.error) {
            setAddressCities([]);
        } else {
            setAddressCities(data.data);
        }
    };


    useEffect(() => {
        console.log(category_details);
    }, [category_details]);

    const getStateNames = async () => {
        const { data } = await address_state({
            login_user_id: authInfo.profile.id || 0,
            login_user_type: authInfo.profile.user_type || "client",
            type: "name_state",
            offset: 0,
            limit: 1000
        });
        setAddressStates(data.data);
    };

    const getCategories = async (inputData) => {
        const { data } = await category_list({ "offset": 0, "limit": 0 });

        if (data.data && Array.isArray(data.data)) {
            setCategory_details(data.data)
            // let businesType = [];
            // data.data.forEach(element => {
            //     businesType.push(element?.title);
            // });
            // setCategory_details(businesType || []);
        }
        return data;
    };

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        getStateNames();
        // getCityName();
    }, [])
    useEffect(() => {
        // console.log(addressStates)
        // console.log(userInfo);
    })

    const handleSubmit = async (event) => {

        setButtonName("loading...");
        event.preventDefault();

        userInfo.type = selectedCategory


        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Submit");
            event.stopPropagation();
        } else {
            const { ok, errorMessage } = validateUserInfo(userInfo);
            if (!ok) {
                setButtonName("Submit");
                return showError(errorMessage);
            }
            // let businesType = [];
            // if (selectedCategory && Array.isArray(selectedCategory) && selectedCategory.length > 0) {
            //     selectedCategory.forEach(element => {
            //         businesType.push(element?.title);
            //     });
            // }

            // console.log(selectedCategory, businesType);
            // return;
            // let { data, message, error } = await createUser(userInfo);
            const response = await ApiCall("dealer/user_profile_register_multi_step", { ...userInfo })
            console.log("response---", response);
            console.log("userInfo---", userInfo);
            setPreviousData(response?.data)
            setRegUserToken(response?.data?.token)
            if (response.error) {
                showError(response?.message);
                setButtonName("Submit");
                return
            } else {
                setUserInfo({ ...userInfo, token_user: response?.data?.token })
                setRegStep(3);
                handleNext();
            }
        }
        setValidated(true);
        setPicLocation(true)
    };

 




    return (
        <Form onSubmit={handleSubmit} className="needs-validation" noValidate validated={validated}>


            {pickLocation && <Container>
                <Row>
                    <Col sm={6}>
                        <InputField type={'text'} minLength={3} name={'name'}
                            label={'Shop Name'} value={userInfo?.name}
                            onChange={handleChange} required={true} placeholder={'Please enter business name'} />
                    </Col>
                    <Col sm={6}>
                        <label className="fw-normal">Categery</label>
                        <Autocomplete
                            filterOptions={(category) => category}
                            className="register-category"
                            size={'small'}
                            multiple
                            onChange={handleChangeCategory}
                            name="business_type"
                            id="tags-register-category"
                            // options={category_details.map((category) => category?.title||"")}
                            options={category_details}
                            // getOptionLabel={(option) => option}
                            getOptionLabel={(option) => option?.title}
                            filterSelectedOptions
                            renderInput={(params, index) => (
                                <TextField key={"cat-" + index}
                                    {...params} label=""
                                    placeholder="Select Category" />)}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <ModalPickAddress pickLocation={pickLocation} setPicLocation={setPicLocation} addressData={addressData} setAddressData={setAddressData} />
                </Row>
                <Row>
                    <Col >
                        <InputField type={'textarea'} name={'address'}
                            label={'Address'} value={userInfo?.address} placeholder={'Please enter business proper address'}
                            onChange={handleChange} required={true} />
                    </Col>
                </Row>

                <Row>
                    <Col >
                        <InputField type={'number'} min={100000} max={999999} name={'pincode'}
                            label={'Pincode'} value={userInfo?.pincode}
                            placeholder={'Please enter pincode'}
                            onChange={handleChange} required={true} />
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <InputField type={'text'} minLength={3} name={'name_city'}
                            label={'City'} value={userInfo?.name_city}
                            placeholder={'Please enter business establish in city'}
                            onChange={handleChange} required={true} options={addressCities} />
                    </Col>
                    <Col sm={6}>
                        <InputField type={'text'} minLength={3} name={'name_state'}
                            label={'State'} value={userInfo?.name_state}
                            placeholder={'Please enter business establish in state'}
                            onChange={handleChange} required={true} options={addressStates} />
                    </Col>

                </Row>

            </Container>}

            {!pickLocation && <Container className="mt-5 mb-5 d-flex justify-content-center align-items-center register-pick-location-container">
                <Row className="mt-5 mb-5">
                    <Col className="">
                        <ModalChangeAddress   pickLocation={pickLocation} setPicLocation={setPicLocation} setAddressData={setAddressData} />
                    </Col>
                </Row>
            </Container>}


            <Form.Group>

                <Row>
                    <Col>
                        <Button type={'button'} className="button-2 float-left my-3"
                            onClick={() => {
                                setRegStep(1)
                                setActiveStep(0)
                            }}>{"Previous"}</Button>
                    </Col>
                    <Col className="text-end">
                        <Button type="submit" variant="" className="btn btn-primary1 my-3 ">{btnName}</Button>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
}