import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    InputGroup,
    Navbar,
    Offcanvas,
    Row
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "./asset/logo.webp"
import { category_list } from "../api/general";
import SearchIcon from '@mui/icons-material/Search';
import "./styles/AuthNav.css";
import banner from "./asset/banner.webp";
import { Avatar, Badge } from "@mui/material";
import { useAuth } from "../hooks";
import SignUpButton from "./SignUpButton";
import SignButton from "./SignButton";
import { ShoppingCart } from "@mui/icons-material";
import { UseScroll } from "./UseScroll";
import Navs from "./Navs";
import UserDrop from "./UserDrop";
import Notification from "./Notification";
import { cart } from "../api/cart";
import { FaSearch } from "react-icons/fa";


const Header = ({ }) => {
    const pathArray = window.location.pathname.split("/");
    const [categoryList, setCategoryList] = useState('All');
    const [searchText, setSearchText] = useState("");
    const [selectedCategorySlug, setSelectedCategorySlug] = useState('All');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [isLogin, setIsLogin] = useState(false);
    const { authInfo } = useAuth();
    const [countCart, setCountCart] = useState(0);
    const { scrollDirection } = UseScroll();
    const [isActiveMenu, setActiveMenu] = useState('home');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (pathArray[1]) {
            setActiveMenu(pathArray[1]);
        } else {
            setActiveMenu('home');
        }
        // console.log(pathArray)
    }, [pathArray, authInfo]);

    useEffect(() => {
        if (authInfo?.profile && Object.keys(authInfo?.profile).length > 0) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, [authInfo, isLogin])


    useEffect(() => {
        const getUsers = async () => {
            const { data } = await category_list({ is_part: "yes" });
            setCategoryList(data.data);
        };
        getUsers();


    }, []);


    const getCartCount = async () => {
        const { data } = await cart({
            id: authInfo?.profile.id,
            user_type: authInfo?.profile.user_type
        });
        const countst = data?.data?.cart_parts?.length || 0;
        setCountCart(countst);
    };

    useEffect(() => {
        getCartCount();
    }, []);


    const handleChange = (e) => {
        setSearchText(e.target.value)
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        handleClose();
        window.location.href = ("/product?search_text=" + searchText + "&category-slug=" + selectedCategorySlug + "");
        // navigate("/product?search_text=" + searchText + "&category-slug=" + category + "");
    }

    const handleClick = (value, title) => {
        // console.log("handlechange---", value)
        setSelectedCategorySlug(value);
        setSelectedCategory(title);
    }

    return (
        <>
            <div className="">
                <Navbar expand="lg" className="fixed-top navbar  navbar-expand-lg navbar-light pb-0 ps-0 pe-0 pt-0">
                    <Container fluid className="d-flex ps-sm-5 pe-sm-5 ps-2 pe-3">
                        <Navbar.Toggle onClick={handleShow} />
                        {/* <BiMenu size={30} onClick={handleShow}/> */}
                        <Navbar.Brand className="me-auto ">
                            {/* <div className="anchorContainer2 mt-2 mb-2 "> */}
                            <Link className="navbar-brand" to={"/"}>
                                <img className="logo" src={logo} alt={"logo"} onError={(e) => e.target.src = banner} />
                            </Link>
                            {/* </div> */}
                        </Navbar.Brand>

                        <Row className="w-50 text-dark me-auto d-lg-block d-none">
                            <Col>
                                <form onSubmit={handleSubmit}>
                                    <InputGroup className=" header-search">
                                        <DropdownButton
                                            variant="outline-secondary"
                                            title={selectedCategory}
                                            id="input-group-dropdown-3">
                                            <Dropdown.Item onClick={() => handleClick('All')}>All</Dropdown.Item>
                                            {
                                                categoryList && Array.isArray(categoryList) && categoryList.map((data, index) => {
                                                    return (
                                                        <Dropdown.Item key={index}
                                                            onClick={() => handleClick(data.slug, data.title)}>{data.title}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </DropdownButton>
                                        <Form.Control name={'search_text'} placeholder="&nbsp;Search..."
                                            onChange={handleChange}
                                            aria-label="Text input with 2 dropdown buttons" />
                                        <Button className="search-icon-btn" type="submit"
                                            variant="outline-secondary" id="button-addon2">
                                            <SearchIcon style={{ color: "white" }} />
                                        </Button>
                                    </InputGroup>
                                </form>

                            </Col>
                        </Row>

                        {isLogin ? <>
                            <Row className="d-sm-block d-none">
                                <Col>
                                    <Notification />
                                </Col>
                            </Row>
                            <Row className="d-sm-none d-block">
                                <Col>
                                    <Avatar onClick={handleShow} alt="notification" className=' d-flex justify-content-center' sx={{ height: "30px", width: "30px", backgroundColor: 'white' }}>
                                        <FaSearch color="var(--primary)" />
                                    </Avatar>
                                </Col>
                            </Row>
                            <Row className="ms-sm-1 me-sm-1 ms-0 me-0">
                                <Col>
                                    <UserDrop />
                                </Col>
                            </Row>
                            <Row className=" text-dark">
                                <Col>
                                    <Link to={"/cart"} style={{ textDecoration: "none", color: "#000000" }}
                                        className={"mx-2"}>
                                        <Badge badgeContent={countCart} color="warning">
                                            <Avatar alt="soping card" sx={{ width: 30, height: 30, bgcolor: "white" }}>
                                                <ShoppingCart size={25} color={"warning"} />
                                            </Avatar>
                                        </Badge>
                                    </Link>
                                </Col>
                            </Row>
                        </> : <div className="d-flex">
                            <div>
                                <div className="d-lg-none d-flex ">
                                    <Avatar onClick={handleShow} alt="notification" className='mt-1 d-flex justify-content-center' sx={{ height: "30px", width: "30px", backgroundColor: 'white' }}>
                                        <FaSearch color="var(--primary)" />
                                    </Avatar>
                                </div>
                                <div className=" d-lg-flex d-none">
                                    <SignUpButton />
                                </div>
                            </div>

                            <div className="">
                                <SignButton />
                            </div>
                        </div>
                        }


                        <Offcanvas show={show} className="offcanvas-sidebar" style={{ width: "70%" }}
                            onHide={handleClose}>
                            <Offcanvas.Header className="pt-0" closeButton>
                                <Offcanvas.Title>
                                    <Link className="navbar-brand" to={"/"}>
                                        <img className="logo" src={logo} alt={"logo"}
                                            onError={(e) => e.target.src = banner} />
                                    </Link>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="ps-0 pe-0">
                                <Container>

                                    <Row className="mb-2 ">
                                        <Col>
                                            <form onSubmit={handleSubmit}>
                                                <InputGroup className=" header-search">
                                                    <DropdownButton
                                                        variant="outline-secondary"
                                                        title={selectedCategorySlug}
                                                        id="input-group-dropdown-3">
                                                        <Dropdown.Item
                                                            onClick={() => handleClick('All')}>All</Dropdown.Item>
                                                        {
                                                            categoryList && Array.isArray(categoryList) && categoryList.map((data, index) => {
                                                                return (
                                                                    <Dropdown.Item key={index}
                                                                        onClick={() => handleClick(data.slug)}>{data.title}</Dropdown.Item>
                                                                )
                                                            })
                                                        }
                                                    </DropdownButton>
                                                    <Form.Control name={'search_text'} placeholder="&nbsp;Search..."
                                                        onChange={handleChange}
                                                        aria-label="Text input with 2 dropdown buttons" />
                                                    <Button className="search-icon-btn" type="submit"
                                                        variant="outline-secondary" id="button-addon2">
                                                        <SearchIcon style={{ color: "white" }} />
                                                    </Button>
                                                </InputGroup>
                                            </form>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Navs handleClose={handleClose} scrollDirection={'down'} />
                                    </Row>
                                </Container>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Container>
                </Navbar>
                <Navbar style={{ marginTop: '60px', zIndex: 2 }} className=" top-navbar p-0 navbar-nav d-lg-flex d-none ">
                    <Navs handleClose={null} scrollDirection={scrollDirection} />
                </Navbar>
            </div>

        </>
    )
}
export default Header