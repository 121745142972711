import React from "react";
import { LocationDetail } from "./LocationDetail";
import { ProblemPageBody } from "./ProblemPageBody";
import LocationDetail2 from "./LocationDetail2";

export function Location({
    data, setData,
    handleBack,
    token,
    
    handeNext,
   
}) {
    return (
        <>
            <div className="center">
                {/*<h2>What is the location ?</h2>*/}
                <h5>Provide Your Location Details</h5>
                <p>
                    {/* Tell us about your device Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Donec dictum augue ex, a feugiat libero porttitor
                    vel. Ut*/}
                    {/*Enhance Your Service Experience:*/}
                    {/*Kindly Provide Your Location Details for Visit Address Selection.*/}
                   <small> We'd like to offer you the best service experience. Could you please share your location details to
                       help us select a suitable visit address?</small>
                </p>
                <ProblemPageBody data={data} />
                <LocationDetail2 data={data} setData={setData} token={token} handleBack={handleBack} handeNext={handeNext} />
            </div>
        </>
    );
}
