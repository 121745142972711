import React, {useEffect, useState} from "react";
import ByCard from "../ByParts/ByCard";
import useWindowSize from "../../Context/WindowSize";

export default function Crouser({brands, id, basePath}) {
    const [col, setCol] = useState(4);
    const [total, setTotal] = useState(5);
    const [margin, setMargin] = useState(3);
    const [loading, setLoading] = useState(false);
    const {width} = useWindowSize();


    useEffect(() => {
        if (width < 768) {
            setTotal(3);
            setCol(4);
            setMargin(0);
        } else if (width < 900) {
            setTotal(4);
            setCol(3);
            setMargin(0);
        } else if (width < 1200) {
            setTotal(5);
            setCol(2);
            setMargin(2);
        } else {
            setTotal(6);
            setCol(2);
            setMargin(0);
        }
    }, [width]);

    useEffect(() => {
        setBrands_details(brands);
    }, [brands]);

    const [brand_details, setBrands_details] = useState(brands);
    const len =
        brand_details?.length % total
            ? parseInt(brand_details?.length / total + 1)
            : parseInt(brand_details?.length / total);

    return (
        <div
            id={`carouselExampleDark-${id}`}
            className="carousel carousel-dark slide"
            data-bs-ride="carousel"
        >
            <div className="carousel-indicators">
                {brand_details?.slice(0, len).map((element, idx) => {
                    return (
                        <button key={'plan-brand-' + (element.id) + "-" + idx}
                                type="button"
                                className={idx === 0 ? "active" : ""}
                                data-bs-target={`#carouselExampleDark-${id}`}
                                data-bs-slide-to={idx}
                                aria-current={idx === 0 ? "true" : ""}
                                aria-label={`Slide ${idx}`}
                        />
                    );
                }) }
            </div>


            <div className="carousel-inner">
                {/* div starts */}
                {brand_details?.slice(0, len).map((element, idxx) => {
                    return (
                        <div className={`carousel-item ${idxx === 0 ? "active" : ""}`} key={"bran-tab-"+idxx}>
                            <div className="container">
                                <div className="row gy-5 text-center">
                                    {brand_details
                                        ?.slice(idxx * total, (idxx + 1) * total)
                                        .map((element, idx) => {
                                            return (
                                                <div key={"bran-tab-card-"+idx} className={`col-${col} d-flex justify-content-center align-item-center mx-auto /*mx-${margin}*/`} >
                                                    <ByCard image={element?.url_icon} slug={element?.slug} title={element?.title} baseUrl={basePath} />
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleDark-${id}`}
                    data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleDark-${id}`}
                    data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"/>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}
