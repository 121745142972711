import React from "react";
import {formatMoney} from "../../hooks/useCustomHelpingMethod";

export default function IncludeCartSummery({data, user, amountDeliveryCharge, symbolCurrency}) {
    const signDiscount = <b className={"text-success"} style={{fontSize: "20px"}}>-</b>;
    const signCharge = <b className={"color-primary"} style={{fontSize: "20px"}}>+</b>;

    return (
        <div className="card mb-4 cart-summary">
            <div className="pt-3">
                <h5 className="mb-0 mx-3 "> Cart Summary</h5>
            </div>
            <div className="card-body pb-2">
                <ul className="list-group list-group-flush">
                    {/*<p className="cart-price-detail">Price Details</p>*/}
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0  ">
                        Subtotal ({data?.part_titles?.length || 0} item):
                        <b className="">{formatMoney((user?.user_type === 'dealer' ? data?.amount_mrp_dealer : data?.amount_mrp_customer) || 0)} {symbolCurrency || "₹"}</b>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        <span>Product Discount</span>
                        <span>{signDiscount} {formatMoney((user?.user_type === 'dealer' ? data?.amount_discount_dealer : data?.amount_discount_customer) || 0)} {symbolCurrency || "₹"}</span>
                    </li>
                    {data?.amount_discount_coupon > 0 ? <>
                        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                            <span>Coupon Discount</span>
                            <span>{signDiscount} {formatMoney((user?.user_type === 'dealer' ? data?.amount_discount_coupon_dealer : data?.amount_discount_coupon_customer) || 0)} {symbolCurrency || "₹"}</span>
                        </li>
                    </> : ""}
                    {/* <div className="d-flex justify-content-end discount-code ">
                                                {data.amount_discount_coupon > 0 ? `- ${ user?.user_type === 'dealer' ? data?.amount_discount_dealer : data?.amount_discount_customer} ${symbolCurrency || "₹"}` : ""}
                                            </div>*/}
                    {amountDeliveryCharge > 0 ?
                        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                            <span>Delivery Charge</span>
                            <span>{signCharge} {formatMoney(amountDeliveryCharge || 0)} {symbolCurrency || "₹"}</span>
                        </li>
                        : ""}
                    <hr/>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                        <b className="cart-price-text">Total Payable:</b>
                        {/*cart-price-text-red*/}
                        <b className="cart-price-text"> {formatMoney((user?.user_type === 'dealer' ? data?.amount_grand_total_dealer : data?.amount_grand_total_customer) || 0)} {symbolCurrency || "₹"}</b>
                    </li>
                </ul>

            </div>
        </div>
    );
}