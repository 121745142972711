import React from 'react'
import HowCard from './HowCard'
import imageStep1 from "./../asset/step01.webp"
import imageStep2 from "./../asset/step02.webp"
import imageStep3 from "./../asset/step03.webp"
import imageStep4 from "./../asset/step04.webp"

export default function HowItWorks() {
    const howItWorksSteps = [
        {
            sn: 1,
            // title: "Device Information",
            title: "Register",
            url_icon: imageStep1,
            description: "",
            // description: "Please provide the necessary details for book service about your device.",
        }, {
            sn: 2,
            // title: "Device Problems",
            title: "Select Your Service",
            url_icon: imageStep2,
            description: "",
            // description: " Please provide the necessary details for book service for your device pr. ",
        }, {
            sn: 3,
            // title: "Visit Schedule",
            title: "Fill Details",
            url_icon: imageStep3,
            description: "",
            // description: "Please provide the schedule info for service engineer to visit. i.e. date for visit and time interval to visit",
        }, {
            sn: 4,
            // title: "Location Information",
            title: "Get a Service",
            url_icon: imageStep4,
            description: "",
            // description: "Provide the location information and client info so our engineer visit that location and repair your device.",
        }
    ]
    return (
        <div>
            <h3 className="text-center servicesHeading text-dark my-3">How We <span
                style={{color: "#EE610F"}}>Work</span></h3>
            <div className="container mb-5 align-items-center ">
                <div className="row">
                    {howItWorksSteps.map((item, index) => { 
                        return (
                            <div key={item?.sn || index}
                                 className="col-lg-3 col-sm-6 col-12 my-3 d-flex justify-content-center align-item-center">
                                <HowCard item={item}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}
