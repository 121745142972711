import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import HorizontalLine from "./HorizontalLine";

function BookingMain() {
    const locationTemp = useLocation();
    //if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
    //    localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
    //}
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        document.title = 'Book a Service | iService - Innovation Service';
    }, [])
    return (
        <>
            <HorizontalLine/>
            {/* <FooterNext prev="/" next="/BookingDetail1" /> */}
        </>
    );
}

export default BookingMain;