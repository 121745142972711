import React, { Children } from "react";

export default function MoreButton({ children, onClickChange, colorChange }) {
  return (
    <>
      {colorChange ? (
        <div onClick={onClickChange} className="selected-button text-center">
          {children}
        </div>
      ) : (
        <div onClick={onClickChange} className="sign-up-button text-center">
          {children}
        </div>
      )}
    </>
  );
}
