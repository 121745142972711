import React, { useEffect, useState } from "react";
import { book_service_step, book_service_step_image_upload } from "../../api/booking";
import { useAuth, useNotification } from "../../hooks";
import { model_problem } from "../../api/general";
import { CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { ProblemPageBody } from "./ProblemPageBody";
import { FaCheck } from "react-icons/fa";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import { BsXLg } from "react-icons/bs";

// import "../ProblemDevice/ppBody.css";
export function ProblemPageDes({ setToken, data, setData, handeNext, handleBack, token, setProblem, setOtherProblem, problem, otherProblem }) {

    const [other, setOther] = useState();
    const [file, setFile] = useState([]);
    const { authInfo } = useAuth();
    const { showError, showSuccess } = useNotification()
    const navigate = useNavigate();
    const [urlImages, setUrlImages] = useState([])

    const [model_problems, setModel_Problems] = useState([])
    const [dataList, setDataList] = useState([]);
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Next')
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)
    const { setOpenBackDrop } = useNotification();
    const [show, setShow] = useState(false);
    const [modalImage, setModalImage] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowImage = (url) => {
        setModalImage(url)
        handleShow()
    }

    const [locationInfo, setLocationInfo] = useState({
        step: "device-problem",
        login_user_id: authInfo.profile.id,
        login_user_type: authInfo.profile.user_type,
        token_order: token,
    });

    useEffect(() => {
        if (data) {
            var list = data?.device_info?.device_problem || "";
            if (typeof list === 'string' || list instanceof String) {
                var problems = list.split(',');
                if (problems && Array.isArray(problems)) {
                    setDataList(problems)
                } else {
                    setDataList([])
                }
            }

            setOther(data?.device_info?.device_problem_other)

            if (data?.device_info?.url_image_client) {
                setUrlImages(data?.device_info?.url_image_client)
            }


        }

    }, [data])



    useEffect(() => {
        // setOther(otherProblem);
        // console.log(otherProblem);
        // let problems = problem.split(',');
        let problems
        // console.log(problems);
        if (problems && Array.isArray(problems)) {
            // setDataList(problems)
        }

    }, [])


    useEffect(() => {
        // console.log(dataList);
    })
    const handleSelect = (e) => {
        if (dataList.includes(e.target.value)) {
            const array = dataList.filter((ele) => {
                return ele !== e.target.value
            })
            setDataList(array);
            // console.log(array)
        } else {
            setDataList([...dataList, e.target.value])
        }
    }
    const apiCall = async (event) => {
        setButtonName("loading...");
        setLoading(true);
        event.preventDefault();
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Next");
            setLoading(false);

            event.stopPropagation();

        } else {
            const formData = new FormData();
            for (let key in locationInfo) {
                formData.append(key, locationInfo[key]);
            }
            formData.append("device_problem", dataList)
            formData.append("url_image_client", file[0])
            formData.append("device_problem_other", other)

            const { data, error } = await book_service_step_image_upload(formData);
            setLoading(false);
            setButtonName("Next");
            setData(data)
            navigate("/book-repair?token=" + data?.token + "&step=" + data?.step)
            // setToken(data.token)




            handeNext()
            if (error || data.error) {
                setLoading(false);

                return showError(data.message);
            } else {
                // setProblem(data?.device_info?.device_problem || dataList);
                // setOtherProblem(data?.device_info?.device_problem_other || other);
            }

            // showSuccess(data.message)
            setLoading(false);

            // console.log(data)
        }

    };

    const problemFun = async (e) => {
        setOpenBackDrop(true)
        setPageLoading(true);
        const { data } = await model_problem({ id: authInfo.profile.id })
        setModel_Problems(data.data)
        setPageLoading(false);
        setOpenBackDrop(false)

    }

    useEffect(() => {
        problemFun();
    }, []);
    return (
        <div className="SelectContainers">
            <CForm className="needs-validation"
                noValidate validated={validated} onSubmit={apiCall}>
                {/* <p className="textname">Select Problem</p> */}
                {/* {pageLoading && <Row className="mt-3"> <SkeletonFileGeneral height={30} col={12} total={2} /> </Row>} */}

                <div className="upContainer">
                    {/*<h6 className={'text-left'}>Select device problems:</h6>*/}
                    <div className="{/*firstSelectioncon*/} {/*selectionDate*/} mb-5">
                        {!pageLoading &&
                            model_problems.map((element, idx) => {
                                return (

                                    <CButton key={idx} id={idx} color="secondary" variant="outline"
                                        onClick={handleSelect}
                                        value={element.title}
                                        shape="rounded-pill"
                                        className={`selectable-btn ${dataList.includes(element.title) ? 'buttonStyle2' : ''}`}>{dataList.includes(element.title) ?
                                            <FaCheck size={15} /> : ''} {element.title}</CButton>

                                );
                            })}
                    </div>
                </div>
                <div className="downContiner">
                    <CRow className={"g-3"}>
                        <CCol xs={12} md={6}>
                            <CFormLabel htmlFor="exampleDataList">Others</CFormLabel>
                            <CFormInput list="datalistOptions"
                                id="exampleDataList" value={other}
                                onChange={(e) => setOther(e.target.value)}
                                placeholder="Type Problem here" className={"py-2"} />
                            <datalist id="datalistOptions">
                                {/*<option value="San Francisco" />*/}
                            </datalist>
                        </CCol>
                        <CCol xs={12} md={6}>
                            <CFormLabel htmlFor="formFileMultiple">Upload Picture (Optional)</CFormLabel>
                            <CFormInput type={"file"} className="form-control"
                                onChange={(e) => setFile(e.target.files)}
                                id="formFileMultiple" multiple />
                        </CCol>
                    </CRow>



                    {modalImage && <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="booking-modal-box p-0" >
                    <img className='d-flex justify-content-center mx-auto' style={{ height: "auto", width: "70%" }} onClick={handleShow}  src={modalImage} />
                    <div className=" booking-modal-cros-btn text-end p-2"><BsXLg className="close-button" size={30} onClick={handleClose} /></div>
                        {/* <div style={{ backgroundImage: `url(${modalImage})` }} className="booking-modal-img">
                            <div className="text-end p-2"><BsXLg className="close-button" size={30} onClick={handleClose} /></div>
                        </div> */}

                    </Modal.Body>
                </Modal>}

                   { urlImages && <Row><Col className='clientDetail mt-3'> Product Images</Col></Row>}



                    <Row >
                        {
                            urlImages && Array.isArray(urlImages) && urlImages.map((img, index) => {
                                return (
                                    <Col xs={6} sm={3} key={index} className="mt-3">
                                        <Card onClick={()=>handleShowImage(img)} className='pointer'>
                                            <Card.Body className="p-2">
                                                <Card.Img alt='client image' className="img-fluid" src={img} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                    </Row>





                    <Row className={"my-4"}>
                        <Col>
                            <button className="button-2 float-left"
                                onClick={handleBack}>{"Previous"}</button>
                        </Col>
                        <Col>
                            <button disabled={loading} type={'submit'}
                                className={"sign-up-button float-end " + (loading && 'disabled-button')}>{loading &&
                                    <CircularProgress size={16} color={"inherit"} />}&nbsp; {btnName}</button>
                        </Col>
                    </Row>
                </div>
                {/*<button className="sign-up-button mx-auto mt-5" type={"submit"}>{btnName}</button>*/}
            </CForm>
        </div>
    );
}
