import {React, useEffect, useState} from "react";
import MoreButton from "../HomePage/MoreButton";
import {useAuth, useNotification} from "../../hooks";
import banner from "../asset/noimage.webp"
import {formatMoney} from "../../hooks/useCustomHelpingMethod";
import {Badge, Col, Row} from "react-bootstrap";
import {Chip} from "@mui/material";


export default function CartProduct({
                                        product,
                                        handelRemove,
                                        handelIncrease,
                                        handelDecrease,
                                        setProductNotAvailable,
                                        quantityChange
                                    }) {
    const [part_id, setPart_id] = useState(product.id);
    const [cart_part_id, setCart_part_id] = useState(product.cart_part_id);
    const [partQuantity, setPartQuantity] = useState(product?.cart_quantity || 1);
    const [notAvailable, setNotAvailable] = useState(false);
    const {showError} = useNotification();
    const {authInfo} = useAuth()
    const canQuantityMin = 1;
    const canQuantityMax = 5;

    const partQuantityIncrease = () => {
        if (canQuantityMax <= partQuantity) {
            showError("maximum limit");
        } else {
            handelIncrease({cart_id: cart_part_id});
            setPartQuantity(partQuantity + 1);
        }

    }
    const partQuantityDecrease = () => {
        if (partQuantity <= canQuantityMin) {
            showError("minimum limit");
        } else {
            setPartQuantity(partQuantity - 1);
            handelDecrease({cart_id: cart_part_id});
        }
    }

    useEffect(() => {
        // console.log(product);
        // console.log(authInfo);
        if (product?.availability !== 'available' && product?.availability !== 'Available') {
            setNotAvailable(true);
            setProductNotAvailable(true);
        }
    })
    let discountPerUser = product?.rate_discount || 0;
    if (authInfo.profile) {
        if (authInfo.profile.user_type && authInfo.profile.user_type === 'dealer') {
            discountPerUser = product?.rate_discount_dealer || 0;
        } else {
            discountPerUser = product?.rate_discount_customer || 0;
        }
    }
    return (
        <div
            className={"card cartProduct w-100 " + (notAvailable ? "border border-3 border-danger" : "")}>
            <div className="card-body d-flex  align-items-center cart-product-card ">
                <div>
                    <img className="product-card-img"
                        src={`${product?.url_icon}?fit=crop&auto=format`}
                        srcSet={`${product?.url_icon}?fit=crop&auto=format&dpr=2 2x`}
                        alt={product?.title}
                        onError={(e) => e.target.src = banner}
                        loading="lazy"
                    >
                    </img>
                    {notAvailable ? (<></>
                        /*<Badge pill bg="danger" className={'badge-top-right'}>Not Available</Badge>*/) : (
                        discountPerUser && discountPerUser > 0 ? (
                            <Badge pill bg="success" className={'badge-top-right'}>-{discountPerUser}%</Badge>
                        ) : ""
                    )}
                </div>
                <div className="mx-5 w-100">
                    <Row>
                        <Col>
                            <h6 className="card-title my-2 cartHeading ">
                                <a>{product?.title}</a>
                            </h6>
                        </Col>
                        {product?.part_type && (
                            <Col xs={4}>
                                <Chip size="" className={'my-1 float-end'} variant="outlined" color="warning"
                                    label={product?.part_type} />
                            </Col>
                        )}
                    </Row>

                    <p className="card-text">
                        {product?.description && ""}
                    </p>

                    <p> Price: {product?.amount_currency_symbol || ""}
                        <span
                            className="text-decoration-line-through">{formatMoney(authInfo?.profile?.user_type === 'dealer' ? (product?.amount_mrp_dealer || 0) : (product?.amount_mrp_customer || 0))}</span>
                        <span
                            className="fw-bold color-primary"> {product?.amount_currency_symbol || ""}{formatMoney(authInfo?.profile?.user_type === 'dealer' ? (product?.amount_price_dealer || 0) : (product?.amount_price_customer || 0))}</span>
                    </p>
                    <div className="d-flex justify-content-evenly">

                        <MoreButton onClickChange={() => handelRemove({part_id: part_id})}>Remove</MoreButton>
                        <div className="input-group mb-3 d-flex justify-content-end">
                            {notAvailable ? <b className={'text-danger'}>Not Available</b> : (
                                <>
                                    <span onClick={() => partQuantityDecrease()}
                                          className={"input-group-text  plus-minus " + (partQuantity <= canQuantityMin ? "disabled-button" : "")}>-</span>
                                    <span className=" mx-3 my-2">
                                        {partQuantity || product.cart_quantity}
                                        {/*{ quantityChange ? <CircularProgress color="warning" size={19}/> : <>{partQuantity || product.cart_quantity} </> }*/}
                                    </span>
                                    <span onClick={() => partQuantityIncrease()}
                                          className={"input-group-text plus-minus " + (partQuantity >= canQuantityMax ? "disabled-button" : "")}>+</span>
                                </>
                            )

                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
