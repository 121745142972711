import React from "react";
import thumb from "../asset/thumb.webp"
import {Link} from "react-router-dom";
import banner from "../asset/banner.webp";

export function Location({token, model, brand, iemi, handeNext}) {
    return (
        <>
            <div className="text-center" style={{marginTop: "100px", marginBottom: "100px"}}>
                <div className='d-flex justify-content-center align-item-center my-5'>
                    <img className='thumb-img ' src={thumb} onError={(e) => e.target.src = banner}/>
                </div>
                <h2 className={" mt-5"}>You job sheet was created successfully! <br/>Order Number  <b className={'text-uppercase'}>#{token}</b></h2>
                <p> You can track your order with <b className={'text-uppercase'}>#{token}</b>. This number is unique for this order.</p>
                <p className={" mt-5"}>
                    <Link className="btn btn-primary3 mx-auto" style={{textDecoration: "none"}} to={"/"}>Back To Home</Link>
                </p>
            </div>
        </>
    );
}
