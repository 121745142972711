import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion, AccordionActions, AccordionDetails, AccordionSummary,
    Alert,
    AlertTitle,
    CircularProgress,
    Fab,
    InputAdornment,
    TextareaAutosize,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import {address_state, get_data_by_url} from "../../api/general";
import {useAuth, useNotification} from "../../hooks";
import {Col, Row} from "react-bootstrap";
import {InputField} from "../InputFields/InputFieldHelper";
import AddressPickLocation from "../LocationPicker/AddressPickLocation";
import {GoogleMapLocation} from "../GoogleMapLocationPick/GoogleMapLocation";
import ReactGoogleMapPicker from "../GoogleMapLocationPick/ReactGoogleMapPicker";

export default function ModalAddressAdd({reloadData, setReloadData,place,defaultOpen}) {
    const [open, setOpen] = useState(defaultOpen||false);
    const [buttonPlace, setButtonPlace] = useState(place||"end");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [addressStates, setAddressStates] = useState([]);
    const [addressCities, setAddressCities] = useState([]);
    const [showLocationPicker, setShowLocationPicker] = useState(true);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(()=>setButtonPlace(place),[place])

    const {showError,} = useNotification();
    const {authInfo} = useAuth();
    const [btnSubmit, setBtnSubmit] = useState('Submit')
    const [messageError, setMessageError] = useState('')
    const [loading, setLoading] = useState(false)
    const stateAddress = {};
    const [address, setAddress] = useState({
        login_user_id: authInfo?.profile?.id || "",
        login_user_type: authInfo?.profile?.user_type || "",
        name: authInfo?.profile?.name || "",
        email: authInfo?.profile?.email || "",
        mobile: authInfo?.profile?.mobile || "",
        pincode: "",
        name_state: "",
        name_city: "",
        address: "",
        landmark: "",
        device_latitude: 0.00,
        device_longitude: 0.00,
        remark: ""
    });
    const handleSubmit = async (event) => {
        setBtnSubmit("loading...");
        setLoading(true); 
        const response = await get_data_by_url("dealer/address-update", address);
        setReloadData(true);
        setBtnSubmit("Submit");
        setLoading(false);

        if (response?.error === false) {
            // showSuccess(response?.message || "Address added successfully");
            handleClose();
        //console.log("response---", response)

        } else {
            showError(response?.message || "Error encounter");
        }
        setShowLocationPicker(true);
        
    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        // if (name === 'name_state') {
        //     getCityName(value).then(r => r);
        //     setAddress({...address, "name_city": ""});
        // }
        // console.log(e.target);
        setAddress({...address, [name]: value});
    };


    return (
        <div>
            <button className={"sign-up-button float-"+(buttonPlace||"end")}
                    onClick={handleClickOpen}>Add Address
            </button>
            {/* <Fab variant="extended" size="small" color="primary" onClick={handleClickOpen}>
                <FaTimes sx={{ mr: 3 }} />
                Cancel Order
            </Fab>*/}
            <Dialog open={open} onClose={handleClose}
                    fullScreen={fullScreen}
                    fullWidth
                    maxWidth="lg"
                // scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description">
                <DialogTitle>Add New Address
                    {!showLocationPicker && <Button variant={'contained'} color={'warning'}
                                                    className={'sign-up-button m-1 float-end'}
                                                    onClick={() => setShowLocationPicker(true)}>Pick Location</Button>}
                </DialogTitle>
                <DialogContent>
                    {showLocationPicker ?
                        // <AddressPickLocation stateAddress={stateAddress} address={address} setAddress={setAddress}/>
                        <GoogleMapLocation addressMain={address} setAddressMain={setAddress} isUpdate={false}/>
                        // <ReactGoogleMapPicker addressMain={address} setAddressMain={setAddress}/>

                        : (<Row className={'gx-3 gy-0'}>
                            <Col md={12}>
                                <InputField type={'text'} minLength={3} name={'name'}
                                            label={'Name'} value={address?.name}
                                            placeholder={'Enter your name'}
                                            onChange={handleChange} required={true}
                                />
                                {/*<TextField variant="outlined" id="address-name" label="Name"  />*/}
                            </Col>
                            <Col md={6}>
                                <InputField appendType={'start'} appendIcon={'+91'} type={'text'}
                                            min={1000000000} max={9999999999} name={'mobile'}
                                            label={'Mobile Number'}
                                            placeholder={'Enter your mobile number'}
                                            onChange={handleChange}
                                            value={address?.mobile} required={true}
                                />
                            </Col>
                            <Col md={6}>
                                <InputField type={'text'} name={'email'}
                                            label={'Email'} value={address?.email}
                                            placeholder={'Your email address'}
                                            onChange={handleChange} required={false}/>
                            </Col>

                            <Col md={12}>
                                {/*<TextField className={'form-control w-100'} name={'address'} label={'Address'}
                                                           value={address?.address} variant="standard"
                                                           onChange={handleChange} required={true}/>*/}
                                <InputField type={'textarea'} name={'address'} label={'Address'}
                                            value={address?.address}
                                            placeholder={'Your address'}
                                            onChange={handleChange} required={true}/>
                            </Col>
                            <Col md={4}>
                                <InputField type={'text'} minLength={3} name={'name_city'}
                                            label={'City'} value={address?.name_city}
                                            placeholder={'Your city name'}
                                            onChange={handleChange} required={true}
                                            options={addressCities}/>
                            </Col>
                            <Col md={4}>
                                <InputField type={'text'} minLength={3} name={'name_state'}
                                            label={'State'} value={address?.name_state}
                                            placeholder={'Your state name'}
                                            onChange={handleChange}
                                            required={true}
                                            options={addressStates}/>
                            </Col>
                            <Col md={4}>
                                <InputField type={'number'} min={100000} max={999999} name={'pincode'}
                                            label={'Pincode'} value={address?.pincode}
                                            placeholder={'Your Area pincode'}
                                            onChange={handleChange} required={true}
                                />
                            </Col>
                            <Col md={12}>
                                <InputField type={'text'} name={'remark'}
                                            label={'Remark (Any delivery instructions)'}
                                            value={address?.remark}
                                            placeholder={'Enter any remark?'}
                                            onChange={handleChange} required={false}/>
                            </Col>
                            {/*<Col md={6}>
                                        <InputField type={'number'} name={'device_latitude'}
                                                    label={'device_latitude'} value={address?.device_latitude}
                                                    placeholder={'Your Area device_latitude'}
                                                    onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputField type={'number'} name={'device_longitude'}
                                                    label={'device_longitude'} value={address?.device_longitude}
                                                    placeholder={'Your Area device_longitude'}
                                                    onChange={handleChange}
                                        />
                                    </Col>*/}
                        </Row>)}
                    <p>
                        <small className={'text-danger capitalize'}>{messageError}</small>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'inherit'}>Cancel</Button>
                    {showLocationPicker ? <Button variant={'outlined'} color={'warning'} className={'sign-up-button'}
                                                  onClick={() => {
                                                      setShowLocationPicker(false);
                                                      // console.log(stateAddress);
                                                      setAddress({...address, ...stateAddress})
                                                  }}>Confirm Location Or Next</Button> :
                        <Button disabled={loading} variant={'contained'} color={'warning'} className={'sign-up-button'}
                                onClick={handleSubmit}> {loading &&
                        <CircularProgress size={16} color={"inherit"}/>}&nbsp; {btnSubmit}</Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}