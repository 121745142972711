import { Avatar } from "@mui/material";
import {
    MDBDropdown, MDBDropdownItem,
    MDBDropdownMenu, MDBDropdownToggle
} from "mdb-react-ui-kit";
import React from "react";
import user from "../../src/components/asset/original.webp"
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BiLogOutCircle } from "react-icons/bi";
import { useAuth } from "../hooks";
// import SignUpButton from "./SignUpButton";
// import SignButton from "./SignButton";
import { FaUserCircle } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
const UserDrop = ({ setNotificationRoot }) => {
    const navigate = useNavigate()
    const { userLogout, authInfo } = useAuth();


    const handelLogout = async () => {
        await userLogout({
            login_user_id: authInfo?.profile?.id,
            device_token: localStorage.getItem('device_token') || "ABCED"
        });
        navigate("/");
        window.location.reload();
    };

    return (<MDBDropdown>
        <Avatar alt="user" style={{ height: "30px", width: "30px" }}>
            <MDBDropdownToggle tag='div' className='nav-link d-flex justify-content-center' role='button'>
                {
                    authInfo?.profile?.url_profile ? <img src={authInfo?.profile?.url_profile || ""} onError={(e) => e.target.src = user} className="user-profile-img" alt={"profile img"} /> : <Avatar alt="user" className="user-profile-img" ><BsFillPersonFill className="me-1 ms-1" /></Avatar>
                }

            </MDBDropdownToggle>
        </Avatar>
        <MDBDropdownMenu className="user-drop-box" style={{ width: "200px" }}>
            <MDBDropdownItem >
                <Container>
                    <Row>
                        <Col>
                            {
                                authInfo?.profile?.url_profile ? <Avatar sx={{ width: 60, height: 60 }}
                                    alt="user"
                                    src={authInfo?.profile?.url_profile || user}
                                    className="mx-auto" /> :
                                    <Avatar alt="user" className="mx-auto" sx={{ width: 60, height: 60 }} >
                                        <BsFillPersonFill size={40} className="me-1 ms-1" />
                                    </Avatar>
                            }


                        </Col>
                    </Row>
                    <Row xs={{ cols: 1 }} className="g-1">
                        <Col className="text-center mt-2 fw-bold"><small>{authInfo?.profile?.name || ""}</small></Col>
                        <Col className="text-center mt-0"><small>+{authInfo?.profile?.mobile_code || "91"} {authInfo?.profile?.mobile || ""}</small></Col>
                    </Row>
                    <hr />
                    <Row xs={{ cols: 1 }} className="g-1">
                        <Col> <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { navigate("/profile?profile=profile-detail"); }}
                            className="py-2 d-flex text-center align-items-center nav-hover-primary"
                        >
                            <FaUserCircle /> <span className="ps-2 "> Profile</span>
                        </div>
                        </Col>
                        <Col> <div style={{ cursor: "pointer" }}
                            className="py-2 d-flex text-center align-items-center nav-hover-primary"
                            onClick={handelLogout}
                        >
                            <BiLogOutCircle className="" /> <span
                                className="ps-2 "> Logout</span>
                        </div></Col>
                    </Row>
                </Container>
            </MDBDropdownItem>

        </MDBDropdownMenu>
    </MDBDropdown>
    )
}
export default UserDrop;