import {React, useState} from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {contact_lead} from "../../api/general";
import {useNotification} from "../../hooks";
import {TextField} from "@mui/material";
import {CFormInput} from "@coreui/react";

export default function ContactForm() {
    const [contactInfo, setContactInfo] = useState({
        name: "",
        mobile: "",
        email: "",
        subject: "",
        message: "",
        last_name: "",
    });
    const [btnText, setBtnText] = useState('Send Query')

    const {showError, showSuccess} = useNotification();

    const handleChange = ({target}) => {
        const {value, name} = target;
        setContactInfo({...contactInfo, [name]: value});
    };
    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log(contactInfo)
        setBtnText('Query sending....')
        const {message, error} = await contact_lead(contactInfo)
        // console.log(error)
        if (error) {
            setBtnText('Send Query');
            return showError(error)
        }

        setBtnText('Send Query');
        showSuccess(message);
        setContactInfo({
            name: "",
            mobile: "",
            subject: "",
            message: "",
            email: "",
            last_name: "",
        })
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="p-sm-5 p-2">
                <div className="row  mb-4 text-center">
                    <h4 className="col-md-12 ">Submit <span className={'color-primary'}>Your Query</span></h4>
                    <small>Need any help? Please feel free to contact us</small>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12 ">
                        <div className="form-outline">
                            <TextField label="Name" size={'small'} variant="outlined" name={'name'}
                                       value={contactInfo.name}
                                       onChange={handleChange}
                                       className={'form-control'}
                                       required={true}
                                       color={"warning"}
                                       placeholder="Please enter your name"/>
                        </div>
                    </div>
                </div>

                <div className="row my-3 ">
                    <div className="col-md-6">
                        <div className="form-outline">
                            <TextField label="Mobile Number" size={'small'} variant="outlined" name={'mobile'}
                                       value={contactInfo.mobile}
                                       min={1000000000} max={9999999999}
                                       onChange={handleChange}
                                       required={true}
                                       type={'number'}
                                       color={"warning"}
                                       className={'form-control'}
                                       placeholder="Please enter your mobile number"/>
                            {/*<label className="form-label contact-from-lable" htmlFor="typeText">
                Phone Number
              </label>

              <ReactPhoneInput
                inputProps={{
                  name: "phone_no",
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{
                  width: "100%",
                }}
                country={"in"}
              />*/}
                        </div>
                    </div>
                    <div className="col-md-6 mt-md-0 mt-3">
                        <div className="form-outline">
                            <TextField label="Email Address" size={'small'} variant="outlined" name={'email'}
                                       value={contactInfo.email}
                                       onChange={handleChange}
                                       color={"warning"}
                                       className={'form-control'}
                                       placeholder="Please enter your email"/>
                        </div>
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col-md-12">
                        <div className="form-outline">
                            <TextField label="Subject" size={'small'} variant="outlined" name={'subject'}
                                       value={contactInfo.subject}
                                       onChange={handleChange}
                                       required={true}
                                       className={'form-control'}
                                       color={"warning"}
                                       placeholder="Please enter your subject"/>
                        </div>
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col-md-12">
                        <div className="form-outline">
                            <TextField label="Your message" size={'small'} variant="outlined" name={'message'}
                                       value={contactInfo.message}
                                       onChange={handleChange}
                                       required={true}
                                       className={'form-control'}
                                       multiline
                                       rows={6}
                                       color={"warning"}
                                // maxRows={4}
                                       placeholder="Please enter your message"/>

                        </div>
                    </div>
                </div>


                <div className="d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary1 my-3">
                        {btnText}
                    </button>
                </div>
            </form>
        </>
    );
}
