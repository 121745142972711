import {React, useEffect, useState} from "react";
import {category_list, model, product_brand} from "../../api/general";
import {product_model} from "../../api/general";
import {book_service} from "../../api/booking";
import {CircularProgress} from "@mui/material";
import {useAuth, useNotification} from "../../hooks";
import {CCol, CForm, CFormInput, CFormLabel, CFormSelect, CRow} from "@coreui/react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Col} from "react-bootstrap";
import DialogTempNotAvailable from "../DialogTempNotAvailable";
import {isAppTempDown} from "../../api/client";

export function BookingForm({
                                data,
                                setData,
                                token,
                                handeNext,
                                setToken,
                            }) {

    const [category_details, setCategory_details] = useState([]);
    const [selected_categoryId, setSelected_categoryId] = useState();
    const [selected_brandId, setSelected_brandId] = useState();
    const [selected_modelId, setSelected_modelId] = useState();
    const [selected_imei, setSelected_imei] = useState();
    const [model_details, setModel_details] = useState([]);
    const [brand_details, setBrand_details] = useState([]);
    const {authInfo} = useAuth();
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Next')
    const [btnDisable, setBtnDisable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [slugCategory, setSlugCategory] = useState((searchParams.get("category-slug")));
    const [slugBrand, setSlugBrand] = useState((searchParams.get("brand-slug")));
    const [slugModel, setSlugModel] = useState((searchParams.get("model-slug")));
    const [loadingBtn, setBtnLoading] = useState(false)
    const {setOpenBackDrop} = useNotification();

    const navigate = useNavigate();
    // setData(data)
    useEffect(() => {
        if (data) {
            setSelected_categoryId(data?.device_info?.category_id || 0);
            setSelected_brandId(data?.device_info?.brand_id || 0);
            setSelected_modelId(data?.device_info?.model_id || 0);
            setSelected_imei(data?.device_info?.device_serial_imei || selected_imei || "")
        }
    }, [data])


    const getCategories = async (inputData) => {
        setOpenBackDrop(true)
        const {data} = await category_list(inputData);
        if (data?.data && Array.isArray(data?.data)) {
            setCategory_details(data?.data);
        } else {
            if (data?.data?.id) setSelected_categoryId(data?.data?.id || 0);
        }
        setOpenBackDrop(false)

        return data;
    };
    const getBrands = async (inputData) => {
        const {data} = await product_brand(inputData);

        if (data?.data && Array.isArray(data?.data)) setBrand_details(data?.data || []);
        return data;
    };
    const getModels = async (inputData) => {
        setOpenBackDrop(true)
        const {data} = await product_model({...inputData,checkData:true});

        if (data?.data && Array.isArray(data?.data)) {

            setModel_details(data?.data);
        }
        setOpenBackDrop(false)

        return data;
    };

    const onLoadCall = async (inputData) => {
        setLoading(true);
        await getCategories({is_service: "yes"});
        if (slugCategory && !selected_categoryId) {
            const category = await getCategories({is_service: "yes", slug_category: slugCategory});
            const brands = await getBrands({category_slug: slugCategory});
        }
        if (slugBrand && !selected_brandId) {
            const brand = await getBrands({brand_slug: slugBrand});
            if (brand?.data) {
                // setBrand(brand?.data?.title);
                setSelected_brandId(brand?.data?.id);
            }
            const models = await getModels({slug_brand: slugBrand});
            // console.log(models);
            if (models) {
                setModel_details(models?.data || []);
            }
        }
        if (slugModel && !selected_modelId) {
            setOpenBackDrop(true);
            const model = await getModels({slug_model: slugModel});
            // console.log(model);
            if (model?.data) {
                // console.log(model?.data);
                // console.log(model?.data?.id);
                setSelected_modelId(model?.data?.id);
            }
            setOpenBackDrop(false);

        }
        setLoading(false);
    };

    useEffect(() => {
        onLoadCall();
        getCategories()
    }, []);

    const onCategoryChange = async (event) => {
        setSelected_categoryId(event.target.value);
        setSelected_modelId('')
        setSelected_brandId('')
        setModel_details([])
        // const { data } = await getBrands({ category_id: event.target.value });
    };
    const onBrandChange = async (event) => {
        setOpenBackDrop(true);

        setSelected_brandId(event.target.value);
        setSelected_modelId('')
        getModels({slug_brand: slugBrand})
        setOpenBackDrop(false);

        // const { data } = await getModels({ brand_id: event.target.value });
        // setBrand(event.target.selectedOptions[0].getAttribute('title'));
    };
    const onModelChange = async (event) => {
        setSelected_modelId(event.target.value);
        // setModel(event.target.selectedOptions[0].getAttribute('title'))
    };
    const onIChange = async (event) => {
        setSelected_imei(event.target.value);
        // setIemi(event.target.value)
    };

    const getBrandList = async () => {
        setOpenBackDrop(true)
        const {data} = await model({category_id: selected_categoryId});
        setBrand_details(data?.data);
        setOpenBackDrop(false)

    }
    const getModelList = async () => {
        setOpenBackDrop(true)
        const {data} = await product_model({brand_id: selected_brandId});
        // setBrand(event.target.selectedOptions[0].getAttribute('title'))
        setModel_details(data?.data || []);
        setOpenBackDrop(false)

    }
    useEffect(() => {
        if (selected_categoryId) getBrandList(selected_categoryId);
    }, [selected_categoryId])
    useEffect(() => {
        if (selected_brandId) getModelList(selected_brandId);
    }, [selected_brandId])


    const {showError, showSuccess} = useNotification()
    const [notAvOpen, setNotAvOpen] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isAppTempDown) {
            setNotAvOpen(true);
            return;
        }

        setButtonName("loading...");
        setBtnLoading(true)

        setBtnDisable(true);

        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Next");
            setBtnLoading(true)

            event.stopPropagation();

        } else {
            const inputData = {
                brand_id: selected_brandId,
                category_id: selected_categoryId,
                model_id: selected_modelId,
                id: authInfo.profile.id,
                user_type: authInfo.profile.user_type,
                device_serial_imei: selected_imei,

            };
            if (token) {
                inputData.token_order = token;
                inputData.step = "device-info";
            }
            const {data, error} = await book_service(inputData);
            setButtonName("Next");
            setBtnLoading(false)

            setBtnDisable(false);
            if (error || data.error) return showError(data.message);
            setData(data?.data)
            navigate("/book-repair?token=" + data?.data?.token + "&" + "step=" + data?.data.step)
            // showSuccess(data.message)
            setToken(data?.data.token)
            handeNext();
        }
        setButtonName("Next");
        setBtnLoading(false)

        setBtnDisable(false);
        setValidated(true);
    };


    return (
        <>
            <DialogTempNotAvailable open={notAvOpen} setOpen={setNotAvOpen}/>
            <div className="center-form container">

                <CForm className="needs-validation"
                       noValidate validated={validated} onSubmit={handleSubmit}>
                    <CRow className={"gy-3"}>
                        <CCol md={6}>
                            <CFormLabel htmlFor="select-category">Select Category</CFormLabel>
                            <CFormSelect id={'select-category'} aria-label="Default select example"
                                         onChange={onCategoryChange} required={true} value={selected_categoryId || 0}>
                                <option value="0" disabled={true}>Please select Category</option>
                                {category_details && Array.isArray(category_details) ? category_details.map((element) => {
                                    return <option value={element.id} key={element.id}>{element.title}</option>;
                                }) : ""}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="select-brand">Select brand</CFormLabel>
                            <CFormSelect id={'select-brand'} aria-label="Default select example"
                                         onChange={onBrandChange}
                                         required value={selected_brandId || 0}>
                                <option value="0" disabled={true}>Please select category First</option>
                                {brand_details === null || !brand_details.length ? (
                                    <></>
                                    // <option value="">Please Select Brand</option>
                                ) : (brand_details.map((element) => {
                                        return <option title={element.title} value={element.id}
                                                       key={element.id}>{element.title}</option>;
                                    })
                                )}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="select-model">Select Model</CFormLabel>
                            <CFormSelect id={'select-model'} aria-label="Default select example"
                                         onChange={onModelChange} required value={selected_modelId || 0}>

                                <option value="0" disabled={true}>Please select brand First</option>

                                {model_details === null || !model_details.length ? (
                                    // <option value="">Please Brand Category </option>
                                    <></>
                                ) : (
                                    model_details.map((element) => {
                                        return <option title={element.title} key={element.id}
                                                       value={element.id || ""}>{element.title}</option>;
                                    })
                                )}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="imei_number">IMEI/Serial number (Optional)</CFormLabel>
                            <CFormInput id="imei_number" onChange={onIChange} value={selected_imei || ""}/>
                        </CCol>
                        <CCol md={12} className={'mt-3'}>
                            <button className={"sign-up-button mx-auto " + (loadingBtn && 'disabled-button')}
                                // disabled={btnDisable && btnDisable === true ? "disabled" : ""}
                                    disabled={loadingBtn}
                                    onClick={handleSubmit}>
                                {loadingBtn && <CircularProgress size={16} color={"inherit"}/>}&nbsp; {btnName}
                            </button>
                        </CCol>
                    </CRow>
                    {/*<button className="sign-up-button" onClick={handleSubmit}>Submit</button>*/}
                </CForm>


            </div>
        </>
    );
}
