import { React, useEffect, useState } from "react";
import { useAuth, useNotification } from "../../hooks";
import { useNavigate } from 'react-router-dom';
import { CCol, CForm, CFormInput, CFormLabel, CFormTextarea, CInputGroup, CInputGroupText, CRow } from "@coreui/react";
import { job_sheet_add } from "../../api/jobsheet";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { CircularProgress } from "@mui/material";

export function JobSheetForm({ handeNext, data, token, setToken, setData }) {

    const navigate = useNavigate();
    const [name, setName] = useState('')
    const [estimate_amount, setEstimate_amount] = useState('')
    const [remark, setRemark] = useState('')
    const [mobile, setMobile] = useState('')
    const [btnName, setButtonName] = useState('Submit & Next')
    const [loading, setLoading] = useState(false)

    const { authInfo } = useAuth();
    const [formData, setFormData] = useState({
        name: data?.name || "",
        mobile: data?.mobile || "",
        remark: data?.remark || "",
        step: 'client-info'
    });

    const handleChange = ({ target }) => {
        const { value, name } = target;
        setFormData({ ...formData, [name]: value });
    };

    const [validated, setValidated] = useState(false)
    const { showError, showSuccess } = useNotification();
    const handleSubmit = async (event) => {
        setButtonName("loading...");
        setLoading(true);

        event.preventDefault();
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Submit & Next");
            setLoading(false);

            event.stopPropagation();
        } else {

            const { data, error } = await job_sheet_add({
                inputData: formData,
                user_id: authInfo?.profile?.id,
                user_type: authInfo?.profile?.user_type,
                token: token
            });
            // console.log("data-------", data)
            setButtonName("Submit & Next");
            navigate("/job-repair?token=" + data.token + "&step=" + data.step)
            setLoading(false);

            if (error || data.error) return showError(data.message);
            // showSuccess(data.message)
            setToken(data.token)
            setData(data)
            handeNext();
        }
        setButtonName("Submit & Next");
        setLoading(false);

        setValidated(true);
    };

    useEffect(() => {
        let remark = "";
        if (data?.remark && Array.isArray(data?.remark) && data?.remark?.length > 0) {
            remark = data?.remark[0]?.remark || "";
        }

        setFormData({
            ...formData,
            name: data?.name || formData?.name || "",
            mobile: data?.mobile || formData?.mobile || "",
            remark: remark || formData?.remark || ""
        })
    }, [data])

    return (
        <>
            <div className="center-form">
                <div className="optionContainer">
                    <Container>
                        <CForm className="needs-validation"
                            noValidate validated={validated} onSubmit={handleSubmit}>
                            <CRow className={"g-3"}>
                                <Col md={6}>
                                    <CFormLabel htmlFor="name">Full Name</CFormLabel>
                                    <CFormInput type="text"
                                        name="name"
                                        required={true}
                                        onChange={handleChange}
                                        className="form-control LocationForm"
                                        placeholder="Enter your name"
                                        value={formData?.name}
                                        aria-label="Username"
                                        aria-describedby="basic-addon1" />
                                </Col>
                                <Col md={6}>
                                    <CFormLabel htmlFor="number">Phone Number</CFormLabel>
                                    <CInputGroup>
                                        <CInputGroupText>+91</CInputGroupText>
                                        <CFormInput type={'number'} min={1000000000} max={9999999999}
                                            placeholder='123******'
                                            id="number"
                                            onChange={handleChange}
                                            value={formData?.mobile}
                                            className="form-control LocationForm"
                                            aria-label="Username"
                                            name="mobile"
                                            aria-describedby="basic-addon1"
                                            required />
                                    </CInputGroup>
                                </Col>
                                <CCol md={12}>
                                    <CFormLabel htmlFor="remark">Remark</CFormLabel>
                                    <CFormTextarea rows={2} placeholder='Type Comments' name={'remark'}
                                        id="remark" value={formData?.remark} onChange={handleChange}
                                        required />
                                </CCol>
                                <CCol xs={12} className={'text-center'}>
                                    <button disabled={loading} className={"sign-up-button float-end text-end " + (loading && 'disabled-button')} type="submit">
                                        {loading && <CircularProgress size={16} color={"inherit"} />}&nbsp;  {btnName}
                                    </button>
                                </CCol>
                            </CRow>

                        </CForm>
                    </Container>
                </div>

                {/* <div className="optionContainer">
                    <div className="form-group">

                        <label htmlFor="remark"> Remark</label>
                        <textarea rows={2} className='rounded  form-control form-control-md'
                                  placeholder='Type Comments'
                                  id="remark" value={remark} onChange={handleRemark} required/>

                    </div>
                    <div className="form-group">

                    </div>
                </div>*/}

            </div>
        </>
    );
}
