import { Avatar } from "@mui/material";
import {
    MDBDropdown, MDBDropdownItem,
    MDBDropdownMenu, MDBDropdownToggle
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
// import user from "../../src/components/asset/original.webp"
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import MoreButton from "./HomePage/MoreButton";
// import { BiLogOutCircle } from "react-icons/bi";
import { BsBellFill, BsSearch } from "react-icons/bs";

import { useAuth } from "../hooks";
import { ApiCall } from "../api/HelpingMethod";
import { cropText } from "../hooks/useCustomHelpingMethod";

const Notification = ({ }) => {
    const navigate = useNavigate()
    const { userLogout, authInfo } = useAuth();
    const [isLogin, setIsLogin] = useState(false);
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(true);

    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo?.profile?.id,
        "login_user_type": authInfo?.profile?.user_type,
        "limit": 6,
        "offset": 0
    })
    useEffect(() => {
        if (authInfo?.profile) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, [authInfo, isLogin])
    var loginuser = JSON.parse(localStorage.getItem("userstorage"));

    const getNotification = async () => {
        setLoading(true)
        const response = await ApiCall("dealer/notification", bodyData)
        if (response?.error === false) {
            setData(response?.data)
        } else {
            setData(null)
        }
        setLoading(false)
    }

    useEffect(() => {
        getNotification()
    }, [])

    return (
        <>

            <MDBDropdown>
                <Avatar alt="notification" style={{ height: "30px", width: "30px" }}>
                    <MDBDropdownToggle tag='div' className='nav-link d-flex justify-content-center'
                        role='button'>
                        <BsBellFill style={{ height: "20px", width: "20px" }} color="var(--primary)"
                            className="user-profile-img" />
                    </MDBDropdownToggle>
                </Avatar>
                <MDBDropdownMenu className="notification-drop-box">
                    <MDBDropdownItem className="mx-2">
                        <Row xs={{ cols: 1 }} className="g-1"><b>Notifications</b></Row>
                        <hr className="my-1" />
                        <Row xs={{ cols: 1 }} className="g-1" style={{ fontSize: "13px" }}>
                            {
                                data && Array.isArray(data) && data.map((data, index) => {
                                    return <Col key={index} onClick={() => {
                                        navigate("/profile?profile=notification")
                                    }} className="d-flex nav-hover-primary">
                                        <BsBellFill className="me-2 mt-1"
                                            color="var(--primary)" />{cropText(data?.title || data?.message || "", 60)}
                                        <br className="mb-1" />
                                        {/*<b style={{ fontSize: ".60rem" }}>{data?.date_add_period ? (data?.date_add_period) : get_datetime_formatted(data?.created_at || "")}</b>*/}
                                    </Col>;
                                })
                            }
                        </Row>

                        <Row>
                            <Col className="text-end" onClick={() => {
                                navigate("/profile?profile=notification")
                            }}>
                                <Link>View All</Link>
                            </Col>
                        </Row>
                    </MDBDropdownItem>

                </MDBDropdownMenu>
            </MDBDropdown>


        </>
    )
}
export default Notification;