import axios from "axios"
import client from "./client";
import {fetchHeader, requiredInput} from "./constant";

let ajaxRequest = null;

async function ApiCall(url, inputData, checkHeader) {
    const sentInput = {...inputData, ...requiredInput}
    // console.log(sentInput, fetchHeader);
    let header = {...fetchHeader};

    if (checkHeader) {
        if (ajaxRequest) {
            ajaxRequest.cancel();
        }
        ajaxRequest = axios.CancelToken.source();
        header.cancelToken = ajaxRequest.token;
    }
    const response = await client.post(url, sentInput, {headers: header})
        .then((r) => r.data).catch((e) => {
            // console.log("apiError", e);
            return {
                error: true,
                message: e?.message,
                data: null,
                status: 400
            }
        })
    // console.log(url, response);
    return response;
}

const getJobsheetStatus = (value = 'open', required = null) => {
    // 'pending',
    //     'open',
    //     'closed',
    //     'return',
    //     'service'
    if (required && required !== "") {
        if (required === 'pending') return {
            id: 1,
            value: 'pending',
            title: 'Pending',
            title_sub: 'Job is under process'
        }
        if (required === 'open') return {id: 2, value: 'open', title: 'Open', title_sub: ''}
        if (required === 'closed') return {
            id: 3,
            value: 'closed',
            title: 'closed',
            title_sub: 'Job successfully Completed'
        }
        if (required === 'return') return {
            id: 4,
            value: 'return',
            title: 'Returned',
            title_sub: 'Job is returned without repair'
        }
        if (required === 'service') return {
            id: 5,
            value: 'service',
            title: 'Request service',
            title_sub: 'Request Repair form iService'
        }
    }
    var data = [
        {
            id: 1,
            value: 'pending',
            title: 'Pending',
            title_sub: 'Job is under process'
        }, {
            id: 2,
            value: 'open',
            title: 'Open',
            title_sub: 'Open this jobsheet Again'
        }, {
            id: 3,
            value: 'closed',
            title: 'Closed',
            title_sub: 'Job successfully Completed'
        }, {
            id: 4,
            value: 'return',
            title: 'Returned',
            title_sub: 'Job is returned without repair'
        }, {
            id: 4,
            value: 'service',
            title: 'Request service',
            title_sub: 'Request Repair form iService'
        }
    ];
    return data;
    // return data.filter(item => item.value !== value);
}

export {getJobsheetStatus, ApiCall}