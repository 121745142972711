import React from 'react'
import MoreButton from './MoreButton'
import {Link} from 'react-router-dom'
import Brands from './Brands'

export default function BrandDisplay() {
    return (
        <div className='d-flex brand-c g-5'>
            <div className='brand-left-display px-3'>
                <h3 className=" brand-display-heading text-dark mt-4">Get Our <span
                    style={{color: "var(--primary)"}}>Services</span></h3>
                <p className='  {/*brandInner*/}'>
                    {/*They will assess the issue, provide a solution, and carry out the necessary repairs with precision and efficiency.*/}
                    {/*Our skilled technicians will arrive at the scheduled time to perform the repair.*/}
                    Our trained engineer will come to your home office when it is convenient for you. Our top priority
                    is data privacy.

                </p>
                <div className='my-4 more-brand-button'>
                    <Link to={"/services"} style={{textDecoration: "none", color: "white"}}>
                        <MoreButton>View More</MoreButton>
                    </Link>
                </div>
            </div>
            <div className='brand-right-display mt-4  px-3'>
                <Brands />
            </div>
        </div>
    )
}
