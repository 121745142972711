import React, {useEffect, useRef, useState} from "react";
import {Col,  Row} from "react-bootstrap";
import UserTypeSelectCard from "./UserTypeSelectCard";
import EngineeringIcon from '@mui/icons-material/Engineering';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import BecomeAPartnerForm from "./BecomeAPartnerForm";
import noitem from "../asset/Web Ad boost.webp"
import {HelmetCustomInclude} from "../HelmetCustomInclude";
import {ApiCall} from "../../api/HelpingMethod";
import {showAdBanners} from "../../api/client";


export default function BecomeAPartner() {
    const [pageTitle] = useState("Become A Partner");
    // const [metaKeywords, setMetaKeywords] = useState(null);
    // const [metaDescription, setMetaDescription] = useState(null);
    const [metaImageUrl] = useState(null);

    const [userType, setUserType] = useState("");
    const [userTypeObject, setUserTypeObject] = useState({});
    const sizeIconUserType = {width: "70px", height: "70px"};
    const userTypes = [
        // {
        //     id: 1,
        //     title: "Customer",
        //     value: 'client'
        // },
        {
            id: 2,
            title: "Dealer",
            description: <ul className={'text-start'}>
                <li>Wholesale Price</li>
                <li>Zero Investment</li>
                <li>Free CRM Dashboard</li>
                <li>Original part Support</li>
            </ul>,
            value: 'dealer',
            icon: <PersonIcon sx={sizeIconUserType} color={'warning'}/>
        }, {
            id: 3,
            title: "Service Engineer",
            description: <ul className={'text-start'}>
                <li>High Profit Margin</li>
                <li>Call base Charges</li>
                <li>Freelance Job</li>
                <li>Training and Part Support</li>
            </ul>,
            value: 'engineer',
            icon: <EngineeringIcon sx={sizeIconUserType} color={'warning'}/>
        }, {
            id: 4,
            title: "Sales Partner",
            description: <ul className={'text-start'}>
                {/*<li>Investment 10000 Rs-50000 Rs</li>*/}
                <li>Cost effective solution</li>
                <li>Own Part Business</li>
                <li>Freelance Job</li>
                <li>Training and Part Support</li>
            </ul>,
            value: 'delivery_boy',
            icon: <DeliveryDiningIcon sx={sizeIconUserType} color={'warning'}/>
        }, {
            id: 5,
            title: "Parts Wholesaler",
            description: <ul className={'text-start'}>
                {/*<li>Investment 1LK-10LK</li>*/}
                <li>Cost effective solution</li>
                <li>1 Crore Business</li>
                <li>100% Cash no credit business</li>
                <li>Original part Support</li>
            </ul>,
            value: 'distributor',
            icon: <ManageAccountsIcon sx={sizeIconUserType} color={'warning'}/>
        }, {
            id: 5,
            title: "Exclusive Service Center",
            description: <ul className={'text-start'}>
                {/*<li>Investment 1LK-5LK</li>*/}
                <li>Cost effective solution</li>
                <li>Free Online CRM</li>
                <li>100% Cash no credit business</li>
                <li>Original part Support</li>
            </ul>,
            value: 'service_center',
            icon: <BusinessCenterIcon sx={sizeIconUserType} color={'warning'}/>
        }
    ];

    const [isShowForm, setIsShowForm] = useState(false);

    const refForm = useRef(null);


    const handleSelectUserType = (val) => {
        refForm.current?.scrollIntoView({behavior: 'smooth'});
        setUserTypeObject(val);
        setUserType(val.value);
        setIsShowForm(true)
    }

    useEffect(() => {
        // document.title = 'Become A Partner | iService - Innovation Service';
        getBannerAdCall();
    }, []);
    const styleIcon = {color: "#ee610f", fontSize: "1.2em"}
    const [bannerTop, setBannerTop] = useState(null);

    const getBannerAdCall = async () => {
        await ApiCall('dealer/advertisement', {
            key_advertisement: "home_become_a_partner_top_page"
        }).then((r) => {
            setBannerTop(r?.data);
        });
    }
    const redirectBanner = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (
        <div>
            <HelmetCustomInclude url={"become-partner"} title={pageTitle} image={metaImageUrl}/>
            <section className="">
                {/*<div className="ourservices">*/}
                {/*    <h1 className=" mx-5 servicesHeading">Become A Partner</h1>*/}
                {/*    <p className="mx-5 subHeading">Are you looking for an exciting business opportunity?</p>*/}
                {/*</div>*/}
                <div className="container-fluid mt-md-5 mt-1">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <div className=" ">
                                <div className="row text-center mb-4">
                                    {/*<h1 className="col-md-12 "><span className={'color-primary'}>Become A Partner</span>*/}
                                    <h1 className="col-md-12 "><span className={'color-primary'}>Become a Seller</span>
                                    </h1>
                                    <h5>Are you looking for an exciting business opportunity?</h5>
                                    {/*<p>Please provide following details.</p>*/}
                                </div>
                                {showAdBanners && bannerTop?.url_image && <Row className='m-0'>
                                    <Col>
                                        <img onClick={() => redirectBanner(bannerTop?.url_redirect)}
                                             src={bannerTop?.url_image || noitem} className='w-100 banner-image pointer'
                                             alt={'no advertisement'}/>
                                    </Col>
                                </Row>}
                                <div className="row ms-sm-4 me-sm-4 ms-1 me-1 mb-3 mt-3">
                                    <div className="col-md-12 ">
                                        <Col className={''}>
                                            {/*<h5 className={'mb-3'}>You want to register with us as : </h5>*/}
                                            <div className="row g-3">
                                                {userTypes.map((element, idx) => {
                                                    return (
                                                        <div className="col-md"
                                                             key={"usr-typ-" + idx}>
                                                            <UserTypeSelectCard
                                                                onClick={() => handleSelectUserType(element)}
                                                                data={element}
                                                                icon={element.icon}
                                                                isActive={element.value === userType}/>
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                        </Col>
                                    </div>
                                </div>

                                {isShowForm && (
                                    <div>
                                        <hr/>
                                        <BecomeAPartnerForm refContent={refForm} userType={userType}
                                                            userTypeObject={userTypeObject}/>
                                    </div>
                                )}

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}
