import client from "./client";

const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYjQxYjcyN2VkOTY5NGMzODQ1ZjM3ZDFmMTkwMjAyYzMxODY4YmU2MTc3OGI2ZjJlOGMwNjY2MzZkZjhhMGE5NzE0ZWE4ZGE2MTBlMjkxYWMiLCJpYXQiOjE2Nzc4MjI2MTEuMTk0MDYxLCJuYmYiOjE2Nzc4MjI2MTEuMTk0MDcsImV4cCI6MzMyMzQ3MzE0MTEuMTYwNjYsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.zTZvF547MhKLOm9sAfqs1Y5hRTpSziPc8TEAV_N_XnukXUoi3KxBHLl6J2avQm2vw_IvIw9Wmvjun8jZjF2xxRICx157cL12lw7TMruNqkynWhZ5k0V_FnP6KrcUO9mTOMR9gNj_mASpHDeMqFeFIJChPWxvd_1lslPaJ91om5FHFnhm8OzzRZXQweSFM9J0UO5t4UfixkWoG0xatubyeFkVp4GsYusoG4UGYFSGCj5aM2KqPmg6LvMIh6skJraV9ufSdj7mWMXCLoA8feSl40bvpHttfdaI709gb3ajyXMtksKt6fGILD8_kESxvP4N1HYdMGO49M2y8ASOHBV5d7OPzYl0PoiTtWSTnmhiJbflFsbczI8OGfYG0cubb8nSQDxRJQ51QReIwO7UCuxnEnDG8Gz7IKOg8ahnarcmBrW2zxr0hM1UmOeAsry9gB-Q8RABcB7fUPBMZN45r6oJqF5inr6qYKouS8_sd78ebUY-jAyYvSFyCL_uZ3lTDic4qXTtICf981GwSa6uGKcdR8UYrq0xbiU0N7RAAua2TF0aVfA6waMT8aCfjuvQA-ypk64YyydkfPr8QOUrqByuNA6OWgkkcdsKSUrD8V6dJvg3ZLEukS_lOTi5SZcvIpjaIQnVKpCgNcLVyqEz-e7ENEr81F0zepDfROlxCeehCpg"
const fetchHeader = {
    Authorization: "Bearer " + authToken,
    accept: "application/json",
};
const requiredInput = {
    device_type: "web",
    istoken: "ISABCDEFGH",
    device_token: localStorage.getItem('device_token') || "ABCDEFGH",
};

export const signInUser = async (userInfo) => {
    try {
        const {data} = await client.post("/client/user_login", {
            ...userInfo, ...requiredInput,
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        }, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response)

        return {error: error.message || error};
    }
};

export const verifyUser = async (userInfo) => {
    try {
        const {data} = await client.post("/client/user_login", {
            ...userInfo, ...requiredInput,
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        }, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};
export const getUser = async (userInfo) => {
    try {
        const {data} = await client.post("/client/user_profile", {
            ...userInfo, ...requiredInput,
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        }, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const registerUser = async (userInfo) => {
    try {
        let headerImport = {...fetchHeader};
        if (userInfo?.uploadImage) {
            headerImport = {
                Authorization: "Bearer " + authToken,
                'Content-Type': 'multipart/form-data'
            }
        }
        const inputReq = {
            ...userInfo, ...requiredInput,
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        };
        // console.log(inputReq)
        // console.log(headerImport)
        const {data} = await client.post("/client/user_profile_register_multi_step", inputReq, {
            headers: {...headerImport},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const registerUserUpload = async (formData) => {
    try {
        formData.append('device_type', requiredInput?.device_type);
        formData.append('istoken', requiredInput?.istoken);
        formData.append('device_token',  localStorage.getItem('device_token') || requiredInput?.device_token);

        // console.log(formData)
        const {data} = await client.post("/client/user_profile_register_multi_step", formData, {
            headers: {
                Authorization: "Bearer " + authToken,
                'Content-Type': 'multipart/form-data'
            },
        });
        // console.log("Form upload");
        // console.log(data);
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const sendOtp = async (userInfo) => {
    try {
        const {data} = await client.post("/client/verify_user_by_otp", {
            ...userInfo, ...requiredInput,
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        }, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const verify = async (userInfo) => {
    try {
        const {data} = await client.post("/client/verify_user", {
            ...userInfo, ...requiredInput,
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        }, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const user_logout = async (userInfo) => {
    try {
        const {data} = await client.post("/client/user_logout", {
            ...userInfo, ...requiredInput,
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        }, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const reset_password = async (userInfo) => {
    try {
        const {data} = await client.post("/client/user_profile_update", {...userInfo, ...requiredInput}, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};


export const updateProfile = async ({inputData, user_id, uploadPic, url_profile}) => {
    try {
        let headerImport = {...fetchHeader};
        if (uploadPic) {
            headerImport = {
                Authorization: "Bearer " + authToken,
                'Content-Type': 'multipart/form-data'
            }
        }

        const inputReq = {
            ...requiredInput,
            login_user_id: user_id,
            values: {...inputData},
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        };

        if (url_profile) {
            inputReq.url_profile = url_profile;
        }
        // console.log(inputReq)
        // console.log(headerImport)

        const {data} = await client.post("/client/user_profile_update", inputReq, {
            headers: {...headerImport},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const userPasswordUpdate = async (userInfo) => {
    try {
        const {data} = await client.post("/client/user_password_update", {
            ...userInfo, ...requiredInput,
            device_token: localStorage.getItem('device_token') || "ABCDEFGH"
        }, {
            headers: {...fetchHeader},
        });
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return response.data;
        // console.log(response.data)

        return {error: error.message || error};
    }
};