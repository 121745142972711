import {ProblemPageBody} from "./ProblemPageBody";
import {ProblemPageDes} from "./ProblemPageDs";


export function ProblemPageForm({token, setToken, brand, iemi, handeNext, handleBack, data}) {

    return (
        <div className="center">
            <h4 className={'my-3'}>Client Device Problem's</h4>
            {/*<h4 className={'my-3'}>Tell Us About Your Client Device Problem's</h4>*/}
            {/*<p> We need some of the following information about customer device</p>*/}

            <ProblemPageBody data={data}/>
            <ProblemPageDes setToken={setToken} data={data} token={token} handeNext={handeNext} handleBack={handleBack} iemi={iemi}/>
        </div>
    );
}
