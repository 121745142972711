import React from 'react'
import ServiceCategories from './ServiceCategories'
// import {useLocation} from "react-router-dom";

export default function ServiceMain() {
   // const locationTemp=useLocation();
      
     
  return (
    <div>
        <div className="ourservices">
        <h3 className=" mx-5 servicesHeading">Service Categories</h3>
        <p className="mx-5 subHeading">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum augue ex, a feugiat libero porttitor vel. 
        </p>
      </div>
      <div className="container my-3">
      <div className="row ">
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
    <div className="col-md-4 py-3"><ServiceCategories/></div>
  </div>
  </div>
    </div>
  )
}
