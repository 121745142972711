const moneyFormatter = new Intl.NumberFormat('en-IN', {
    // maximumSignificantDigits: 3,
    maximumFractionDigits: 2,
})

// const moneyFormatter = (num) => num.toLocaleString('en-In', {
//     // maximumSignificantDigits: 3,
//     maximumFractionDigits: 2,
// });

const cropText = (str, count = 50) => {
    if (str) {
        if (str.length > count) {
            const deTagged = str.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
            let deNewlined = deTagged.replace(/\n/g, '');
            deNewlined = deNewlined.replaceAll("&nbsp;", ' ');

            return deNewlined.substring(0, count) + "..."
        } else {
            return str;
        }
    } else {
        return "";
    }

};

const getFirstWordCap = (str) => {
    str = str.replace(/_/g, " ");
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
};

const profileText = (str) => {
    const count = 5;
    if (str.length > count) {
        return str.substring(0, count);
    } else {
        return str;
    }

}


const formatMoney = (amount) => moneyFormatter.format(amount || 0);

// const formatMoney = (amount) => moneyFormatter(amount || 0);

function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
        return true;
    } else {
        img.onload = () => {
            return true;
        };
        img.onerror = () => {
            return false;
        };
    }
}


function get_status_jobsheet() {
    return [
        'pending_quotation',
        'price_updated',
        'confirmed',
        'processing',
        'pick_up_completed',
        'delivered_to_repair_factory',
        'pending_delivery',
        'delivered',
        'completed',
        'return_requested',
        'return_rejected',
        'return_accepted',
        'refunded',
        'cancelled_by_user',
        'rejected_by_admin',
        'rejected_by_agent'
    ];
}

function get_color_by_status(status) {
    let color = "#fc8844";
    if (status === 'incomplete') {
        // color = "#f11616";
        color = "#757474";
    } else if (status === 'pending_quotation' || status === 'pending') {
        color = "#fc8844";
    } else if (status === 'delivered' || status === 'completed') {
        color = "#38d713";
    } else if (status === 'cancelled_by_user' || status === 'cancelled') {
        color = "#e70000";
    } else {
        color = "#1d68de";
    }
    return color;
}

const getFileSizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const getFullPageTitle = (title) => {
    return (title || "") + " | iService - Innovation Service"
}

const dateTimeOptions = {day: '2-digit', month: 'short', year: 'numeric', hour: "numeric", minute: "numeric"};
const dateOptions = {day: '2-digit', month: 'short', year: 'numeric'};

const get_datetime_formatted = (inputDate) => {
    if (inputDate) {
        return new Date(inputDate).toLocaleDateString('en-IN', dateTimeOptions);
    } else {
        return null;
    }
}

function get_date_formatted(dateCustom) {
    // let date = new Date();
    // if (dateCustom) {
    //     date = new Date(dateCustom);
    // }
    // const day = date.getDate();
    // const month = date.getMonth();
    // const year = date.getFullYear();
    //
    // return day + "/" + month + "/" + year;
    if (dateCustom)
        return new Date(dateCustom).toLocaleDateString('en-IN', dateOptions)
    else
        return "";
}

function get_date_formatted_current(dateCustom) {
    return new Date().toLocaleDateString('en-IN', dateOptions)
}

function get_status_formatted(status) {
    if (status) {
        if (status === 'cancelled_by_user') {
            status = "Cancelled";
        } else if (status === 'reschedule_by_user') {
            status = "Rescheduled";
        } else if (status === 'delivery_success') {
            status = "Delivered";
        } else {

        }
        return status ? getFirstWordCap(status.replaceAll("_", " ")) : "";
    } else {
        return status;
    }

}

export {
    get_status_formatted,
    getFullPageTitle,
    getFileSizes,
    get_color_by_status,
    get_status_jobsheet,
    cropText,
    moneyFormatter,
    get_datetime_formatted,
    formatMoney,
    checkIfImageExists,
    getFirstWordCap,
    get_date_formatted,
    profileText,
    get_date_formatted_current
};