import React from 'react'
import {Link, Outlet} from 'react-router-dom';
import {useAuth} from '../hooks';
import AuthNav from './AuthNav';
import Footer from './Footer';
import TempNav from './TempNav';
import {FaArrowCircleUp, FaArrowUp, FaWhatsapp, FaWhatsappSquare} from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";
import Header from './Header';

export default function Layout({}) {
    const {authInfo} = useAuth();
    return (
        <div>
            <section style={{paddingBottom: "8vh"}} className={'d-print-none'}>
                <Header   />
            </section>
            <div className="sticky-socal-bar d-print-none">
                <Link target={"_blank"}
                      to={encodeURI("https://api.whatsapp.com/send?phone=919713300060&text=Hello iService Team, I need help.")}
                      style={{textDecoration: "none"}}>
                    {/*<FaWhatsapp size={40} color={"#25D366"}/> */}
                    <FaWhatsapp size={30} color={"#ffffff"}/>
                </Link>
            </div>
            <ScrollToTop className="sticky-scroll-to-top d-print-none" style={{marginRight: "-30px"}} smooth
                         component={<FaArrowUp color={"var(--primary)"} size={20}/>}/>
            <Outlet/>
            <Footer />
        </div>
    )
}
