import {React, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useAuth, useNotification} from "../../hooks";
import {StepZeroSelectUserType} from "./Register/StepZeroSelectUserType";
import {StepOneBasicDetail} from "./Register/StepOneBasicDetail";
import {StepTwoBusinessDetail} from "./Register/StepTwoBusinessDetail";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {StepThreeAdditionalDetail} from "./Register/StepThreeAdditionalDetail";
import {StepFourShopImageDetail} from "./Register/StepFourShopImageDetail";
import "./../../SignUpForm.css";

export default function SignUpForm() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {checkUser} = useAuth();
    const {showError, showSuccess} = useNotification();
    const [regStep, setRegStep] = useState(0);
    const [regUserType, setRegUserType] = useState(searchParams.get("user_type_def") || "client");
    const [regUserToken, setRegUserToken] = useState("");
    const [isLoaded, setIsLoaded] = useState(true);
    const {state} = useLocation();
    const [activeStep, setActiveStep] = useState(0);
    const [pickLocation, setPicLocation] = useState(false)
    const [previousData, setPreviousData] = useState({})
    const [regUserType2, setRegUserType2] = useState(regUserType);

    const userDetails = JSON.parse(localStorage.getItem("user-register"));

    const checkUserProcess = async (username) => {
        if (isLoaded && username) {
            const { error, data, message } = await checkUser({
                username: userDetails?.username
            });
            // console.log(error);
            // console.log(message);
            if (!error && data) {
                setUserInfo(data);
                // console.log(data); 

                if (data?.user_type) {
                    setRegUserType(data?.user_type);
                }
                if (data?.token) {
                    setRegUserToken(data?.token);
                }
                if (data?.step) {
                    if (data.step === "business" || data.step === 'shop') {
                        setRegStep(2);
                        setActiveStep(1)
                    } else if (data.step === 'upload_registration') {
                        setRegStep(3);
                        setActiveStep(2)

                    } else if (data.step === 'upload_images') {
                        setRegStep(4);
                        setActiveStep(3)
                    } else {
                        setRegStep(1);
                        setActiveStep(0)  
                    }
                    // setActiveStep((prevActiveStep) => (regStep - 1));
                }
                setIsLoaded(false);
            }

        }
    }

    useEffect(() => {
        if (userDetails?.username) {
            checkUserProcess(userDetails?.username);
        } else {
            if (!regUserType) navigate("/auth");
        }
    }
    )

    useEffect(() => {
        if (searchParams.get("user_type_def")) {
            setRegUserType(searchParams.get("user_type_def"));
            setRegStep(1);
        }
    },[])
     useEffect(() => {
         setRegUserType2(regUserType || searchParams.get("user_type_def"));
         console.log(regUserType, searchParams.get("user_type_def"));
     },[searchParams.get("user_type_def"),regUserType])

    const [userInfo, setUserInfo] = useState({
        name: "",
        email: userDetails?.email || "",
        mobile: userDetails?.mobile || "",
        username: userDetails?.username || "",
        password: "",
        otp: "",
        // device_type: "web",
        // istoken: "ISABCDEFGH",
        // device_token: "ABCDEFGHIJK"
    });

    const steps = ["Personal", "Business", "Additional", "Shop Images"];
    const [skipped, setSkipped] = useState(new Set());

    const isStepOptional = (step) => step === 1;

    const isStepSkipped = (step) => skipped.has(step);

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <section className="section">
            <div className="col-12 col-md-8 col-lg-6 col-xl-7 temp1">

                <div className="card shadow-2-strong auth-card ">
                    <div className="card-body {/*temp*/}">

                        {/*<p className=" text-center auth-heading text-capitalize">
                            {regUserType||regUserType2} Registration
                            Create an account
                            <br/>
                            {regUserType ? "(Registration For " + regUserType + ")" : ""}
                        </p>*/}
                        {/*className="stepLabelSignUp"*/}
                        {regUserType === 'dealer' ? (<Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index + 1)) {
                                    labelProps.optional = <Typography variant="caption" />;
                                }
                                if (isStepSkipped(index + 1)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps} className="steplabel">
                                        <StepLabel {...labelProps} style={{color: "#00000"}}>
                                            <div className=" ">{label}</div>
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>) : <></> }
                        {/*<p className="text-center mb-5">
                            Book A Repair, purchase parts and get the best offers.
                        </p>*/}
                        <p className={'my-5'} />
                        {regStep === 0 &&
                            <StepZeroSelectUserType handleNext={handleNext} showError={showError} showSuccess={showSuccess}
                                regStep={regStep} userInfo={userInfo}
                                setRegStep={setRegStep} regUserType={regUserType}
                                setRegUserType={setRegUserType} />}
                        {regStep === 1 &&
                            <StepOneBasicDetail previousData={previousData} setPreviousData={setPreviousData} regUserToken={regUserToken} setRegUserToken={setRegUserToken}
                                handleNext={handleNext} email={userInfo.email} mobile={userInfo.mobile}
                                username={userInfo.username}
                                basicData={userInfo}
                                showError={showError}
                                showSuccess={showSuccess} regStep={regStep}
                                setRegStep={setRegStep} regUserType={regUserType}
                                setRegUserType={setRegUserType} />}

                        {regStep === 2 &&
                            <StepTwoBusinessDetail previousData={previousData} setPreviousData={setPreviousData} pickLocation={pickLocation} setPicLocation={setPicLocation} regUserToken={regUserToken} setRegUserToken={setRegUserToken}
                                handleNext={handleNext} email={userInfo.email} mobile={userInfo.mobile}
                                username={userInfo.username}
                                showError={showError} basicData={userInfo}
                                showSuccess={showSuccess} regStep={regStep}
                                setRegStep={setRegStep} regUserType={regUserType}
                                setRegUserType={setRegUserType} setActiveStep={setActiveStep}/>}
                        {regStep === 3 &&
                            <StepThreeAdditionalDetail previousData={previousData} setPreviousData={setPreviousData} regUserToken={regUserToken} setRegUserToken={setRegUserToken}
                                handleNext={handleNext} email={userInfo.email}
                                mobile={userInfo.mobile}
                                username={userInfo.username}
                                showError={showError} basicData={userInfo}
                                showSuccess={showSuccess} regStep={regStep}
                                setRegStep={setRegStep} regUserType={regUserType}
                                setRegUserType={setRegUserType} setActiveStep={setActiveStep}/> }
                        {regStep === 4 &&
                            <StepFourShopImageDetail previousData={previousData} setPreviousData={setPreviousData} pickLocation={pickLocation} setPicLocation={setPicLocation} regUserToken={regUserToken} setRegUserToken={setRegUserToken}
                                handleNext={handleNext} email={userInfo.email} mobile={userInfo.mobile}
                                username={userInfo.username}
                                showError={showError} basicData={userInfo}
                                showSuccess={showSuccess} regStep={regStep}
                                setRegStep={setRegStep} regUserType={regUserType}
                                setRegUserType={setRegUserType} setActiveStep={setActiveStep} />}
                        {/*<p className="text-center">
                            Already have an account?
                            <Link to={"/auth"} style={{color: "#EE610F"}}>
                                {" "}
                                Login
                            </Link>
                        </p>*/}
                        <p className="text-center mt-3">
                            Back to home?
                            <Link to={"/"} style={{ color: "#EE610F" }}> Home</Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
export const isValidEmail = (email) => {
    const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return isValid.test(email);
};
