import {React, useEffect, useState} from "react";
import {category_list} from "../../api/general";
import ServiceCard from "../HomePage/ServiceCard";
import SkeletonFile from "./SkeletonFile";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import {cropText, getFullPageTitle} from "../../hooks/useCustomHelpingMethod";
import {appFooterLogo} from "../../api/constant";
import noitem from "../asset/Comp 2_00000.jpeg"
import {Col, Row} from "react-bootstrap";
import {HelmetCustomInclude} from "../HelmetCustomInclude";
import {showAdBanners} from "../../api/client";
import {ApiCall} from "../../api/HelpingMethod";

export default function ServicePage() {
    const [pageTitle, setPageTitle] = useState("Book Repair Services for Mobile, Laptop, Tablet, TV Installation and More");
    // const [metaKeywords, setMetaKeywords] = useState("Mobile Service, Laptop Service, TV Installation, Tablet Service, Phone Repair Service, Onsite Repair");
    // const [metaDescription, setMetaDescription] = useState("Our expert technicians have years of experience repairing various mobile and laptops. They can quickly and effectively diagnose and repair your mobile phone or laptop problems.");
    const [metaImageUrl, setMetaImageUrl] = useState(null);

    const [product_details, setProduct_details] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const getData = async () => {
            setLoading(true);
            const {data} = await category_list({is_service: "yes"});
            setLoading(false);
            setProduct_details(data.data);
        };
        getData();
        getBannerAdCall();
    }, []);

    const [bannerTop, setBannerTop] = useState(null);
    const [bannerBottom, setBannerBottom] = useState(null);

    const getBannerAdCall = async () => {
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_service_top_in_page_banner"
        }).then((r) => {
            setBannerTop(r?.data);
        });
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_service_bottom_in_page_banner"
        }).then((r) => {
            setBannerBottom(r?.data);
        });
    }
    const redirectBanner = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (
        <div>
            <HelmetCustomInclude url={"services"} title={pageTitle} image={metaImageUrl}/>

            <div className="ourservices">
                <div className="container">
                    <h3 className="servicesHeading">Our Services</h3>
                    <p className=" subHeading ">
                        {/*We at iService believe in giving our best to our customers. That's the reason we don't compromise on quality*/}
                        We repair almost everything in front of you, including your most important gadgets, with highly
                        skilled engineers.

                    </p>
                </div>
            </div>


            {showAdBanners && bannerTop?.url_image && <Row className='m-1'>
                <Col>
                    <img onClick={() => redirectBanner(bannerTop?.url_redirect)}
                         src={bannerTop?.url_image || noitem} className='w-100 banner-image pointer'
                         alt={'no advertisement'}/>
                </Col>
            </Row>}

            <div className="container my-3">

                <div className="row">
                    {loading && <SkeletonFile/>}

                    {product_details.map((element, index) => {
                        return (
                            <div key={element?.slug || index}
                                 className="col-md-3 my-3 d-flex justify-content-center align-item-center">
                                <ServiceCard
                                    title={element.title}
                                    image={element.url_icon}
                                    description={element.description}
                                    slug={element.slug}
                                />
                            </div>
                        );
                    })}

                </div>

            </div>

            {showAdBanners && bannerBottom?.url_image && <Row className='m-1'>
                <Col>
                    <img onClick={() => redirectBanner(bannerBottom?.url_redirect)}
                         src={bannerBottom?.url_image || noitem} className='w-100 banner-image pointer'
                         alt={'no advertisement'}/>
                </Col>
            </Row>}

        </div>
    );
}
