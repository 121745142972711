import React, { useEffect, useState } from "react";
import { checkIfImageExists } from "../../hooks/useCustomHelpingMethod";
import banner from "../asset/banner.webp"
import { part_list } from "../../api/general";
import { useNavigate } from "react-router-dom";
import ShopCard from "../PartPurchase/ShopCard";
import { Col, Row } from "react-bootstrap";

export default function SimilarProducts({ data, brand_id, model_id, colLg = 6, colMd = 4, colSm = 3, colXs = 2 }) {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate()
    // console.log("Similar product")
    // console.log(brand_id)
    const getData = async () => {
        const inputData = {
            offset: 0
        }
        if (brand_id) {
            inputData.brand_id = brand_id;
            inputData.brand_true = true;
        }
        if (model_id) {
            inputData.model_id = model_id;
            inputData.model_true = true;
        }
        // console.log(inputData);
        const { data } = await part_list(inputData);
        // console.log(data);
        if (!data.error) {
            setProducts(data.data);
            // console.log(setProducts.length);
        }
    };

    useEffect(() => {
        getData();
    }, [brand_id, model_id]);
    const viewAllProduct = () => {
        // let reDirectRoute = "/shop?";
        let reDirectRoute = "/product?";
        navigate(reDirectRoute);
    }
    return (
        <div>
            {/*<hr />*/}
            <div className="container  align-items-center my-2">
                <h2 className="">Similar Products</h2>
                <Row lg={colLg} md={colMd} sm={colSm} xs={colXs}>
                    {products != null && products.slice(0, 8).map((element,index) => {
                        return (
                            <Col key={index} className={' my-3 d-flex justify-content-center align-item-center'}>
                                <ShopCard part={element}
                                    image={element.url_image && checkIfImageExists(element.url_image) ? element.url_image : banner}
                                    price={element.amount_price_customer} title={element.title}
                                    slug={element.slug} />
                            </Col>
                        );
                    })
                    }
                </Row>
            </div>
        </div>
    );
}
