import ImageSlider from "../../Context/ImageSlider";
import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { get_coupon_codes } from "../../api/general";
import { useAuth } from "../../hooks";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import { Carousel } from "react-bootstrap";
import banner from "../asset/banner.webp"
import { useNavigate } from "react-router-dom";

export function HomeTopSlider({ data }) {
    const { authInfo } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [sliderImages, setSliderImages] = useState([])
    const getBannerData = async () => {
        setIsLoading(true);
        const { data } = await get_coupon_codes({
            limit: 10,
            offset: 0,
            login_user_id: authInfo?.profile?.id || 0,
            login_user_type: authInfo?.profile?.user_type || "client"
        })
        setSliderImages(data || []) 
        setIsLoading(false);
    };

    useEffect(() => {
        getBannerData();
    }, []);

    const newLocation = (url) => {
        window.location.href(url) 
    }

    return (
        <>
            <Card className={'card-custom my-3 mx-auto'}>
                {isLoading && <SkeletonFileGeneral height={400} col={12} total={1} />}
                {/*<CustomImageSlider />*/}
                {/*{!isLoading && <ImageSlider sliderData={sliderImages} slides={sliderImages}/>}*/}
                {!isLoading && <Carousel className={'slider-home-top'} data-bs-theme="dark">
                    {sliderImages && Array.isArray(sliderImages) && sliderImages.length > 0 && sliderImages.map((imageObject, index) => (
                        <Carousel.Item onClick={() => imageObject?.url_redirect ? window.open(imageObject?.url_redirect) : null} interval={5000} key={index} className={'carousel-item-home pointer'}>
                            {/*<ExampleCarouselImage text="First slide" />*/}
                            <img className="d-block w-100"
                                src={imageObject.url_image ? imageObject.url_image : banner}/*"holder.js/800x400?text=Second slide&bg=eee"*/
                                alt={imageObject?.title || "slider image"}
                                onError={(e) => e.target.src = banner}
                            />
                            <Carousel.Caption>
                                {/*<h3>{imageObject?.title}</h3>*/}
                                {/*<p>{imageObject?.description}</p>*/}
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>}
            </Card>
            {/* <div className=''>
                <h1 className='banner-heading-text'>Indian’s <span
                    className={'color-primary'}>Most Trusted</span> Repair
                </h1>
                <h1 className='banner-heading-text'>Services - Fast & Reliable</h1>
                <h5 className='banner-heading-sub-text'>
                    <p> At Service Jet, we take immense pride in being recognized as one of India's most
                        trusted providers of repair services. We have earned this distinction by
                        consistently
                        delivering fast and reliable solutions to meet the repair needs of our valued
                        customers.</p>
                    <p><b>Fast Service:</b> We understand that when something breaks down, you need it fixed
                        quickly. Our expert technicians are dedicated to providing speedy solutions without
                        compromising on quality.</p>

                    <p><b>Reliability:</b> We have built our reputation on being a reliable partner for all
                        your repair needs. You can count on us to get the job done right the first time.</p>
                </h5>
                <Row xs={{cols: 1}} sm={{cols: 2}} md={{cols: 3}} className={"g-3"}>
                    <Col><TopServiceRepairCard icon={<HiOutlineShoppingCart size={30}/>}
                                               title={'Reliable Purchase'}/></Col>
                    <Col><TopServiceRepairCard icon={<BiTimer size={30}/>} title={'Fast Repair'}/></Col>
                    <Col><TopServiceRepairCard icon={<HiOutlineCheckBadge size={35}/>}
                                               title={'Best Service Engineer'}/></Col>
                </Row>
            </div>*/}
        </>
    );
}