// import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import {Card, Row, Col, Image} from "react-bootstrap";
import './../../../MultipleFilesUpload.css';
import {getFileSizes} from "../../../hooks/useCustomHelpingMethod";
import {FaTrash} from 'react-icons/fa'

export function UploadMultipleFiles({setSelectedFile, selectedFile, files, setFiles}) {


    // const filesizes = (bytes, decimals = 2) => {
    //     if (bytes === 0) return '0 Bytes';
    //     const k = 1024;
    //     const dm = decimals < 0 ? 0 : decimals;
    //     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    //     const i = Math.floor(Math.log(bytes) / Math.log(k));
    //     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    // }

    const InputChange = (e) => {
        // --For Multiple File Input
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                setSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: i /*shortid.generate()*/,
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            fileOriginal: e.target.files[i],
                            datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: getFileSizes(e.target.files[i].size)
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }

    }


    const DeleteSelectFile = (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = selectedFile.filter((data) => data.id !== id);
            setSelectedFile(result);
        } else {
            // alert('No');
        }

    }

    const DeleteFile = async (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = files.filter((data) => data.id !== id);
            setFiles(result);
        } else {
            // alert('No');
        }
    }

    return (
        <div className="fileupload-view">
            <Card>
                <Card.Body>
                    <div className="kb-data-box">
                        <div className="kb-modal-data-title">
                            <div className="kb-data-title">
                                <h6>Upload Your Business Shop images</h6>
                            </div>
                        </div>
                        <div className="kb-file-upload">
                            <div className="file-upload-box">
                                <input type="file" id="fileupload" className="file-upload-input"
                                       onChange={InputChange} multiple/>
                                <span>Drag and drop or <span className="file-link">Choose your files</span></span>
                            </div>
                        </div>
                        <div className="kb-attach-box pb-3 text-left text-start"
                             style={{maxHeight: "250px", maxWidth: "100%", overflow: "auto"}}>
                            <Row className={'g-3 w-100'} md={{cols: 3}} sm={{cols: 2}} xs={{cols: 1}}>
                                {
                                    selectedFile.map((data, index) => {
                                        const {id, filename, filetype, fileimage, datetime, filesize} = data;
                                        return (
                                            <Col className="overflow-hidden" key={id}>
                                                {
                                                    filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                        <div className="file-image">
                                                            <Image src={fileimage} alt="sa"/>
                                                        </div> :
                                                        <div className="file-image"><i className="far fa-file-alt"/>
                                                        </div>
                                                }
                                                <div>
                                                    <div className="row fw-bold" style={{fontSize: "10px"}}>
                                                        {/*<div className={'col'}>{filename}</div>*/}
                                                        <div className={'col-9'}> {filesize}</div>
                                                        <div className={'col-3 text-center'}>
                                                            <a href="#" onClick={() => DeleteSelectFile(id)}><FaTrash className={'text-danger'}/></a></div>
                                                    </div>
                                                    {/*<div className="file-actions">
                                                        <button type="button"
                                                                className="file-action-btn"
                                                                onClick={() => DeleteSelectFile(id)}>Delete
                                                        </button>
                                                    </div>*/}
                                                </div>
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}