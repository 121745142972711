import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert, AlertTitle, CircularProgress, Fab, InputAdornment, TextareaAutosize} from "@mui/material";
import {FaTimes} from "react-icons/fa";
import {booking_cancel} from "../../../api/booking";
import {useState} from "react";
import {useAuth, useNotification} from "../../../hooks";
import {useNavigate} from "react-router-dom";

import {Col, Row} from "react-bootstrap";
import {CForm, CFormInput} from "@coreui/react";
import {ApiCall} from '../../../api/HelpingMethod';

export default function RescheduleModal({status, token}) {
    const [open, setOpen] = React.useState(false);


    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [list, setList] = useState([
        "09AM-11AM",
        "11AM-01PM",
        "01PM-03PM",
        "03PM-05PM",
        "05PM-07PM",
        "07PM-09PM",
    ]);

    const [dateMin, setDateMin] = useState(new Date().toISOString().split("T")[0]);
    const [dateMax, setDateMax] = useState(new Date());
    const addDays = () => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 7);
        setDateMax(newDate.toISOString().split("T")[0]);
    };

    React.useEffect(() => {
        addDays();
    }, []);
    const [validated, setValidated] = useState(false)
    const {showError, showSuccess} = useNotification()
    const {authInfo} = useAuth()
    const [loading, setLoading] = useState(false)
    const dateChange = (e) => setDate(e.target.value);
    const handleSelect = (element) => setTime(element);
    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "action_type": "update_status",
        "status": "reschedule_by_user"
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        setLoading(true)
        bodyData.expected_visit_date = date
        bodyData.expected_visit_time = time
        bodyData.token_order = token

        if (!bodyData?.expected_visit_date) {
            setLoading(false)
            showError("Please select next visit date");
            return;
        }
        if (!bodyData?.expected_visit_time) {
            setLoading(false)
            showError("Please select next visit time");
            return;
        }


        const response = await ApiCall("dealer/order/book-service-action", bodyData);
        if (response?.error === false) {
            setLoading(false)
            showSuccess(response?.message)
            window.location.reload()
        } else {
            showError(response?.message)
        }
        setLoading(false)

        handleClose()
    }

    return (
        <div>
            {/* <Button className={'sign-up-button'} onClick={handleClickOpen}>
                Cancel Order
            </Button>*/}
            <button className="sign-up-button float-end"
                    onClick={handleClickOpen}>Reschedule
            </button>
            {/* <Fab variant="extended" size="small" color="primary" onClick={handleClickOpen}>
                <FaTimes sx={{ mr: 3 }} />
                Cancel Order
            </Fab>*/}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Order Reschedule</DialogTitle>
                <DialogContent>
                    <div className="dateContainer">
                        <CForm className="needs-validation"
                               noValidate validated={validated}
                            //  onSubmit={handleSubmit}
                        >
                            <Row className={"g-4 "}>
                                <Col className={'mx-auto '} md={6}>
                                    <CFormInput type="date" onChange={dateChange}
                                                className="book-date"
                                                min={dateMin} max={dateMax}
                                                value={date}
                                                placeholder="Select date which engineer visit"/>
                                </Col>
                                <Col md={12}>
                                    <div className="selectionDate">
                                        {list.map((element, idx) => {
                                            // console.log(element);
                                            return (
                                                <button key={idx} onClick={() => handleSelect(element)} value={element}
                                                        type={'button'}
                                                        className={`Selectiondatebtn ${time === element ? "Selectiondatebtn1" : ""}`}>
                                                    {element}
                                                </button>
                                            );
                                        })
                                        }
                                    </div>

                                </Col>
                            </Row>

                        </CForm>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'inherit'}>Cancel</Button>
                    <Button disabled={loading} variant={'contained'} color={'warning'} className={'sign-up-button'}
                            onClick={handleSubmit}>{loading &&
                    <CircularProgress size={16} color={"inherit"}/>}&nbsp; Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}