import React, { useEffect  } from "react";
import ProfileAside from "./ProfileAside";
import UserInformation from "./UserInformation";
import { Card, Col, Container, Row } from "react-bootstrap";
// import userimg from "../asset/Ellipse 30.webp";
import "./UserProfile.css";
// import { BiPhone } from "react-icons/bi";
import ChangePassword from "./ChangePassword";
// import { Avatar, Chip } from "@mui/material";
import { useAuth, useNotification } from "../../hooks";
// import { getFirstWordCap } from "../../hooks/useCustomHelpingMethod";
import NotificationList from "../../Notification/NotificationList";
import AddressList from "./AddressList";
import BusinessDetail from "./BusinessDetail";
import { useNavigate, useSearchParams } from "react-router-dom";
import WalletTransition from "./WalletTransition";

const UserDetails = () => {
    const { authInfo } = useAuth();
    const [searchParems] = useSearchParams()
    const { showError} = useNotification();
    const navigate = useNavigate();

    // var res = url.split("/");
    // var pos = res.indexOf('detail');
    // var result = res[pos+1];
    // console.log(url.pathname); 

    useEffect(() => {
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
    })

    useEffect(() => {
        document.title = 'Profile | iService - Innovation Service';
    }, [])
    return (

        <>
            <Container fluid className="my-5 " id="main_container">
                <Row>
                    <Col md={3} className="mb-3 mb-md-0 padding0">
                        <ProfileAside />
                    </Col>
                    <Col md={9}>
                        <Card id="card_box" className="border-0">
                            <Card.Body className="ps-2 pe-2 pt-0 pb-0 pe-0 ">
                                <Container fluid className="ps-1 pe-1 ">
                                    <Row>
                                        {/* <Col md={3} xl={2} id="img_container" className=" ">
                                            <Row className="mt-4">
                                                <Col className=" text-center">
                                                    <Avatar sx={{ width: 70, height: 70 }} alt="Remy Sharp"
                                                        src={authInfo?.profile?.url_profile || userimg}
                                                        className="mx-auto" />
                                                </Col>
 
                                            </Row>
                                            <Row id="user_id">
                                                <Col className="text-center">
                                                    <span className="fw-bold">{authInfo?.profile?.name}</span> <br />
                                                    <BiPhone /><small
                                                        className="">+{authInfo?.profile?.mobile_code || 91} {authInfo?.profile?.mobile}</small><br /> 
                                                    <Chip
                                                        label={getFirstWordCap(authInfo?.profile?.user_type || "Client")}
                                                        color={'warning'} size="small" variant="outlined" /><br /> 
                                                </Col>
                                            </Row>
                                        </Col> */}
                                        <Col className="">
                                            <Container className="py-3">
                                                {/* {sideMenu === 1 ? <UserInformation /> : <ChangePassword />} */}
                                                {searchParems.get('profile') === 'profile-detail' || searchParems.get('profile') === null ? <UserInformation /> : <></>}
                                                {searchParems.get('profile') === 'change-password' && <ChangePassword />}
                                                {searchParems.get('profile') === 'notification' && <NotificationList />}
                                                {searchParems.get('profile') === 'address' && <AddressList />}
                                                {searchParems.get('profile') === 'business-detail' && <BusinessDetail />}
                                                {searchParems.get('profile') === 'wallet' && <WalletTransition />}


                                            </Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}
export default UserDetails;