import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth, useNotification} from '../../hooks'
import {get_bookings} from '../../api/booking'
import {Card, Col, Modal, Row} from "react-bootstrap";
import {CCardBody} from "@coreui/react";
import * as PropTypes from "prop-types";
import {BookingDetailCard} from "./component/BookingDetailCard";
import {BookingDeviceInfoCard} from "./component/BookingDeviceInfoCard";
import {BookingServiceInfoCard} from "./component/BookingServiceInfoCard";
import {BookingPartInfoCard} from "./component/BookingPartInfoCard";
import ModalConfirmCancel from "./component/ModalConfirmCancel";
import BookingPayment from "./BookingPayment";
import {Alert, Fab} from "@mui/material";
import {FaMapMarkerAlt} from "react-icons/fa";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import {useParams} from "react-router";
import RescheduleModal from './component/RescheduleModal';
import {BsXLg} from 'react-icons/bs';
import handlePaymentTransaction from '../Cart/handel_payment_razorpay';
import useRazorpay from 'react-razorpay';
import {IoIosWarning} from "react-icons/io";
import {CgDanger} from "react-icons/cg";
import TrackingModel from "./TrackingModel";

class CIcon extends React.Component {
    render() {
        return null;
    }
}

CIcon.propTypes = {
    icon: PropTypes.any,
    height: PropTypes.number
};
export default function BookingDetails() {
    const [deviceInfo, setDeviceInfo] = useState({})
    const [clientInfo, setClientInfo] = useState({})
    const [partsInfo, setPartsInfo] = useState([])
    const [dataInfo, setDataInfo] = useState()
    const [serviceInfo, setServiceInfo] = useState(false)
    const [orderAddress, setOrderAddress] = useState({})
    const {orderToken} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [urlImages, setUrlImages] = useState()
    const [Razorpay] = useRazorpay();

    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const {showError, showSuccess} = useNotification();
    const [reloadData, setReloadData] = useState(false)
    const [pageTitle, setPageTitle] = useState("Booking Details")
    const [show, setShow] = useState(false);
    const [modalImage, setModalImage] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowImage = (url) => {
        setModalImage(url)
        handleShow()
    }

    useEffect(() => {
        if (dataInfo) {
            if (dataInfo?.order_type && dataInfo.order_type === 'part') {
                setPageTitle('Order Detail #' + (orderToken || ""));
                document.title = 'Order #' + (orderToken || "") + ' | iService - Innovation Service';
            } else {
                setPageTitle('Booking Detail #' + (orderToken || ""));
                document.title = 'Booking Detail #' + (orderToken || "") + ' | iService - Innovation Service';
            }
        } else {
            document.title = 'Booking | iService - Innovation Service';
        }
    }, [orderToken, dataInfo])

    const getUsers = async () => {
        setIsLoading(true);

        if (orderToken) {
            const {data, error} = await get_bookings({
                id: authInfo?.profile?.id,
                user_type: authInfo?.profile?.user_type,
                token_order: orderToken
            });

            if (data?.data?.device_info?.url_image_client?.length) {
                setUrlImages(data?.data?.device_info?.url_image_client || null)
            }
            setReloadData(false);
            if (error) return showError(error)
            setDeviceInfo(data?.data?.device_info)
            setDataInfo(data?.data)
            setClientInfo(data?.data?.user)
            setPartsInfo(data?.data?.order_parts)
            setServiceInfo(data?.data?.agent)
            setOrderAddress(data?.data?.order_address || data?.data?.address)
            // console.log("data---------", data);
            // console.log(dataInfo);
        } else {
            showError("Undefined token");
            navigate('/');
        }
        setIsLoading(false);
    }


    useEffect(() => {
        getUsers();
    }, [orderToken, reloadData]);
    // partsInfo,
    const style = {color: "var(--primary)", fontSize: "1.2em"}


    const handlePayNow = async () => {
        const response = await handlePaymentTransaction(
            {
                navigate: navigate,
                transaction: dataInfo?.pending_transaction || null,
                Razorpay: Razorpay,
                // setNotAvOpen: setNotAvOpen,
                paymentMode: dataInfo?.payment_mode,
                order: dataInfo || null,
                user: authInfo?.profile,
                amount_total: dataInfo?.pending_transaction?.amount || 0,
                amount_currency: dataInfo?.pending_transaction?.amount_currency || "INR",
                // amount_total: 1,
                // amount_currency: "INR",
                setReloadData: setReloadData,
                reloadData: reloadData,
                showSuccessMessage: showSuccess,
                showErrorMessage: showError
            });
        // console.log(response);
        if (response?.error) {
            showError(response?.message);
            // successRedirect(data.data.token, data?.data?.order_parts[0]?.part?.token_brand);
        }
    }


    return (
        <>
            <div className="ourservices">
                <div className="">
                    <h3 className="servicesHeading">{pageTitle ?? "Booking Details"}</h3>
                    <h4 className="servicesHeading">{dataInfo?.datetime_formatted}</h4>

                    <p className=" subHeading ">
                        iService is the one-stop-shop for all your spare parts needs. iService deals with all types of
                        spare parts and service
                    </p>
                </div>
            </div>
            <section className={'mx-5 my-5 '}>

                {dataInfo?.status === 'incomplete' && dataInfo?.status_payment === 'pending' ?
                    <Row className=' part-order-incomplete-bg '>
                        <Col sm={12} className='d-flex align-items-center '>
                            <span> <CgDanger size={20} style={{color: "red"}}/> Your order process is pending for payment, Please pay now to confirm your order. &nbsp;</span>
                            <button className="pay-now-btn float-end" onClick={handlePayNow}>Pay Now</button>
                            {/* <Alert severity="error">This is an error Alert.</Alert> */}
                        </Col>
                    </Row> :
                    <></>
                }


                <Row className='text-end '>
                    {/* <Col xs={0} md={6} lg={8} xl={10} ></Col>
                    <Col xs={6} md={3}  lg={2} xl={1} className={'py-3 pe-5 text-end'}>
                        {dataInfo?.status && dataInfo.status === 'pending' ? <RescheduleModal status={dataInfo?.status} token={dataInfo?.token} /> : <></>}
                    </Col> */}

                    {/* <Col className='d-flex justify-content-start'>
                        {dataInfo?.status && dataInfo.status === "incomplete" ? <div>
                            <span>If your order is incomplete please pay now </span>
                            <button className="sign-up-button "
                            >Pay Now
                            </button>
                        </div> : <></>}
                    </Col> */}

                    <Col className={' d-flex justify-content-end ps-5'}>

                        {dataInfo?.tracking_url ? <div>
                            {/*<Fab variant="extended" size="small" color="primary" className={'px-4 mt-1 me-1'}
                                 onClick={() => window.open(dataInfo?.tracking_url, "_blank")}> <FaMapMarkerAlt
                                sx={{mr: 3}}/> Track Order </Fab>*/}
                                <TrackingModel tracking_url={dataInfo?.tracking_url} />
                        </div> : <></>
                        }
                        {dataInfo?.status && dataInfo.status === 'pending' ? (
                            /*<button className="sign-up-button mx-auto"
                                    onClick={handleCancelOrder}>{btnCancel}</button>*/
                            <ModalConfirmCancel tokenOrder={orderToken} reloadData={reloadData}
                                                setReloadData={setReloadData}/>
                        ) : ""}

                        {dataInfo?.status && dataInfo.status === 'out_for_delivery' ? (
                            <p><b>Note: </b>Service Engineer reached and diagnosing device.</p>
                        ) : ""}

                        {dataInfo?.order_type === 'service' && dataInfo?.status && dataInfo.status === 'pending' ?
                            <RescheduleModal status={dataInfo?.status} token={dataInfo?.token}/> : <></>}
                    </Col>


                    {/*<Col md={(dataInfo?.tracking_url) ? 4 : 6} className={'text-center py-3'}>

                        {dataInfo?.status && dataInfo.status === 'pending' ? (

                            <ModalAddReview dataInfo={dataInfo} tokenOrder={orderToken}
                                            setReloadData={setReloadData} partsInfo={partsInfo}/>
                        ) : ""}

                    </Col>*/}


                </Row>


                <Row className={'g-3 mt-2'} md={{cols: 2}} xs={{cols: 1}}>
                    {isLoading && <SkeletonFileGeneral height={220} total={4} col={6}/>}
                    {!isLoading && <Col className={'py-2 d-flex align-items-stretch'}>
                        <BookingDetailCard dataInfo={dataInfo} clientInfo={clientInfo} orderAddress={orderAddress}/>
                    </Col>}
                    {!isLoading && serviceInfo && (<Col className={'py-2 d-flex align-items-stretch'}>
                        <BookingServiceInfoCard dataInfo={dataInfo} serviceInfo={serviceInfo}  setReloadData={setReloadData}/>
                    </Col>)}

                    {/*{dataInfo?.order_type === 'part' && (*/}
                    {!isLoading && <Col className={'py-2 d-flex align-items-stretch'}>
                        <BookingPayment dataInfo={dataInfo} clientInfo={clientInfo} orderAddress={orderAddress}/>
                    </Col>}
                    {/*)}*/}
                    {!isLoading && deviceInfo && deviceInfo?.category_id && dataInfo?.order_type === "service" && (
                        <Col className={'py-2 d-flex align-items-stretch'}>
                            <BookingDeviceInfoCard dataInfo={dataInfo} deviceInfo={deviceInfo}/>
                        </Col>)}
                    {!isLoading && partsInfo && Array.isArray(partsInfo) && partsInfo.length > 0 && (
                        <Col className={'py-2 d-flex align-items-stretch'}>
                            <BookingPartInfoCard dataInfo={dataInfo} partsInfo={partsInfo} deviceInfo={deviceInfo}
                                                 tokenOrder={orderToken} setReloadData={setReloadData}/>
                        </Col>)}


                    {/* {dataInfo?.remark && (<Col md={6} className={'py-2 d-flex align-items-stretch'}>
                        <Card className={'shadow w-100'}>
                            <CCardBody>
                                <Row xs={{cols: 1}} className={'g-3'}>
                                    <Col> <span className="clientDetail  ">Remarks</span></Col>
                                    <Col>
                                        <p>{dataInfo?.remark || ""}</p>
                                    </Col>
                                </Row>
                            </CCardBody>
                        </Card>
                    </Col>)}*/}
                    {!isLoading && dataInfo?.remark && Array.isArray(dataInfo?.remark) && dataInfo?.remark.length > 0 && (
                        <Col className={'py-2 d-flex align-items-stretch'}>
                            <Card className={'shadow w-100'}>
                                <CCardBody>
                                    <Row>
                                        <Col xs={12}><span className="clientDetail">Remarks</span></Col>
                                        {/*<Col xs={3}><ModalJobsheetRemarkAdd tokenOrder={dataInfo?.token}
                                                                            reloadData={reloadData}
                                                                            setReloadData={setReloadData}/></Col>*/}
                                    </Row>
                                    <Row xs={{cols: 1}} className={'g-3 mt-4'}>
                                        <Col>
                                            {dataInfo.remark && Array.isArray(dataInfo.remark) ? dataInfo.remark.map((remark, index) => {
                                                const date = remark?.date || "";
                                                return (
                                                    <>
                                                        <Row key={index} xs={{cols: 1}} className={'mb-2'}>
                                                            <Col><small>{remark?.remark || ""}</small></Col>
                                                            <Col className={'text-end text-right'}>
                                                                <small
                                                                    style={{fontSize: "12px"}}><b>{date}</b></small><br/>
                                                                <small
                                                                    style={{fontSize: "12px"}}><b>{remark?.added_by}</b></small>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                );
                                            }) : (<p>{dataInfo?.remark || ""}</p>)}

                                        </Col>
                                    </Row>
                                </CCardBody>
                            </Card>
                        </Col>)}
                </Row>


                {!isLoading && modalImage &&
                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter"
                       centered>
                    <Modal.Body className="booking-modal-box p-0">
                        <img className='d-flex justify-content-center mx-auto' style={{height: "auto", width: "70%"}}
                             onClick={handleShow} src={modalImage}/>
                        <div className=" booking-modal-cros-btn text-end p-2"><BsXLg className="close-button" size={30}
                                                                                     onClick={handleClose}/></div>
                        {/* <div style={{ backgroundImage: `url(${modalImage})` }} className="booking-modal-img">
                            <div className="text-end p-2"><BsXLg className="close-button" size={30} onClick={handleClose} /></div>
                        </div> */}

                    </Modal.Body>
                </Modal>}


                {!urlImages ? <></> : <Row><Col className='clientDetail'> Product Images</Col></Row>}

                <Row>
                    {!isLoading && urlImages && Array.isArray(urlImages) && urlImages.map((img, index) => {
                            return (
                                <Col xs={6} sm={3} md={2} key={index} className="mt-3">
                                    <Card onClick={() => handleShowImage(img)} className='pointer'>
                                        <Card.Body className="p-2">
                                            <Card.Img alt='client image' className="img-fluid" src={img}/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }

                </Row>

                {!isLoading&& <Row className='text-center mt-2'>
                    <Col className={' d-flex justify-content-end ps-5'}>

                        {dataInfo?.status && dataInfo.status !== 'incomplete' ? (
                            <button className="sign-up-button mx-auto "
                                    onClick={() => navigate('/order-invoice/' + (dataInfo?.token))}>View
                                Invoice</button>
                        ) : ""}
                    </Col>
                </Row>
                }
                }

            </section>

        </>
    )
}
