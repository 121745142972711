import React, {useEffect, useState} from "react";
import {checkIfImageExists} from "../../hooks/useCustomHelpingMethod";
import banner from "../asset/banner.webp"
import SimilarProductShopCard from "./SimilarProductShopCard";

export default function LastVisitProducts({data}) {
    const [products, setProducts] = useState([]);
    useEffect(() => {
        let historySes = localStorage?.getItem('recentVisitsPartHistory');
        const historyJson = historySes ? JSON?.parse(historySes) : [];
        setProducts([...historyJson]);
    }, []);
    return (
        <div>
            <div className="container  align-items-center">
                <h4 className="">Recently View</h4>
                <div className="row">
                    {products != null && products.slice(0, 6).map((element, index) => {
                        return (
                            data?.slug !== element?.slug && <div key={element?.slug} className="  my-2 d-flex justify-content-center align-item-center" >
                                <SimilarProductShopCard
                                    part={element}
                                    image={element?.url_icon && checkIfImageExists(element?.url_icon) ? element.url_icon : banner}
                                    price={element?.amount_price_customer} title={element?.title}
                                    slug={element?.slug} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
