import {CCard, CCardBody} from "@coreui/react";
import {Avatar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {FaAngleRight} from "react-icons/fa";

export function DashboardCard({data, icon, link}) {
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) navigate(link);
        else navigate("/");
    }
    return (
        <>
            <CCard className={'m-2 card-custom card-link'} onClick={handleClick}>
                <CCardBody>
                    <div className={'row align-items-center'}>
                        <div className={'col-2'}>
                            <Avatar alt={data?.title} sx={{bgcolor: "var(--primary)", width: 40, height: 40}}
                                    aria-label="recipe">{icon}</Avatar>
                        </div>
                        <div className={'col-8'}>
                            <b className={'fs-5'}>{data?.count}</b> <br/>
                            <span>{data?.title}</span>
                        </div>
                        <div className={'col-2 text-end'}>
                            {/*<a onClick={handleClick} className={'fw-bold read-more'}><small>See All</small></a>*/}
                            <a onClick={handleClick}
                               className={'read-more float-end float-right'}><FaAngleRight
                                size={25}/></a>
                        </div>
                    </div>

                </CCardBody>
            </CCard>
            {/*<CCard className={'m-2 card-custom'}>
                <CCardBody>
                    <div className={'d-flex align-items-center'}>
                        <div className={'d-flex justify-content-center'}>
                            <Avatar sx={{bgcolor: "var(--primary)", width: 50, height: 50}}
                                    aria-label="recipe">{icon}</Avatar>
                        </div>
                        <div className={'px-3'}>
                            <b className={'fs-5'}>{data?.count}</b> <br/>
                            <p>{data?.title}</p>
                        </div>

                    </div>
                    <div className={'text-end'}>
                        <a onClick={handleClick} className={'fw-bold read-more'}><small>See All</small></a>
                        <a onClick={handleClick} className={'read-more float-end float-right'}><b>View</b><FaAngleRight
                            size={25}/></a>
                    </div>
                </CCardBody>
            </CCard>*/}
        </>
    );
}