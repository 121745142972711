import React from 'react'
import FormCard from './FormCard'
import TabsForForm from './TabsForForm'

export default function BannerForm() {
    return (
        <div className='my-3 '>
            <FormCard/>
        </div>
    )
}
