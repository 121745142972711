import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop() {
    const {pathname, search} = useLocation();

    const excludePaths = [
        '/auth',
        '/signup',
        '/signin',
        '/forgot',
        '/reset-password',
        '/login',
    ]

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        if (!excludePaths.includes(pathname)) {
            if ((pathname + "" + search) !== localStorage.getItem("prevLocation")) {
                localStorage.setItem('prevLocation', pathname + "" + search);
            }
        }
    }, [pathname, search]);

    return null;
}