import React, {useEffect, useState} from 'react'
import MoreButton from './MoreButton'
import {useNavigate} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useAuth, useNotification} from "../../hooks";
import DialogTempNotAvailable from "../DialogTempNotAvailable";
import {isAppTempDown, showAdBanners} from "../../api/client";
import noBanner from "../asset/front-banner-top.webp"
import {ApiCall} from "../../api/HelpingMethod";


export default function BookServiceAndJobSheet() {
    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const {showError} = useNotification();
    const [notAvOpen, setNotAvOpen] = useState(false);
    const [bannerTopPage, setBannerTopPage] = useState(null);
    const [bannerAsideBooking, setBannerAsideBooking] = useState(null);
    const {setShowLoginModel} = useNotification();
    // console.log("authInfo---", authInfo)
    const redirectToBookService = async () => {
        if (authInfo?.profile?.id) {
            if (isAppTempDown) {
                setNotAvOpen(true);
                return;
            }
            navigate("/book-repair");
        } else {
            localStorage.setItem('prevLocation', "/book-repair");
            setShowLoginModel(true);
        }
    };
    const redirectToCreateJobSheet = async () => {
        if (authInfo.profile.id) {
            if (authInfo.profile.user_type === 'dealer') {
                navigate("/job-repair");
            } else {
                return showError("You are not authorize to create job sheet.");
            }
        } else {
            showError("Please login to create new jobsheet.");
            navigate("/auth");
        }

    };

    const getApiCall = async () => {
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_home_below_get_service"
        }).then((r) => {
            setBannerTopPage(r?.data);
        });
        if (!authInfo?.profile?.user_type || authInfo?.profile?.user_type !== 'dealer') {
            await ApiCall('dealer/advertisement', {
                key_advertisement: "front_home_aside_book_for_other_service_block"
            }).then((r) => {
                setBannerAsideBooking(r?.data);
            });
        }

    }

    useEffect(() => {
        getApiCall();
    }, [])

    const redirectBanner = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (
        <>
            {/*fluid*/}
            <DialogTempNotAvailable open={notAvOpen} setOpen={setNotAvOpen}/>

            <Container fluid className={"my-5 "}>
                {(bannerTopPage && bannerTopPage?.url_image) ?
                    (showAdBanners && <Row className=' ms-sm-4 me-sm-4 ms-0 me-0  ps-sm-2 pe-sm-2 ps-0 pe-0  '>
                        <Col>
                            <img onClick={() => redirectBanner(bannerTopPage?.url_redirect)}
                                 onError={(e) => e.target.src = noBanner} src={bannerTopPage?.url_image}
                                 className='w-100 banner-image' alt={'no advertisement'}/>
                        </Col>
                    </Row>) : ""}

                <Row className='mt-3 px-sm-5 px-0'>

                    {bannerAsideBooking && bannerAsideBooking?.url_image ? (
                        <Col className={authInfo?.profile?.user_type === 'dealer' ? 'd-none' : 'd-block mt-1'}
                             md={(authInfo?.profile?.user_type && authInfo?.profile?.user_type === 'dealer') ? 0 : 6}>
                            <img onClick={() => redirectBanner(bannerAsideBooking?.url_redirect)} alt={'no image'}
                                 onError={(e) => e.target.src = noBanner}
                                 src={bannerAsideBooking?.url_image} className='by-orignal'/>
                        </Col>
                    ) : ""}

                    <Col sm={(authInfo?.profile?.user_type && authInfo?.profile?.user_type === 'dealer') ? 6 : 6}
                         className='mt-1'>
                        <div className='div2'>
                            <h3 className="text-center servicesHeading text-dark">Book Services For <span
                                style={{color: "#EE610F"}}>Others</span></h3>
                            <p className='text-center py-3 px-3  ourMissonInner'>

                                Book a service for your loved ones with iService in only two easy steps and you're done.
                                Plus, you'll earn reward points on every service you book.

                            </p>
                            <div className='d-flex justify-content-center align-item mt-4'>
                                <a onClick={redirectToBookService} style={{textDecoration: "none", color: "white"}}>
                                    <MoreButton>Book Now</MoreButton>
                                </a>
                            </div>
                        </div>
                    </Col>
                    {authInfo.profile.user_type && authInfo.profile.user_type === 'dealer' ? (
                        <Col sm={6} className='mt-1'>
                            <div className='div2'>
                                <h3 className="text-center servicesHeading text-dark"> Make A <span
                                    style={{color: "#EE610F"}}>Jobsheet</span></h3>
                                <p className='text-center py-2 px-3  ourMissonInner'>
                                    {/*You can also create a jobsheet for you customer with us. Our Job Sheet Service for
                                Dealers is a cutting-edge solution designed to simplify client service tracking and
                                enhance customer satisfaction.*/}
                                    For your customer, You can also make a Jobsheet. Our innovative Job Sheet Service
                                    for
                                    Dealers lets you track client service more easily and improve customer satisfaction
                                    by
                                    including your shop name and phone number. {/*It is free for the dealer*/}

                                </p>
                                <div className='d-flex justify-content-center align-item'>
                                    <a onClick={redirectToCreateJobSheet}
                                       style={{textDecoration: "none", color: "white"}}>
                                        <MoreButton>Create Jobsheet</MoreButton>
                                    </a>
                                </div>
                            </div>
                        </Col>) : ""}

                </Row>
            </Container>
        </>
    )
}
