import React, { useEffect, useState } from "react";
import { useAuth, useNotification } from "../../hooks";
import { model_problem } from "../../api/general";
import { sheet_service_step } from "../../api/jobsheet";
import { CButton, CCol, CFormInput, CFormLabel, CRow } from "@coreui/react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import { BsXLg } from "react-icons/bs";

// import "../ProblemDevice/ppBody.css";
export function ProblemPageDes({ setToken, handeNext, iemi, handleBack, data, token }) {

    const [other, setOther] = useState();
    const [file, setFile] = useState();
    const { authInfo } = useAuth();
    const { showError, showSuccess } = useNotification()
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState()
    const [model_problems, setModel_Problems] = useState([])
    const [dataList, setDataList] = useState([]);
    const [estimateAmount, setEstimateAmount] = useState("");
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Submit')
    const navigate = useNavigate();
    const [urlImages, setUrlImages] = useState([])
    const { setOpenBackDrop } = useNotification();
    const [show, setShow] = useState(false);
    const [modalImage, setModalImage] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowImage = (url) => {
        setModalImage(url)
        handleShow()
    }

    const [locationInfo, setLocationInfo] = useState({
        // device_type: "web",
        // istoken: "ISABCDEFGH",
        step: "device-problem",
        login_user_type: authInfo?.profile?.user_type || "dealer",
        login_user_id: authInfo?.profile?.id || 0,
        token_order: token,
        estimate_amount: data?.estimate_amount || "",
        url_image_client: "",
        device_problem_other: data?.device_problem_other || "",
        device_problem: data?.device_problem || [],
        device_serial_imei: iemi,
    });


    useEffect(() => {
        if (data) {

            setLocationInfo({
                ...locationInfo,
                device_problem_other: data?.device_problem_other,
                estimate_amount: data?.estimate_amount,
                device_problem: [data?.device_problem]
            })

            if (data?.device_problem) {
                var list = data?.device_problem || "";
                if (typeof list === 'string' || list instanceof String) {
                    var problems = list.split(',');
                    if (problems && Array.isArray(problems)) setDataList(problems)
                    else setDataList([])
                }
            }
            if (data?.url_images_client) {
                setUrlImages(data?.url_images_client)
            }

            // console.log("data?.url_images_client---", data?.url_images_client)


            // setSelected_categoryId(data?.category_id || 0);
            // setSelected_brandId(data?.brand_id || 0);
            // setSelected_modelId(data?.model_id || 0);
            // setSelected_imei(data?.device_serial_imei || selected_imei || "")
        }
    }, [data])


    const handleSelect = (e) => {
        if (dataList.includes(e.target.value)) {
            const array = dataList.filter((ele) => {
                return ele !== e.target.value;
            })
            setDataList(array);
            // console.log(array)
        } else {
            setDataList([...dataList, e.target.value])
        }
    }


    const onEstimateAmountChange = async (event) => {
        setEstimateAmount(event.target.value);
        setLocationInfo({ ...locationInfo, estimate_amount: event.target.value })
    };

    const apiCall = async (event) => {
        setButtonName("loading...");
        setLoading(true);

        event.preventDefault();
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Submit");
            setLoading(false);

            event.stopPropagation();

        } else {
            // console.log("hi")
            const formData = new FormData();
            for (let key in locationInfo) {
                formData.append(key, locationInfo[key]);
            }
            // console.log("", dataList);
            // console.log(other);
            formData.append("device_problem", dataList)
            // console.log(file);
//             if (file) {
//                 console.log("Is array",file);
//                 let images=[];
               
//                 for (let i = 0; i < file?.length; i++) {
//                     // images.push(URL.createObjectURL(file[i]));
//                     images.push(file[i]);
//                   }
// console.log(images);
//                 formData.append("url_image_client[]", images) 
//             } 
            // formData.append("url_image_client", file) 

            if (file) { 
                formData.append("url_image_client", file[0]) 
            } 

            formData.append("device_problem_other", other)
            formData.append("estimate_amount", estimateAmount)
            const { data, error } = await sheet_service_step({
                details: formData,
                is_image: true
            }); 

            setButtonName("Submit");
            setLoading(false);
            navigate("/job-repair?token=" + data.data.token + "&step=" + data.data.step)
            setToken(data?.data?.token)

            if (error || data.error) return showError(data.message);
            
            handeNext()
        }

        setButtonName("Submit");
        setLoading(false);

        setValidated(true);

    };

    const problem = async (e) => {
        setPageLoading(true);
        setOpenBackDrop(true);
        const { data } = await model_problem({ id: authInfo.profile.id })
        setModel_Problems(data.data)
        setPageLoading(false);
        setOpenBackDrop(false);

    }

    useEffect(() => {
        problem();
        // setDataList(data?.device_problem);
    }, []);

    return (
        <>
            <div className="SelectContainers">
                {/* <p className="textname">Select Problem</p> */}
                {/* {pageLoading &&  <SkeletonFileGeneral className="mt-3 mb-0" height={40} col={12} total={1} /> } */}

                <div className="upContainer">

                    <div className="{/*firstSelectioncon*/} selectionDate">

                        {!pageLoading &&
                            model_problems.map((element, idx) => {
                                return (
                                    /*<button id={idx} onClick={handleSelect} value={element.title}
                                            className={`Selectionbtn ${dataList.includes(element.title) ?
                                                'buttonStyle2' : ''}`}> {element.title}</button>*/
                                    <CButton key={idx} id={idx} color="secondary" variant="outline"
                                        onClick={handleSelect}
                                        value={element.title}
                                        shape="rounded-pill"
                                        className={`selectable-btn ${dataList.includes(element.title) ? 'buttonStyle2' : ''}`}>{dataList.includes(element.title) ?
                                            <FaCheck size={15} /> : ''} {element.title}</CButton>

                                );
                            })}
                    </div>
                </div>

                <div className="downContiner">
                    <CRow className={"g-3"}>
                        <CCol xs={12} md={6}>
                            <CFormLabel htmlFor="exampleDataList">Others</CFormLabel>
                            <CFormInput list="datalistOptions" value={locationInfo?.device_problem_other}
                                id="exampleDataList" name={'device_problem_other'}
                                onChange={(e) => {
                                    setOther(e.target.value)
                                    setLocationInfo({ ...locationInfo, device_problem_other: e.target.value })
                                }}
                                placeholder="Type Other Problem Here" />
                            <datalist id="datalistOptions">
                                {/*<option value="San Francisco" />*/}
                                {/*<option value="New York" />*/}
                                {/*<option value="Seattle" />*/}
                                {/*<option value="Los Angeles" />*/}
                                {/*<option value="Chicago" />*/}
                            </datalist>
                        </CCol>
                        <CCol xs={12} md={6}>
                            <CFormLabel htmlFor="estimateAmount">Estimated Amount</CFormLabel>
                            <CFormInput type={"number"} id="estimateAmount" name={'estimate_amount'}
                                value={locationInfo?.estimate_amount}
                                onChange={onEstimateAmountChange}
                                placeholder="Type Estimated Amount Here" />
                        </CCol>
                        <CCol xs={12} md={6}>
                            <CFormLabel htmlFor="formFileMultiple">Upload Product Images (Optional)</CFormLabel>
                            <CFormInput type={"file"} className="form-control"
                                onChange={(e) => setFile(e.target.files)}
                                id="formFileMultiple" multiple />
                        </CCol>
                    </CRow>

                    {modalImage && <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="booking-modal-box p-0" >
                    <img className='d-flex justify-content-center mx-auto' style={{ height: "auto", width: "70%" }} onClick={handleShow}  src={modalImage} />
                    <div className=" booking-modal-cros-btn text-end p-2"><BsXLg className="close-button" size={30} onClick={handleClose} /></div>
                        {/* <div style={{ backgroundImage: `url(${modalImage})` }} className="booking-modal-img">
                            <div className="text-end p-2"><BsXLg className="close-button" size={30} onClick={handleClose} /></div>
                        </div> */}

                    </Modal.Body>
                </Modal>}

                    {urlImages && <Row><Col className='clientDetail'> Product Images</Col></Row>}

                    <Row>
                        {
                            urlImages && Array.isArray(urlImages) && urlImages.map((img, index) => {
                                return (
                                    <Col xs={6} sm={3} md={2} xl={2} key={index} className="mt-3">
                                        <Card onClick={()=>handleShowImage(img)} className='pointer'>
                                            <Card.Body className="p-2">
                                                <Card.Img alt='client image' className="img-fluid " src={img} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                    </Row>

                    <Row className={"my-4"}>
                        <Col>
                            <button className="button-2 float-left"
                                onClick={handleBack}>{"Previous"}</button>
                        </Col>
                        <Col>
                            <button disabled={loading}
                                className={"sign-up-button float-end text-end " + (loading && 'disabled-button')}
                                onClick={apiCall}>{loading &&
                                    <CircularProgress size={16} color={"inherit"} />}&nbsp;{btnName}</button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    );
}
