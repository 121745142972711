import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
// import "../ProblemDevice/ppBody.css";
export function ProblemPageBody({  data }) {

 

  const fontStyle = { fontSize: ".8rem" };
  return (
    <>
      <Accordion style={{ minWidth: "50%" }} className={'text-start'}>
        <AccordionSummary expandIcon={<FaAngleDown />} aria-controls="panel1a-content" id="panel1a-header">
          <b>Details:</b>
        </AccordionSummary>
        <AccordionDetails>
          <Row xs={{ cols: 1 }} className={'g-2 text-start'}>
            <Col>Name: <b style={fontStyle}>{data && data?.name ? data?.name : ""}</b></Col>
            <Col>Mobile: <b style={fontStyle}>{data && data?.mobile ? data?.mobile : ""}</b></Col>
            <Col>Remark: <b style={fontStyle}>{data && data?.remark ? data?.remark[0]?.remark : ""}</b></Col>
            <Col>Model: <b style={fontStyle}>{data && data?.title_model ? data?.title_model : ""} </b></Col>
            <Col>IMEI Number: <b style={fontStyle}>{data && data?.device_serial_imei ? data?.device_serial_imei : ""}</b></Col>
          </Row>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
