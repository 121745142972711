import {CCardBody} from "@coreui/react";
import {  Card, Col, Row} from "react-bootstrap";
import React from "react";
import {
    Chip,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {checkIfImageExists, formatMoney} from "../../../hooks/useCustomHelpingMethod";
import ModalJobsheetPartAdd from "./ModalJobsheetPartAdd";

export function IncludeJobsheetPartInfoCard({dataInfo, partsInfo, orderToken, reloadData, setReloadData, tokenOrder}) {

    const style = {color: "var(--primary)", fontSize: "1.2em"}
    var partAmountTotal = 0;
    // console.log(partsInfo);
    return (
        <Card className={'shadow  w-100'}>
            <CCardBody>
                <Row xs={{cols: 1}} className={'g-3'}>
                    <Col xs={9}><p className="clientDetail text-dark">Part(s) Ordered
                        ({dataInfo?.count_part || partsInfo.length || 0})</p>
                    </Col>
                    <Col xs={3}>
                        <ModalJobsheetPartAdd tokenOrder={dataInfo?.token} reloadData={reloadData}
                                              setReloadData={setReloadData}/>
                    </Col>
                </Row>
                <Row className={'g-2 figure-caption'} xs={{cols: 1}}>
                    <Col>
                        <TableContainer component={Paper} variant="outlined">
                            <Table aria-label="demo table" size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        {/*<TableCell sm={1}>SN.</TableCell>*/}
                                        {/*<TableCell>Icon</TableCell>*/}
                                        <TableCell sm={9}>Part Name</TableCell>
                                        {/*<TableCell sm={2} className={'text-center'}>Price</TableCell>*/}
                                        {/*<TableCell sm={1} className={'text-center'}>Qty</TableCell>*/}
                                        <TableCell sm={3} align="right">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {partsInfo && partsInfo.map((part, index) => {
                                        partAmountTotal += part.amount_total ? part.amount_total : 0;
                                        return (
                                            <TableRow key={index} style={{fontSize: "12px"}}>
                                                <TableCell xs={9}><b>{index + 1}.</b> <small>{part?.title_part}</small></TableCell>
                                                {/* <TableCell
                                                    className={'text-center fw-bold'}>{dataInfo?.amount_currency_symbol || "RS."} {(part?.amount_price || 0)}</TableCell>
                                                <TableCell
                                                    className={'text-center fw-bold'}>{part?.quantity_part || 1}</TableCell>*/}
                                                {/*formatMoney*/}
                                                <TableCell xs={3}
                                                           align="right"><small>{dataInfo?.amount_currency_symbol || "RS."} {(part?.amount_total || 0)}</small></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    <TableRow>
                                        {/*<TableCell rowSpan={3}/>*/}
                                        <TableCell colSpan={1}><b>Subtotal</b></TableCell>
                                        {/*formatMoney*/}
                                        <TableCell align="right" colSpan={1}><small><b>{dataInfo?.amount_currency_symbol || "RS."} {(partAmountTotal || 0)}</b></small></TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <TableCell colSpan={2}>Total</TableCell>
                                        <TableCell align="right"
                                                   colSpan={1}>{dataInfo?.amount_currency_symbol || "RS."} {(partAmountTotal|| 0)}</TableCell>
                                    </TableRow>*/}
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Col>
                    {/* <Col>
                        <hr/>
                        <Row>
                            <Col xs={4} className={'fw-bold'}>Total</Col>
                            <Col xs={8}><span
                                className={"float-end fw-bold"}>{dataInfo?.amount_currency_symbol || "RS."} {partAmountTotal || 0}</span></Col>
                        </Row>
                    </Col>*/}
                </Row>

            </CCardBody>
        </Card>
    );
}