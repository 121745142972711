import {React, useEffect, useState} from "react";
import {home, model} from "../../api/general";
import useWindowSize from "../../Context/WindowSize";
import Crouser from "./Crouser";
import {BeatLoader} from "react-spinners";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import Row from "react-bootstrap/Row";

export default function BuyPartsForMobile({category_id, title, basePath, isLoading}) {
    // console.log(category_id)
    const [full_details, setFull_details] = useState([]);
    const [col, setCol] = useState(4);
    const [total, setTotal] = useState(5);
    const [margin, setMargin] = useState(3);
    const {width} = useWindowSize();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (width < 768) {
            setTotal(3)
            setCol(4)
            setMargin(0)
        } else {
            setTotal(5)
            setCol(2)
            setMargin(3)
        }
    }, [width]);

    useEffect(() => {
        const getDetails = async () => {
            setLoading(true)
            const {data} = await model({category_id: category_id});
            setLoading(false)
            setFull_details(data.data);
        };
        if (category_id) {
            getDetails();
        }
    }, [category_id]);

    return (
        <>
            <div className="container my-2">
                <div className="text-center">
                    {/*{loading && <BeatLoader color="#ee610f" size={25}/>}*/}

                </div>
            </div>
            <div className=" mt-5 text-left">
                {(loading || isLoading) && <Row><SkeletonFileGeneral total={1} col={4} colLg={4} height={30}/></Row>}
                {(loading || isLoading) && <Row><SkeletonFileGeneral total={6} col={4} colLg={2} height={150}/></Row>}

                <h2 className="text-left servicesHeading text-dark">
                    {full_details.length ? <><span style={{color: "#EE610F"}}>Select</span> {title} Brand</> : ""}
                </h2>
            </div>
            {(!loading && !isLoading) && <Crouser brands={full_details} id="0" basePath={basePath}/>}
        </>
    );
}

