import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./Booking.css"
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import NotificationProvider from "./Context/NotificationProvider";
import AuthProvider from "./Context/AuthProvider";
import "react-loading-skeleton/dist/skeleton.css";
import ScrollToTop from "./hooks/ScrollToTop";
import WebNotification from "./Notification/WebNotification";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter basename="/">
        <ScrollToTop/>
        <NotificationProvider>
            <AuthProvider>
                <WebNotification/>
                <App/>

            </AuthProvider>
        </NotificationProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
