import * as React from 'react';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress} from "@mui/material";
// import { FaTimes } from "react-icons/fa";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import { styled } from '@mui/material/styles';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import StarIcon from '@mui/icons-material/Star';
import { useAuth, useNotification } from '../../hooks';
import { ApiCall } from '../../api/HelpingMethod';




// const StyledRating = styled(Rating)({
//     '& .MuiRating-iconFilled': {
//         color: '#ef5d00',
//     },
//     '& .MuiRating-iconHover': {
//         color: '#ef5d00',
//     },
// });






export default function ModalTopUp({ token_part, tokenOrder, setReloadData }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = useState(0);
    const { authInfo } = useAuth();
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState()

    const [addReviewBody] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "ref_type": "part",
        "token_ref": token_part,
        "count_rating": value,
        "message": ""
    })

    // console.log("partsInfo---======", token_part);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { showError, showSuccess } = useNotification();
    // const [btnCancel, setBtnCancel] = useState('Add Review')
    // const [remark, setRemark] = useState('')
    // const [messageError, setMessageError] = useState('')



    const addReview = async (event) => {
        setLoading(true);

        // setBtnCancel("loading...");
        const response = await ApiCall("dealer/user_review_update", addReviewBody)
        // console.log("response---->>", response);

        // setBtnCancel('Add Review')
        setLoading(false);

        if (response?.error === true) {
            // setMessageError(response?.message);
            return showError(response?.message);
        } else {
            // setMessageError(response?.message);
            // await getUsers();
            showSuccess(response?.message);
        }
        // setReloadData(true);

        handleClose();
        setValue(0)
    };




    return (
        <div>
            <Button onClick={handleClickOpen} className={'ps-5 pe-5 sign-up-button'} variant="contained" color="warning">
                {/* {loadingBtn && <CircularProgress size={16} color={"inherit"}/>} */}
                &nbsp; Top Up</Button>
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle>Add Transition</DialogTitle>
                <DialogContent>
                    <DialogContentText className={'mb-3'}>

                        <Container>
                            <Row className=" mt-2">
                                <Col className=' text-dark h4'>Amount</Col>
                            </Row>

                            <Row className=" mt-1">
                                <Col className='text-center text-dark h3'>
                                    <Form.Control minLength={0} value={amount} onChange={(e)=>setAmount(e.target.value)}
                                      type='number' placeholder='Enter Amount' />
                                </Col>
                            </Row>

                        </Container>

                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'inherit'}>Cancel</Button>

                    <Button disabled={loading} variant={'contained'} color={'warning'} className={'sign-up-button'}
                        onClick={addReview}>{loading && <CircularProgress size={16} color={"inherit"} />}&nbsp; Add Transition</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}