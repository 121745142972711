import {React} from 'react'
import {Link} from 'react-router-dom';
import banner from "../asset/noimage.webp"
import imgOriginal from "../asset/original.webp"
import {cropText, formatMoney} from "../../hooks/useCustomHelpingMethod";
import {FaMoneyBill} from "react-icons/fa";
import {useAuth} from "../../hooks";
import {Badge} from "react-bootstrap";
import {styled, Tooltip, tooltipClasses, Zoom} from "@mui/material";

export default function ShopCard(props) {
    // const navigate = useNavigate();
    const {authInfo} = useAuth();

    // const onTitle = async (event) => {
    //     // navigate("/product/" + props.slug)
    //     navigate("/product/" + props.slug);
    //     // window.location.href = "/product/" + props.slug;
    //     // window.location.href = "/product/" + props.slug;
    // }

    const icon = props.part?.url_icon || props.part?.url_image || props.part?.url_image || props.image || banner;
    const title = props.part?.title || props.title || "";
    // const title_brand = cropText(props.part?.title_brand || "");
    // const title_model = cropText(props.part?.title_model || "");
    // const priceMrp = props.part?.amount_mrp || 0;
    let priceUser = props.part?.amount_total || 0;
    if (authInfo.profile) {
        if (authInfo.profile.user_type && authInfo.profile.user_type === 'dealer') {
            priceUser = props.part?.amount_total_dealer || 0;
        } else {
            priceUser = props.part?.amount_total_customer || 0;
        }
    }
    const moneySymbol = props.part?.amount_currency_symbol || "₹";
    const availability = props.part?.availability || "not-available";

    let discountPerUser = props.part?.rate_discount || 0;
    if (authInfo.profile) {
        if (authInfo.profile.user_type && authInfo.profile.user_type === 'dealer') {
            discountPerUser = props.part?.rate_discount_dealer || 0;
        } else {
            discountPerUser = props.part?.rate_discount_customer || 0;
        }
    }

    const style = { color: "#ee610f", fontSize: "1.2em" }

    // const handleClick = () => {
    //
    // }
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow TransitionComponent={Zoom} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));
    return (
        <Link to={"/product/" + props.slug} className={'text-decoration-none text-dark'}>
            <div className={"card w-100 card-custom card-link align-items-stretch"}
            // onClick={onTitle}
            // onClick={()=>navigate("/product/" + props.slug)}
            >

                <div className="card-body">
                    <div className='shop-image-div'>
                        <img className="card shop-image "
                            src={icon /*&& checkIfImageExists(icon)*/ ? icon : banner}
                            onError={(e) => e.target.src = banner}
                            alt={title} />
                        {props?.part?.setting?.is_original && props?.part?.setting?.is_original === 'yes' &&
                            <img src={imgOriginal} alt={title} className={'badge-top-left original-img'} />}
                        {/*<Badge pill bg="success" className={'badge-top-left'}>Original</Badge>*/}
                        {availability && availability === "available" && discountPerUser && discountPerUser > 0 ?
                            <Badge pill bg="danger" className={'badge-top-right'}>-{discountPerUser}%</Badge> : ""}
                    </div>
                    <div className="{/*purchaseCardBody*/} product-heading">
                        {/*<p className="card-text servicesCard-sub-Heading my-2">
                        <Chip style={{fontSize: "11px"}} size="small" className={'m-1'} variant="outlined"
                              color="warning" label={title_brand}/>
                        <Chip style={{fontSize: "11px"}} size="small" className={'m-1'} variant="outlined"
                              color="warning" label={title_model}/>
                        {props.part?.part_type&&( <Chip style={{fontSize: "11px"}} size="small" className={'m-1'} variant="outlined"
                                                        color="warning" label={props.part?.part_type}/>)}

                    </p>*/}
                        <LightTooltip title={title || ""} placement="top">
                            {/*onClick={onTitle} */}
                            <p className="card-title shop-detail-title mb-2 fw-bold">
                                <small> {cropText(title || "", 50)}</small>
                            </p>
                        </LightTooltip>
                        {/* <p className="card-text servicesCard-sub-Heading fa fa-money-bill">
                            <FaMoneyBill style={style}/> Price:
                            <span className="shop-product-price float-end">
                        {availability && availability !== "available" ?
                            <Badge pill bg="danger">Out Of Stock</Badge> :
                            (moneySymbol || "₹") + " " + (formatMoney(priceUser || 0))}
                            </span>
                        </p> */}


                        {availability === 'available' ?
                            <div className="card-text servicesCard-sub-Heading fa fa-money-bill">
                                <FaMoneyBill
                                    style={style} />
                                <span className="shop-product-price float-end">
                                    {/* {props?.part?.part_color &&
                                    <Avatar variant="rounded" className={"mx-1"}
                                            sx={{
                                                bgcolor: props?.part?.part_color || '#ffffff',
                                                width: 18,
                                                height: 18
                                            }}>{""}</Avatar>} */}
                                    {(moneySymbol || "₹") + " " + (formatMoney(priceUser || 0))}
                                </span>
                            </div> : <Badge pill bg="danger">Out Of Stock</Badge>}
                    </div>
                </div>
            </div>
        </Link>
    )
}
