import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {cropText} from "../hooks/useCustomHelpingMethod";

export default function SignUpButton() {
    const [btnSize,setBtnSize]=useState(30);
    // const [btnText,setBtnText]=useState("Become a Partner");
    // const [btnText,setBtnText]=useState("Bulk Enquiry");
    const [btnText]=useState("Become a Seller");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const setWindowDimensions=()=>{
        setWindowWidth(window.innerWidth);
        if (window.innerWidth<=400){
            setBtnSize(12)
        }else{
            setBtnSize(30)
        }
    }
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, [])
    return (
        <Link className='d-sm-flex d-none mx-2' to="/become-partner" style={{textDecoration: "none"}}>
            <div className='button-2 fw-bolder'>
                {cropText(btnText,btnSize)}
            </div> 
        </Link>
    )
}
