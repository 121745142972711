import {React} from "react";
import {useNavigate} from "react-router-dom";
import banner from "../asset/banner.webp"

export default function ServiceCard({title, image, description, slug}) {

    // const onImageError = (e) => {
    //     e.target.src = banner
    // }

    const navigate = useNavigate();
    const handleClick = async () => {
        // navigate("/mobile-repair?category-slug=" + slug);
        navigate("/services/" + slug);
    };

    return (
        <div className="card mainCard" onClick={handleClick} style={{cursor: "pointer"}}>
            <div className="card-body">
                <img className="card mx-auto serviceImage" src={image ? image : banner}
                     onError={(e) => e.target.src = banner}
                     alt="Card image cap"/>
                <div className="">
                    <h4 className="card-title my-2 servicesCardHeading">{title}</h4>
                    {/*<div className="card-text servicesCard-sub-Heading"  dangerouslySetInnerHTML={{__html: description}}/>*/}
                    <a className="" href="#" style={{color: "#EE610F"}}>Read More</a>
                </div>
            </div>
        </div>
    );
}
