import {FaMapMarkerAlt, FaTimesCircle} from "react-icons/fa";
import {
    AppBar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab, Toolbar, Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React from "react";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";

export default function TrackingModel({tracking_url}) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <>
        {/*<Fab variant="extended" size="small" color="primary" className={'px-4 mt-1 me-1'}
             onClick={() => window.open(tracking_url, "_blank")}> <FaMapMarkerAlt
            sx={{mr: 3}}/>&nbsp; Track Order </Fab>*/}
        <Fab variant="extended" size="small" color="primary" className={'px-4 mt-1 me-1'}
             onClick={handleClickOpen}> <FaMapMarkerAlt
            sx={{mr: 3}}/>&nbsp; Track Order </Fab>

        <React.Fragment>
            {/*fullScreen*/}
            <Dialog    fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}
                    aria-labelledby="dialog-track">
                {/*<DialogTitle id="dialog-track"> {"Track Your shipment"}</DialogTitle>*/}
                <DialogContent>
                    {/*<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Modal title
                    </DialogTitle>*/}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 2,
                            top: 2,
                            color: "red",
                        }}
                    >
                        <FaTimesCircle />
                    </IconButton>
                    {/*<AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <FaTimesCircle />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Sound
                            </Typography>
                            <Button autoFocus color="inherit" onClick={handleClose}>
                                save
                            </Button>
                        </Toolbar>
                    </AppBar>*/}
                    <iframe width={"100%"} height={"500"}  src={tracking_url}
                            title="" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                    {/*<DialogContentText>
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    </>
}