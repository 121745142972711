import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchIcon from "@mui/icons-material/Search";
import {FaArrowDown, FaArrowUp, FaCheck} from "react-icons/fa";

export default function UserTypeSelectCard({icon, onClick, data, isActive}) {
    return (
        <Card onClick={onClick} className={'card-hover-primary ' + (isActive ? "card-hover-primary-selected " : "")}>
            {/*<CardMedia
                sx={{ height: 140 }}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="green iguana"
            />*/}

            <CardContent className={'text-center'}>
                {icon}
                <Typography gutterBottom variant="h6" component="div">
                    {data?.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div">
                    {data?.description}

                </Typography>
                {/*<p className={'text-center'}>Apply Now</p>*/}
            </CardContent>
            {/*<CardActions className={'text-center'}>*/}
            {/*    /!*<Button size="small">Share</Button>*!/*/}
            {/*    */}
            {/*</CardActions>*/}
            <p className={'text-center'}><Button size="small"
                                                 color={'warning'}><b>{isActive ? <> Fill The Form <FaArrowDown
                size={17}/> </> : "Enquiry Now"}</b></Button>
            </p>
        </Card>
    );
}