import {CCardBody} from "@coreui/react";
import {Card, Col, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import banner from "../../asset/banner.webp";
import {Paper, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import ModalAddReview from "./ModalAddReview";
import {useAuth} from "../../../hooks";

export function BookingPartInfoCard({dataInfo, partsInfo, deviceInfo, setReloadData, tokenOrder}) {
    var partAmountTotal = 0;
    // const [open, setOpen] = React.useState(false);
    const {authInfo} = useAuth();

    // const [addReviewBody, setAddReviewBody] = useState({
    //     "login_user_id": authInfo.profile.id,
    //     "login_user_type": authInfo.profile.user_type,
    //     "ref_type": "part",
    //     // "token_ref": token_part,
    //     "count_rating": 0,
    //     "message": ""
    // })
useEffect(()=>{
    console.log(partsInfo);
})
    return (
        <Card className={'shadow  w-100'}>
            <CCardBody>
                <Row xs={{cols: 1}} className={'g-3'}>
                    <Col><p className="clientDetail text-dark">Part(s) Ordered ({dataInfo?.count_part || 0})</p>
                    </Col>
                </Row>
                <Row className={'g-2 figure-caption'} xs={{cols: 1}}>
                    <Col>
                        <TableContainer component={Paper} variant="outlined">
                            <Table aria-label="demo table" size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SN.</TableCell>
                                        {/*<TableCell>Icon</TableCell>*/}
                                        <TableCell colSpan={5} className={'text-center'}>Part Name</TableCell>
                                        <TableCell className={'text-center'}>Price</TableCell>
                                        <TableCell className={'text-center'}>Qty</TableCell>
                                        <TableCell colSpan={2} className={'text-center'}>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {partsInfo && partsInfo.map((part, index) => {
                                        partAmountTotal += part.amount_total ? part.amount_total : 0;
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell style={{minWidth: 240}} colSpan={5} className={'text-muted'}>
                                                    <div className=" d-sm-flex d-block justify-content-center">
                                                        {/*&& checkIfImageExists(part?.part?.url_icon)*/}
                                                        {part?.part?.url_icon && part?.part?.url_icon ? (
                                                            <img style={{maxHeight: "50px"}}
                                                                 src={`${part?.part?.url_icon}?fit=crop&auto=format`}
                                                                 srcSet={`${part?.part?.url_icon}?fit=crop&auto=format&dpr=2 2x`}
                                                                 alt={part.title}
                                                                 className="text-center ms-3"
                                                                 onError={(e) => e.target.src = banner}
                                                                 loading="lazy"
                                                            />
                                                        ) : (
                                                            <img style={{maxHeight: "50px"}}
                                                                 src={`${banner}?fit=crop&auto=format`}
                                                                 srcSet={`${banner}?fit=crop&auto=format&dpr=2 2x`}
                                                                 alt={part.title}
                                                                 onError={(e) => e.target.src = banner}
                                                                 loading="lazy"
                                                                 className="text-center ms-3"

                                                            />
                                                        )}
                                                        <div className={'ms-1'}>
                                                            <b><small>{part?.title_part || ""}</small></b> <br/>
                                                            <small>({part?.title_model}, {part?.title_brand}, {part?.title_category})</small>
                                                            <br/>
                                                            {dataInfo?.status === 'repair_success' || dataInfo?.status === 'completed' || dataInfo?.status === 'delivery_success' ?
                                                                (part?.user_review ? <>
                                                                        <Rating size={"small"} readOnly
                                                                                name="simple-controlled"
                                                                                value={part?.user_review?.count_rating || 0}
                                                                                defaultValue={part?.user_review?.count_rating || 0}
                                                                                precision={0.5}/>
                                                                    </> :
                                                                    <ModalAddReview
                                                                        token_ref={part?.token_part || part?.token}
                                                                        ref_type={'part'}
                                                                        reviewFor={'order'}
                                                                        tokenReviewFor={part?.token_order}
                                                                        setReloadData={setReloadData}/>) :
                                                                <></>
                                                            }

                                                        </div>
                                                    </div>

                                                </TableCell>
                                                <TableCell
                                                    className={'text-center fw-bold'}><small>{dataInfo?.amount_currency_symbol || "RS."}{(part?.amount_price || 0)}</small></TableCell>
                                                <TableCell
                                                    className={'text-center fw-bold'}><small>{part?.quantity_part || 1}</small></TableCell>
                                                {/*formatMoney*/}

                                                <TableCell className={'text-center fw-bold'}>
                                                    {/* <span className="mb-5 ">dgfgf</span> <br/> */}
                                                    <small>{dataInfo?.amount_currency_symbol || "RS."} {(part?.amount_total || 0)}</small></TableCell>
                                                {/*<TableCell className={'text-center fw-bold pb-5 mb-5'}>
                                                    <Dropdown align="end" style={{ cursor: "pointer" }}>
                                                        <Dropdown.Toggle as={CustomToggle} >
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="border" >
                                                                <Container className=" pt-4 pb-4" >
                                                                    <Row>
                                                                        <Col>
                                                                            <ModalAddReview token_part={part?.token_part} partsInfo={partsInfo} dataInfo={dataInfo} tokenOrder={tokenOrder}
                                                                                setReloadData={setReloadData} />
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </TableCell>*/}
                                            </TableRow>
                                        )
                                    })}
                                    <TableRow>
                                        <TableCell colSpan={4}/>
                                        <TableCell colSpan={3} align="right">Subtotal</TableCell>
                                        {/*formatMoney*/}
                                        <TableCell
                                            align="right"
                                            colSpan={2}>{dataInfo?.amount_currency_symbol || "RS."} {(dataInfo?.amount_price || 0)}</TableCell>
                                    </TableRow>
                                    {dataInfo?.discount_coupon_code && dataInfo?.discount_coupon_code > 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4}/>
                                            <TableCell colSpan={3}
                                                       align="right">Discount <small><b>{dataInfo?.discount_coupon_code}({dataInfo?.discount_coupon_code_rate || 0}%)</b></small></TableCell>
                                            <TableCell colSpan={2} className={'text-success'}
                                                       align="right">- {dataInfo?.amount_currency_symbol || "RS."} {(dataInfo?.amount_discount_coupon || 0)} </TableCell>
                                        </TableRow>
                                    ) : ""}
                                    {dataInfo?.amount_delivery_charge && dataInfo?.amount_delivery_charge > 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4}/>
                                            <TableCell colSpan={3} align="right">Delivery Charge</TableCell>

                                            <TableCell colSpan={2} className={'text-danger'}
                                                       align="right">+ {dataInfo?.amount_currency_symbol || "RS."} {dataInfo?.amount_delivery_charge || 0} </TableCell>
                                        </TableRow>
                                    ) : ""}

                                    <TableRow>
                                        <TableCell colSpan={4}/>
                                        <TableCell colSpan={3} align="right">Total</TableCell>
                                        <TableCell align="right"
                                                   colSpan={2}>{dataInfo?.amount_currency_symbol || "RS."} {(dataInfo?.amount_total || 0)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Col>
                    {/* <Col>
                        <hr/>
                        <Row>
                            <Col xs={4} className={'fw-bold'}>Total</Col>
                            <Col xs={8}><span
                                className={"float-end fw-bold"}>{dataInfo?.amount_currency_symbol || "RS."} {partAmountTotal || 0}</span></Col>
                        </Row>
                    </Col>*/}
                </Row>

            </CCardBody>
        </Card>
    );
}