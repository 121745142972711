import { React, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { registerUserUpload } from "../../../api/auth";
import { UploadMultipleFiles } from "./UplodMultipleFiles";
import { InputField } from "../../InputFields/InputFieldHelper";
import { CFormInput, CFormLabel } from "@coreui/react";
import { useNavigate } from "react-router-dom";


export function StepFourShopImageDetail({ setPicLocation,regUserToken,handleNext,showError,showSuccess,previousData,
    setPreviousData,setActiveStep, setRegStep
}) {
    const navigate = useNavigate();
    const [btnName, setButtonName] = useState('Submit');
    const [selectedFile, setSelectedFile] = useState([]);
    const [files, setFiles] = useState([]);

    const [userInfo, setUserInfo] = useState({
        token_user: regUserToken,
        step: 'upload_images',
        url_images: []
    });

    useEffect(() => {
        // console.log(userInfo);
    })

    const handleSubmit = async (event) => {
        // console.log("loading,..")
        setButtonName("loading...");
        event.preventDefault();
        let error = true;
        let message = "Images required to continue";
        if (selectedFile.length > 0) {
            for (let index = 0; index < selectedFile.length; index++) {
                const formData = new FormData();
                formData.append("step", userInfo.step);
                formData.append("url_images", selectedFile[index].fileOriginal);
                if (index === (selectedFile.length - 1)) {
                    formData.append("type", 'next');
                } else {
                    formData.append("type", 'upload');
                }
                formData.append("token_user", userInfo.token_user);

                let { data, message, error } = await registerUserUpload(formData);
                setPreviousData(data)  
                if (error) {
                    showError(message);
                } else {
                    // showSuccess(message);
                }
            }
            setSelectedFile([]);
            showSuccess("All detail's submitted successfully. Please wait for verification.");
            setRegStep(5);
            handleNext();
            navigate("/", {
                replace: true,
            });

        } else {
            showError('Please select file');
            setButtonName("Submit");
        }
    };


    return (
        <Form onSubmit={handleSubmit} className="needs-validation text-center" noValidate>
            <UploadMultipleFiles files={files} setFiles={setFiles} selectedFile={selectedFile}
                setSelectedFile={setSelectedFile} />
            <Form.Group>
                <Row>
                    <Col>
                        <Button type={'button'} className="button-2 float-left my-3"
                            onClick={() => {
                                setRegStep(3)
                                setActiveStep(2)
                                setPicLocation(true)
                            }}>{"Previous"}</Button>
                    </Col>
                    <Col className="text-end">
                        <Button type="submit" variant="" className="btn btn-primary1 my-3 ">{btnName}</Button>
                    </Col>
                </Row>
                {/*<Button type="submit" variant={""} className="btn btn-primary1 my-5">{btnName}</Button>*/}
            </Form.Group>
        </Form>
    );
}