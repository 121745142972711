import { React, useEffect, useState } from "react";
import { useAuth, useNotification } from "../../hooks";

const validateUserInfo = (email) => {
  if (!email.trim()) return { ok: false, error: "Email is missing!" };
  if (!isValidEmail(email)) return { ok: false, error: "Invalid email!" };

  return { ok: true };
};

export default function ForgotPassword() {
  const [userInfo, setUserInfo] = useState({
    device_type: "web",
    istoken: "ISABCDEFGH",
    username: "",
    device_token:  localStorage.getItem('device_token') || "ABCDEFGHIJK",
    otp: "",
    reason: "",
  });


  const { verifyUser, authInfo, sendUserOtp } = useAuth();
  const [otpVisible, setOtpVisible] = useState(false);
  const [btnText, setBtnText] = useState("Verify Email");

  const { showError, showSuccess } = useNotification();

  const handleChange = async ({ target }) => {
    const { value, name } = target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (!otpVisible) {
      const { ok, error } = validateUserInfo(userInfo.username);

      if (!ok) return showError(error);
      const {notVerified} = await verifyUser(userInfo,"verification-for-password-reset");

      if(notVerified){
          return showError("Please register first to continue")
      }
      if (!notVerified) {
         const {sendStatus} = await sendUserOtp(userInfo)

         if(sendStatus){
            setOtpVisible(true)
            setBtnText("Verify OTP")
         }
          
      }
    }
    if (otpVisible) {
      sendUserOtp(userInfo, "verification-for-reset-password");
    }
  };

  return (
    <section className="section">
      <div className="col-12 col-md-8 col-lg-6 col-xl-7 temp2">
        <div className="card shadow-2-strong auth-card ">
          <div className="card-body temp">
            <p className=" text-center auth-heading">
              Forgot Password
            </p>
            <p className="text-center mb-5">
              Book A Repair, purchase parts and get the best offers.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="form-outline my-3">
                <label
                  className="form-label fw-bold auth-input-heading"
                  htmlFor="typeEmailX-2"
                >
                  Email Address
                </label>
                <input
                  name="username"
                  value={userInfo.username}
                  onChange={handleChange}
                  type="email"
                  id="typeEmailX-2"
                  className="form-control form-control-lg"
                />
              </div>
              {otpVisible && (
                <div className="form-outline my-3">
                  <label
                    className="form-label fw-bold auth-input-heading"
                    htmlFor="typePasswordX-4"
                  >
                    OTP
                  </label>
                  <input
                    onChange={handleChange}
                    name="otp"
                    value={userInfo.otp}
                    type="password"
                    id="typePasswordX-4"
                    className="form-control form-control-lg"
                  />
                </div>
              )}

              <button type="submit" className="btn btn-primary1 my-3 ">
                {btnText}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export const isValidEmail = (email) => {
  const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return isValid.test(email);
};
