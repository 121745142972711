import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {useAuth, useNotification} from "../../hooks";
import {ApiCall} from "../../api/HelpingMethod";
import {BsPlusLg} from "react-icons/bs";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import {useNavigate} from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

const QuickEntry = () => {

    const {authInfo} = useAuth();
    const [btnText, setBtnText] = useState('Submit')

    const [card, setCard] = useState([]);
    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        // "address_id": 256,
    })
    const navigate = useNavigate()
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false)
    const {showError, showSuccess} = useNotification();
    const [cartAddress, setCartAddress] = useState([])
    const [isAddressSelect, setIsAddressSelect] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)


    const [addressBody, setAddressBody] = useState({
        "login_user_id": authInfo?.profile?.id,
        "login_user_type": authInfo?.profile?.user_type,
        "limit": authInfo?.profile?.user_type === "client" ? 3 : 1,
        "offset": 0
    })

    const getCartAddress = async (e) => {
        setLoading(true);
        const result = await ApiCall("/dealer/address", addressBody)
        setIsAddressSelect(result?.data[0]?.id)
        setCartAddress(result?.data);
        setLoading(false);
    }

    useEffect(() => {
        getCartAddress();
    }, []);

    const [arr, setArr] = useState(
        [
            {
                type: "text",
                value: "",
                id: 1,
                label: 'Part Title',
            }
        ]
    );
    const addInput = () => {
        setArr(s => {
            return [
                ...s,
                {
                    type: "text",
                    value: '',
                    id: s.length + 1,
                    label: 'Part Title',
                }
            ];
        });
    };

    const onSelectAddress = (id) => {
        // console.log("iddddddddd---", id)
        setIsAddressSelect(id);
    }

    function handleChange(event, i) {
        const {name, value} = event.target;
        setFormData({...formData, [name]: event.target.value})

        const arr = [...card]
        arr[i] = {...arr[i], [event.target.name]: event.target.value, ['id']: i}
        setCard(arr);
    }

    const handleRemove = (id, value) => {
        var a = arr.filter(function (value, index, arr) {
            // return value.id !== id 
            return value.id !== id
        });

        setArr(a)
        var b = card.filter(function (value, index, arr) {
            return value.id !== id
        });

        setCard(b);

    }
    const handleSubmit = async () => {
        setBtnLoading(true);
        setBtnText(<><CircularProgress className="me-2" style={{color: "var(--primary)"}} size={15}/>
            Submit</>)
        bodyData.order_parts = card
        bodyData.address_id = isAddressSelect
        const response = await ApiCall("dealer/part_quick_order", bodyData)
        // console.log("response---", response)
        // console.log("bodyData---", bodyData)

        if (response?.error === false) {
            showSuccess(response?.message)
            navigate("/booking-details/" + response?.data?.token + "?order=" + response?.data?.token, {
                state: {token: response?.data?.token},
                replace: true,
            });
        } else {
            showError(response?.message)

            // setData()
        }
        setBtnText('Submit')
        setBtnLoading(false);

    }

    return (
        <>
            <div className="ourservices">
                <h3 className=" mx-lg-5 servicesHeading">Quick Entry</h3>
                <p className="mx-lg-5 subHeading">
                    {/*Get Spare Parts at low prices on iService. Wide range of Spare Parts. Get Free Shipping & COD options across India*/}
                    iService offers low-cost spare parts. A large selection of spare parts is available. Free shipping
                    and cash-on-delivery (COD) are available in many Indian cities.

                </p>
            </div>
            <Container className="mt-2 mb-5">

                <Row className="mt-3">
                    <Col md={6}>
                        <Row className="mt-2 mb-1">
                            <Col className="">
                                <h5 className="quickentryHeading">Select Address</h5>
                            </Col>
                        </Row>
                        <Container>
                            {loading &&
                            <Row className="mt-3"> <SkeletonFileGeneral height={90} col={12} total={3}/> </Row>}
                            {!loading &&
                            <Row className={"mt-3"}><Col><FormControl sx={{m: 1}} error={false} variant="standard">
                                <RadioGroup aria-labelledby="demo-error-radios"
                                            name="address_id"
                                            value={isAddressSelect || 0}
                                    // onChange={handlePaymentModeChange}
                                >
                                    {cartAddress && Array.isArray(cartAddress) && cartAddress.length > 0 ? cartAddress.map((address, index) => {
                                        return (
                                            <Row className={'mb-2'}
                                                 key={address?.token || address?.id || index}>
                                                <Col>
                                                    <Card style={{cursor: "pointer"}}
                                                          onClick={() => onSelectAddress(address.id)}
                                                          className={isAddressSelect === address?.id ? "select-address shadow" : "shadow"}>
                                                        <Card.Body>

                                                            <FormControlLabel
                                                                value={address?.id}
                                                                control={<Radio color={"warning"}/>}
                                                                label={address?.full_address || "No Address"}/>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )
                                    }) : <h6 className={'text-danger'}>No addresses available. Please add an
                                        address to place an
                                        order.</h6>}
                                </RadioGroup>
                            </FormControl></Col></Row>
                            }
                        </Container>

                    </Col>
                    <Col md={6} className="mt-md-0 mt-3">
                        <Row className="mt-2 mb-1">
                            <Col className="">
                                <h5 className="quickentryHeading">Quick Order</h5>
                            </Col>
                        </Row>
                        <Card className="shadow">
                            <Card.Body>


                                {loading &&
                                <Row className="mt-3"> <SkeletonFileGeneral height={40} col={12} total={2}/> </Row>}

                                {!loading && <Container>


                                    <Row className="mt-1">
                                        <Col>
                                            <TextField
                                                label={'Example: iPhone 11, Folder'}
                                                size={'small'}
                                                variant="outlined"
                                                onChange={(e) => handleChange(e, 0)}
                                                className={'form-control quck-entry-input'}
                                                name={"title_part"}
                                                type={'text'}
                                                color={"warning"}
                                            />
                                        </Col>
                                    </Row>


                                    {arr.map((item, i) => {
                                        return (
                                            <>

                                                {/* <Row key={i} className="mt-1">
                                                    <Col sm={8} md={12} lg={9}>
                                                        <TextField
                                                            label={'Example: iPhone 11, Battery'}
                                                            size={'small'}
                                                            variant="outlined"
                                                            onChange={(e) => handleChange(e, i + 1)}
                                                            className={'form-control quck-entry-input'}
                                                            name={"title_part"}
                                                            type={'text'}
                                                            color={"warning"}/> 
                                                    </Col>
                                                    <Col sm={4} md={12} lg={3}
                                                        className=" text-end mt-md-2 mt-sm-0 mt-lg-0 mt-2">
                                                        <Button className="" onClick={() => handleRemove(item.id)}
                                                            variant="outlined" style={{
                                                                color: "var(--primary)",
                                                                borderColor: "var(--primary)"
                                                            }}> <BsTrashFill className="me-1" /> Remove</Button>
                                                    </Col>
                                                </Row> */}


                                                <Row key={i} className="mt-2">
                                                    <Col>
                                                        <FormControl
                                                            label={'Example: iPhone 11, Battery'}
                                                            size={'small'}
                                                            variant="outlined"
                                                            onChange={(e) => handleChange(e, i + 1)}
                                                            className={'form-control quck-entry-input'}
                                                            name={"title_part"}
                                                            type={'text'}
                                                            color={"warning"}
                                                        >
                                                            <InputLabel htmlFor="outlined-adornment-password">Example:
                                                                iPhone 11, Battery</InputLabel>
                                                            <OutlinedInput
                                                                id="outlined-adornment-password"
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            edge="end">
                                                                            <DeleteIcon
                                                                                onClick={() => handleRemove(item.id)}
                                                                                color="warning"/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                label="Password"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                </Row>


                                            </>
                                        );
                                    })}
                                    <Row className="mt-3">
                                        <Col>
                                            <Button variant="outlined" onClick={addInput} style={{
                                                color: "var(--primary)",
                                                borderColor: "var(--primary)"
                                            }}><BsPlusLg/> &nbsp; Add Part item</Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="text-center mt-4">
                                            <Button disabled={btnLoading || !cartAddress.length ? true : false}
                                                    variant='contained' color={'warning'}
                                                    className={'sign-up-button'} onClick={handleSubmit}>{btnLoading &&
                                            <CircularProgress className="me-2" color="inherit"
                                                              size={16}/>} Submit</Button>
                                        </Col>
                                    </Row>


                                </Container>}
                            </Card.Body>
                        </Card>

                    </Col>

                </Row>

            </Container>


        </>
    )
}
export default QuickEntry;