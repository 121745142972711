import {React, useEffect, useState} from "react";
import MoreButton from "../HomePage/MoreButton";
import {cartAction} from "../../api/cart";
import {useAuth, useNotification} from "../../hooks";
import {Link, useNavigate} from "react-router-dom";
import {FaMoneyBill, FaShoppingBasket, FaShoppingCart} from "react-icons/fa";
import {Badge, Col, Form, Row} from "react-bootstrap";
import {
    Avatar,
    Chip,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Rating
} from "@mui/material";
import ProductGallery from "./ProductGallery";
import {cropText, formatMoney} from "../../hooks/useCustomHelpingMethod";
import {ApiCall} from "../../api/HelpingMethod";
import Button from "@mui/material/Button";
import ProductNotifyMe from "./ProductNotifyMe";


export default function ProductInfoCard({part, id, title, brand, img, inCart, model}) {
    const {showError, showSuccess} = useNotification()
    const [buttonText, setButtonText] = useState("Add to Cart")
    const [buttonTextBuy, setButtonTextBuy] = useState("Buy Now")
    const [buyNowLoading, setByNowLoading] = useState(false)
    const [isAvailable, setIsAvailable] = useState(false)

    const [cart, setCart] = useState(inCart)
    const {authInfo} = useAuth()
    const [colorChange, setColorChange] = useState(false)
    const {setShowLoginModel} = useNotification();
    const [btnLoading, setBtnLoading] = useState(false)
    const [btnLoading2, setBtnLoading2] = useState(false)
    const [btnText, setBtnText] = useState('Check')
    const [data, setData] = useState('')
    const [colorVariants, setColorVariants] = useState(part?.variant_products || [])

    const [compatibleLimitDefault] = useState(25)
    const [compatibleLimit, setCompatibleLimit] = useState(compatibleLimitDefault)

    useEffect(() => {
        setColorVariants(part?.variant_products || []);
    }, [part])

    const navigate = useNavigate()
    const onClick = async () => {
        setButtonText("loading...");
        setBtnLoading(true);
        if (!authInfo.profile.id) {
            showError("Please login to add this product into cart.");
            setButtonText("Add to Cart");
            setBtnLoading(false);

            navigate("/auth");
        } else {
            const data = await cartAction({
                id: authInfo?.profile?.id,
                user_type: authInfo?.profile?.user_type,
                actionType: "add_to_cart",
                part_id: id
            })
            if (!data.data || data.data.error) {
                showError(data.data.message || "An error occurs");
                setColorChange(true);
                setButtonText("View Cart");
                setBtnLoading(false);

            } else {
                showSuccess(data.data.message)
                setCart("yes");
                setColorChange(true);
                setButtonText("Added to Cart");
                setBtnLoading(false);

            }
            window.location.reload();
        }
    };

    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "pincode": "",
        "limit": 10,
        "offset": 0
    })

    const handleChangePincode = (e) => {
        const {name, value} = e.target;
        setBodyData({...bodyData, [name]: value})
    }

    const getDeliveryAddress = async (e) => {
        e.preventDefault();
        setBtnLoading2(true);
        setBtnText(<>Check</>)

        const response = await ApiCall("dealer/delivery_pincode", bodyData)
        if (bodyData.pincode === '') {
            // showError('Please enter the pincode')
            setData(<b className={'text-danger '}>Please enter the pincode</b>)
        } else {
            if (response?.error === false) {
                let message = {
                    'cod': {}, 'delivery': {},
                };
                if (response?.data?.is_cod_available === 'yes') {
                    message.cod = {
                        error: false,
                        message: "COD is available.",
                    }
                } else {
                    message.cod = {
                        error: true,
                        message: "COD is not available.",
                    }
                }
                if (response?.data?.is_delivery_available === 'yes') {
                    message.delivery = {
                        error: false,
                        message: "Delivery is available at your location.",
                    }
                } else {
                    message.delivery = {
                        error: true,
                        message: "Delivery is not available at your location.",
                    }
                }

                // setData("<b>" + message + "</b>")
                setData(message);
                // showSuccess(response?.message)
            } else {

                setData({
                    cod: {
                        error: true,
                        message: "COD is not available.",
                    }, delivery: {
                        error: true,
                        message: "Delivery is not available at your location.",
                    }
                })
                // showError(response?.message)
            }
        }
        setBtnLoading2(false);
        setBtnText('Check')
    }

    const onNavigate = async () => {
        navigate("/cart");
    }

    const style = {color: "#ee610f", fontSize: "1.5em"}

    const part_title = part?.title || title || "";
    // const part_title_category = part?.title_category || "";
    const part_title_brand = part?.title_brand || brand;
    const part_title_model = part?.title_model || model;
    const part_priceMrp = part?.amount_mrp || 0;
    let part_priceUser = part?.amount_total || 0;
    if (authInfo.profile) {
        if (authInfo.profile.user_type && authInfo.profile.user_type === 'dealer') {
            part_priceUser = part?.amount_total_dealer || 0;
        } else {
            part_priceUser = part?.amount_total_customer || 0;
        }
    }
    const moneySymbol = part?.amount_currency_symbol || "₹";


    let discountPerUser = part?.rate_discount || 0;
    let discountAmountUser = part?.amount_discount || 0;
    if (authInfo.profile) {
        if (authInfo.profile.user_type && authInfo.profile.user_type === 'dealer') {
            discountAmountUser = part?.amount_discount_dealer || 0;
        } else {
            discountAmountUser = part?.amount_discount_customer || 0;
        }
    }

    const onClickBuyNow = async () => {

        setButtonTextBuy("loading...");
        setByNowLoading(true);
        if (!authInfo?.profile?.id) {
            setShowLoginModel(true);
            showError("Please login to buy this product");
            setButtonTextBuy("Buy Now");
            setByNowLoading(false);

            // navigate("/auth");
        } else {
            const data = await cartAction({
                id: authInfo?.profile?.id,
                user_type: authInfo?.profile?.user_type,
                actionType: "add_to_cart",
                part_id: id
            })
            setButtonTextBuy("Buy Now");
            setByNowLoading(false);

            if (!data.data || data.data.error) {
                showError(data.data.message || "An error occurs");
            } else {
                // showSuccess(data.data.message)
                // navigate("/cart?token_part=" + (part?.token));
                navigate("/cart-checkout?token_part=" + (part?.token));
            }
        }
    };


    // const partColor = [
    //     {
    //         id: 1,
    //         color: "#d11616",
    //         is_selected: true,
    //     }, {
    //         id: 2,
    //         color: "#6f4afb",
    //         is_selected: false
    //     }, {
    //         id: 3,
    //         color: "#6af1cd",
    //         is_selected: false
    //     }, {
    //         id: 4,
    //         color: "#9da905",
    //         is_selected: false
    //     }
    // ]

    useEffect(() => {
        if (part) {
            setIsAvailable(part?.status === 'active' && part?.availability === 'available');
        }
    }, [part]);


    let part_rating = part?.count_rating_overall && part?.count_rating_overall !== 0 ? part?.count_rating_overall : 3.5;

    return (
        <div className="card shadow /*cartProduct*/">
            <div className="card-body {/*cart-product-card*/}">
                <Row className={'g-3'}>
                    <Col md={12} lg={6}>
                        <div className="{/*product-info-image*/}">
                            <ProductGallery img={img} discount={discountPerUser} data={part}/>

                            {/*<ProductGalleryLightBox images={img}/>*/}
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className="mx-4">
                            <h3 className="card-title my-2 product-info-heading ">
                                <b>{part_title || ""}</b>
                            </h3>
                            <div className="d-flex">
                                {}
                                <Rating readOnly name="simple-controlled"
                                        value={part_rating || 0}
                                        defaultValue={part_rating || 0}
                                        precision={0.5}/>

                            </div>
                            <div className="d-flex mt-2">
                                {isAvailable ? <>
                                    <FaMoneyBill style={style} className={"mr-3"}/>
                                    <span className="text-decoration-line-through mx-2  fw-bold">
                                        {moneySymbol || "Rs."}{formatMoney(part_priceMrp ?? 0)}
                                    </span>
                                    <p className="fw-bold fs-5 color-primary mr-3">
                                        {moneySymbol || "Rs."}{formatMoney(part_priceUser ?? 0)}
                                    </p>
                                </> : <h5 className={'m-2'}><Badge pill bg="danger">Out Of Stock</Badge></h5>}
                                {isAvailable && discountAmountUser && discountAmountUser > 0 ?
                                    <Chip size="small"
                                          label={"SAVE " + (moneySymbol || "Rs.") + " " + (formatMoney(discountAmountUser || 0))}
                                          color="success"
                                          className={'m-2'}/> : ""}
                            </div>
                            {/*<p className="card-text my-1">
                                <small className="fw-bold">Category:</small> <Chip size="small" className={'m-1'}
                                    variant="outlined" color="primary"
                                    label={part_title_category} />

                            </p>*/}
                            <div className="card-text my-1">
                                <small className="fw-bold">Brand:</small> <Chip size="small" className={'m-1'}
                                                                                variant="outlined" color="primary"
                                                                                label={part_title_brand}/>

                            </div>
                            <div className="card-text my-1">
                                <small className="fw-bold">Model: </small><Chip size="small" className={'m-1'}
                                                                                variant="outlined" color="primary"
                                                                                label={part_title_model}/>
                            </div>
                            {part?.compatibility && <>
                                <div className="card-text my-1">
                                    <small className="fw-bold">Compatible
                                        Model: </small><span><small>
                                    {compatibleLimit && part?.compatibility?.length > compatibleLimitDefault ?
                                        <>{cropText(part?.compatibility, compatibleLimit)} <b
                                            onClick={() => setCompatibleLimit(0)} className={"pointer color-primary"}>more</b></> :
                                        <>{part?.compatibility} {part?.compatibility?.length > compatibleLimitDefault &&
                                        <b onClick={() => setCompatibleLimit(compatibleLimitDefault)}
                                           className={"pointer color-primary"}>less</b>}</>
                                    }
                                        </small></span>
                                </div>
                            </>}

                            {/*<p className={'card-text'}>
                                <Stack direction="row" spacing={1} sx={{
                                    display: 'flex',
                                    // justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    // listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}>
                                    <b>Compatible: </b>
                                    <Chip size="small" className={'m-1'} variant="outlined" color="primary" label={part_title_model}/>
                                    <Chip size="small" className={'m-1'} variant="outlined" color="primary" label={part_title_brand}/>
                                    <Chip size="small" className={'m-1'} variant="outlined" color="primary" label={part_title_category}/>
                                </Stack>
                            </p>*/}
                            {/*{(part_type || true) && <p className="card-text my-1">
                                <small className="fw-bold">Part Type: </small><span
                                className={"mx-1"}><Chip size="small" label={part_type || "screen card"}/> </span>
                            </p>}*/}

                            {/*<p>Availability : {stock} </p>*/}

                            <div className={'mt-0 '}>
                                <Row>
                                    <Col className="mb-1 mt-0 d-flex">
                                        <small className="fw-bold me-2 my-2">Color : </small>
                                        {colorVariants && Array.isArray(colorVariants) && colorVariants?.length >= 0 ?
                                            colorVariants.map((partColor,index) => {
                                                let isSelect = partColor?.part_color === part?.part_color;
                                                let color = {
                                                    bgcolor: partColor?.part_color || '#fffff',
                                                    width: 20,
                                                    height: 20
                                                };
                                                return (
                                                    <Link key={index} to={"/product/" + partColor.slug}
                                                          className={'text-decoration-none text-dark'}>
                                                        <Avatar key={partColor.id} variant="rounded"
                                                                className={isSelect ? "mt-2 mx-1 selected-color" : "mt-2 mx-1 pointer"}
                                                                sx={color}>{" "}</Avatar>
                                                    </Link>
                                                )
                                            })
                                            :
                                            <>
                                                {part?.part_color && part?.part_color !== "" &&
                                                <>
                                                    <small className="fw-bold me-2 mt-2">Color: </small>
                                                    <Avatar variant="rounded" className={"mt-2 mx-1 selected-color"}
                                                            sx={{ bgcolor: part?.part_color || '#ffffff', width: 25,
                                                                height: 25 }}>{""}</Avatar>
                                                </>
                                                }
                                            </>
                                        }
                                    </Col>

                                </Row>
                                <Row className="fw-bold my-2"><Col>Check COD Available</Col></Row>
                                <Row>
                                    <Form onSubmit={getDeliveryAddress}>
                                        <Col>
                                            <FormControl variant="outlined" color={"warning"}>
                                                <InputLabel htmlFor="outlined-adornment-password">Enter
                                                    Pincode</InputLabel>
                                                <OutlinedInput
                                                    color={'warning'}
                                                    value={bodyData?.pincode || ""}
                                                    aria-required={true}
                                                    required={true}
                                                    onChange={handleChangePincode} name="pincode"
                                                    placeholder={'Enter pincode here...'}
                                                    type="number" aria-valuemax={6} aria-valuemin={6}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Button disabled={btnLoading2} type={'submit'}
                                                                    className="bgprimary" variant={'contained'}
                                                            >{btnLoading2 &&
                                                            <CircularProgress size={16}
                                                                              color={"inherit"}/>}&nbsp;{btnText}</Button>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>

                                            {/*<Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                                                <TextField size={"small"} value={bodyData.pincode || ""}
                                                           aria-required={true}
                                                           required={true}
                                                           onChange={handleChangePincode} name="pincode"
                                                           placeholder={'Enter pincode here...'}
                                                           type="number" aria-valuemax={6} aria-valuemin={6}/>
                                                <Button disabled={btnLoading2} type={'submit'}
                                                        className="" variant={'contained'}
                                                        color={'warning'}>{btnLoading2 &&
                                                <CircularProgress size={16} color={"inherit"}/>}&nbsp;{btnText}</Button>
                                            </Box>*/}
                                        </Col>
                                    </Form>
                                </Row>


                                {data?.cod && (data?.cod?.error === false ?
                                    <b className={'text-success'}><small>{data?.cod?.message}</small></b> :
                                    <b className={'text-danger'}><small>{data?.cod?.message}</small></b>)}
                                <br/>
                                {data?.delivery && (data?.delivery?.error === false ?
                                    <b className={'text-success'}><small>{data?.delivery?.message}</small></b> :
                                    <b className={'text-danger'}><small>{data?.delivery?.message}</small></b>)}
                            </div>


                            <Row xs={{cols: 1}} className=" g-2">


                                <Col className="mt-3">
                                    {isAvailable ? (
                                        authInfo.profile.id ? (
                                            cart === "yes" || inCart === "yes" ?
                                                <MoreButton onClickChange={onNavigate} className={'mx-auto'}>
                                                    <FaShoppingCart/>  &nbsp; View Cart </MoreButton> :
                                                <MoreButton disabled={btnLoading} onClickChange={onClick}
                                                            colorChange={colorChange}>
                                                    {btnLoading && <CircularProgress size={16}
                                                                                     color={"inherit"}/>}<FaShoppingCart/> &nbsp; {buttonText}
                                                </MoreButton>

                                        ) : (<MoreButton onClickChange={() => setShowLoginModel(true)}
                                                         className={'mx-auto'}><FaShoppingCart/>  &nbsp; Add To Cart
                                        </MoreButton>)

                                    ) : (<>
                                        <ProductNotifyMe tokenPart={part?.token} part={part}/>
                                        {/*<b className={'text-danger text-center'}>*Notify me when available*</b>*/}
                                        {/*<b className={'text-danger text-center'}>*This product is currently not
                                            available.
                                            Please visit after sometime.*</b>*/}
                                    </>)}

                                </Col>

                                <Col>
                                    {isAvailable ? (
                                        authInfo?.profile?.id ? (
                                            <div onClick={onClickBuyNow} className="buy-button text-center">
                                                {buyNowLoading &&
                                                <CircularProgress size={16} color={"inherit"}/>} &nbsp; &nbsp;
                                                <FaShoppingBasket/>  &nbsp; {buttonTextBuy}
                                            </div>) : (
                                            <div onClick={() => setShowLoginModel(true)}
                                                 className="buy-button text-center">
                                                <FaShoppingBasket/>  &nbsp; {buttonTextBuy}
                                            </div>)
                                    ) : ""}
                                </Col>
                            </Row>

                            {/* <div className="d-flex text-center">
                                <div className="">
                                    {isAvailable? (
                                        cart === "yes" || inCart === "yes" ?
                                            <MoreButton onClickChange={onNavigate} className={'mx-auto'}>View
                                                Cart</MoreButton> :
                                            <MoreButton onClickChange={onClick}
                                                colorChange={colorChange}>{buttonText}</MoreButton>

                                    ) : (<b className={'text-danger text-center'}>*This product is currently not available.
                                        Please visit after sometime.*</b>))}
                                </div>

                                <div className="">
                                    {isAvailable?
                                        <div onClick={onClickBuyNow} className="buy-button text-center">
                                            {buttonTextBuy}
                                        </div> : "")}
                                </div>
                            </div> */}

                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
