import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Card} from "@mui/material";
import banner from "../asset/banner.webp"
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";


export default function Header({title, img, description, isLoading}) {
    const [isOverFlow, setIsOverFlow] = useState(false);
    const [isReadMore, setIsReadMore] = useState(false);
    const containerStyleOverflow = {
        height: "100px",
        overflow: "hidden",

    };
    const containerStyleOverflowNot = {
        // height: "200px",
        overflow: "hidden"
    };

    const handleReadMore = () => {
        setIsReadMore(!isReadMore);
    }

    useEffect(() => {
        const length = description?.length || 0;
        if (length > 1000) {
            setIsOverFlow(true);
        } else {
            setIsOverFlow(false);
        }
        // console.log(length);
    }, [description])

    return (
        /*mt-5*/ /*pb-5*/
        <div className=" " style={{marginBottom: "50px"}}>
            <div className=" my-1">
                <Row>
                    {isLoading && <SkeletonFileGeneral total={1} col={4} colLg={4} height={30}/>}
                    <Col xs={12}/>
                    {isLoading && <SkeletonFileGeneral total={1} col={8} colLg={9} height={250}/>}
                    {isLoading && <SkeletonFileGeneral total={1} col={4} colLg={3} height={250}/>}
                    <Col xs={{span: 12, order: 2}} md={{span: 9, order: 1}}>
                        <h3 className="servicesHeading text-dark">
                            About <span style={{color: "#EE610F"}}>{title}</span> Repair
                        </h3>
                        <p className="headerInAbout mt-3 "
                           style={isOverFlow && !isReadMore ? containerStyleOverflow : containerStyleOverflowNot}>
                            <div dangerouslySetInnerHTML={{__html: description}}/>
                        </p>
                        <p>{isOverFlow && (!isReadMore ? (
                            <a className={'btn btn-link'} onClick={handleReadMore}>Read More</a>) : (
                            <a className={'btn btn-link'} onClick={handleReadMore}>Read Less</a>))}</p>
                    </Col>
                    <Col xs={{span: 12, order: 1}} md={{span: 3, order: 2}} className={'mb-5'}>
                        <Card>
                            <img className="w-100" alt={title} src={img ? img : banner} onError={(e) => e.target.src = banner}/>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
