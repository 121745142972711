import { React, createContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Backdrop, CircularProgress } from "@mui/material";
import { MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog } from "mdb-react-ui-kit";
import LoginModal from "./LoginModal";
import SmallLoginModal from "./SmallLoginModal";
import LargeLoginModal from "./LargeLoginModal";


export const NotificationContext = createContext();

export default function NotificationProvider({ children }) {

  const showSuccess = (value) => {
    // console.log("hi")
    toast.success(value, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const showWarning = (value) => {
    toast.warn(value, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showError = (value) => {
    toast.error(value, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };


  const [openBackDrop, setOpenBackDrop] = useState(false);


  const [showLoginModel, setShowLoginModel] = useState(false);




  return (
    <NotificationContext.Provider value={{ showSuccess, showWarning, showError, setShowLoginModel, setOpenBackDrop }}>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />



      {/* 
      <Modal show={showLoginModel} onHide={handleClose} animation={true} className="login-modal" centered> 
        <Modal.Body >
          <Container>
            <Row className="fw-bold text-center mt-3">
              <Col >
                <BsExclamationCircle color="#eb3434" size={100} />
              </Col>
            </Row>
            <Row className="fw-bold mt-4 text-center">
              <Col>
                Please Login to proceed with the process.
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col className="text-center">
                <Button onClick={() => setShowLoginModel(false)} variant='outlined' className='me-3' style={{ color: "var(--primary)", borderColor: "var(--primary)" }}>Cancel</Button>
                <Link  >
                  <Button variant='contained' color='warning' className='sign-up-button ps-4 pe-4'
                    onClick={handleClose}>Login</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal> */}



      <div>
        <Backdrop
          sx={{ color: 'var(--primary)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackDrop}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <MDBModal open={showLoginModel} tabIndex='-1' onClose={() => setShowLoginModel(false)}>
        <MDBModalDialog size='lg' centered>
          <MDBModalContent>
            <MDBModalBody className="pt-0 ps-0 pe-0 pb-0">
              <LoginModal />
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>



      {/* <MDBModal open={showLoginModel} tabIndex='-1' onClose={() => setShowLoginModel(false)}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody className="pt-0 ps-0 pe-0 pb-0">
              <SmallLoginModal/>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal> */}


      {/* <MDBModal open={showLoginModel} tabIndex='-1' onClose={() => setShowLoginModel(false)}>
        <MDBModalDialog size="xl" centered>
          <MDBModalContent>
            <MDBModalBody className="pt-0 ps-0 pe-0 pb-0">
              <LargeLoginModal />
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal> */}

      {children}

    </NotificationContext.Provider>
  );
}
;
