import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BiUserCircle, BiCalendar, BiTime, BiChat } from "react-icons/bi";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { CardMedia, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import RelatedBlog from "./RelatedBlog";
import { useAuth } from "../../hooks";
import { ApiCall } from "../../api/HelpingMethod";
import { get_datetime_formatted } from "../../hooks/useCustomHelpingMethod";
import Modal from 'react-bootstrap/Modal';
import { BsXLg } from "react-icons/bs";

const BlogDetail = () => {
    const [data, setData] = useState({})
    const { slugBlog } = useParams();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { authInfo } = useAuth();

    const [loading, setLoading] = useState(false);
    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        // "slug_blog": "iot-enabled-energy-meter",
        "slug_blog": slugBlog
    })

    const getData = async () => {
        setLoading(true)
        const response = await ApiCall("dealer/blog", bodyData)
        if (response?.error === false) {
            setData(response?.data)
        } else {
            setData(null)
        }
        setLoading(false)
    }




    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        getData()
    }, [slugBlog])

    return (
        <div>
            <div className="ourservices">
                <h3 className=" mx-lg-5 servicesHeading">{data?.title || ""}</h3>
                <p className="mx-lg-5 subHeading">
                    {/*Get Spare Parts at low prices on iService. Wide range of Spare Parts. Get Free Shipping & COD options across India*/}
                    {/* iService offers low-cost spare parts. A large selection of spare parts is available. Free shipping
                    and cash-on-delivery (COD) are available in many Indian cities*/}

                </p>
            </div>

            <Container className="pt-3">
                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="blog-modal p-0" >
                        <div style={{ backgroundImage: `url(${data?.media_url})` }} className="blog-modal-img">
                            <div className="text-end p-3">
                                <BsXLg className="close-button" size={30} onClick={handleClose} />
                            </div>
                            {/* <img onClick={handleShow} style={{ height: "100%", width: "100%" }} src={data?.media_url} /> */}
                        </div>

                    </Modal.Body>
                </Modal>


                <Row className="h1 mb-4 text-center">
                    {loading ? (
                        <Skeleton className="rounded mt-5 mx-auto" variant="rectangle" height={20} width={400} />
                    ) : (
                        <h4 className="mt-5 fw-bold">{data?.title}</h4>
                    )}
                </Row>
                <Row className="mb-4 text-center">
                    <Col>
                        {loading ? (
                            <Skeleton className="rounded mx-auto" variant="rectangle" height={20} width={200} />
                        ) : (
                            <>
                                {" "}
                                <BiUserCircle color="var(--primary)" />&nbsp;
                                I Service India &nbsp;&nbsp;&nbsp; <BiCalendar color="var(--primary)" />{" "}
                                {get_datetime_formatted(data?.date_schedule)}{" "}
                            </>
                        )}
                    </Col>
                </Row>
                <Row>
                    {loading ? (
                        <Col className="">
                            <Skeleton className="rounded " variant="rectangle" height={400} />
                        </Col>
                    ) : (
                        <Col className="text-center">
                            <img onClick={handleShow} alt={data?.title}
                                className="rounded pointer img-fluid w-50" src={data?.media_url} />
                        </Col>
                    )}
                    <div className="fontFamily mt-3">
                        <div dangerouslySetInnerHTML={{
                            __html: data?.description
                        }} />
                    </div>
                </Row>

            </Container>

            <RelatedBlog />
        </div>
    );
};
export default BlogDetail;

