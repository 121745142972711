import * as React from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {CircularProgress, Fab, InputAdornment, TextField} from "@mui/material";
import {FaPlus, FaTimes} from "react-icons/fa";
import {booking_cancel, jobsheet_action} from "../../../api/booking";
import {useEffect, useState} from "react";
import {useAuth, useNotification} from "../../../hooks";
import {Col, Form, Row} from "react-bootstrap";
import {CCol, CFormInput, CFormLabel, CFormTextarea} from "@coreui/react";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function ModalJobsheetPartAdd({tokenOrder, reloadData, setReloadData}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        // console.log(tokenOrder);
    })
    const {showError, showSuccess} = useNotification();
    const {authInfo} = useAuth();
    const [btnSubmit, setBtnSubmit] = useState('Save Changes')
    const [loading, setLoading] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [inputData, setInputData] = useState({
        login_user_id: authInfo.profile.id,
        login_user_type: authInfo.profile.user_type,
        action_type: "part_add_new",
        title_part: "",
        amount_price: ""
    })
    const handleAddNewOrder = async (event) => {
        event.preventDefault();
        setBtnSubmit("loading...");
        setLoading(true);
        const {data, error, message} = await jobsheet_action({...inputData, token_order: tokenOrder});
        // console.log(error, data);
        setBtnSubmit("Save Changes");
        if (error) {
            setLoading(false);
            setMessageError(message);
            return showError(message);
        } else {
            setMessageError(message);
            // await getUsers();
            showSuccess(message);
        }
        setReloadData(true);
        setLoading(false);
        setInputData({...inputData, title_part: "", amount_price: ""})
        handleClose();
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setInputData({...inputData, [name]: value});
    };
    return (
        <div>
            {/* <Button className={'sign-up-button'} onClick={handleClickOpen}>
                Cancel Order
            </Button>*/}
            {/* <button className="btn btn-out selectable-btn float-end" onClick={handleClickOpen}>
                <FaPlus />
            </button>*/}
            <Fab variant="extended" size="small" style={{zIndex:1}} color="warning" onClick={handleClickOpen} className={"float-end"}>
                <FaPlus sx={{mr: 3}}/>
            </Fab>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <Form onSubmit={handleAddNewOrder}>
                    <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                        Add new parts for your jobsheet
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <DialogContent dividers>

                        <Row xs={{cols: 1}} className={'g-3'}>
                            <Col>
                                {/*<CFormLabel htmlFor="add_part_name">Part Name</CFormLabel>*/}
                                <TextField size={'small'} color={'warning'} id="add_part_name" label="Part Name"
                                           variant="outlined"
                                           name={'title_part'}
                                           value={inputData?.title_part}
                                           onChange={handleChange}
                                           className={'form-control'} required={true}
                                           placeholder="Type part name here"/>
                                {/*<CFormInput type={"text"} id="add_part_name" name={'title'}
                                        value={inputData?.title}
                                        onChange={handleChange}
                                        placeholder="Type Problem here"/>*/}
                            </Col>

                            <Col>
                                <TextField size={'small'} color={'warning'} type={'number'} id="add_part_amount"
                                           label="Part Amount"
                                           variant="outlined"
                                           name={'amount_price'}
                                           value={inputData?.amount}
                                           className={'form-control'}
                                           onChange={handleChange} required={true}
                                           placeholder="Type part amount here"
                                           startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                />
                                {/*<CFormLabel htmlFor="add_amount">Part Amount</CFormLabel>*/}
                                {/*<CFormInput type={"number"} id="add_amount" name={'amount'}*/}
                                {/*            value={inputData?.amount}*/}
                                {/*            onChange={handleChange}*/}
                                {/*            placeholder="Type part amount here"/>*/}
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} variant={'contained'} color={'warning'} autoFocus type={"submit"}>
                            {loading && <CircularProgress size={16} color={"inherit"}/>}&nbsp;{btnSubmit}
                        </Button>
                    </DialogActions>
                </Form>
            </BootstrapDialog>
        </div>
    );
}