import React, {useEffect, useState} from "react";
import MoreButton from "../HomePage/MoreButton";
import {useAuth, useNotification} from "../../hooks";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import {Col, Row} from "react-bootstrap";
import {get_bookings, get_order_part} from "../../api/booking";
import {BookingListCard} from "./BookingListCard";
import emptyImage from "./../asset/download.svg";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import banner from "../asset/banner.webp";
import {Button, CircularProgress, TextField} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";

export default function BookingList() {
    // const locationTemp = useLocation();
    // if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
    //     localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
    // }
    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const {showError, showSuccess} = useNotification();
    const [dataBookings, setDataBookings] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [bookingType, setBookingType] = useState((searchParams.get("type")))
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [btnLoadMore, setBtnLoadMore] = useState("Load More");
    const [isMoreData, setIsMoreData] = useState(true);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [inputData, setInputData] = useState({
        id: authInfo.profile?.id,
        user_type: authInfo.profile?.user_type,
        limit: 6
    });

    const redirectToBookService = async () => {
        if (authInfo.profile.id) navigate("/book-repair");
        else {
            showError("Please login to book service.");
            navigate("/auth");
        }
    };
    const getServiceBooking = async (type) => {
        setIsLoading(true);

        let input = {...inputData, offset: offset}
        if (searchText !== '') {
            input.search_text = searchText;
        }
        // console.log(input);
        const rsp = type && type === 'part' ? await get_order_part(input) : await get_bookings(input);
        if (rsp) {
            const {data, error} = rsp;
            if (!error) {
                // setProduct_details((prev) => [...new Set([...prev, ...data.data])]);
                setDataBookings(data.data || [])
                setIsLoading(false);
                // console.log(data);
                if (data.data.length < 6) {
                    setIsMoreData(false);
                } else {
                    setIsMoreData(true);
                    // setOffset(offset + 1);
                }
            } else {
                if (error !== 'cancelled') {
                    // setIsMoreData(false);
                    // setIsLoading(false);
                    // return showError(error);
                }
            }
        }

        // console.log(data.data);
    }

    useEffect(() => {
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
    })
    useEffect(() => {
        if (bookingType === 'part') {
            document.title = 'Booking Part Orders | iService - Innovation Service';
        } else {
            document.title = 'Service Bookings | iService - Innovation Service';
        }
        getServiceBooking(bookingType).then(r => r);
    }, [bookingType, searchText])
    useEffect(() => {
        // console.log(product_details);
        if (offset !== 0) {
            getServiceBookingOther(bookingType).then(r => r);
        }
    }, [offset]);
    const handleInfiniteScroll = async () => {
        setOffset(offset + 1);
    };
    const getServiceBookingOther = async (type) => {
        setIsMoreLoading(true);
        let input = {...inputData, offset: offset}
        if (searchText !== '') {
            input.search_text = searchText;
        }
        const {data, error} = type && type === 'part' ? await get_order_part(input) : await get_bookings(input);

        if (!error) {
            setDataBookings((prev) => [...new Set([...prev, ...data.data])]);
            // setDataBookings(data.data)
            setIsMoreLoading(false);
            // console.log(product_details.length);
            if (data.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
                // setOffset(offset + 1);
            }
        } else {
            setIsMoreData(false);
            return showError(error);
        }
        // console.log(data.data);
    }

    const handleChange = (e) => {
        // console.log(e);
        if (offset !== 0) setOffset(0);
        setSearchText(e.target.value);
    }
    return (
        <section className={'mx-5'}>
            <div className=" mt-md-5 pt-1 mt-3">
                <div className="">
                    <Breadcrumbs menu={{title: "Booking", link: '/booking'}}/>
                    <h3 className="mt-4">
                        {bookingType === 'part' ? "Booking Part Orders" : "Service Bookings"}
                    </h3>
                    <p className=" subHeading "/>
                </div>
            </div>
            {/*<h3 className={'text-center py-5'}>Your booking here</h3>*/}
            <div className={'mb-5 min-height2'}>
                <Row className={'my-3'}>
                    <Col>
                        <Box role="presentation">
                            <Paper
                                sx={{p: '3px 3px', display: 'flex', alignItems: 'center', width: "100%"}}>
                                <InputBase sx={{ml: 1, flex: 1}} onKeyUp={handleChange} name="search_text"
                                           placeholder="Search your order here...."
                                           inputProps={{'aria-label': 'Search product here'}}/>
                                <IconButton type="button" aria-label="search">
                                    <SearchIcon/>
                                </IconButton>
                                {/*<Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>*/}

                            </Paper>
                        </Box>
                    </Col>
                </Row>

                {isLoading && <Row><SkeletonFileGeneral height={120} total={6} col={4}/></Row>}

                {!isLoading && (dataBookings && dataBookings.length > 0 ? (
                    <>
                        <Row xs={{cols: 1}} md={{cols: 2}} lg={{cols: 3}} className={'g-3'}>
                            {
                                dataBookings.map((booking, idx) => {
                                    return (
                                        <Col key={idx}>
                                            <BookingListCard data={booking} key={booking?.token}
                                                             link={"/booking-details/" + (booking?.token) + "?order=" + (booking?.token)}/>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </>
                ) : (<>
                    <div className="d-flex align-item-center justify-content-center">
                        <img className="no-item d-flex align-item-center justify-content-center" src={emptyImage}
                             style={{maxHeight: "300px"}} alt={"book"} onError={(e) => e.target.src = banner}/>
                    </div>
                    <div className="text-center servicesCardHeading fs-5">You are not book any service yet. Please book
                        your
                        first service
                    </div>
                    <div className="d-flex align-item-center justify-content-center my-4">
                        <a onClick={redirectToBookService} style={{textDecoration: "none", color: "white"}}>
                            <MoreButton>Book Now</MoreButton>
                        </a>
                    </div>
                </>))}

                <div className="text-center">
                    {isMoreData && (<>
                            {/* <CLoadingButton variant="outline" loading={stateO} onClick={() => setStateO(!stateO)}>
                            Submit
                        </CLoadingButton>*/}
                            <Button type="submit" variant="outlined"  
                                // className={(isMoreLoading ? "disabled" : "") + "my-3 "}
                                    className={"my-3 "}
                                    disabled={isMoreLoading}
                                    color="warning" 

                                    onClick={handleInfiniteScroll}>
                                {/* {isMoreLoading ? <CircularProgress size={25} color="inherit"/> : btnLoadMore} */}
                                {isMoreLoading && <CircularProgress size={16} color={"inherit"}/>}&nbsp; {btnLoadMore}

                            </Button>
                        </>

                    )}

                </div>
            </div>
        </section>
    );
}