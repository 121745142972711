import React, {useEffect, useState} from "react";
import MoreButton from "../HomePage/MoreButton";
import {useAuth, useNotification} from "../../hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {Container} from "@mui/material";
import {get_job_sheet_dashboard} from "../../api/jobsheet";
import Breadcrumbs from "../Breadcrumbs";
import {Col, Row} from "react-bootstrap";
import {DashboardCard} from "./DashboardCard";
import {FaCheckCircle, FaExclamationTriangle,FaFolder,FaFolderOpen,  FaServicestack
} from "react-icons/fa";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";

export default function JobSheetDashboard() {
    const locationTemp = useLocation();
  //  if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
   //     localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
   // }
    const [dataDashboard, setDataDashboard] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const {showError, showSuccess} = useNotification();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Jobsheet Dashboard | iService - Innovation Service';
    }, [])
    const redirectToCreateJobSheet = async () => {
        if (authInfo.profile.id) {
            if (authInfo.profile.user_type === 'dealer') {
                navigate("/job-repair");
            } else {
                return showError("You are not authorize to create job sheet.");
            }
        } else {
            showError("Please login to create new jobsheet.");
            navigate("/auth");
        }
    };

    useEffect(() => {
        const getJobSheet = async () => {
            setIsLoading(true);
            const {data, error} = await get_job_sheet_dashboard({
                id: authInfo.profile?.id,
                user_type: authInfo.profile?.user_type
            });
            if (error) return showError(error)
            setDataDashboard(data.data)
            //   console.log(data.data);
            setIsLoading(false);
        }
        getJobSheet().then(r => "");
    }, []);

    useEffect(() => {
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
    })

    return (<section className={'mx-5'}>
        <div className="mt-md-5 pt-1 mt-3">
            <div className="">
                <Breadcrumbs menu={{title: "Jobsheet Dashboard", link: '/job-sheet'}}/>
                <h3 className="mt-4">Dashboard</h3>
                <p className=" subHeading "/>
            </div>
        </div>
        {/*<h3 className={'text-center py-5'}>Your booking here</h3>*/}
        <>
            <div className={'mb-5 min-height2'}>
                {isLoading && <Row><SkeletonFileGeneral height={120} total={3} col={4}/></Row>}
                {
                    !isLoading && (
                        dataDashboard ? (
                            <Row xs={{cols: 1}} md={{cols: 3}} className={'g-3'}>
                                <Col>
                                    <DashboardCard data={dataDashboard?.jobsheet_open} icon={<FaFolderOpen size={30}/>}
                                                   link={"/job-sheet/open"}/>
                                </Col>
                                <Col>
                                    <DashboardCard data={dataDashboard?.jobsheet_part_pending}
                                                   icon={<FaFolder size={30}/>} link={"/job-sheet/pending"}/>
                                </Col>

                                <Col>
                                    <DashboardCard data={dataDashboard?.jobsheet_closed}
                                                   icon={<FaCheckCircle size={30}/>}
                                                   link={"/job-sheet/closed"}/>
                                </Col>

                                <Col>
                                    <DashboardCard data={dataDashboard?.jobsheet_return}
                                                   icon={<FaExclamationTriangle size={30}/>}
                                                   link={"/job-sheet/return"}/>
                                </Col>

                                <Col>
                                    <DashboardCard data={dataDashboard?.jobsheet_service}
                                                   icon={<FaServicestack size={30}/>}
                                                   link={"/job-sheet/service"}/>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <div className="d-flex align-item-center justify-content-center">
                                    <img className="no-item d-flex align-item-center justify-content-center"
                                         src="download.svg"
                                         style={{maxHeight: "300px"}}/>
                                </div>
                                <div className="text-center servicesCardHeading fs-5">You are not create any jobsheet
                                    yet.
                                </div>
                                <div className="d-flex align-item-center justify-content-center my-4">
                                    <a onClick={redirectToCreateJobSheet}
                                       style={{textDecoration: "none", color: "white"}}>
                                        <MoreButton>Create Job Sheet</MoreButton>
                                    </a>
                                </div>
                            </>
                        )
                    )
                }
                <div className="d-flex align-item-center justify-content-center mb-4 mt-5">
                    <a onClick={redirectToCreateJobSheet}
                       style={{textDecoration: "none", color: "white"}}>
                        <MoreButton>Create Job Sheet</MoreButton>
                    </a>
                </div>


            </div>
        </>
    </section>);
}