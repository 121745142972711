import React from 'react'
import TabsForForm from './TabsForForm'
import {Card, Col} from "react-bootstrap";

export default function FormCard() {
    return (
        <Card className='/*form-card*/ card-custom mx-auto'  style={{maxWidth: "500px"}}>
            <Card.Body>
                <TabsForForm/>
            </Card.Body>
        </Card>
    )
}
