import React, {useEffect} from 'react'
import NavigatePage from './NavigatePage'

export default function AuthVerfiy() {
    useEffect(() => {
        document.title = 'Verify Account | iService - Innovation Service';
    }, [])
    return (
        <div>
            <section className="">
                <div className="container-fluid px-0">
                    <div className="row g-0">
                        <div className="col-lg-6 auth-left-div"/>
                        <div className="col-lg-6 vh-100 d-flex align-item-center justify-content-center">
                            <NavigatePage/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
