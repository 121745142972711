import React, {useEffect, useState} from "react";
import {about} from "../../api/general";
import {useNotification} from "../../hooks";
import Row from "react-bootstrap/Row";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";

export default function Header({setPageTitle, setMetaKeywords, setMetaDescription}) {
    var [aboutt, setAboutt] = useState({})
    var [isLoading, setIsLoading] = useState(true)
    const {showError} = useNotification();
    var getAboutData = async () => {
        setIsLoading(true);
        const {data} = await about()
        setAboutt(data.data)
        if (data?.data) {
            if (data?.data?.title) setPageTitle(data?.data?.title);
            // if (data?.data?.page_keywords) setMetaKeywords(data?.data?.page_keywords);
            // if (data?.data?.page_description) setMetaDescription(data?.data?.page_description);
        } else {
            showError(data?.message);
        }
        setIsLoading(false);
        // console.log(data.data)
    };

    useEffect(() => {
        getAboutData();
    }, []);
    return (
        <div className="bannerAbout my-2">
            <Row>
                {isLoading && <SkeletonFileGeneral total={1} col={4} colLg={4} height={30}/>}
                {isLoading && <SkeletonFileGeneral total={1} col={12} colLg={12} height={300}/>}
            </Row>
            <div className="banner-in-about">
                <div>

                    {/*<h5 className="headerInAbout">*/}
                    <div dangerouslySetInnerHTML={{__html: (aboutt.description || "")}}/>
                    {/*</h5>*/}
                </div>
                <div>

                </div>
            </div>
        </div>
    );
}
