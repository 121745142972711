import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import user from "../components/asset/favicon.png"
import { Link, useAsyncError, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";
import { ApiCall } from "../api/HelpingMethod";
import { cropText, get_datetime_formatted } from "../hooks/useCustomHelpingMethod";
import ReadMore from "./ReadMore";
import SkeletonFileGeneral from "../hooks/SkeletonFileGeneral";
import { FaBell } from "react-icons/fa";
import { Avatar, CircularProgress } from "@mui/material";


const NotificationList = () => {
    const navigate = useNavigate()
    const { userLogout, authInfo } = useAuth();
    const [offset, setOffset] = useState(0);
    const [isMoreData, setIsMoreData] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const [textLength, setTextLenght] = useState(100)
    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo?.profile?.id,
        "login_user_type": authInfo?.profile?.user_type,
        "limit": 10,
        "offset": 0
    })

    const getNotification = async () => {
        setLoading(true)
        setOffset(0)
        const response = await ApiCall("dealer/notification", { ...bodyData, offset: 0 })
        console.log("response-----", response)
        if (response?.error === false) {
            setData(response?.data)
            if (response.data.length < 10) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
        } else {
            setData([])
        }
        setLoading(false)
    }
    const nextData = async () => {
        setBtnLoading(true);
        setIsLoadingMore(true);

        const response = await ApiCall("dealer/notification", { ...bodyData, offset: offset || 0 })

        if (response?.error === false) {
            //setData(response?.data)
            if (response.data.length < 10) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
            if (response.data.length) {
                setData((prev) => [...new Set([...prev, ...response.data])]);
            }
        } else {
            setData([])
        }
        setIsLoadingMore(false);
        setBtnLoading(false);
    }
    useEffect(() => {
        getNotification()
    }, [])
    useEffect(() => {
        // console.log(product_details);
        if (offset !== 0) {
            nextData();
        }
    }, [offset]);


    return (
        <>
            <Container className="ps-sm-2 pe-sm-2 ps-0 pe-0">
                <Row className="h5 ms-1 mt-4">Notification</Row>
                {loading && <Row className="mt-3"> <SkeletonFileGeneral height={50} col={12} total={5} /> </Row>}
                <Row xs={{ cols: 1 }} className="g-2">
                    {!loading &&
                        data && Array.isArray(data) && data.map((data) => {
                            return (
                                <>
                                    <Col>
                                        {/* <Card>
                                            <Card.Body className="py-2 px-1"> */}
                                        <Container className="p-0">
                                            <Row className="border-bottom pb-3">
                                                <Col xs={12} md={10} lg={10} className="d-flex align-items-center">
                                                    {/* <img className="img-fluid " src={data?.url_image || user} /> */}
                                                    <Avatar sx={{ width: 25, height: 25, bgcolor: "var(--primary)" }} alt="notification-image" src={data?.url_image} className="mx-2"> <FaBell size={15} /></Avatar>
                                                    <div>
                                                        <div className="h6">{data.title}</div>

                                                        <ReadMore data={data?.message} defaultsize={textLength} className="ms-2" /></div>
                                                </Col>
                                                <Col xs={12} md={2} lg={2} className="float-end text-end"><b style={{ fontSize: ".70rem" }}>{data?.date_add_period ? (data?.date_add_period) : get_datetime_formatted(data?.created_at || "")}</b></Col>
                                            </Row>
                                        </Container>
                                        {/* </Card.Body>
                                        </Card> */}
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
                <Row>
                    <Col>
                        <div className="text-center">
                            {isMoreData && (
                                <Button disabled={btnLoading} type="submit" variant=""
                                    className="btn btn-primary3 my-3 "
                                    onClick={() => setOffset(offset + 1)}>{btnLoading &&
                                        <CircularProgress size={16} color={"inherit"} />}&nbsp; Load More</Button>
                            )}

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default NotificationList;