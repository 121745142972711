import {DevicePageBody} from "./DevicePageBody"
import {DevicePageDes} from "./DevicePageDs";
import * as React from "react";


export function DevicePageForm({ setData,setToken,token, iemi, setBrand, setModel, setIemi, handeNext, handleBack, data}) {

    return (
        <div className="center">
            {/*<h4 className={'my-3'}>Tell Us About Your Client Device Detail's</h4>*/}
            <h4 className={'my-3'}>Client Device Detail's</h4>
            {/*<p>Tell us about your device information. i.e. device category, brand, and device IMEI number.</p>*/}
            <DevicePageBody data={data}/>
            <DevicePageDes setData={setData} setToken={setToken} data={data} setBrand={setBrand} setModel={setModel} iemi={iemi} setIemi={setIemi} token={token} handeNext={handeNext}  handleBack={handleBack} />
        </div>
    );
}
