import { React, useEffect, useState } from "react";
import { useAuth } from "../../../hooks";
import { isValidEmail } from "../SignUpForm";
import { Button, Col, Form, Row } from "react-bootstrap";
import { isValidMobile } from "../NavigatePage";
import { CFormInput, CFormLabel } from "@coreui/react";
import { registerUserUpload } from "../../../api/auth";
import banner from "../../asset/noimage.webp";

const validateUserInfo = ({ name, email, mobile, password }) => {
    const isValidName = /^[a-z A-Z]+$/;

    if (!name.trim()) return { ok: false, errorMessage: "Name is missing!" };
    // if (!isValidName.test(name)) return {ok: false, error: "Invalid name!"};

    if (email) {
        if (!email.trim()) return { ok: false, errorMessage: "Email is missing!" };
        if (!isValidEmail(email)) return { ok: false, errorMessage: "Invalid email!" };
    }
    if (mobile) {
        if (!mobile.trim()) return { ok: false, errorMessage: "mobile is missing!" };
        if (!isValidMobile(mobile)) return { ok: false, errorMessage: "Invalid mobile!" };
    }

    return { ok: true };
};

export function StepThreeAdditionalDetail({
    setRegUserToken,
    regUserToken,
    handleNext,
    previousData,
    setPreviousData,
    state,
    showError,
    showSuccess,
    user_id,
    regUserType,
    setActiveStep,
    setRegStep
}) {
    const { createUser, authInfo } = useAuth();
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Next')
    const [file, setFile] = useState();

    const [userInfo, setUserInfo] = useState({
        token_user: regUserToken,
        // token_user: "ccccc0ec",
        step: 'upload_registration',
        url_registration: previousData?.business?.url_registration || "",
        imagePreviewUrl: ""
    });


    console.log("previousData--", previousData)

    const handleChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(file);
        reader.onloadend = () => {
            setUserInfo({
                ...userInfo,
                url_registration: file,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file)
    }

    useEffect(() => {
        // console.log(userInfo);
        // console.log(file);
    })

    const handleSubmit = async (event) => {
        setButtonName("loading...");
        event.preventDefault();

        const formData = new FormData();
        formData.append("step", userInfo.step);
        formData.append("url_registration", userInfo.url_registration);
        formData.append("token_user", userInfo.token_user);

        let { data, message, error } = await registerUserUpload(formData);
        setPreviousData(data)
      
        setRegUserToken(data.token)
        if (error) {
            showError(message);
            setButtonName("Submit");
            return;
        } else {
            setUserInfo({ ...userInfo, token_user: data.token })
            setRegStep(4);
            handleNext();
        }
        setButtonName("Submit");
    };


    return (
        <Form onSubmit={handleSubmit} className="needs-validation text-center" noValidate validated={validated}>
            <div className="imgPreview  mb-5" style={{ Height: "200px", Width: "200px" }}>
                {userInfo.imagePreviewUrl ?
                    <img src={userInfo.imagePreviewUrl} alt={'prev'} style={{ maxHeight: "200px", maxWidth: "200px" }} onError={(e) => e.target.src = banner} /> : previousData?.business?.url_registration ? <img src={previousData?.business?.url_registration} alt={'prev'} style={{ maxHeight: "200px", maxWidth: "200px" }} onError={(e) => e.target.src = banner} /> :
                        <div className="previewText">Please select an Image for Preview</div>
                }
            </div>
            <Form.Group>
                <CFormLabel htmlFor="formFileMultiple">
                    Upload Registration or License/GST Certificate.
                </CFormLabel>
                <CFormInput type={"file"} className="form-control"
                    onChange={handleChange}
                    id="formFileMultiple" />
            </Form.Group>
            {/* <InputField type={'file'} name={'url_registration'}
                        label={'Upload Shop Registration'}
                        onChange={handleChange} placeholder={'Business Registration'}/>*/}
            <Form.Group>
                <Row>
                    <Row>
                        <Col>
                            <Button type={'button'} className="button-2 float-left my-3"
                                onClick={() => {
                                    setRegStep(2)
                                    setActiveStep(1)
                                }}>{"Previous"}</Button>
                        </Col>
                        <Col className="text-end">
                            <Button type="submit" variant="" className="btn btn-primary1 my-3 ">{btnName}</Button>
                        </Col>
                    </Row>
                </Row>
            </Form.Group>
        </Form>
    );
}