import {CBadge, CCard, CCardBody} from "@coreui/react";
import {useNavigate} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {get_color_by_status, get_date_formatted} from "../../hooks/useCustomHelpingMethod";
import {FaPhoneAlt, FaUser} from "react-icons/fa";

export function JobsheetListCard({data, link}) {
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) navigate(link);
        else navigate("/job-sheet");
    }
    const colorBadge = get_color_by_status(data?.status);
    // console.log(data);
    return (
        <>
            <CCard className={'m-1 shadow card-link'} onClick={handleClick}>
                <CCardBody>
                    <Row className={'g-2'}>
                        <Col>
                            <h6 className={'text-uppercase'}>#{data?.token}</h6>
                            <small className={'d-block text-muted'}><FaUser color={'var(--primary)'}/> {data?.name}</small>
                            <small><FaPhoneAlt color={'var(--primary)'}/> +91 {data?.mobile}</small>
                        </Col>
                        <Col className={'text-end'}>
                            <CBadge color={colorBadge} style={{
                                border: "1px solid",
                                borderColor: colorBadge,
                                color: colorBadge
                            }}>{data?.status}</CBadge><br/>
                            <small className={'d-block text-muted mt-2'}>{get_date_formatted(data?.created_at)}</small>
                        </Col>
                    </Row>
                </CCardBody>
            </CCard>
        </>
    );
}