import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {part_list} from "../../../api/general";
import banner from "../../asset/banner.webp";
import {FaCartPlus,   FaShoppingCart} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import {cartAction} from "../../../api/cart";
import {useAuth, useNotification} from "../../../hooks";
import {useNavigate} from "react-router-dom";
import SkeletonFileGeneral from "../../../hooks/SkeletonFileGeneral";

export default function IncludeModalPartsSuggestion({isLoading, modelId, tokenOrder}) {
    const [modelParts, setModelParts] = useState([]);
    const {showError, showSuccess} = useNotification()
    const {authInfo} = useAuth()
    const [reload, setReload] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const navigate = useNavigate()

    const apiCall = async (modelIdTemp = null, tokenOrderTemp = null) => {
        setIsLoading2(true);
        if (modelIdTemp) {

            const inputData = {
                limit: 10,
                offset: 0,
                login_user_id: authInfo.profile.id,
                login_user_type: authInfo.profile.user_type,
            }
            if (modelIdTemp) {
                inputData.model_id = modelIdTemp;
            }
            // console.log(inputData);
            const {data} = await part_list(inputData);
            setModelParts([...data.data]);
            // console.log(data);
        }
        setIsLoading2(false);
    }
    useEffect(() => {
        apiCall(modelId, tokenOrder).then(r => r);
    }, [modelId, tokenOrder, reload])

    const onClick = async (part_id) => {
        if (!authInfo.profile.id) {
            showError("Please login to add this product into cart.");
            navigate("/auth");
        } else {
            const data = await cartAction({
                id: authInfo?.profile?.id,
                user_type: authInfo?.profile?.user_type,
                actionType: "add_to_cart",
                part_id: part_id
            })
            if (!data.data || data.data.error) {
                showError(data.data.message || "An error occurs");
            } else {
                setReload(true)
                showSuccess(data.data.message)
            }
        }
    };

    const addedIcon = () => <IconButton onClick={onNavigate} color="success"
                                        size={"small"} aria-label="add to order"
                                        className={"float-end float-right"}>
        <FaShoppingCart sx={{mr: 3}} className={"float-end float-right"}/>
    </IconButton>;

    const addIcon = (part_id) => <IconButton onClick={() => onClick(part_id)}
                                             color="warning" size={"small"}
                                             aria-label="add to order"
                                             className={"float-end float-right"}>
        <FaCartPlus sx={{mr: 3}} className={"float-end float-right"}/>
    </IconButton>;

    const onNavigate = async () => {
        navigate("/cart");
    }
    return (
        <>
            <Row className={'g-3'}>
                <Col xs={12}>
                    <h5 className={'mx-auto'}>Part Suggestions</h5>
                </Col>
                <Col xs={12}>
                    <Row className={'g-2  figure-caption'}>
                        {(isLoading2 || isLoading) && <SkeletonFileGeneral height={75} total={4} col={12}/>}
                        {!isLoading2 && !isLoading && <>
                            {modelParts && Array.isArray(modelParts) && modelParts.length > 0 ? modelParts.map((part, index) => {
                                return (
                                    <Col xs={12} key={index}>
                                        <div className="card shadow">
                                            <div className="card-body px-2">
                                                <div className="d-flex  align-items-center">
                                                    <div className="row">
                                                        <div className="col-12 col-md-2">
                                                            {part?.url_icon && part?.url_icon ? (
                                                                <img style={{
                                                                    maxHeight: "50px",
                                                                    width:"100%",
                                                                    maxWidth: "50px"
                                                                }}
                                                                     src={`${part?.url_icon}?fit=crop&auto=format`}
                                                                     srcSet={`${part?.url_icon}?fit=crop&auto=format&dpr=2 2x`}
                                                                     alt={part.title}
                                                                     onError={(e) => e.target.src = banner}
                                                                     loading="lazy"
                                                                />
                                                            ) : (
                                                                <img style={{maxHeight: "35px"}}
                                                                     src={`${banner}?fit=crop&auto=format`}
                                                                     srcSet={`${banner}?fit=crop&auto=format&dpr=2 2x`}
                                                                     alt={part.title}
                                                                     onError={(e) => e.target.src = banner}
                                                                     loading="lazy"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="col-10 col-md-8" style={{cursor: "pointer"}}
                                                             onClick={() => {
                                                                 window.location.href = "/product/" + part.slug;
                                                             }}>
                                                            <small><b>{part?.title}</b></small> <br/>
                                                            <b>{part?.amount_currency_symbol || "RS."} {(part?.amount_total || 0)}</b>

                                                        </div>
                                                        <div className="col-2 col-md-1">
                                                            {part?.availability && (part?.availability === 'available' ? (
                                                                part?.is_in_user_cart === "yes" ? addedIcon(part?.id) : addIcon(part?.id)
                                                            ) : "")}
                                                        </div>
                                                    </div>
                                                    {/*&& checkIfImageExists(part?.part?.url_icon)*/}


                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }) : (
                                <h6>No part suggestion available for this order</h6>
                            )}
                        </>}

                    </Row>
                </Col>
            </Row>
        </>
    );
}