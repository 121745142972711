import React, { useEffect, useState } from "react";
import MoreButton from "../HomePage/MoreButton";
import { useAuth, useNotification } from "../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CircularProgress, Container } from "@mui/material";
import { get_job_sheet } from "../../api/jobsheet";
import Breadcrumbs from "../Breadcrumbs";
import { Col, Row } from "react-bootstrap";
import { JobsheetListCard } from "./JobsheetListCard";
import emptyImage from "./../asset/download.svg";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default function JobSheetList(props) {
    const locationTemp = useLocation();
   // if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
    //    localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
   // }

    const [dataJobsheet, setDataJobsheet] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { authInfo } = useAuth();
    const navigate = useNavigate();
    const { showError, showSuccess } = useNotification();
    const location = useLocation();
    const [offset, setOffset] = useState(0);
    const [btnLoadMore] = useState("Load More");
    const [isMoreData, setIsMoreData] = useState(true);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [inputData] = useState({
        id: authInfo.profile?.id,
        user_type: authInfo.profile?.user_type,
        limit: 6
    });

    const redirectToCreateJobSheet = async () => {
        if (authInfo.profile.id) {
            if (authInfo.profile.user_type === 'dealer') {
                navigate("/job-repair");
            } else {
                return showError("You are not authorize to create job sheet.");
            }
        } else {
            showError("Please login to create new jobsheet.");
            navigate("/auth");
        }
    };

    const getJobSheet = async () => {
        setIsLoading(true);
        const segs = location.pathname;
        const segment = segs.substring(segs.lastIndexOf('/') + 1);
        const inputsData = { ...inputData, offset: offset };
        if (searchText !== '') inputsData.search_text = searchText;
        if (segment) inputsData.status = segment;

        // console.log(inputsData);
        const rsp = await get_job_sheet(inputsData);
        if (rsp) {
            const { data, error } = rsp;
            if (!error) {
                setDataJobsheet(data?.data || [])
                setIsLoading(false);
                // console.log(data);
                if (data.data.length < 6) {
                    setIsMoreData(false);
                } else {
                    setIsMoreData(true);
                    // setOffset(offset + 1);
                }
            } else {
                setIsMoreData(false);
                setIsLoading(false);
                return showError(error);
            }
        }

    }
    const getJobSheetMore = async () => {
        setIsMoreLoading(true);
        const segs = location.pathname;
        const segment = segs.substring(segs.lastIndexOf('/') + 1);
        const inputsData = { ...inputData, offset: offset };
        if (searchText !== '') inputsData.search_text = searchText;
        if (segment) inputsData.status = segment;

        // console.log(inputsData);
        const { data, error } = await get_job_sheet(inputsData);
        if (!error) {
            setDataJobsheet((prev) => [...new Set([...prev, ...data.data])]);
            // setDataBookings(data.data)
            setIsMoreLoading(false);
            // console.log(product_details.length);
            if (data.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
                // setOffset(offset + 1);
            }
        } else {
            setIsMoreData(false);
            return showError(error);
        }
    }
    useEffect(() => {
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
    })
    useEffect(() => {
        document.title = 'Dealer Jobsheet | iService - Innovation Service';
        getJobSheet(searchText).then(r => r);
    }, [searchText]);
    const handleChange = (e) => {
        // console.log(e);
        if (offset !== 0) setOffset(0);
        setSearchText(e.target.value);
    }
    useEffect(() => {
        // console.log(product_details);
        if (offset !== 0) {
            getJobSheetMore().then(r => r);
        }
    }, [offset]);
    const handleInfiniteScroll = async () => {
        setOffset(offset + 1);
    };
    return (<section className={'mx-5'}>
        <div className="mt-3">
            <div className="">
                <Breadcrumbs menu={{ title: "Jobsheet", link: '/job-sheet' }} />
                <h3 className="mt-3">Dealer Jobsheet's</h3>
                <p className=" subHeading " />
            </div>
        </div>
        {/*<h3 className={'text-center py-5'}>Your booking here</h3>*/}
        <>
            <div className={'my-5 min-height2'}>
                <Row className={'my-3'}>
                    <Col>
                        <Box role="presentation">
                            <Paper
                                sx={{ p: '3px 3px', display: 'flex', alignItems: 'center', width: "100%" }}>
                                <InputBase sx={{ ml: 1, flex: 1 }} onChange={handleChange} name="search_text"
                                    placeholder="Search your order here...."
                                    inputProps={{ 'aria-label': 'Search product here' }} />
                                <IconButton type="button" aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                                {/*<Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>*/}
                            </Paper>
                        </Box>
                    </Col>
                </Row>
                {isLoading && <Row><SkeletonFileGeneral height={120} total={6} col={4} /></Row>}

                {
                    !isLoading && (dataJobsheet && dataJobsheet.length > 0 ? (
                        <Row xs={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 3 }} className={'g-3'}>
                            {
                                dataJobsheet.map((jobsheet, index) => {
                                    return (
                                        <Col key={index}>
                                            <JobsheetListCard data={jobsheet}
                                                link={"/job-sheet/detail/" + (jobsheet.token) + "?order=" + (jobsheet.token)} />
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    ) : (
                        <>
                            <div className="d-flex align-item-center justify-content-center">
                                <img className="no-item d-flex align-item-center justify-content-center"
                                    src={emptyImage}
                                    style={{ maxHeight: "300px" }} alt={'empty-image'} />
                            </div>
                            <div className="text-center servicesCardHeading fs-5">You are not create any jobsheet yet.
                            </div>
                            <div className="d-flex align-item-center justify-content-center my-4">
                                <a onClick={redirectToCreateJobSheet} style={{ textDecoration: "none", color: "white" }}>
                                    <MoreButton>Create Job Sheet</MoreButton>
                                </a>
                            </div>
                        </>
                    ))
                }
                <div className="text-center">
                    {isMoreData && (<>
                        <Button type="submit" variant="outlined" color={'warning'}
                            // className={(isMoreLoading ? "disabled" : "") + "my-3 "}
                            className={"my-3 "}
                            disabled={isMoreLoading}
                            onClick={handleInfiniteScroll}>
                            {/* {isMoreLoading ? <CircularProgress size={25} color="inherit"/> : btnLoadMore} */}
                            {isMoreLoading && <CircularProgress size={16} color={"inherit"} />}&nbsp; {btnLoadMore}
                        </Button>
                    </>
                    )}
                </div>
            </div>
        </>
    </section>);
}