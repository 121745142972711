import React, {useEffect, useState} from "react";
import HowItWorks from "../HomePage/HowItWorks";
import Header from "./Header";
import {useLocation} from "react-router-dom";
import {HelmetCustomInclude} from "../HelmetCustomInclude";


export default function About() {
    const [pageTitle, setPageTitle] = useState("About Us | Our Company is Providing High-Quality Products and Service");
    const [metaKeywords, setMetaKeywords] = useState("About us, Service, Company, Parts, Repair, Original Parts, mobile repairing shop near me, Doorstep Services");
    const [metaDescription, setMetaDescription] = useState("We are the Best Mobile Phone Spare Parts Provider for all popular brands of mobile phones, Laptops, and tablets. 90% of orders are Deliver the same day.");
    const [metaImageUrl, setMetaImageUrl] = useState(null);
    // const locationTemp = useLocation();

    // useEffect(() => {
    //     // window.scrollTo(0, 0);
    //     // console.log("Landed on this page from ", history.location.state.from )
    //     // console.log("Landed on this page from ", localStorage.getItem("prevLocation"))
    //     // if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
    //     //     localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
    //     // }
    // }, []);


    return (
        <div>

            <HelmetCustomInclude url={"about"} title={pageTitle} image={metaImageUrl}/>

            <div className="ourservices">
                <h3 className=" mr-5 servicesHeading">About iService</h3>
                <p className="mr-5 subHeading">
                    {/*we are one of the fastest-growing eCommerce stores for buying spare parts.*/}
                    We are one of the fastest-growing eCommerce websites & App for buying spare parts or services.
                </p>
            </div>
            <Header setPageTitle={setPageTitle} setMetaKeywords={setMetaKeywords}
                    setMetaDescription={setMetaDescription}/>
            {/* <div className="div2">
        <h1 className="text-center servicesHeading text-dark">Our <span style={{ color: "#EE610F" }}  >Mission</span></h1>
        <p className="text-center ourMissonInner py-3 px-5">
       
        </p>
      </div>
      <p className="text-center my-2 ourMissonInner p-4">
        
      </p> */}
            {/*<OurTeam/>*/}
            <HowItWorks/>
            {/*<WhyChooseUs/>*/}
            <h3 className="text-center servicesHeading text-dark my-3">Customer <span
                style={{color: "#EE610F"}}>Reviews</span></h3>
            <div className="container mb-5 align-items-center ">
                <iframe
                    style={{
                        border: "none",
                        margin: "0px",
                        padding: "0px",
                        width: "100%",
                        minWidth: "300px",
                        display: "block",
                        userSelect: "none",
                        minHeight: "285px",
                        zIndex: "9999999"
                    }}
                    src="https://app.cloutly.com/widget-cloutly/877dc87b-6fb3-4f31-a312-d7eb774fa204"/>
            </div>

        </div>
    );
}
