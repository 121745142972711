import React from "react";
import Skeleton from 'react-loading-skeleton'

export default function SkeletonFileGeneral({height = 200, total = 8, col = 3, colLg = 0, mx = ""}) {
    return (
        <>
            {Array(total)
                .fill()
                .map((item, index) => {
                    return (
                        <div key={index}
                             className={"col-sm-" + col + " col-lg-" + (colLg || col) + " card-group mb-4 " + (mx && "mx-" + mx)}>
                            <div className="card">
                                <Skeleton height={height}/>
                            </div>
                        </div>
                    );
                })}
        </>
    );
}
