import {getFullPageTitle} from "../hooks/useCustomHelpingMethod";
import {appFooterLogo} from "../api/constant";
import {Helmet} from "react-helmet";
import {React, useEffect, useState} from "react";
import {ApiCall} from "../api/HelpingMethod";

export function HelmetCustomInclude({url, ref_type, ref_id, title, image, keywords, description}) {
    const [pageTitle, setPageTitle] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [metaImageUrl, setMetaImageUrl] = useState(appFooterLogo);
    const getMetaData = async () => {
        let isUpdated = false;
        let inputData = {}
        if (url) {
            inputData = {page_url: url}
            isUpdated = true;
        } else if (ref_id && ref_type) {
            inputData = {ref_id: ref_id, ref_type: ref_type}
            isUpdated = true;
        } else {
            isUpdated = false;
        }
        // console.log(isUpdated);
        if (isUpdated && !keywords) {
            const response = await ApiCall('dealer/meta_data', inputData).then((r) => r);
            if (response?.data) {
                if (response?.data?.title) {
                    setPageTitle(getFullPageTitle(response?.data?.title));
                } else {
                    setPageTitle(getFullPageTitle(title || ""));
                }
                if (response?.data?.keywords) {
                    setMetaKeywords(response?.data?.keywords);
                }
                if (response?.data?.description) {
                    setMetaDescription(response?.data?.description);
                }
                if (response?.data?.url_image) {
                    setMetaImageUrl(response?.data?.url_image);
                } else {
                    if (image) {
                        setMetaImageUrl(image);
                    }
                }
                isUpdated = true;
            } else {
                isUpdated = false;
            }
        }
        if (!isUpdated && (keywords || description)) {
            setPageTitle(getFullPageTitle(title || ""));
            setMetaKeywords(keywords);
            setMetaDescription(description);
            setMetaImageUrl(image);
        }
    };
    useEffect(() => {
        getMetaData().then(r => r);
    }, [url, ref_id, ref_type, image, keywords, description])

    return (
        <>
            {/*{metaKeywords && metaDescription &&*/}
            {/*<>*/}
            <Helmet>
                <title>{pageTitle || title || ""}</title>
                <meta name="title" content={pageTitle || title || ""}/>
                <meta name="keywords" content={metaKeywords || keywords || ""}/>
                <meta name="description" content={metaDescription || description || ""}/>
                <meta name="image" content={metaImageUrl || image || appFooterLogo}/>
                <meta name="url" content={window.location.href || ""}/>

                <meta property="og:url" content={window.location.href || ""}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={pageTitle || title || ""}/>
                <meta property="og:description" content={metaDescription || description || ""}/>
                <meta property="og:image" content={metaImageUrl || image || appFooterLogo}/>

                <meta name="twitter:title" content={pageTitle || title || ""} />
                <meta name="twitter:description" content={metaDescription || description || ""} />
                <meta name="twitter:image" content={metaImageUrl || image || appFooterLogo} />
                <meta name="twitter:card" content="summary_large_image"/>

            </Helmet>
            {/*</>*/}
            {/*}*/}

        </>
    )
}