import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";

export function StepNavigation({steps, activeStep, isStepOptional, isStepSkipped}) {
    return (
        <Stepper activeStep={activeStep} className="stepLabelSignUp">
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                    labelProps.optional = <Typography variant="caption"/>;
                }
                if (isStepSkipped(index)) {
                    stepProps.completed = false;
                }
                return (
                    <Step key={label} {...stepProps} className="steplabel">
                        <StepLabel {...labelProps}><span className="d-sm-flex d-none">{label}</span></StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}
