import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
} from "react-geocode";
import {mapApiKey} from "../../api/constant";

// Set default response language and region (optional).
// This sets default values for language and region for geocoding requests.
setDefaults({
    key: mapApiKey, // Your API key here.
    language: "en", // Default language for responses.
    region: "es", // Default region for responses.
});

// Alternatively

// Set Google Maps Geocoding API key for quota management (optional but recommended).
// Use this if you want to set the API key independently.
setKey(mapApiKey); // Your API key here.

// Set default response language (optional).
// This sets the default language for geocoding responses.
setLanguage("en"); // Default language for responses.

// Set default response region (optional).
// This sets the default region for geocoding responses.
setRegion("es"); // Default region for responses.

// Get latitude & longitude from address.
/*fromAddress("Eiffel Tower")
    .then(({results}) => {
        const {lat, lng} = results[0].geometry.location;
        // console.log(lat, lng);
    })
    .catch(console.error);*/


// Set default location_type filter (optional).
// This sets the default location type filter to "ROOFTOP" for geocoding responses.
setLocationType("ROOFTOP");

// Get formatted address, city, state, country from latitude & longitude.

// Override default options for geocode requests.

export const getAddressFromLatLong = (lat, long) => {
    if (lat && long) {
        return geocode(RequestType.LATLNG, `${lat},${long}`, {
            location_type: "ROOFTOP", // Override location type filter for this request.
            enable_address_descriptor: true, // Include address descriptor in response.
        })
            .then((response) => {
                // console.log(response);
                const results = response?.results || null;
                if (results && Array.isArray(results) && results.length > 0) {
                    const address = results[0].formatted_address;
                    const {city, state, country, postal} = results[0].address_components.reduce(
                        (acc, component) => {
                            if (component.types.includes("locality"))
                                acc.city = component.long_name;
                            else if (component.types.includes("administrative_area_level_1"))
                                acc.state = component.long_name;
                            else if (component.types.includes("country"))
                                acc.country = component.long_name;
                            else if (component.types.includes("postal_code"))
                                acc.postal = component.long_name;
                            return acc;
                        },
                        {}
                    );
                    let array1 = address.split(", ");
                    let addString = "";
                    if (array1.length > 0) {
                        for (let i = 0; i < array1.length - 3; i++) {
                            if (i === 0) {
                                addString += "" + array1[i];
                            } else {
                                addString += ", " + array1[i];
                            }
                        }
                    }
                    // console.log( results[0].address_components);
                    return {
                        full_address: address,
                        address: addString,
                        city: city,
                        state: state,
                        country: country,
                        pin_code: postal,
                    }
                }
            })
            .catch(console.error);
    }
}