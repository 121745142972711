import * as React from 'react';
import Button from '@mui/material/Button';
import {CircularProgress} from "@mui/material";
import {useEffect, useState} from "react";
import {useAuth, useNotification} from "../../hooks";
import {ApiCall} from "../../api/HelpingMethod";
import {FaBell, FaBellSlash} from "react-icons/fa";

export default function ProductNotifyMe({tokenPart, part}) {
    const {showError, showSuccess} = useNotification();
    const {authInfo} = useAuth();
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [inputData] = useState({
        login_user_id: authInfo?.profile?.id,
        login_user_type: authInfo?.profile?.user_type,
        type: "notify",
        token_part: tokenPart
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSubmit(true);
        await ApiCall('/dealer/part_request_update', inputData, true).then((r) => {
            // console.log(r);
            if (r?.error) {
                showError(r?.message || "Something went wrong. Please try again.");
            } else {
                showSuccess("Thank you! For reaching to us. Your request is successfully submitted, We will notify you when part available.");
            }
        });
        setLoadingSubmit(false);
    }
    useEffect(() => {
      //  console.log(part);
    }, [part])

    return (
        <div className={'text-center'}>
            {part.is_user_notify !== 'yes' ?
                <div onClick={handleSubmit} className="button-notify text-center">
                    {loadingSubmit ?
                        <CircularProgress size={16} color={"inherit"}/> :
                        <FaBell size={16} color={"inherit"}/>}  &nbsp; Notify Me When Available
                </div> :
                <Button disabled={true} className="sign-up-button bg-color-primary w-100 text-center disabled"
                        variant='contained'>
                    <FaBellSlash size={16} color={"inherit"}/>&nbsp;Notification requested
                </Button>
            }


            {/*<Button onClick={handleSubmit} disabled={loadingSubmit} type={"submit"} variant='contained'
                    color={'warning'}
                    className={'sign-up-button px-5'}>{loadingSubmit ?
                <CircularProgress size={16} color={"inherit"}/> : <FaBell  size={16} color={"inherit"}/>} Notify</Button>*/}
        </div>
    );
}