import { Link, useNavigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { useAuth } from "../../../hooks";
import { isValidEmail } from "../SignUpForm";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { InputFieldPassword } from "../../InputFields/InputFieldHelper";
import { isValidMobile } from "../NavigatePage";
import { CCol } from "@coreui/react";

const validateUserInfo = ({ name, email, mobile, password }) => {
    // const isValidName = /^[a-z A-Z]+$/;

    if (!name.trim()) return { ok: false, errorMessage: "Name is missing!" };
    // if (!isValidName.test(name)) return {ok: false, errorMessage: "Invalid name!"};

    if (email) {
        if (!email.trim()) return { ok: false, errorMessage: "Email is missing!" };
        if (!isValidEmail(email)) return { ok: false, errorMessage: "Invalid email!" };
    }
    if (mobile) {
        if (!mobile.trim()) return { ok: false, errorMessage: "mobile is missing!" };
        if (!isValidMobile(mobile)) return { ok: false, errorMessage: "Invalid mobile!" };
    }

    if (!password.trim()) return { ok: false, errorMessage: "Password is missing!" };
    if (password.length < 6)
        return { ok: false, errorMessage: "Password must be 6 characters long!" };

    return { ok: true };
};

export function StepOneBasicDetail({
    basicData,
    setRegUserToken,
    regUserToken,
    handleNext,
    email,
    username,
    mobile,
    previousData,
    setPreviousData,
    showError,
    showSuccess,
    user_id,
    regUserType,
    regStep,
    setRegStep
}) {
    const { createUser, sendUserOtp, authInfo } = useAuth();
    const [otpVisible, setOtpVisible] = useState(false)
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Next')

    const [userInfo, setUserInfo] = useState({
        name: previousData?.name || "",
        email: previousData?.email || email,
        username: username,
        token_user: previousData?.token || regUserToken,
        mobile: previousData?.mobile || mobile,
        email_verified: email ? 'yes' : 'no',
        mobile_verified: mobile ? 'yes' : 'no',
        user_type: previousData?.user_type || regUserType || "client",
        password: "",
        otp: "",
        device_type: "web",
        istoken: "ISABCDEFGH",
        device_token: localStorage.getItem('device_token') || "ABCDEFGHIJK"
    });
    const navigate = useNavigate();

    const handleChange = ({ target }) => {
        const { value, name } = target;
        setUserInfo({ ...userInfo, [name]: value });
    };
    useEffect(() => {
        //    console.log(basicData);
    })


    const handleSubmit = async (event) => {
        setButtonName("loading...");
        event.preventDefault();
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Register");
            event.stopPropagation();
        } else {
            const { ok, errorMessage } = validateUserInfo(userInfo);
            if (!ok) return showError(errorMessage);

            let { data, message, error } = await createUser(userInfo);
            setPreviousData(data)
            setRegUserToken(data.token)
            if (error) {
                // console.log("err insert")
                showError(message);
                setButtonName("Register");
                return
            } else {
                setUserInfo({ ...userInfo, token_user: data.token })
                //showSuccess("Basic detail submitted successfully");
                // console.log("token set")
                // console.log("Step set")
                // console.log(userInfo)
                setRegStep(2);
                handleNext();
                // navigate(localStorage.getItem("prevLocation"))
            }
        }
        setButtonName("Register");
        setValidated(true);
    };

    return (
        <Form onSubmit={handleSubmit} className="needs-validation" noValidate validated={validated}>
            <Form.Group className="mb-3" controlId="ControlInput1">
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" placeholder="Please enter your name" name={'name'} required
                    onChange={handleChange} value={userInfo?.name} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlInput2">
                <Form.Label>Mobile Number</Form.Label>
                <InputGroup>
                    <InputGroup.Text>+91</InputGroup.Text>
                    <Form.Control type={'number'} min={1000000000} max={9999999999} placeholder='89******'
                        name="mobile"
                        value={userInfo?.mobile}
                        disabled={!!mobile}
                        onChange={handleChange}
                        className="form-control LocationForm"
                        aria-label="number"
                        aria-describedby="mobile-text"
                        required />
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="ControlInput3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="****@example.com" name={'email'} onChange={handleChange}
                    value={userInfo?.email} disabled={!!email} />
            </Form.Group>

            <InputFieldPassword className="mb-3" label={"Password New"} id={'password'} name={"password"}
                onChange={handleChange} value={userInfo.password} />

            <Form.Group className="mb-3">
                <div className="registration-checkbox" key={`inline-checkbox d-flex flex-row`}>
                    <Form.Check inline name="group1" type={"checkbox"} id={`inline-checkbox-1`} required={true} />
                    <b className="form-check-label">
                        I agree to I service’s{" "}
                        <Link target={"_blank"} to={"/page/terms-conditions?slug=terms-conditions"} className={'colorprimary'}>
                            terms of use
                        </Link>{" "}
                        and{" "}
                        <Link target={"_blank"} to={"/page/privacy-policy?slug=privacy-policy"} className={'colorprimary'}>
                            privacy policy{" "}
                        </Link>
                    </b>
                </div>

            </Form.Group>
            <Form.Group>
                <Row>
                    {/* <Col>
                        <button className="button-2 float-left"
                                onClick={()=>setRegStep(0)}>{"Previous"}</button>
                    </Col>*/}
                    <Col className="text-end">
                        <Button type="submit" variant="" className="btn btn-primary1 my-3 ">{regUserType === 'dealer' ? btnName : <>Submit</>} </Button>
                    </Col>
                </Row>
                {/*<Button type="submit" variant="" className="btn btn-primary1 my-3 ">{btnName}</Button>*/}
            </Form.Group>
        </Form>
    );
}