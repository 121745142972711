import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box"; 
import {Link} from "react-router-dom";
import IncludeAccordionByList from "./IncludeAccordionByList";
import IncludeReviewsByList from "./IncludeReviewsByList";
import ProductDescription from "./ProductDescription";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


export default function ProductTabs({data}) {
    const [value, setValue] = React.useState(0);
    const color = "var(--primary)";

    // console.log("data--->", data)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{width: "100%"}}>
                <div className="shop-tab">
                    <Box>
                        <Tabs
                            className="ter "
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            textColor={"inherit"}
                            allowScrollButtonsMobile
                            sx={{
                                ".Mui-selected": {
                                    color: {color}
                                },
                            }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "var(--primary)"
                                }
                            }}
                            aria-label="Product tabs"

                        >

                            <Tab label="Description" {...a11yProps(0)} />
                            <Tab label="Compatibility" {...a11yProps(1)} />
                            {/*<Tab label="Availability" {...a11yProps(2)} />*/}
                            <Tab label="Warranty" {...a11yProps(2)} />
                            <Tab label="Shipping Estimation" {...a11yProps(3)} />
                            <Tab label="Faq" {...a11yProps(4)} />
                            <Tab label="Reviews" {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                </div>
                <TabPanel value={value} index={0}>
                    <ProductDescription description={data?.description || "Information not available"}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {/* {<
                        data?.title_category ? <>
                        
                        </>
                    } */}
                    <p>Category: <b>{data?.title_category||""}</b></p>
                    <p>Brand: <b>{data?.title_brand||""}</b></p>
                    <p>Compatible Model: <b>{data?.compatibility || data?.title_model||""}</b></p>
                    {/*{data.compatibilty?data.compatibilty:"Information not available"}*/}
                </TabPanel>
                {/*<TabPanel value={value} index={2}>
     <b>Availability: </b> {data?.availability ||"Information not available"}
      </TabPanel>*/}
                <TabPanel value={value} index={2}>
                    {/*{data?.warranty || "Information not available"} <br/>*/}
                    <p>Covered in Warranty: <b>{data?.warranty||"Information not available"}</b></p>
                    <p>Warranty T&C: <b><Link to={'/page/terms-warranty'}> click here</Link></b></p>
                    <p>Country of Origin: <b>China</b></p>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {data?.shipping_estimation || "Information not available"}
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <IncludeAccordionByList tokenPart={data?.token} idPart={data?.id}/>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <IncludeReviewsByList tokenPart={data?.token} idPart={data?.id}/>
                </TabPanel>
            </Box>
        </>
    );
}
