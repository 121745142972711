import React from 'react'
import { Link } from "react-router-dom";
import { useNotification } from '../hooks';

export default function SignButton() {
    const { setShowLoginModel } = useNotification();

    return (
        <Link onClick={() => setShowLoginModel(true)} style={{ textDecoration: "none" }}>
            <div className='sign-button fw-bolder'>
                Login
            </div>
        </Link>
    )
}
