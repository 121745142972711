import React, { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import { useAuth, useNotification } from '../../hooks'
import { Card, Col, Modal, Row } from "react-bootstrap";
import {  CCardBody } from "@coreui/react";
import * as PropTypes from "prop-types";
import banner from "../asset/banner.webp"
import { BsTelephone, BsXLg } from "react-icons/bs";
import { get_job_sheet, jobsheet_update } from "../../api/jobsheet";
import IncludeModalPartsSuggestion from "./component/IncludeModalPartsSuggestion";
import { IncludeJobsheetPartInfoCard } from "./component/IncludeJobsheetPartInfoCard";
import ModalJobsheetRemarkAdd from "./component/ModalJobsheetRemarkAdd";
import DialogJobsheetStatusUpdate from "./component/DialogJobsheetStatusUpdate";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import { useParams } from "react-router";

class CIcon extends React.Component {
    render() {
        return null;
    }
}

CIcon.propTypes = {
    icon: PropTypes.any,
    height: PropTypes.number
};
export default function BookingDetails() {
    const [clientInfo, setClientInfo] = useState({})
    const [partsInfo, setPartsInfo] = useState([])
    const [dataInfo, setDataInfo] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const { authInfo } = useAuth();
    const navigate = useNavigate();
    const { orderToken } = useParams();

    const { showError, showSuccess } = useNotification();
    const [btnOpen, setBtnOpen] = useState('Mark as Open')
    const [btnClosed, setBtnClosed] = useState('Mark as Closed')
    const [btnPending, setBtnPending] = useState('Mark as Part Pending')
    const [reloadData, setReloadData] = useState(false)
    const [urlImages, setUrlImages] = useState( )
    const [show, setShow] = useState(false);
    const [modalImage, setModalImage] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowImage = (url) => {
        setModalImage(url)
        handleShow()
    }
    useEffect(() => {
        // setOrderToken(searchParams.get("order"));
        // window.scrollTo(0, 0);
    }, [orderToken])

    useEffect(() => {
        if (dataInfo?.token) {
            document.title = 'Jobsheet #' + (dataInfo?.token || "") + ' | iService - Innovation Service';
        } else {
            document.title = 'Jobsheet | iService - Innovation Service';
        }
    }, [dataInfo])

    const getUsers = async () => {
        if (orderToken) {
            setIsLoading(true);
            const { data, error } = await get_job_sheet({
                id: authInfo.profile?.id,
                user_type: authInfo.profile?.user_type,
                token_order: orderToken
            }); 
            if (data?.data?.url_images_client?.length) {
                setUrlImages(data?.data?.url_images_client)
            }
            setReloadData(false);
            if (error) return showError(error)
            setDataInfo(data.data)
            setClientInfo(data.data.user)
            setPartsInfo(data?.data?.order_parts || data?.data?.parts || [])
            // setServiceInfo(data.data.agent)
            // setOrderAddress(data?.data?.order_address || data?.data?.address)
            // console.log(data.data);
            // console.log(dataInfo);
            setIsLoading(false);
        } else {
            showError("Undefined token");
            navigate('/');
        }
    }
    useEffect(() => {
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
    })
    useEffect(() => {
        getUsers().then(r => r);
    }, [orderToken, reloadData]);

    const handleUpdateOrder = async (status) => {
        // console.log(status);
        setBtnOpen("loading...");
        setBtnClosed("loading...");
        setBtnPending("loading...");
        const { data, error } = await jobsheet_update({
            id: authInfo?.profile?.id,
            user_type: authInfo?.profile?.user_type || "client",
            token_order: orderToken,
            values: {
                status: status
            }
        });

        if (error || !data || data?.error) {
            setBtnOpen("Mark as Open");
            setBtnClosed("Mark as Closed");
            setBtnPending("Mark as Part Pending");
            return showError(data.message);
        } else {
            showSuccess(data.message)
            await getUsers();
        }
        setBtnOpen("Mark as Open");
        setBtnClosed("Mark as Closed");
        setBtnPending("Mark as Part Pending");
    };

    // partsInfo,
    var partAmountTotal = 0;
    const style = { color: "#ee610f", fontSize: "1.2em" }
    return (
        <>
            <div className="ourservices">
                <div className="">
                    <h3 className="servicesHeading text-uppercase">Jobsheet Details (#{orderToken})</h3>
                    <p className=" subHeading ">
                        iService is the one-stop-shop for all your spare parts needs. iService deals with all types of
                        spare parts and service
                    </p>
                </div>
            </div>
            <section className={'my-5 mx-5'}>
                <Row>
                    <Col md={9}>
                        {isLoading && <Row><SkeletonFileGeneral height={220} total={4} col={6} /></Row>}
                        {!isLoading && <Row className={'g-3'}>
                            <Col md={6} className={'d-flex align-items-stretch'}>
                                <Card className={'shadow w-100'}>
                                    <CCardBody>
                                        <Row xs={{ cols: 1 }} className={'g-3'}>
                                            <Col>
                                                <div className="firstStatus">
                                                    <Row>
                                                        <Col>
                                                            <h5 className={''}>{/*Order*/} <span
                                                                className="text-uppercase">#{dataInfo?.token}</span>
                                                            </h5>
                                                        </Col>
                                                        <Col>
                                                            <DialogJobsheetStatusUpdate dataInfo={dataInfo}
                                                                reloadData={reloadData}
                                                                setReloadData={setReloadData} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                            <Col><h6>Customer Details</h6></Col>
                                            <Col>
                                                <div className={'d-flex flex-row'}>
                                                    <div><img className={"rounded-circle mx-2"}
                                                        src={clientInfo.url_profile ? clientInfo.url_profile : banner}
                                                        alt="user" width={"50px"} height={"50px"}
                                                        onError={(e) => e.target.src = banner} /></div>
                                                    <div className="customerDetail">
                                                        <span
                                                            className="names">{dataInfo?.name || dataInfo?.name}</span>
                                                        <div className="phoneNumber">
                                                            <div>+91 {(dataInfo?.mobile ? dataInfo?.mobile : "")}</div>
                                                        </div>

                                                    </div>
                                                    <div>
                                                        <a href={"tel:" + (dataInfo?.mobile || "")} className={''}>
                                                            <BsTelephone style={style}
                                                                className={'align-items-center justify-content-center'} />
                                                        </a>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        {dataInfo?.delivery_date && <>
                                            <hr />
                                            <Row className={'g-2'}>
                                                <Col xs={4} className={'text-muted'}>Delivery Date</Col>
                                                <Col xs={8}><small
                                                    className={"float-end fw-bold"}>{dataInfo?.delivery_date}</small></Col>
                                            </Row>
                                        </>}

                                    </CCardBody>
                                </Card>
                            </Col>
                            <Col md={6} className={'d-flex align-items-stretch'}>
                                <Card className={'shadow  w-100'}>
                                    <CCardBody>
                                        <Row xs={{ cols: 1 }} className={'g-3'}>
                                            <Col><h6>Device Details</h6></Col>
                                        </Row>
                                        <Row className={'g-2 figure-caption'}>
                                            <Col xs={4} className={'text-muted'}>Category</Col>
                                            <Col xs={8}><small
                                                className={"float-end fw-bold"}>{dataInfo?.title_category}</small></Col>

                                            <Col xs={4} className={'text-muted'}>Brand</Col>
                                            <Col xs={8}><small
                                                className={"float-end fw-bold"}>{dataInfo?.title_brand}</small></Col>

                                            <Col xs={4} className={'text-muted'}>Model</Col>
                                            <Col xs={8}><small
                                                className={"float-end fw-bold"}>{dataInfo?.title_model}</small></Col>

                                            <Col xs={4} className={'text-muted'}>IMEI/Serial number</Col>
                                            <Col xs={8}><small
                                                className={"float-end fw-bold"}>{dataInfo?.device_serial_imei}</small></Col>

                                            <Col xs={4} className={'text-muted'}>Problem</Col>
                                            <Col xs={8}><small
                                                className={"float-end fw-bold"}>{dataInfo?.device_problem ? dataInfo.device_problem : ""} {dataInfo?.device_problem_other ? " / " + dataInfo.device_problem_other : ""}</small></Col>
                                        </Row>
                                    </CCardBody>
                                </Card>
                            </Col>
                            {/*{partsInfo && ()}*/}
                            <Col md={6} className={'d-flex align-items-stretch'}>
                                <IncludeJobsheetPartInfoCard dataInfo={dataInfo} partsInfo={partsInfo}
                                    tokenOrder={orderToken} reloadData={reloadData}
                                    setReloadData={setReloadData} />
                            </Col>
                            {dataInfo?.remark && (<Col md={6} className={'d-flex align-items-stretch'}>
                                <Card className={'shadow w-100'}>
                                    <CCardBody>
                                        <Row>
                                            <Col xs={9}><span className="clientDetail">Remarks</span></Col>
                                            <Col xs={3}><ModalJobsheetRemarkAdd tokenOrder={dataInfo?.token}
                                                reloadData={reloadData}
                                                setReloadData={setReloadData} /></Col>
                                        </Row>
                                        <Row xs={{ cols: 1 }} className={'g-3 mt-4'}
                                            style={{ maxHeight: "250px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Col>
                                                {dataInfo.remark && Array.isArray(dataInfo.remark) ? dataInfo.remark.map((remark, index) => {
                                                    const date = remark?.date || "";
                                                    return (
                                                        <>
                                                            <hr className={'my-1'} />
                                                            <Row className={''}>
                                                                <Col sm={12}><span
                                                                    style={{ fontSize: "13px" }}>{remark?.remark || ""}</span></Col>
                                                                <Col sm={12}
                                                                    className={'text-end text-right'}><small
                                                                        style={{ fontSize: "10px" }}><b>{date}</b></small></Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }) : (<p>{dataInfo?.remark || ""}</p>)}

                                            </Col>
                                        </Row>
                                    </CCardBody>
                                </Card>
                            </Col>)}
                            <Col md={12} className={'text-center'}>
                                {/*{dataInfo?.status && dataInfo.status === 'pending' && (
                                    <button className="sign-up-button mx-auto"
                                            onClick={() => handleUpdateOrder('open')}>{btnOpen}</button>
                                )}
                                {dataInfo?.status && dataInfo.status === 'open' && (
                                    <>
                                        <div className="btn-group mx-auto">
                                            <button className="sign-up-button mx-3"
                                                    onClick={() => handleUpdateOrder('closed')}>{btnClosed}</button>
                                            <button className="sign-up-button-light mx-3"
                                                    onClick={() => handleUpdateOrder('pending')}>{btnPending}</button>
                                        </div>
                                    </>
                                )}*/}

                                {/*{dataInfo?.status && dataInfo.status === 'open' ? (
                                    <p><b>Note: </b>Service Engineer reached and diagnosing device.</p>
                                ) : ""}*/}

                            </Col>
                        </Row>}
                    </Col>
                    <Col md={3} className={'my-2'}>
                        <hr className={'d-block d-md-none'} />
                        <IncludeModalPartsSuggestion modelId={dataInfo?.model_id} isLoading={isLoading} />
                    </Col>
                </Row>
                {modalImage && <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="booking-modal-box p-0" >
                    <img className='d-flex justify-content-center mx-auto' style={{ height: "auto", width: "70%" }} onClick={handleShow}  src={modalImage} />
                    <div className=" booking-modal-cros-btn text-end p-2"><BsXLg className="close-button" size={30} onClick={handleClose} /></div>
                        {/* <div style={{ backgroundImage: `url(${modalImage})` }} className="booking-modal-img">
                            <div className="text-end p-2"><BsXLg className="close-button" size={30} onClick={handleClose} /></div>
                        </div> */}

                    </Modal.Body>
                </Modal>}
                { urlImages ? <Row><Col className='clientDetail'> Product Images</Col></Row> :<></> }

                <Row >
                    {
                        urlImages && Array.isArray(urlImages) && urlImages.map((img, index) => {
                            return (
                                <Col xs={6} sm={3} md={2} key={index} className="mt-3">
                                    <Card onClick={()=>handleShowImage(img)} className='pointer'>
                                        <Card.Body className="p-2">
                                            <Card.Img alt='client image' className="img-fluid " src={img} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }

                </Row>


            </section>
        </>
    )
}
