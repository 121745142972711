import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Alert } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogTempNotAvailable({ open, setOpen }) {
    // const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Slide in alert dialog
            </Button>*/}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Service Alert"}</DialogTitle>
                <DialogContent>

                    {/* <DialogContentText id="alert-dialog-slide-description"> */}
                        <div>
                            <Alert severity="warning">
                                <div> 
                                    No need to worry! Our service is temporarily unavailable at the moment. Please check back
                                    later,
                                    as we are working to restore it soon. Thank you for your patience.
                                </div>
                            </Alert>
                        </div>
                    {/* </DialogContentText>  */}
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose}>Disagree</Button>*/}
                    <Button onClick={handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}