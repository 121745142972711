import React, {useEffect, useState} from "react";
import {Card, Col, Container, Modal, Row} from "react-bootstrap";
import {useAuth} from "../../hooks";
import {BsXLg} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
// import {Image} from "@mui/icons-material";

const BusinessDetail = () => {
    const {authInfo} = useAuth();
    const [show, setShow] = useState(false);
    const [modalImage, setModalImage] = useState(null)
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowImage = (url) => {
        setModalImage(url)
        handleShow()
    }

    useEffect(() => {
        if (authInfo?.profile?.user_type === 'client') {
            // showError("Please login to continue.");
            navigate("/auth");
        }
    })


    return (
        <>
            <Container className="ps-0 pe-0">
                <Row className="h5 ">Business Detail</Row>
                <Row className={"mt-3"}>
                    <Col md={6}>
                        <Col className="fw-bold mb-1">Personal Information</Col>

                        <Row className="">
                            <Col sm={4} md={3}> Name: </Col> <Col
                            className="text-end">{authInfo?.profile?.business?.name || ""}</Col>
                        </Row>
                        <Row className="mt-2">
                            <Col sm={4} md={3}> email: </Col> <Col
                            className="text-end"> {authInfo?.profile?.business?.email || authInfo?.profile?.email || ''}</Col>
                        </Row>
                        <Row className="mt-2">
                            <Col sm={4} md={3}> Mobile:</Col> <Col
                            className="text-end"> {authInfo?.profile?.business?.mobile || authInfo?.profile?.mobile || ''}  </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col sm={4} md={3}> Address: </Col> <Col
                            className="text-end"> {authInfo?.profile?.business?.address || ''}, {authInfo?.profile?.business?.name_city || ''}, {authInfo?.profile?.business?.name_state || ''}, {authInfo?.profile?.business?.name_country || ''}  </Col>
                        </Row>


                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col xs={12} className="fw-bold mt-md-0 mt-3 mb-1">Registration Certificate</Col>
                            <Col xs={12} className='mt-3 text-center'>
                                {authInfo?.profile?.business?.url_registration ? <>
                                    {/* <iframe style={{maxHeight: "250px"}}
                                            onClick={() => handleShowImage(authInfo?.profile?.business?.url_registration)}
                                            src={authInfo?.profile?.business?.url_registration} width={"90%"}/>*/}
                                    <img alt={'reg'}
                                         onClick={() => handleShowImage(authInfo?.profile?.business?.url_registration)}
                                         src={authInfo?.profile?.business?.url_registration}
                                         style={{maxWidth: "200px", maxHeight: "150px"}}/>

                                </> : ""}
                            </Col>
                        </Row>

                    </Col>

                </Row>


                <Row><Col className='fw-bold mt-3'>
                    <hr/>
                </Col></Row>

                {modalImage &&
                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter"
                       centered>
                    <Modal.Body className="booking-modal-box p-0">
                        <img className='d-flex justify-content-center mx-auto' style={{height: "auto", width: "70%"}}
                             onClick={handleShow} src={modalImage} alt={'img'}/>
                        <div className=" booking-modal-cros-btn text-end p-2">
                            <BsXLg className="close-button" size={30} onClick={handleClose}/>
                        </div>
                        {/* <div style={{ backgroundImage: `url(${modalImage})` }} className="booking-modal-img">
                            <div className="text-end p-2"><BsXLg className="close-button" size={30} onClick={handleClose} /></div>
                        </div> */}

                    </Modal.Body>
                </Modal>}

                <Row className={"mt-1"}>
                    <Col xs={12} className='fw-bold mt-3'> Shop Images</Col>

                    {
                        authInfo?.profile?.business?.url_images && Array.isArray(authInfo?.profile?.business?.url_images) && authInfo?.profile?.business?.url_images.map((img, index) => {
                            return (
                                <Col xs={6} sm={3} md={2} key={index} className="mt-3">
                                    <Card onClick={() => handleShowImage(img)} className='pointer'>
                                        <Card.Body className="p-2">
                                            <Card.Img alt='client image' className="img-fluid " src={img}/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }

                </Row>

            </Container>
        </>
    )
}
export default BusinessDetail