import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
// import ReactPhoneInput from "react-phone-input-2";
import {Card, Col, Row} from "react-bootstrap";
// import { CFormInput, CFormLabel, CInputGroup, CInputGroupText } from "@coreui/react";
import {CircularProgress, FormControl, FormControlLabel, RadioGroup,} from "@mui/material";
// import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import "../Cart/Cart.css"
// import { ContactMail } from "@mui/icons-material";
// import axios from "axios";
// import useRazorpay from "react-razorpay";
import {useAuth, useNotification} from "../../hooks";
import {cart} from "../../api/cart";
// import { getUser } from "../../api/auth";
// import { isAppTempDown } from "../../api/client";
// import handlePaymentTransaction from "../Cart/handel_payment_razorpay";
import {ApiCall} from "../../api/HelpingMethod";
// import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import ModalAddressAdd from "../Cart/ModalAddressAdd";
// import { formatMoney } from "../../hooks/useCustomHelpingMethod";
// import IntegrationRazorPay from "../Cart/IntrigationRazorpay";
import {book_service_step} from "../../api/booking";


export default function LocationDetail2({ data, setData, handeNext, handleBack, token }) {
    // const locationTemp = useLocation();
    // if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
    //     localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
    // }
    const [isAddressSelect, setIsAddressSelect] = useState(false)
    const [loading, setLoading] = useState(false);
    // const [cart_details, setCart_details] = useState([]);
    // const [whole_details, setWhole_details] = useState([]);
    const [cartAddress, setCartAddress] = useState([])
    const [updated, setUpdated] = useState(false);
    const { showError } = useNotification();
    const { authInfo } = useAuth();
    const navigate = useNavigate();
    const [btnName, setButtonName] = useState('Submit')
    const [loadingBtn, setButtonLoading] = useState(false)
    const { setOpenBackDrop } = useNotification();

    const [addressBody] = useState({
        "login_user_id": authInfo?.profile?.id,
        "login_user_type": authInfo?.profile?.user_type,
        "limit": 3,
        "offset": 0
    })

    const [searchParams, setSearchParams] = useSearchParams();
    const [tokenPart, setTokenPart] = useState(searchParams.get("token_part") || null);
    const [locationInfo, setLocationInfo] = useState({
        login_user_id: authInfo.profile.id,
        login_user_type: authInfo.profile.user_type,
        step: "location-info",
        token_order: token,
        name: authInfo.profile?.name,
        mobile: authInfo.profile?.mobile,
        email: authInfo.profile?.email,
        name_state: "",
        name_city: "",
        address: "",
        pincode: "",
        device_latitude: "0.00",
        device_longitude: "0.00",
    });

    useEffect(() => {
        if (data?.order_address?.address_id) {
            setIsAddressSelect(data?.order_address?.address_id || "")
        }
    }, [data])

    useEffect(() => {
        document.title = 'Confirm Location | iService - Innovation Service';
    }, [])

    const getData = async ({ token_part, coupon, couponTrue }) => {
        let inpDt = {
            id: authInfo?.profile.id,
            user_type: authInfo?.profile.user_type,
            coupon: coupon,
            couponTrue: couponTrue
        }
        if (token_part) {
            inpDt.token_part = token_part;
        }
        const { data } = await cart(inpDt);
        // console.log("datadata--", data);
        if (data.error) {
            showError(data.message);
        } else {
            // if (data?.data?.parts) await setCart_details(data?.data?.parts);
            // if (data?.data) await setWhole_details(data?.data);
        }
        return data;
        // console.log(whole_details);
    };




    const handleSubmit = async (event) => {
        setButtonLoading(true);
        setButtonName("loading...");
        event.preventDefault();
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Submit");
            setButtonLoading(false);
            event.stopPropagation();

        } else {
            const { data, error } = await book_service_step({
                details: { ...locationInfo, address_id: isAddressSelect }
            });
            setButtonName("Submit");
            if (error || data.error) {
                setButtonLoading(false);
                return showError(data.message);
            } else {
                setButtonLoading(false);
                // showSuccess(data.message)
                navigate("/booking-details/" + token + "?order=" + token, {
                    state: { token: token },
                    replace: true,
                });
            }
        }

        // navigate("/booking-details?order=" + token, {
        //     state: {token: token},
        //     replace: true,
        // });

        // setButtonName("Submit");
        // setValidated(true);
    };


    const getCartAddress = async (e) => {
        setLoading(true);
        setOpenBackDrop(true);
        const result = await ApiCall("/dealer/address", addressBody)
       // console.log("result---", result)
        if (result?.data) {
            if (authInfo?.profile.user_type === 'dealer' && result?.data.length > 1) {
                setIsAddressSelect(result?.data[1]?.id || 0);
            } else {
                setIsAddressSelect(result?.data[0]?.id || 0);
            }
        }
        setCartAddress(result?.data);
        setLoading(false);
        setOpenBackDrop(false);
        setUpdated(false);
    }
    useEffect(() => {
        getData({ token_part: tokenPart, coupon: "", couponTrue: false });
        getCartAddress();
    }, [updated, tokenPart]);
    const onSelectAddress = (id) => {
        // console.log(id);
        setIsAddressSelect(id);
    }

    return (
        <div>
            <section>
                {/* {loading &&
                    <div className="container">
                        <Row className={"d-flex mb-4"}>
                            <h5 className="product-info-heading col-lg-3"><Skeleton height={40} /></h5>
                            <div className="col-lg-12" />
                            <Col lg={12}>
                                <Row>
                                    <SkeletonFileGeneral height={50} total={5} col={12} />
                                </Row>
                            </Col>
                        </Row>
                    </div>
                } */}
                <div className="container">

                    {!loading && (
                        <div className="row d-flex justify-content-center mb-4">
                            {cartAddress && Array.isArray(cartAddress) && cartAddress.length > 0 ?
                                <>
                                    <h5 className="mb-0 mx-3 product-info-heading col-lg-12">Select Location</h5>
                                    <ModalAddressAdd reloadData={updated} setReloadData={setUpdated}/>
                                    <div className=" py-2" style={{maxHeight: "500px", overflow: "auto"}}>

                                        <FormControl sx={{m: 1}} error={false} variant="standard">
                                            <RadioGroup aria-labelledby="demo-error-radios"
                                                        name="address_id"
                                                        value={isAddressSelect || 0}
                                                // onChange={handlePaymentModeChange}
                                            >
                                                {cartAddress && Array.isArray(cartAddress) && cartAddress.map((address, index) => {
                                                    return (
                                                        <Row className={'mb-2'}
                                                             key={address?.token || address?.id || index}>
                                                            <Col>
                                                                <Card style={{cursor: "pointer"}}
                                                                      onClick={() => onSelectAddress(address.id)}
                                                                      className={isAddressSelect === address?.id ? "select-address" : ""}>
                                                                    <Card.Body>
                                                                        <FormControlLabel value={address?.id}
                                                                                          control={<Radio
                                                                                              color={"warning"}/>}
                                                                                          label={address?.full_address || "No Address"}/>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}

                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </> :
                                <>
                                    <p className={"my-5 ms-5"}>
                                        <ModalAddressAdd reloadData={updated} setReloadData={setUpdated}
                                                         place={'center'} defaultOpen={true}/>
                                    </p>
                                </>
                            }
                        </div>
                    )}
                    <Row className={"my-sm-5 my-1 d-flex"}>
                        <Col xs={6}>
                            <button className="button-2" onClick={handleBack}>{"Previous"}</button>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            <button disabled={loadingBtn || !cartAddress.length}
                                    className={"sign-up-button " + (loadingBtn || !cartAddress.length && 'disabled-button')}
                                    onClick={handleSubmit} type={'submit'}>{loadingBtn &&
                            <CircularProgress size={16} color={"inherit"}/>}&nbsp; {btnName}</button>
                        </Col>
                    </Row>
                </div>
            </section>
            {/* <DialogTempNotAvailable open={notAvOpen} setOpen={setNotAvOpen} /> */}
        </div>
    );
}
