import React, {useState} from "react";
import AuthPage from "./components/Authentication/AuthPage";
import HomePage from "./components/HomePage/HomePage";
import ServicePage from "./components/OurServices/ServicePage";
import About from "./components/About/About";
import {Route, Routes} from "react-router-dom";
import AuthSignIn from "./components/Authentication/AuthSignIn";
import PurchasePage from "./components/PartPurchase/PurchasePage";
import OrderBanner from "./components/OrderConfirmation/OrderBanner";
import CartMain from "./components/Cart/CartMain";
import ServiceMain from "./components/ServiceCategories/ServiceMain";
import ContactUsMain from "./components/ContactUs/ContactUsMain";
import Main from "./components/ProductInfromation/Main";
import BuyMain from "./components/ByParts/BuyMain";
import AboutRepair from "./components/MobileRepair/AboutRepair";
import AuthVerfiy from "./components/Authentication/AuthVerfiy";
import Layout from "./components/Layout";
import ForgotPage from "./components/Authentication/ForgotPage";
import ResetPage from "./components/Authentication/ResetPage";
import BookingMain from "./components/ClientBooking/BookingMain";
import BookingList from "./components/BookingDetails/BookingList";
import BookingDetails from "./components/BookingDetails/BookingDetails";
import BookingPayment from "./components/BookingDetails/BookingPayment";
import JobSheetMain from "./components/DealerJobsheet.js/JobSheetMain";
import BookingDashboard from "./components/BookingDetails/BookingDashboard";
import JobSheetDashboard from "./components/DealerJobSheetDetail/JobSheetDashboard";
import JobSheetList from "./components/DealerJobSheetDetail/JobSheetList";
import JobsheetDetails from "./components/DealerJobSheetDetail/JobsheetDetails";
import UserDetails from "./components/UserInformation/UserDetails";
import GeneralPage from "./components/GeneralPage/GeneralPage";
import BecomeAPartner from "./components/BecomeAPartner/BecomeAPartner";
import CartCheckOut from "./components/Cart/CartCheckOut";
import ReviewDetail from "./components/ProductInfromation/ReviewDetail";
import BlogList from "./components/Blogs/BlogList";
import BlogDetail from "./components/Blogs/BlogDetail";
import FranchiseList from "./components/Franchise/FranchiseList";
import NotificationList from "./Notification/NotificationList";
import {GoogleMapLocation} from "./components/GoogleMapLocationPick/GoogleMapLocation";
import GeneralPagePrivacyPolicy from "./components/GeneralPage/GeneralPagePrivacyPolicy";
import QuickEntry from "./components/CustomPartOrder/QuickEntry";
import PartRequest from "./components/CustomPartOrder/PartRequest";
import FranchiseDetail from "./components/Franchise/FranchiseDetail";
import Invoice from "./components/BookingDetails/Invoice";


export default function App() {
    const [loginNavigate, setLoginNavigate] = useState("/")

    return (
        <>
            <Routes>
                <Route exact path="/google-map-location" element={<GoogleMapLocation/>}/>
                <Route exact path="/react-google-map-picker" element={<GoogleMapLocation/>}/>
                <Route exact path="/auth" element={<AuthVerfiy/>}/>
                <Route exact path="/signup" element={<AuthPage/>}/>
                <Route exact path="/signin" element={<AuthSignIn loginNavigate={loginNavigate}/>}/>
                <Route exact path="/forgot" element={<ForgotPage/>}/>
                <Route exact path="/reset-password" element={<ResetPage/>}/>
                <Route exact path="/"  element={<Layout />}>
                    <Route index element={<HomePage/>}/> 
                    <Route path="/order-complete" element={<OrderBanner/>}/>
                    <Route path="/cart" element={<CartMain/>}/>
                    <Route path="/cart-checkout" element={<CartCheckOut/>}/>
                    <Route path="/service-categories" element={<ServiceMain/>}/>

                    {/*General pages*/}
                    <Route path="/about" element={<About/>}/>
                    <Route path="/page/:slugPage" element={<GeneralPage/>}/>
                    <Route path="/contact-us" element={<ContactUsMain/>}/>
                    <Route path="/privacy-policy" element={<GeneralPagePrivacyPolicy/>}/> 

                    {/* old product Routes*/}
                    <Route path="/shop" element={<PurchasePage/>}/>
                    <Route path="/product-info" element={<Main/>}/>
                    {/*new product detail*/}
                    <Route path="/product" element={<PurchasePage/>}/>
                    <Route path="/product/:slugPart" element={<Main/>}/>

                    {/*service routes*/}
                    <Route path="/services" element={<ServicePage/>}/>
                    <Route path="/mobile-repair" element={<AboutRepair/>}/>
                    {/*new route  */}
                    <Route path="/services/:slugCategory" element={<AboutRepair/>}/>

                    <Route path="/review/:tokenpart" element={<ReviewDetail/>}/>
                    <Route path="/blog" element={<BlogList/>}/>
                    <Route path="/blog/:slugBlog" element={<BlogDetail/>}/>
                    <Route path="/quick-entry" element={<QuickEntry/>}/>
                    <Route path="/notification" element={<NotificationList/>}/>
                    <Route path="/part-request" element={<PartRequest/>}/>


                    <Route path="/franchise" element={<FranchiseList/>}/>
                    <Route path="/franchise/:slugFranchise" element={<FranchiseDetail/>}/>

                    <Route path="/buy-parts" element={<BuyMain/>}/>

                    <Route path="/become-partner" element={<BecomeAPartner/>}/>

                    {/*Booking Routes*/}
                    <Route path="/book-repair" element={<BookingMain/>}/>
                    <Route path="/booking" element={<BookingDashboard/>}/>
                    <Route path="/booking/:type" element={<BookingList/>}/>
                    <Route path="/booking-details/:orderToken" element={<BookingDetails/>}/>
                    {/*<Route path="/booking-detailss" element={<BookingWIthRemarks/>}/>*/}
                    <Route path="/booking-payment" element={<BookingPayment/>}/>
                    <Route path="/order-invoice/:orderToken" element={<Invoice />}/>


                    {/*Jobsheet Routes*/}
                    <Route path="/job-repair" element={<JobSheetMain/>}/>
                    <Route path="/job-sheet" element={<JobSheetDashboard/>}/>
                    <Route path="/job-sheet/:status" element={<JobSheetList/>}/>
                    <Route path="/job-sheet/detail/:orderToken" element={<JobsheetDetails/>}/>

                    <Route path="/profile" element={<UserDetails />}/>



                    <Route path="*" element={<p>Path not resolved</p>}/>
                </Route>
            </Routes>
        </>
    );
}
