import React from "react";
import { Image} from "react-bootstrap";
import mobileRepair from "../asset/booking-offer-banner.webp";

export function DeviceInfoBlock() {
    return (
        <div className="brandbox container my-3 ">
            <Image className={'mx-auto'} src={mobileRepair} style={{maxHeight: "150px", maxWidth: "100%"}}/>
        </div>
    );
}