import React, {   useState } from "react";
import {  Col , Form, InputGroup, Row } from "react-bootstrap";
// import userimg from "../asset/Ellipse 30.webp";
// import { FaEdit, FaLock, FaLockOpen, FaRegUser } from "react-icons/fa";
import { InputField, InputRadioField } from "../InputFields/InputFieldHelper";
import { useAuth, useNotification } from "../../hooks";
import {  BiEnvelope,   BiPhone, BiUpload, BiUser } from "react-icons/bi";
import {  FormLabel } from "@mui/material";
// import { job_sheet_add } from "../../api/jobsheet";
import { updateProfile } from "../../api/auth";

const UserInformation = () => {
    const { authInfo, setSessionData } = useAuth();
    const [btnSubmit, setBtnSubmit] = useState("Save Changes");
    const [userProfile, setUserProfile] = useState({
        name: authInfo?.profile?.name || "",
        dob: authInfo?.profile?.dob || "",
        mobile: authInfo?.profile?.mobile || "",
        alt_mobile: authInfo?.profile?.alt_mobile || "",
        email: authInfo?.profile?.email || "",
        gender: authInfo?.profile?.gender || "",
    });
    const [profilePic, setProfilePic] = useState("");
    const { showError, showSuccess } = useNotification();
    const [file, setFile] = useState();




    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserProfile({ ...userProfile, [name]: value })
    }


    const handleChangeFile = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(file);
        reader.onloadend = () => {
            setProfilePic(file);
        }
        reader.readAsDataURL(file)
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        setBtnSubmit("Loading....");
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setBtnSubmit("Save Changes");
            e.stopPropagation();
        } else {
            const formData = new FormData();
            for (let key in userProfile) {
                formData.append(key, userProfile[key]);
            }
            // if (profilePic) {
            //     formData.append("url_profile", profilePic)
            // }

            const { data, error, message } = await updateProfile({
                inputData: userProfile,
                user_id: authInfo?.profile?.id,
                uploadPic: true,
                url_profile: profilePic
            });
            // console.log(data);
            setBtnSubmit("Save Changes");
            if (error || data.error) return showError(data.message || message || "");
            setSessionData(data);
            showSuccess(data?.message || message || "Profile successfully Updated");
        }
        setBtnSubmit("Save Changes");
    }
    // useEffect(() => {
    //     console.log(userProfile);
    //     console.log(btnSubmit);
    // }, [])
    return (
        <>
            <div>
                <Form onSubmit={handleSubmit}>
                    <Row className="h5 ms-1 mt-4">Profile Information</Row>
                    <Row className="mt-4" md={{ cols: 2 }} xs={{ cols: 1 }}>
                        <Col>
                            <InputField type={'text'} minLength={3} name={'name'}
                                label={'Name'} value={userProfile?.name}
                                onChange={handleChange} appendType={'start'}
                                color={"warning"}
                                appendIcon={<BiUser />} required={true} />
                        </Col>
                        <Col>
                            {/* <InputField type={'file'} name={'url_profile'}
                                        label={'Upload Profile'} 
                                        onChange={handleChangeFile} appendType={'start'}
                                        appendIcon={<BiUpload/>}/> */}

                            <Form.Group className="mt-1" controlId={"input"}>
                                <Form.Label className={"mb-0"} style={{ fontWeight: "lighter" }}><strong>Upload Profile</strong> </Form.Label>
                                <InputGroup >
                                    <InputGroup.Text><BiUpload /></InputGroup.Text>
                                    <Form.Control style={{lineHeight:"16px"}} className="p-1" name={'url_profile'} type={"file"}
                                        onChange={handleChangeFile}
                                    />


                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <InputField type={'number'} minLength={10} name={'mobile_no '}
                                min={1000000000} max={9999999999}
                                disabled={true}
                                label={'Mobile Number'} value={userProfile?.mobile}
                                onChange={handleChange} appendType={'start'}
                                appendIcon={<BiPhone />} required={false} />
                        </Col>
                        <Col>
                            <InputField type={'number'} minLength={10} name={'alt_mobile'}
                                label={'Alt Mobile Number'} value={userProfile?.alt_mobile}
                                onChange={handleChange} appendType={'start'}
                                min={1000000000} max={9999999999}
                                appendIcon={<BiPhone />} />
                        </Col>
                        <Col>
                            <InputField type={'email'} name={'email'}
                                label={'Email Address'} value={userProfile?.email}
                                onChange={handleChange} appendType={'start'}
                                appendIcon={<BiEnvelope />} required={true} />
                        </Col>
                        <Col>
                            <InputRadioField label={'Gender'} name={'gender'} value={userProfile?.gender}
                                onChange={handleChange}
                                options={[
                                    { label: "Male", value: "male" },
                                    { label: "Female", value: "female" },
                                    { label: "Other", value: "other" }
                                ]}
                            />
                        </Col>
                        <Col>
                            <div className="mb-3">
                                <FormLabel><b className={'text-dark'}>Date Of Birth</b></FormLabel>
                                <input  name={'dob'} value={userProfile?.dob}
                                    onChange={handleChange} type="date" className={'w-100 '} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <button className="sign-up-button" type={"submit"}>{btnSubmit}</button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}
export default UserInformation;