import React, {useEffect} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import img from "../../components/asset/noimage.webp"
import {
    Box,
    CircularProgress,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useState} from "react";
import {BsFillEnvelopeFill, BsFillGeoAltFill, BsFillStopwatchFill, BsTelephoneFill} from "react-icons/bs";
import {useAuth} from "../../hooks";
import {ApiCall} from "../../api/HelpingMethod";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import noitem from "../asset/Comp 2_00000.jpeg"
import {HelmetCustomInclude} from "../HelmetCustomInclude";
import {showAdBanners} from "../../api/client";
import {Link} from "react-router-dom";


const FranchiseList = () => {
    const [data, setData] = useState([])
    const {authInfo} = useAuth();
    const [loading, setLoading] = useState(false)

    const [searchInput, setSearchInput] = useState("");
    const [offset, setOffset] = useState(0);
    const [isMoreData, setIsMoreData] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);

    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "limit": 10,
        "offset": 0
    })


    const getData = async () => {
        setLoading(true)
        setOffset(0)

        const inputData = {
            offset: 0
        }
        if (searchInput) {
            inputData.search_text = searchInput;
        }


        const response = await ApiCall("dealer/franchise", {...bodyData, ...inputData})

        if (response?.error === false) {
            setData(response?.data)
            if (response.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
        } else {
            setData()
        }
        setLoading(false)
    }


    const nextData = async () => {
        setBtnLoading(true);

        const inputData = {
            offset: offset || 0
        }
        if (searchInput) {
            inputData.search_text = searchInput;
        }
        const response = await ApiCall("dealer/franchise", {...bodyData, ...inputData})

        if (response?.error === false) {
            setData(response?.data)
            if (response.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
            if (response.data.length) {
                setData((prev) => [...new Set([...prev, ...response.data])]);
            }
        } else {
            setData([])
        }
        setBtnLoading(false);
    }


    useEffect(() => {
        if (offset !== 0) {
            nextData();
        }
    }, [offset]);


    useEffect(() => {
        getData();
    }, [searchInput]);


    useEffect(() => {
        getBannerAdCall();
    }, []);


    const handelChangeSearch = async (e) => {
        setSearchInput(e.target.value);
    };
    const handelInfiniteScroll = async () => {
        setOffset(offset + 1);
    };

    const [bannerTop, setBannerTop] = useState(null);
    const [bannerBottom, setBannerBottom] = useState(null);

    const getBannerAdCall = async () => {
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_franchise_top_page_banner"
        }).then((r) => {
            setBannerTop(r?.data);
        });
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_franchise_bottom_page_banner"
        }).then((r) => {
            setBannerBottom(r?.data);
        });
    }
    const redirectBanner = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (
        <>
            <HelmetCustomInclude url={"franchise"} title={"Franchise"} image={null}/>
            <div className="ourservices">
                <h3 className=" mx-lg-5 servicesHeading">Franchise</h3>
                <p className="mx-lg-5 subHeading">
                    {/*iService offers low-cost spare parts. A large selection of spare parts is available. Free shipping
                    and cash-on-delivery (COD) are available in many Indian cities.*/}

                </p>
            </div>
            <Container fluid className="mb-4">
                {showAdBanners && bannerTop?.url_image && <Row className='m-0'>
                    <Col>
                        <img onClick={() => redirectBanner(bannerTop?.url_redirect)}
                             src={bannerTop?.url_image || noitem} className='w-100 banner-image pointer'
                             alt={'no advertisement'}/>
                    </Col>
                </Row>}
                <Row className={'mt-5 ms-0 me-0 ms-sm-5 me-sm-5'}>
                    <Col>
                        <Box role="presentation">
                            <Paper
                                sx={{p: '3px 3px', display: 'flex', alignItems: 'center', width: "100%"}}>
                                <InputBase sx={{ml: 1, flex: 1}}
                                           onChange={handelChangeSearch}
                                           name="search_text"
                                           placeholder="Search your order here...."
                                           inputProps={{'aria-label': 'Search product here'}}/>
                                <IconButton type="button" aria-label="search">
                                    <SearchIcon/>
                                </IconButton>
                                {/*<Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>*/}

                            </Paper>
                        </Box>
                    </Col>
                    {/* <Col sm={4} md={2} className="mt-sm-0 mt-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={review}
                                label="Select City"
                                onChange={handleChange}
                                defaultValue='dfdfdfdfdf' >
                                <MenuItem value={'Bhopal'}>Bhopal</MenuItem>
                                <MenuItem value={'Nagpur'}>Nagpur</MenuItem>
                                <MenuItem value={'Hyderabad'}>Hyderabad</MenuItem>
                                <MenuItem value={'Chennai'}>Chennai</MenuItem>

                            </Select>
                        </FormControl>
                    </Col> */}
                </Row>


                {loading &&
                <Row className="mt-2 ms-0 me-0 ms-sm-5 me-sm-5"> <SkeletonFileGeneral height={150} col={6} total={2}/>
                </Row>}


                {!loading && <> <Row className="ms-0 me-0 ms-sm-5 me-sm-5">
                    {
                        data && Array.isArray(data) && data.length > 0 ? data.map((data, index) => {
                            return (
                                <Col lg={6} className="mt-3" key={index}>
                                    <Link to={"/franchise/" + (data?.slug)} className={'text-decoration-none text-dark'}>
                                        <Card className="shadow">
                                            <Card.Body>
                                                <Container className="pe-0">
                                                    <Row>
                                                        <Col xs={2} className="p-0 ">
                                                            <div className=" ">
                                                                <img alt={data.name} src={data?.url_profile || img}
                                                                     style={{borderRadius: "50px"}}
                                                                     className="img-fluid"/>
                                                            </div>
                                                        </Col>
                                                        <Col xs={10}>
                                                            <Container className=" p-0 p-sm-1">
                                                                <Row> <Col className="fw-bold">{data?.name}</Col> </Row>
                                                                <Row> <Col><BsFillEnvelopeFill
                                                                    color="var(--primary)"/>&nbsp;{data?.email}</Col>
                                                                </Row>
                                                                <Row> <Col><BsTelephoneFill
                                                                    color="var(--primary)"/>&nbsp;{data?.mobile}</Col>
                                                                </Row>
                                                                <Row> <Col><BsFillStopwatchFill
                                                                    color="var(--primary)"/>&nbsp;
                                                                    <span>Open Hours :</span> <b>{data?.open_day} {data?.open_hour?"("+data?.open_hour+")":""}</b>
                                                                </Col> </Row>

                                                                <Row> <Col><BsFillGeoAltFill
                                                                    color="var(--primary)"/>&nbsp;{data?.address}</Col>
                                                                </Row>
                                                            </Container>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            )
                        }) : (<h6 className={'text-center'}>
                            There is no franchise available currently Please come back later
                        </h6>)
                    }

                </Row>
                    <Row>
                        <Col>
                            <div className="text-center">
                                {isMoreData && (
                                    <Button disabled={btnLoading} type="submit" variant=""
                                            className="btn btn-primary3 my-3 "
                                            onClick={handelInfiniteScroll}>{btnLoading &&
                                    <CircularProgress size={16} color={"inherit"}/>}&nbsp; Load More</Button>
                                )}

                            </div>
                        </Col>
                    </Row>
                </>}


                {showAdBanners && bannerBottom?.url_image && <Row className='mt-3 ms-0 me-0 ms-sm-5 me-sm-5'>
                    <Col>
                        <img onClick={() => redirectBanner(bannerBottom?.url_redirect)}
                             src={bannerBottom?.url_image || noitem} className='w-100 banner-image pointer'
                             alt={'no advertisement'}/>
                    </Col>
                </Row>}


                {/* <Row className="mb-4">
                    <Col>
                        <div className="text-center">
                            {isMoreData && (
                                <Button disabled={btnLoading} type="submit" variant=""
                                    className="btn btn-primary3 my-3 "
                                    onClick={handelInfiniteScroll}>{btnLoading &&
                                        <CircularProgress size={16} color={"inherit"} />}&nbsp; Load More</Button>
                            )}

                        </div>
                    </Col>
                </Row> */}
            </Container>

        </>
    )
}
export default FranchiseList