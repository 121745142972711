import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import logo from "../asset/logo.webp"
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useAuth, useNotification} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {get_bookings} from "../../api/booking";
import {appAddressMain, appAddressStreet, appLogoDark, appName, appPhoneNumber} from "../../api/constant";
import {formatMoney, get_date_formatted, getFullPageTitle} from "../../hooks/useCustomHelpingMethod";
import {FaDownload} from "react-icons/fa";

const Invoice = () => {
    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState("Order Invoice")
    const {orderToken} = useParams();
    const [dataInfo, setDataInfo] = useState()
    const [dataAddress, setDataAddress] = useState()
    const [dataParts, setDataParts] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const {showError, showSuccess} = useNotification();

    const getOrder = async () => {
        setIsLoading(true);
        if (orderToken) {
            const {data, error} = await get_bookings({
                id: authInfo?.profile?.id,
                user_type: authInfo?.profile?.user_type,
                token_order: orderToken
            });
            if (error) return showError(error)

            // setDeviceInfo(data?.data?.device_info)
            setDataInfo(data?.data)
            // setClientInfo(data?.data?.user)
            setDataParts(data?.data?.order_parts)
            // setServiceInfo(data?.data?.agent)
            setDataAddress(data?.data?.order_address || data?.data?.address)
            console.log("data---------", data?.data);
            // console.log(dataInfo);
        } else {
            showError("Undefined token");
            navigate('/');
        }
        setIsLoading(false);
    }


    useEffect(() => {
        getOrder().then(r => r);
        setPageTitle('Order Invoice #' + (orderToken || ""));
        document.title = getFullPageTitle('Order #' + (orderToken || "") );
    }, [orderToken])

    return (
        <>
            <div className="ourservices d-print-none">
                <h3 className=" mx-lg-5 servicesHeading">Invoice Detail (#{dataInfo?.token})</h3>
                <p className="mx-lg-5 subHeading">
                    {/*Get Spare Parts at low prices on iService. Wide range of Spare Parts. Get Free Shipping & COD options across India*/}
                </p>
            </div>
            <Container fluid={true} className="mt-3 mb-5 px-md-5 px-0 main_print">
                <Row className={'my-2 print_header'}>
                    <Col xs={6}>
                        <img className="img-fluid mt-3" style={{maxHeight: "70px"}} src={appLogoDark} alt={'logo'}/>
                    </Col>
                    <Col xs={6} className={'text-end mt-3'}>
                        <b><span className={'text-muted'}>INVOICE</span> <br/><b
                            className={'text-uppercase'}><small>#{dataInfo?.token}</small></b> </b> <br/>
                        {/*<h6><span className={'text-muted'}>INVOICE DATE</span> <br/><b>{get_date_formatted_current()} </b> </h6>*/}
                        <b><span className={'text-muted'}>ORDER DATE</span>
                            <br/><b><small>{get_date_formatted(dataInfo?.created_at || "")}</small> </b> </b>
                    </Col>
                </Row>
                <hr/>
                <div className={'print_middle_section'}>
                    <Row className="my-2">
                        <Col xs={6} className={'mt-2'}>
                            <h6 className={'text-muted'}>BILL TO</h6>
                            <b>{dataAddress?.name}</b> <br/>
                            <small>
                                <b><small>{dataAddress?.address}</small> </b> <br/>
                                <b><small>{dataAddress?.name_city}, {dataAddress?.name_state} ({dataAddress?.pincode}), {dataInfo?.agent?.address?.name_country || "India"}</small></b><br/>
                                <b><small>+{dataAddress?.mobile_code || "91"}{dataAddress?.mobile}</small></b> <br/>
                            </small>
                        </Col>
                        <Col xs={6} className="mt-2 text-end">
                            <h6 className={'text-muted'}>BILL FROM</h6>
                            {dataInfo?.order_type === 'service' && dataInfo?.agent ?
                                <>
                                    <b>{dataInfo?.agent?.name || "Service Engineer"}</b> <br/>
                                    <b><small>{dataInfo?.agent?.address?.address},</small></b><br/>
                                    <b><small>{dataInfo?.agent?.address?.name_city}, {dataInfo?.agent?.address?.name_state} ({dataInfo?.agent?.address?.pincode}), {dataInfo?.agent?.address?.name_country || "India"} </small></b><br/>
                                    <b><small>+{dataInfo?.agent?.mobile_code || "91"}{dataInfo?.agent?.mobile}</small></b><br/>
                                </> :
                                <>
                                    <b>{appName}</b><br/>
                                    <b><small>{appAddressStreet},</small></b><br/>
                                    <b><small>{appAddressMain}</small></b><br/>
                                    <b><small>+91{appPhoneNumber}</small></b><br/>
                                </>
                            }

                        </Col>

                    </Row>

                    <Row className={"my-5 px-2"}>
                        {dataInfo?.order_type === 'service' ? <>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="demo table" size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2} className={'text-muted'}>MODEL</TableCell>
                                            <TableCell colSpan={2} className={' text-muted'}>PROBLEM</TableCell>
                                            <TableCell className={'text-end text-muted'}>PRICE</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {/*<TableCell>{index + 1}</TableCell>*/}
                                            <TableCell colSpan={2} className={'fw-bold'}>
                                                <small>({dataInfo?.device_info?.title_model}, {dataInfo?.device_info?.title_brand}, {dataInfo?.device_info?.title_category})</small>
                                            </TableCell>
                                            <TableCell colSpan={2}
                                                       className={'fw-bold'}><small>{dataInfo?.device_info?.device_problem || ""}, {dataInfo?.device_info?.device_problem_other || ""} </small></TableCell>
                                            <TableCell className={'text-end fw-bold'}>
                                                {/* <span className="mb-5 ">dgfgf</span> <br/> */}
                                                <small>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.device_info?.amount_total || 0)}</small></TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}/>
                                            <TableCell colSpan={2} align="right"><b
                                                className={'text-muted'}>Subtotal</b></TableCell>
                                            {/*formatMoney*/}
                                            <TableCell
                                                align="right"
                                            ><b>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_price || 0)}</b></TableCell>
                                        </TableRow>
                                        {dataInfo?.discount_coupon_code && dataInfo?.discount_coupon_code > 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={2}/>
                                                <TableCell colSpan={2}
                                                           align="right">Discount <small><b>{dataInfo?.discount_coupon_code}({dataInfo?.discount_coupon_code_rate || 0}%)</b></small></TableCell>
                                                <TableCell className={'text-success'}
                                                           align="right">- {dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_discount_coupon || 0)} </TableCell>
                                            </TableRow>
                                        ) : ""}
                                        {dataInfo?.amount_delivery_charge && dataInfo?.amount_delivery_charge > 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={2}/>
                                                <TableCell colSpan={2} align="right">Delivery Charge</TableCell>

                                                <TableCell className={'text-danger'}
                                                           align="right">+ {dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_delivery_charge || 0)} </TableCell>
                                            </TableRow>
                                        ) : ""}

                                        {/*<TableRow >
                                    <TableCell colSpan={4}/>
                                    <TableCell colSpan={3} align="right"><b className={'text-muted'}>Total</b></TableCell>
                                    <TableCell align="right"
                                               colSpan={2}><b>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_total || 0)}</b></TableCell>
                                </TableRow>*/}
                                        {dataInfo?.amount_paid > 0 ? <TableRow>
                                            <TableCell colSpan={2}/>
                                            <TableCell colSpan={2} align="right"><b className={'text-muted'}>Paid
                                                Amount</b></TableCell>
                                            <TableCell
                                                align="right"><b>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_paid || 0)}</b></TableCell>
                                        </TableRow> : ""}

                                        <TableRow>
                                            <TableCell colSpan={2}/>
                                            <TableCell colSpan={2} align="right"><b className={'text-muted'}>Payable
                                                Amount
                                                (Pending)</b></TableCell>
                                            <TableCell
                                                align="right"><b>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_pending || 0)}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </> : <>
                            <TableContainer component={Paper} variant="outlined">
                                <Table aria-label="demo table" size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            {/*<TableCell className={'text-muted'}>SN.</TableCell>*/}
                                            <TableCell colSpan={2} className={'text-muted'}>PART NAME</TableCell>
                                            {/*<TableCell className={'text-end text-muted'}>MRP</TableCell>*/}
                                            <TableCell className={'text-end text-muted'}>PRICE</TableCell>
                                            <TableCell className={'text-end text-muted'}>QTY</TableCell>
                                            <TableCell colSpan={2} className={'text-end text-muted'}>AMOUNT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataParts && dataParts.map((part, index) => {
                                            // partAmountTotal += part.amount_total ? part.amount_total : 0;
                                            return (
                                                <TableRow key={index}>
                                                    {/*<TableCell>{index + 1}</TableCell>*/}
                                                    <TableCell style={{minWidth: 240}} colSpan={2}
                                                               className={'text-muted'}>
                                                        <b><small>{part?.title_part || ""}</small></b> <br/>
                                                        <small>({part?.title_model}, {part?.title_brand}, {part?.title_category})</small>
                                                    </TableCell>
                                                    {/*<TableCell className={'text-end fw-bold'}><del><small>{dataInfo?.amount_currency_symbol || "RS."}{formatMoney(part?.part?.amount_mrp || 0)}</small></del></TableCell>*/}
                                                    <TableCell
                                                        className={'text-end fw-bold'}><small>{dataInfo?.amount_currency_symbol || "RS."}{formatMoney(part?.amount_price || 0)}</small></TableCell>
                                                    <TableCell
                                                        className={'text-end fw-bold'}><small>{part?.quantity_part || 1}</small></TableCell>
                                                    <TableCell className={'text-end fw-bold'} colSpan={2}>
                                                        {/* <span className="mb-5 ">dgfgf</span> <br/> */}
                                                        <small>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(part?.amount_total || 0)}</small></TableCell>

                                                </TableRow>
                                            )
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={2}/>
                                            <TableCell colSpan={2} align="right"><b
                                                className={'text-muted'}>Subtotal</b></TableCell>
                                            {/*formatMoney*/}
                                            <TableCell
                                                align="right"
                                                colSpan={2}><b>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_price || 0)}</b></TableCell>
                                        </TableRow>
                                        {dataInfo?.discount_coupon_code && dataInfo?.discount_coupon_code > 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={2}/>
                                                <TableCell colSpan={2}
                                                           align="right">Discount <small><b>{dataInfo?.discount_coupon_code}({dataInfo?.discount_coupon_code_rate || 0}%)</b></small></TableCell>
                                                <TableCell colSpan={2} className={'text-success'}
                                                           align="right">- {dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_discount_coupon || 0)} </TableCell>
                                            </TableRow>
                                        ) : ""}
                                        {dataInfo?.amount_delivery_charge && dataInfo?.amount_delivery_charge > 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={2}/>
                                                <TableCell colSpan={2} align="right">Delivery Charge</TableCell>

                                                <TableCell colSpan={2} className={'text-danger'}
                                                           align="right">+ {dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_delivery_charge || 0)} </TableCell>
                                            </TableRow>
                                        ) : ""}

                                        {/*<TableRow >
                                    <TableCell colSpan={4}/>
                                    <TableCell colSpan={3} align="right"><b className={'text-muted'}>Total</b></TableCell>
                                    <TableCell align="right"
                                               colSpan={2}><b>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_total || 0)}</b></TableCell>
                                </TableRow>*/}
                                        {dataInfo?.amount_paid > 0 ? <TableRow>
                                            <TableCell colSpan={2}/>
                                            <TableCell colSpan={2} align="right"><b className={'text-muted'}>Paid
                                                Amount</b></TableCell>
                                            <TableCell align="right"
                                                       colSpan={2}><b>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_paid || 0)}</b></TableCell>
                                        </TableRow> : ""}

                                        <TableRow>
                                            <TableCell colSpan={2}/>
                                            <TableCell colSpan={2} align="right"><b className={'text-muted'}>Payable
                                                Amount
                                                (Pending)</b></TableCell>
                                            <TableCell align="right"
                                                       colSpan={2}><b>{dataInfo?.amount_currency_symbol || "RS."} {formatMoney(dataInfo?.amount_pending || 0)}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>}

                    </Row>
                </div>
                <Row className={'my-5 print_footer'}>
                    <Col className={'text-center'}><small><b><i>*This document is system-generated invoice and it does
                        not require a signature.*</i></b></small></Col>
                </Row>
                <Row className={"d-print-none text-center"}>
                    <Col sm={4} md={2} className={"mx-auto"}>
                        <button className="sign-up-button mx-auto " onClick={() => window.print()}>
                            <FaDownload/>&nbsp; Download
                        </button>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Invoice;