import { ProblemPageBody } from "./ProblemPageBody";
import { ProblemPageDes } from "./ProblemPageDs";


export function ProblemPageForm({
    data, setData,
    token,
    setToken,
    handleBack,
    handeNext,
}) {
    // console.log(token)
    return (
        <div className="center">
            {/*<h2>What is the problem of the device ?</h2>*/}
            <h5>What exactly is the issue with the device?</h5>
            {/*<p>Please fill the following details about you product condition. i.e. problem, and images of your device.</p>*/}
            <p><small>Please provide the following information about your product's condition:</small></p>
            <ProblemPageBody data={data} />
            <ProblemPageDes setToken={setToken} handleBack={handleBack} token={token} handeNext={handeNext} data={data} setData={setData} />
        </div>
    );
}
