import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { get_data_by_url } from "../../api/general";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import { Button, Col, Row } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';

export default function IncludeAccordionByList({ tokenPart, idPart }) {
    const [faqs, setFaqs] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isMoreData, setIsMoreData] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);


    const getData = async () => {
        setLoading(true);
        setOffset(0)

        let bsIn = {
            limit: 10,
            offset: offset,
            ref_type: "part"
        }
        if (tokenPart) {
            bsIn.token_ref = tokenPart;
        }
        if (idPart) {
            bsIn.ref_id = idPart;
        }
        const response = await get_data_by_url("general/faq", bsIn); 
      

        if (response?.error || !response?.data) {

        } else {
            // if (Array.isArray(response?.data) >= 10) {
            //     setOffset(offset + 1);
            // }
            setFaqs(response?.data);

            if (response.data.length < 10) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }

        }
        setLoading(false);
    };


    useEffect(() => {
        getData()
    }, []);



    const nextData = async () => {
        setBtnLoading(true);

        let bsIn = {
            limit: 10,
            offset: offset,
            ref_type: "part"
        }
        if (tokenPart) {
            bsIn.token_ref = tokenPart;
        }
        if (idPart) {
            bsIn.ref_id = idPart;
        }
        const response = await get_data_by_url("general/faq", bsIn) 

        if (response?.error || !response?.data) {

        } else {
            setFaqs(response?.data);
            if (response.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
        }
        setLoading(false);
        setBtnLoading(false);
    }




    useEffect(() => {
        if (offset !== 0) {
            nextData();
        }
    }, [offset]);







    const handelInfiniteScroll = async () => {
        setOffset(offset + 1);
    };





    return (
        <div>
            {loading && <><SkeletonFileGeneral height={30} total={4} col={12} mx={0} /></>}

            {!loading && (faqs && Array.isArray(faqs) && faqs.length > 0 ? faqs.map((faq, index) => {

                return (<Accordion key={faq?.slug || faq?.token || index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"
                        id={"panel1a-" + index}>
                        <Typography><b>{index + 1}. {faq?.title}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {faq?.description}
                        </Typography>
                    </AccordionDetails>
                </Accordion>)
            }) : <>
                <b className={'text-danger'}>No Faq available for this product</b>
            </>)}

            <Row>
                <Col>
                    <div className="text-center">
                        { !faqs && Array.isArray(faqs) && faqs.length > 0 && isMoreData && (
                            <Button disabled={btnLoading} type="submit" variant=""
                                className="btn btn-primary3 my-3 "
                                onClick={handelInfiniteScroll}>{btnLoading &&
                                    <CircularProgress size={16} color={"inherit"} />}&nbsp; Load More</Button>
                        )}

                    </div>
                </Col>
            </Row>


        </div>
    );
}