import React, { useEffect, useState } from "react";
import {  book_service_step } from "../../api/booking";
import { CircularProgress } from "@mui/material";
import { useAuth, useNotification } from "../../hooks";
import { Col, Row } from "react-bootstrap";
import {  CForm, CFormInput } from "@coreui/react"; 
import { useNavigate } from "react-router-dom";

// import "./Need.css";
export function NeedBody({ data, setData, token, handleBack, handeNext }) {
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [list, setList] = useState([
        "09AM-11AM",
        "11AM-01PM",
        "01PM-03PM",
        "03PM-05PM",
        "05PM-07PM",
        "07PM-09PM",
    ]);

    const [dateMin, setDateMin] = useState(new Date().toISOString().split("T")[0]);
    const [dateMax, setDateMax] = useState(new Date());
    const addDays = () => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 7);
        setDateMax(newDate.toISOString().split("T")[0]);
    };

    useEffect(() => {
        addDays();
    }, []);
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Next')
    const { showError, showSuccess } = useNotification()
    const { authInfo } = useAuth()
    const [loading, setLoading] = useState(false)

    const dateChange = (e) => setDate(e.target.value);

    const handleSelect = (element) => setTime(element);


    useEffect(() => {
        if (data) {
            if (data?.expected_visit_date) {
                setDate(data?.expected_visit_date)
            }
            if (data?.expected_visit_time) {
                setDate(data?.expected_visit_time)
            }
            setCurrentTime(data?.expected_visit_date || "", data?.expected_visit_time || "");
        }
    }, [data])


    const setCurrentTime = (visitDate, visitTime) => {
        const dateTemp = new Date();
        const currTimeHour = dateTemp.getHours();
        const currTimeMinute = dateTemp.getMinutes();
        if (visitDate) {
            setDate(visitDate);
        } else {
            setDate(dateMin);
        }
        // console.log(visitDate, visitTime);
        if (visitTime) {
            setTime(visitTime)
        } else {
            // if ( currTimeMinute >= 0 && currTimeMinute <= 9&&currTimeHour <= 9) {
            //     setTime("09AM - 11AM")
            // } else if (currTimeMinute >= 9 &&currTimeMinute <= 11&&currTimeHour <= 11) {
            //     setTime("11AM - 01PM")
            // } else if (currTimeMinute >= 11 &&currTimeMinute <= 13&&currTimeHour <= 9) {
            //     setTime("01PM - 03PM")
            // } else if (currTimeMinute >= 13 &&currTimeMinute <= 17) {
            //     setTime("05PM - 07PM")
            // } else if (currTimeMinute >= 17 &&currTimeMinute <= 19) {
            //     setTime("07PM - 09PM")
            // } 
            if (currTimeHour < 9) {
                setTime("09AM-11AM")
            } else if (currTimeHour < 11) {
                setTime("11AM-01PM")
            } else if (currTimeHour < 13) {
                setTime("01PM-03PM")
            } else if (currTimeHour < 15) {
                setTime("03PM-05PM")
            } else if (currTimeHour < 17) {
                setTime("05PM-07PM")
            } else if (currTimeHour < 19) {
                setTime("07PM-09PM")
            } else {
                if (visitDate) {
                    setDate(visitDate);
                } else {
                    dateTemp.setDate(dateTemp.getDate() + 1);
                    setDate(dateTemp.toISOString().split("T")[0]);
                }
                setTime("09AM-11AM")
            }
        }


    }

    const handleSubmit = async (event) => {
        setButtonName("loading...");
        setLoading(true);
        event.preventDefault();
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Next");
            setLoading(false);

            event.stopPropagation();

        } else {
            const sendData =
            {
                step: "visit-schedule",
                token_order: token,
                login_user_id: authInfo.profile.id,
                login_user_type: authInfo.profile.user_type,
                expected_visit_date: date,
                expected_visit_time: time,
            }
            // console.log(sendData);
            const { data, error } = await book_service_step({ details: sendData, isImage: false });
            if (error || data.error) {
                setButtonName("Next");
                setLoading(false);

                return showError(data.message);
            }
            // setVisitDate(data?.data?.expected_visit_date);
            // setVisitTime(data?.data?.expected_visit_time);
            setData(data?.data)
            navigate("/book-repair?token=" + data?.data?.token + "&step=" + data?.data?.step)
            // showSuccess(data?.data?.message||"Detail sucessfully submitted");
            setButtonName("Next");
            setLoading(false);
            handeNext();

            // console.log(data);
        }
    };

    return (
        <>
            <div className="dateContainer">
                <CForm className="needs-validation"
                    noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className={"g-4 "}>
                        <Col className={'mx-auto '} md={6}>
                            <CFormInput type="date" onChange={dateChange}
                            className="book-date"
                                min={dateMin} max={dateMax}
                                value={date}
                                placeholder="Select date which engineer visit" />
                        </Col>
                        <Col md={12}>
                            <div className="selectionDate">
                                {list.map((element, idx) => {
                                    // console.log(element);
                                    return (
                                        <button key={idx} onClick={() => handleSelect(element)} value={element}
                                            type={'button'}
                                            className={`Selectiondatebtn ${time === element ? "Selectiondatebtn1" : ""}`}>
                                            {element}
                                        </button>
                                    );
                                })
                                }
                            </div>

                        </Col>
                    </Row>
                    <Row className={"my-4"}>
                        <Col>
                            <button className="button-2 float-left"
                                onClick={handleBack}>{"Previous"}</button>
                        </Col>
                        <Col>
                            <button disabled={loading}
                                className={"sign-up-button float-end " + (loading && 'disabled-button')}
                                type={'submit'}>{loading &&
                                    <CircularProgress size={16} color={"inherit"} />}&nbsp; {btnName}</button>
                        </Col>
                    </Row>

                    {/*<button className="sign-up-button mx-auto mt-5" type={"submit"}>{btnName}</button>*/}
                </CForm>
            </div>
        </>
    );
}
