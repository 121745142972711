import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ShopCard from "../PartPurchase/ShopCard";
import {checkIfImageExists} from "../../hooks/useCustomHelpingMethod";
import banner from "../asset/banner.webp";

import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import {A11y, Autoplay, Navigation, Pagination} from "swiper/modules";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import SkeletonFile from "../OurServices/SkeletonFile";
import {showAdBanners} from "../../api/client";


export default function ProductPartFeatured({ data, title, loading, setLoading, type ,index}) {
    const [countSlide, setCountSlide] = useState(showAdBanners ? 4 : 6);
    const [parts, setParts] = useState([]);
    const [loadingFea, setLoadingFea] = useState(true);
    useEffect(() => {
        setLoading(true);
        setLoadingFea(true);
        setParts(data || []);
        setLoading(false);
        setLoadingFea(false);
    }, [data])
    useEffect(() => {
        // console.log(parts);
        updateWidthAndHeight();
    })
    const updateWidthAndHeight = () => {
        if (window.innerWidth >= 1000) {
            setCountSlide(type === 'ad' && showAdBanners ? 4 : 6);
        } else if (window.innerWidth >= 800) {
            setCountSlide(type === 'ad' && showAdBanners ? 2 : 4);
        } else if (window.innerWidth >= 642) {
            setCountSlide(type === 'ad' && showAdBanners ? 2 : 3);
        } else if (window.innerWidth >= 400) {
            setCountSlide(type === 'ad' && showAdBanners ? 2 : 2);
        } else {
            setCountSlide(2);
        }
    };
    window.addEventListener("resize", updateWidthAndHeight);

    return (
        <>
            {(loading || loadingFea) && <div  >
                <h1 className={`buy-parts-div text-dark my-5`}>
                    <SkeletonFileGeneral height={20} total={1} col={12} mx={'auto'} />
                </h1>
                <Row className="ms-0 me-0 p-0 gx-0">
                    <SkeletonFile total={4} />
                    {/*<SkeletonFileGeneral height={140} total={5} col={2} mx={'auto'}/>*/}
                </Row>
            </div>}
            {(!loading && !loadingFea) && parts && Array.isArray(parts) && parts.length > 0 && (
                <>
                    <section key={index} className={"my-5 px-2 px-md-5"}>
                        <h3 className={`text-dark`}>{title || "Featured Products"}</h3>

                        <Row  className={"my-2 g-3"} md={{ cols: 1 }}>
                            <Col>
                                <Swiper
                                    // navigation={{
                                    //     prevEl: '.prev',
                                    //     nextEl: '.next',
                                    // }}
                                    loop={true}
                                    modules={[Pagination, Navigation, A11y, Autoplay]}
                                    // pagination={{clickable: true}}
                                    navigation={true}
                                    // scrollbar={{ draggable: true }}
                                    // autoplay={{
                                    //     delay: 2500,
                                    //     disableOnInteraction: false,
                                    // }}
                                    spaceBetween={30}
                                    slidesPerView={countSlide}
                                    className="mySwiper "
                                >
                                    {parts.map((element, index) => {
                                        return (
                                            <SwiperSlide key={index} className={"py-2"}>
                                                <ShopCard part={element}
                                                    image={element.url_image && checkIfImageExists(element.url_image) ? element.url_image : banner}
                                                    price={element.amount_price_customer} title={element.title}
                                                    slug={element.slug} />
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </Col>
                        </Row>

                    </section>
                </>
            )}
        </>
    );
}