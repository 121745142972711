import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Alert,
    AlertTitle,
    CircularProgress,
    Fab,
    InputAdornment,
    Rating,
    TextareaAutosize,
    Typography
} from "@mui/material";
import {FaTimes} from "react-icons/fa";
import {booking_cancel} from "../../../api/booking";
import {useState} from "react";
import {useAuth, useNotification} from "../../../hooks";
import {Card, Col, Container, Form, FormControl, FormLabel, Row} from "react-bootstrap";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {ApiCall} from '../../../api/HelpingMethod';

export default function ModelPartReport({part, tokenOrder, setReloadData}) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = useState(0);
    const {authInfo} = useAuth();
    const {setShowLoginModel} = useNotification();
    const [loading, setLoading] = useState(false)

    const [reportBody, setReportBody] = useState({
        "login_user_id": authInfo?.profile.id,
        "login_user_type": authInfo?.profile.user_type,
        "message": "",
        "token_ref": part?.token,
        "ref_type": "part"
    })

    // console.log("part-------.,,,", part)


    const handleClickOpen = () => {
        if (!authInfo?.profile?.id) {
            setShowLoginModel(true)
        } else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {showError, showSuccess} = useNotification();
    const [btnCancel, setBtnCancel] = useState('Report')
    const [remark, setRemark] = useState('')
    const [messageError, setMessageError] = useState('')

    // console.log("parttttt---", authInfo)

    const handleChange = (e) => {
        const {name, value} = e.target;
        setReportBody({...reportBody, [name]: value});
    };


    const handleReport = async (event) => {
        setLoading(true);

        setBtnCancel("loading...");
        const response = await ApiCall("dealer/product_report_update", reportBody)
        // console.log("response---->>", response);

        setBtnCancel('Report')

        if (response?.error === true) {
            setMessageError(response?.message);
            return showError(response?.message);
        } else {
            setMessageError(response?.message);
            showSuccess(response?.message);
        }
        setLoading(false);

        handleClose();
    };


    return (
        <div className={'mb-2'}>

            {/* {
                authInfo?.profile.id ?
                    <button className="sign-up-button mx-auto" onClick={handleClickOpen} >Report </button>
                    : <button className="sign-up-button mx-auto" onClick={() => setShowLoginModel(true)} >Report </button>
            } */}
            {/* <Button variant={'contained'} color={'warning'} type={"button"} className='mx-auto' onClick={handleClickOpen}>
                Report this part
            </Button> */}
            {/*<b><small>Having any problem with this part? <span onClick={handleClickOpen} className="color-primary" style={{ cursor: "pointer" }}>click here to report</span></small></b>*/}
            <b><small>Wrong part? <span onClick={handleClickOpen} className="color-primary" style={{cursor: "pointer"}}>click here to report</span></small></b>
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle>Report Part</DialogTitle>
                <DialogContent>
                    <DialogContentText className={'mb-3'}>

                        <Container>
                            <Row><Col className='text-dark'>{part?.title}</Col></Row>
                            <Row className=" mt-2">
                                <Col className=''>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl value={authInfo?.profile.name}/>
                                </Col>

                                <Col className=''>
                                    <FormLabel>Mobile</FormLabel>
                                    <FormControl value={authInfo?.profile.mobile}/>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Add Report</Form.Label>
                                        <Form.Control onChange={handleChange} as="textarea"
                                                      value={reportBody?.message} name='message' rows={3}/>
                                    </Form.Group>
                                </Form>
                            </Row>

                        </Container>


                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'inherit'}>Cancel</Button>
                    <Button disabled={loading} variant={'contained'} color={'warning'} className={'sign-up-button'}
                            onClick={handleReport}>{loading &&
                    <CircularProgress size={16} color={"inherit"}/>}&nbsp; {btnCancel}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}