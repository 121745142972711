import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { styled, Tooltip, tooltipClasses, Zoom } from "@mui/material";
import { BsFillPencilFill } from 'react-icons/bs';
import { useAuth, useNotification } from '../../../hooks';
import { get_data_by_url } from '../../../api/general';
import { GoogleMapLocation } from '../../GoogleMapLocationPick/GoogleMapLocation';
import { InputField } from '../../InputFields/InputFieldHelper';

export default function ModalChangeAddress({pickLocation, setPicLocation, setAddressData }) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [showLocationPicker, setShowLocationPicker] = useState(true);
    const [picLocationName, setPicLocationName] = useState('Pick')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [messageError, setMessageError] = useState('')
    const stateAddress = {};
    const [address, setAddress] = useState({
        pincode: "",
        name_state: "",
        name_city: "",
        address: "",
        device_latitude: 0.00,
        device_longitude: 0.00,

    });







    return (
        <div>

            <Row className="colorprimary text-end text-decoration-underline pointer" onClick={handleClickOpen}>
                <Col>{pickLocation ? "Change" : "Pick"} Address</Col>
            </Row>

            <Dialog open={open} onClose={handleClose}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                // scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">
                <DialogTitle>Add New Address
                    {!showLocationPicker && <Button variant={'contained'} color={'warning'}
                        className={'sign-up-button m-1 float-end'}
                        onClick={() => setShowLocationPicker(true)}>Pick Location</Button>}
                </DialogTitle>
                <DialogContent>
                    {
                        // <AddressPickLocation stateAddress={stateAddress} address={address} setAddress={setAddress}/>
                        <GoogleMapLocation addressMain={address} setAddressMain={setAddressData} isUpdate={false} />
                        // <ReactGoogleMapPicker addressMain={address} setAddressMain={setAddress}/>

                    }
                    <p>
                        <small className={'text-danger capitalize'}>{messageError}</small>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'inherit'}>Cancel</Button>
                    <Button variant={'outlined'} color={'warning'} className={'sign-up-button'}
                        onClick={() => {
                            handleClose();
                            // console.log(stateAddress);
                            setAddress({ ...address, ...stateAddress })
                            setPicLocationName("Change")
                            setPicLocation(true)
                        }}>Confirm Location Or Next</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}