import useRazorpay from "react-razorpay";
import {appAddress, appTitle} from "../../api/constant";
import logo from "../asset/logo.webp"
import React, {useEffect, useState} from "react";
import {get_data_by_url} from "../../api/general";
import {useNotification} from "../../hooks";
import {isAppPaymentOn} from "../../api/client";
import {CircularProgress} from "@mui/material";

export default function IntegrationRazorPay({
                                                addressId,
                                                locationInfo,
                                                setNotAvOpen,
                                                setPayment,
                                                handelCheckout,
                                                paymentMode,
                                                order,
                                                user,
                                                amount_total,
                                                amount_currency,
                                                loadingMain
                                            }) {
    const [Razorpay] = useRazorpay();
    const {showError, showSuccess} = useNotification();
    const [address, setAddress] = useState(locationInfo);
    const [loading, setLoading] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)

    // const RazorpayInst = require('razorpay');

    // const create_order_payment = async () => {
    //     var instance = new Razorpay({
    //         key_id: 'YOUR_KEY_ID',
    //         key_secret: 'YOUR_KEY_SECRET',
    //     });
    //     const order = await instance.create_order_payment(params);
    //
    // }
    useEffect(() => {
        setAddress(locationInfo);
    }, [locationInfo])

    const capture_payment = async (paymentResponse) => {

        const inputData = {
            login_user_id: user?.id || "",
            login_user_type: user?.user_type || "",
            address_id: addressId,
            amount: amount_total,
            amount_currency: amount_currency,
            txn_order_id: paymentResponse?.razorpay_order_id ?? paymentResponse?.razorpay_payment_id ?? "",
            txn_ref: paymentResponse?.razorpay_payment_id,
            razorpay_order_id: paymentResponse?.razorpay_order_id ?? paymentResponse?.razorpay_payment_id ?? "",
            razorpay_payment_id: paymentResponse?.razorpay_payment_id??"",
            razorpay_payment_signature: paymentResponse?.razorpay_signature??"",
            remark: "",
            payment_source: paymentMode,
        }
        var response = await get_data_by_url("dealer/payment-update", inputData);

        if (response?.data) {
            await setPayment(response?.data);
        }
        // console.log(response);
        return response?.data;
    }

    const loadRazorpayScript = () => {
        const script = document.createElement("script");
        script.src = `https://checkout.razorpay.com/v1/checkout.js`;
        document.body.appendChild(script);
    };

    const handlePayment = async (params) => {
        setLoadingBtn(true);
        // console.log(addressId);
        // return;
        // if (!addressId) {
        //     showError("Please Select an address to continue");
        //     return
        // }
        // if (!address?.address) {
        //     showError("Please enter address details");
        //     return
        // }
        // if (!address?.name) {
        //     showError("Please enter name");
        //     return
        // }
        // if (!address?.mobile) {
        //     showError("Please enter mobile");
        //     return
        // }
        // if (!address?.name_state) {
        //     showError("Please enter state name");
        //     return
        // }
        // if (!address?.name_city) {
        //     showError("Please enter city name");
        //     return
        // }
        // if (!address?.pincode) {
        //     showError("Please enter pincode");
        //     return
        // }
        // console.log("",isAppTempDown,isAppPaymentOn);
        // if (isAppTempDown) {
        //     setNotAvOpen(true);
        //     return;
        // }

        if (paymentMode === 'cod') {
            handelCheckout(null);
        } else if (paymentMode === 'wallet') {
            handelCheckout(null, "wallet");
        }
        else if (paymentMode === 'razorpay') {
            if (!isAppPaymentOn) {
                setNotAvOpen(true);
                setLoadingBtn(false)
                return;
            }

            loadRazorpayScript();
            // setLoading(false);

            // const order = await createOrder(params); //  Create order on your backend

            const amount = amount_total * 100; // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            const currency = amount_currency || "INR"
            const name = appTitle || "iService"
            const description = "Payment For " + (order?.title || "1234") + "";
            // const logoApp = appFooterLogo || logo || ""
            const logoApp = logo || ""

            const options = {
                key: "rzp_live_JiqQPEKp9QCeKq", // Enter the Key ID generated from the Dashboard iservice
                // key: "rzp_live_E0P5QfFU2Fkc3h", // Enter the Key ID generated from the Dashboard aimerse
                // amount: amount || 0,
                amount: amount,
                currency: currency || "INR",
                name: name,
                description: description || "Test Transaction",
                image: logoApp,
                // order_id: "", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
                handler: async function (response) {
                    // console.log(response);
                    // alert(response.razorpay_payment_id);
                    // alert(response.razorpay_order_id);
                    // alert(response.razorpay_signature);
                    const payment = await capture_payment(response);
                    if (payment) {
                        handelCheckout(payment);
                    }
                },
                prefill: {
                    name: user?.name || " ",
                    email: user?.email || " ",
                    contact: user?.mobile || " ",
                },
                notes: {
                    address: appAddress || "",
                },
                theme: {
                    // color: "#3399cc",
                    color: "#ef5d00",
                    width: "500px !important",

                },
                // width: "500px",

            };

            const rzp1 = new Razorpay(options);
            // setLoading(false);

            rzp1.on("payment.failed", function (response) {
                // console.log(response);
                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
                showError("Payment failed, Try again");
                return {
                    status: "fail",
                    data: response
                }
            });
            setLoadingBtn(false)
            rzp1.open();

        }
        else {
            showError("Please select payment method");
        }
        setLoading(false);

    };
    return (
        <button disabled={loadingBtn} onClick={handlePayment}
                className={"payment-button text-center my-4 " + (loadingBtn && 'disabled-button')}>
            {loadingBtn && <CircularProgress size={16} color={"inherit"}/>}&nbsp; Proceed to Payment
        </button>
        // <div className="App">
        //     <button onClick={handlePayment}>Payment</button>
        // </div>
    );
}