import {Link} from "@mui/material";
import {useLocation} from "react-router-dom";
import "../breadcrumbs.css"

function Breadcrumbs({menu}) {
    const location = useLocation();

    return (
        <nav>
            <Link to="/" className={(location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active")+" color-primary"}>
                <b>Home</b>
            </Link>
            <span className="breadcrumb-arrow">&gt;</span>
            <Link to={menu?.link}
                  className={location.pathname.startsWith(menu?.link) ? "breadcrumb-active" : "breadcrumb-not-active"}>
                <b>{menu?.title}</b>
            </Link>
        </nav>
    );
}

export default Breadcrumbs;