// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//testing
/*const firebaseConfig = {
    apiKey: "AIzaSyDGRAIML6GJNBwq2Q_1nf8FVlpZ33M1mkY",
    authDomain: "test-notification-30b40.firebaseapp.com",
    projectId: "test-notification-30b40",
    storageBucket: "test-notification-30b40.appspot.com",
    messagingSenderId: "585019389369",
    appId: "1:585019389369:web:df6c9e40d8ce682dfc7268",
    measurementId: "G-5Z1EB0PHSX"
};*/

const firebaseConfig = {
    apiKey: "AIzaSyAgFdIn8znbr3E5FJWfGuCU0UZPPHwJfPs",
    authDomain: "iservice-1aa7e.firebaseapp.com",
    databaseURL: "https://iservice-1aa7e.firebaseio.com",
    projectId: "iservice-1aa7e",
    storageBucket: "iservice-1aa7e.appspot.com",
    messagingSenderId: "493350624534",
    appId: "1:493350624534:web:5465ff6a5d5d0e5cb6c437",
    measurementId: "G-ZQY586N4W1"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

// console.log(app,firebaseConfig);

export const getDeviceToken = (setTokenFound) => {
    // console.log("get device token",messaging);
    return getToken(messaging, {
        //iservice
        vapidKey: 'BB7b5TmXv71-4mqyjeW2JDSezinEAYiGzTcCpiisTD6hRxaP80B1BOL3yFYhilbC_w5iDn4t24TQivP-5LOna30',
        //testing
        // vapidKey: 'BMCHzyr8iQkyYC5Tm5xxeuLM48xEy6KTcA3XLfDL0V3itqciFUcKvDdN2ip7BnPQGn58B03IxnBxLJ-NYMffvYk'
    }).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            localStorage.setItem('device_token', currentToken);
            setTokenFound(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
             console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
         console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });