import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function SkeletonFile({height=200,total=8}) {
  return (
    <>
      {Array(total)
        .fill()
        .map((item, index) => {
          return (
            <div key={"skl-"+index} className="col-sm-3 card-group mb-4">
              <div className="card">
                <Skeleton height={height}  />
              </div>
            </div>
          );
        })}
    </>
  );
}
