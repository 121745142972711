import React, {createContext, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {registerUser, reset_password, sendOtp, signInUser, user_logout, verify,} from "../api/auth";
import {useNotification} from "../hooks";

export const AuthContext = createContext();
const info = JSON.parse(localStorage.getItem("user-info"));
const defaultAuthInfo = {
    profile: {...info},
    isLoggedIn: false,
    isPending: false,
    error: false,
};

export default function AuthProvider({ children }) {
    const [authInfo, setAuthInfo] = useState({ ...defaultAuthInfo });
    const [verified, setVerified] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { showError, showSuccess } = useNotification();

    const handleLoginWithPassword = async (details, loginNavigate) => {
        const { error, data, message } = await signInUser({ ...details });
        if (error) {
            showError(message);
            return;
        }

        if (data) {
            if (data.status !== 'active') {
                showError("User is not activated or verification pending. Please contact admin to verify you account.");
                // if (localStorage.getItem("prevLocation") && localStorage.getItem("prevLocation") !== location?.state + "" + location?.search) {
                navigate("/");
                return;
            }
            if (data.user_type !== 'client' && data.user_type !== 'dealer') {
                showError("You are not register with us as dealer or client. Only Dealer or Client can login this website.");
                // showError("User with given number is not authorize to login. Only Dealer or Client can assess this website.");
                navigate("/");
                return;
            }
        }

        //console.log(data);
        setAuthInfo({
            profile: { ...data },
            isLoggedIn: true,
            isPending: false,
            error: "",
        });



        // console.log("logininfo------", data)

        localStorage.setItem("login_user_token", data.token);
        localStorage.setItem("login_user_type", data.user_type);
        localStorage.setItem("user-info", JSON.stringify(data));
        localStorage.setItem("image", data?.url_profile);
        //console.log(localStorage);
      //  showSuccess("Login Successfully");
        // if (localStorage.getItem("prevLocation") && localStorage.getItem("prevLocation") !== location?.state + "" + location?.search) 
        //  if (loginNavigate && loginNavigate !== location?.pathname + "" + location?.search) {
        //     // navigate(localStorage.getItem("prevLocation") + "hhhhhhhhh");
        //     navigate(loginNavigate);

        // } else {
        //     navigate("/");
        // }
        if (localStorage.getItem('prevLocation')) navigate(localStorage.getItem('prevLocation')); else navigate("/");

    }

    const setSessionData = async (data) => {
        //console.log(data);
        setAuthInfo({
            profile: { ...data },
            isLoggedIn: true,
            isPending: false,
            error: "",
        });


        localStorage.setItem("user-name", data.name);
        localStorage.setItem("email", data.email);
        localStorage.setItem("mobile", data.mobile);
        localStorage.setItem("user-info", JSON.stringify(data));
        localStorage.setItem("image", data?.url_profile);
        //console.log(localStorage);
    };

    const resetpassword = async (details) => {
        const { error, data, message } = await reset_password({ ...details });
        if (error) {
            showError(message);
            return;
        }
        // // console.log(message);
        navigate("/auth");
        showSuccess("Password Reset Successful");
    };

    const createUser = async (details, uploadImage) => {
        const { error, data, message } = await registerUser({ ...details, uploadImage: uploadImage || false });
        if (error) {
            showError(message);
            return { error, data, message };
        }
        // console.log("data");
        // console.log(data);
        if (data.step === 'completed') {
            if (data.status === 'active') {
                showSuccess("Registration Complete");
                localStorage.setItem("user-name", data.name);
                localStorage.setItem("email", data.email);
                setAuthInfo({
                    profile: { ...data },
                    isLoggedIn: true,
                    isPending: false,
                    error: "",
                });
                localStorage.setItem("user-info", JSON.stringify(data));
            } else {
                showSuccess("Registration completed successfully. Please wait until our admin verify you.");
            }
            navigate(localStorage.getItem("prevLocation"), {
                replace: true,
            });

        } else {

            showSuccess(message);
        }
        // console.log("step");
        // console.log(data);
        // if (setRegUserToken) {
        //     setRegUserToken(data.token);
        // }
        return { error, data, message }
    };

    const verifyUser = async (details, reason) => {
        const { error, data, message } = await verify({ ...details });
        // // console.log(data)
        if (error) {
            setAuthInfo({ ...authInfo, isPending: false, error: true });
            return { notVerified: true, data };
        }
        /*if (data) {
            if (data.status !== 'active') {
                showError("User is not activated or verification pending. Please contact admin to verify you account.");
                return {notVerified: false, data};
            }
            if (data.user_type !== 'client' && data.user_type !== 'dealer') {
                showError("You are not register with us as dealer or client. Only Dealer or Client can login this website.");
                return {notVerified: false, data};
            }
        }*/
        if (reason === "verification-for-password-reset") {
            return { notVerified: false, data };
        }

        if (data?.step) {
            if (data?.step !== "completed" && data?.status === "incomplete") {
                return { notVerified: true, data };
            }
        }

        showSuccess("Please login to proceed further");
        localStorage.setItem("is_verified", "true");
        setVerified(true);
        navigate("/signin", {
            state: { username: details.username }, email: details.email, mobile: details.mobile,
            replace: true,
        });
        // setTimeout(() => {

        // }, 2000);

        // console.log(data);
        return { notVerified: false, data };
    };

    const checkUser = async (details) => {
        const { error, data, message } = await verify({ ...details });
        return { error, data, message };
    };

    const userLogout = async (details) => {
        const { error, data, message } = await user_logout({ ...details });
        if (error) {
            localStorage.clear();
            setAuthInfo({ ...defaultAuthInfo });
            return showError(message || "Invalid User");
        }
        showSuccess(message);
        localStorage.clear();
        setAuthInfo({ ...defaultAuthInfo });
    };

    const sendUserOtp = async (details, reason) => {
        const { error, data, message, user } = await sendOtp({ ...details });
        if (error) {
            showError(message);
            return { sendStatus: false, data };
        }
        // console.log(data);
        // if (user) {
        //     console.log(user);
        // }
        if (details.otp) {
            showSuccess("OTP Verification Successful");
        } else {
            showSuccess(message);
        }
        // console.log("Details")
        // console.log(details)

        if (reason) {
            if (reason === "verification-before-registration") {
                navigate("/signup", {
                    state: { email: details.email || "", mobile: details.mobile || "", username: details.username || "" },
                    replace: true,
                });
                localStorage.setItem("is_verified", "true");
            } else if (reason === "login-by-otp") {
                const new_user = data.user;
                if (new_user) {
                    if (new_user.status !== 'active') {
                        showError("User is not activated or verification pending. Please contact admin to verify you account.");
                        // if (localStorage.getItem("prevLocation") && localStorage.getItem("prevLocation") !== location?.state + "" + location?.search) {
                        //     navigate(localStorage.getItem("prevLocation"));
                        // } else {
                        navigate("/");
                        // }
                        return;
                    }
                    if (new_user.user_type !== 'client' && new_user.user_type !== 'dealer') {
                        showError("You are not register with us as dealer or client. Only Dealer or Client can login this website.");
                        // showError("User with given number is not authorize to login. Only Dealer or Client can assess this website.");
                        navigate("/");
                        return;
                    }
                }

                // navigate("/", {
                //     state: {email: details.email, mobile: details.mobile, username: details.username},
                //     replace: true,
                // });
                if (localStorage.getItem("prevLocation") && localStorage.getItem("prevLocation") !== location?.state + "" + location?.search) {
                    navigate(localStorage.getItem("prevLocation"), {
                        state: { email: details.email, mobile: details.mobile, username: details.username },
                        replace: true,
                    });
                } else {
                    navigate("/", {
                        state: { email: details.email, mobile: details.mobile, username: details.username },
                        replace: true,
                    });
                }


                setAuthInfo({
                    profile: { ...new_user },
                    isLoggedIn: true,
                    isPending: false,
                    error: "",
                });
                localStorage.setItem("image", data?.user?.url_profile);
                localStorage.setItem("user-name", data?.user?.name);
                localStorage.setItem("email", data?.user?.email);
                localStorage.setItem("user-info", JSON.stringify(data?.user));
            } else if (reason === "verification-for-reset-password") {
                navigate("/reset-password", {
                    state: { id: data.user.id, email: details.email, mobile: details.mobile, username: details.username },
                    replace: true,
                });
            }
        }
        return { sendStatus: true, data };
    };

    //   const handleLogout = () => {
    //     localStorage.removeItem("auth-token");
    //     setAuthInfo({ ...defaultAuthInfo });
    //   };

    //  handleLogout
    return (
        <AuthContext.Provider
            value={{
                handleLoginWithPassword,
                createUser,
                sendUserOtp,
                verifyUser,
                userLogout,
                resetpassword,
                authInfo,
                checkUser,
                setSessionData
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
