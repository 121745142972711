import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import { mapApiKey } from "../../api/constant";
import { getAddressFromLatLong } from "../LocationPicker/GeocodeFuction";
import { CircularProgress } from "@mui/material";

export function GoogleMapLocation({ addressMain, setAddressMain, isUpdate }) {
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState({ lat: 23.23336930850487, lng: 77.43426308496514 });
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [address, setAddress] = useState({});
    useEffect(() => { 

        if (isUpdate) {
            setCenter({ lat: addressMain?.device_latitude||center?.lat, lng: addressMain?.device_longitude||center?.lng });
            setSelectedLocation({ lat: addressMain?.device_latitude||center?.lat, lng: addressMain?.device_longitude||center?.lng });
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCenter({ lat: position.coords.latitude||center?.lat, lng: position.coords.longitude||center?.lng });
                    setSelectedLocation({ lat: position.coords.latitude||center?.lat, lng: position.coords.longitude||center?.lng });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        }





    }, []);

    const onLoad = (map) => {
        setMap(map);
    };

    const onUnmount = () => {
        setMap(null);
    };
    const handleMapClick = (event) => {
        setSelectedLocation({
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        });
    };
    useEffect(() => {
        if (selectedLocation?.lat && selectedLocation?.lng) getAddress().then(r => r);
    }, [selectedLocation]);

    const getAddress = async () => {
        await getAddressFromLatLong(selectedLocation?.lat, selectedLocation?.lng).then(r => {
            // console.log(r);
            setAddress({
                ...address,
                pincode: r?.pin_code || "",
                address: r?.address || "",
                name_city: r?.city || "",
                name_state: r?.state || "",
                device_latitude: selectedLocation?.lat || "",
                device_longitude: selectedLocation?.lng || "",
            });
            setAddressMain({
                ...addressMain,
                pincode: r?.pin_code || "",
                address: r?.address || "",
                name_city: r?.city || "",
                name_state: r?.state || "",
                device_latitude: selectedLocation?.lat || "",
                device_longitude: selectedLocation?.lng || "",
            });

        });
    }
    return (
        <LoadScript googleMapsApiKey={mapApiKey}
            loadingElement={<h1 className={'text-center'}><CircularProgress size={30} color={"inherit"} /></h1>}>
            <GoogleMap
                mapContainerStyle={{ height: "80vh", width: "100%" }}
                center={center}
                onClick={handleMapClick}
                zoom={15}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                {/*<Marker position={center}/>*/}
                {selectedLocation && <Marker position={selectedLocation} />}
                {/*{selectedLocation && (*/}
                {/*    <AdvancedMarkerElement position={selectedLocation}/>*/}
                {/*)}*/}
            </GoogleMap>
        </LoadScript>
    );
}