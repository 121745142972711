import {React, createContext, useEffect, useState} from "react";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Card, Col, Container, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {BsExclamationCircle, BsEyeFill, BsEyeSlashFill, BsXLg} from "react-icons/bs";
import {Link, useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import {Backdrop, CircularProgress} from "@mui/material";
import {MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog} from "mdb-react-ui-kit";
import {ApiCall} from "../api/HelpingMethod";
import {BsPencilSquare} from "react-icons/bs";
import {useNotification} from "../hooks";
import logo from "../components/asset/logo.png"
import {isAppOTP} from "../api/client";

const LoginModal = () => {

    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);

    const [passwordType, setPasswordType] = useState('password')
    const navigate = useNavigate();
    const [sendOtp, setSendOtp] = useState(false)

    const {showError, showSuccess, setShowLoginModel, setOpenBackDrop} = useNotification();
    const [resendOtp, setResendOtp] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [LoginStep, setLoginStep] = useState("verify_user_by_otp")
    const [isOtp, setIsOtp] = useState(false)
    const [otpButton, setOtpButton] = useState('Get Otp')
    const [otpInput, setOtpInput] = useState(false)

    const [bodydata, setBodydata] = useState({
        device_token: localStorage.getItem('device_token') || "ABCDEFGHIJK",
        username: "",
        email: "",
        mobile: ""
    })

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };
    const toggleOpen = () => {
        setShowLoginModel(false)
        setLoginStep('verify_user_by_otp')
        setSendOtp(false)
        setIsOtp(false)
        bodydata.mobile = ''
        bodydata.username = ''
        bodydata.email = ''
        delete bodydata.otp
    };

    const handleChange = (e) => {

        const {name, value} = e.target
        setBodydata({...bodydata, [name]: value})
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        // setSeconds(null)
        // setMinutes(null)


        e.preventDefault()
        if (bodydata.password) {
            bodydata.password = bodydata.password
        }
        if (LoginStep === 'verify_user_by_otp') {
            bodydata.operation = 'verify'
            bodydata.for = "verify"
        }


        const response = await ApiCall("dealer/" + LoginStep, bodydata, true);


        if (response.error === false && response.data?.is_user_available === 'no' && LoginStep === 'verify_user_by_otp') {
            // bodydata.otp = response?.data?.otp
            if (response?.data?.type === 'mobile') {
                localStorage.setItem("user-register", JSON.stringify({
                    mobile: bodydata.username,
                    email: "",
                    username: bodydata.username || ""
                }));
            } else if (response?.data?.type === 'email') {
                localStorage.setItem("user-register", JSON.stringify({
                    mobile: "",
                    email: bodydata.username,
                    username: bodydata.username || ""
                }));
            }
            if (isAppOTP) bodydata.otp = response?.data?.otp
            setIsOtp(true)
            setSendOtp(true)
            setMinutes(5)
            setSeconds(0)
            setOtpInput(true);
            setOtpButton('Verify Otp')

        } else if (response.error === false && response.data?.is_user_available === 'yes' && response.data?.status === "incomplete" && LoginStep === 'verify_user_by_otp') {
            // setIsOtp(true)
            getOtp(e)

        } else if (response.error === false && response.data?.is_user_available === 'yes'
            && response.data?.status === 'pending' || response.data?.status === 'active' && LoginStep === 'verify_user_by_otp') {
            setLoginStep('user_login')

        } else if (response?.error === true && response?.data === null && LoginStep === 'verify_user_by_otp') {
            if (isAppOTP) bodydata.otp = response?.data?.otp


            setIsOtp(true)
            setSendOtp(true)

            showError(response.message)
            setMinutes(5)
            setSeconds(0)

        } else {
            // showError(response.message)

        }

        if (response.error === false && LoginStep === 'user_login') {
            showSuccess(response.message)
            setShowLoginModel(false)
            localStorage.setItem("user-info", JSON.stringify(response?.data));
            window.location.reload();
        } else if (response.error === true && LoginStep === 'user_login') {
            showError(response.message)

        }

        setLoading(false);
    }


    const getOtp = async (e) => {
        setOtpLoading(true);

        e.preventDefault()
        delete bodydata.operation
        if (otpButton === 'Get Otp') {
            delete bodydata.otp
            setOpenBackDrop(true);

        }

        const response = await ApiCall("dealer/verify_user_by_otp", bodydata, true);


        if (response.error === false && response?.data?.otp && (otpButton === 'Get Otp' || resendOtp)) {
            setIsOtp(true)
            setMinutes(5)
            setSeconds(0)
            if(isAppOTP)  bodydata.otp = response?.data?.otp
            setOtpInput(true);
            setOtpButton('Verify Otp')

        } else if (response.error === false && otpButton === 'Verify Otp' && (!response?.data?.user || !response?.data?.status || response?.data?.status === "incomplete" || response?.data?.status === "")) {
            localStorage.setItem("user-register", JSON.stringify({
                mobile: bodydata.username,
                email: "",
                username: bodydata.username || ""
            }));
            setShowLoginModel(false)
            setOtpInput(false);
            setMinutes(0)
            setSeconds(0)
            navigate("/signup")
            setLoginStep('verify_user_by_otp')
            setOtpButton('Get Otp')
            setSendOtp(false)
            setIsOtp(false)
            bodydata.mobile = ''
            bodydata.username = ''
            bodydata.email = ''
            delete bodydata.otp
        } else if (response.error === false && response.data.user === null && otpButton === 'Verify Otp' && (response?.data?.status === "incomplete" || response?.data?.status === "")) {
            localStorage.setItem("user-register", JSON.stringify({
                mobile: bodydata.username,
                email: "",
                username: bodydata.username || ""
            }));
            setShowLoginModel(false)
            navigate("/signup")
            setOtpInput(false);
            setMinutes(0)
            setSeconds(0)
            setLoginStep('verify_user_by_otp')
            setOtpButton('Get Otp')
            setSendOtp(false)
            setIsOtp(false)
            bodydata.mobile = ''
            bodydata.username = ''
            bodydata.email = ''
            delete bodydata.otp
        } else if (response?.error === false && response?.data?.user && response?.data?.status === "active") {
            navigate("/")
            localStorage.setItem("user-info", JSON.stringify(response?.data?.user || response?.data));
            window.location.reload();
            setShowLoginModel(false)
            setOtpInput(false);
            setMinutes(0)
            setSeconds(0)
            setLoginStep('verify_user_by_otp')
            setOtpButton('Get Otp')
            setSendOtp(false)
            setIsOtp(false)
            bodydata.mobile = ''
            bodydata.username = ''
            bodydata.email = ''
            delete bodydata.otp
        } else if (response?.error === true) {
            showError(response?.message)

        }
        setOtpLoading(false);
        setOpenBackDrop(false);


    }


    const ResendOtp = async (e) => {
        setOpenBackDrop(true);

        e.preventDefault()
        delete bodydata.operation
        delete bodydata.otp
        const response = await ApiCall("dealer/verify_user_by_otp", bodydata, true);
        if (response.error === false && response?.data?.otp) {
            setIsOtp(true)
            setMinutes(5)
            setSeconds(0)
            bodydata.otp = response?.data?.otp
            setOtpInput(true);
            setOtpButton('Verify Otp')
        } else {
            setOtpInput(false);
        }
        setOpenBackDrop(false);

    }


    const continueProcessNavigate = (e) => {
        e.preventDefault()
        setShowLoginModel(false)
        navigate("/auth")
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - +1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds, minutes]);


    return (
        <>
            <Container className="scroll-color">
                <Row>
                    <Col lg={6} xs={0} className="ps-0 pe-0 d-lg-flex d-none" style={{height: "79vh"}}>
                        <div className="modal-img">
                            {/* <Row className=" ms-auto me-auto">
                        <Col className="text-primary fw-bold h3">Login</Col>
                      </Row> 
                      <Row className="mt-auto">
                        <Col className="fw-bold mt-1 ms-2 text-light">Enter UserName And Password To Login</Col>
                      </Row> */}
                        </div>

                    </Col>
                    <Col lg={6} xs={12} className="login-modal-scroll">
                        <Row className="text-end">
                            <Col className="m-2 me-4">
                                <BsXLg style={{position: "fixed"}} className="close-button" size={30}
                                       onClick={toggleOpen}/>

                            </Col>
                        </Row>
                        <Form className=" ">


                            {isOtp === false && LoginStep === 'verify_user_by_otp' &&
                            <Container className="mt-2">
                                <Row className="text-center"><Col>
                                    <img alt={'banner'} src={logo} className="img-fluid w-50"/>
                                </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col className="text-center fw-bold h5">
                                        Verify your account.
                                    </Col>
                                </Row>
                                <Row className=" ">
                                    <Col className="text-center ">
                                        {/* <small>Book A Repair, purchase parts and get the best offers.</small> */}
                                        <small>Verify your email address or mobile number to login or
                                            register</small>
                                    </Col>
                                </Row>

                                <Row className="mt-5 fw-bold ">
                                    <Col className="">Mobile</Col>
                                </Row>
                                <Row>
                                    <Col className="">
                                        <Form.Control value={bodydata?.username} onChange={handleChange} name="username"
                                                      type="mobile" maxLength={10} minLength={10}/>
                                    </Col>
                                </Row>

                                <Row className="mt-4 pb-4">
                                    <Col className="text-center ">
                                        <button onClick={handleSubmit} className={'btn btn-primary1 w-100 my-3'}
                                                disabled={loading}> {loading && <><CircularProgress color="inherit"
                                                                                                    className="mt-1"
                                                                                                    size={16}/> &nbsp;&nbsp;</>} Verify
                                        </button>
                                    </Col>
                                </Row>

                            </Container>}

                            {isOtp === false && LoginStep === 'user_login' &&
                            <Container className="mt-3 ">
                                <Row className="mt-2">
                                    <Col className="text-center fw-bold h5">
                                        Log/Sign in to your Account
                                    </Col>
                                </Row>
                                <Row className=" ">
                                    <Col className="text-center ">
                                        {/* <small>Book A Repair, purchase parts and get the best offers.</small> */}
                                        <small>Please enter your account password to login</small>
                                    </Col>
                                </Row>
                                <Row className="mt-3 fw-bold">
                                    <Col className="">Mobile</Col>
                                </Row>
                                <Row>
                                    <Col className="">
                                        <InputGroup className="mb-3">
                                            <Form.Control disabled onChange={handleChange} value={bodydata?.username}
                                                          name="username" type="mobile" maxLength={10} minLength={10}/>
                                            <InputGroup.Text className="pointer" id="basic-addon1"
                                                             onClick={() => setLoginStep('verify_user_by_otp')}><BsPencilSquare/></InputGroup.Text>
                                        </InputGroup>

                                    </Col>
                                </Row>
                                <Row className="mt-2 fw-bold">
                                    <Col className="">Password</Col>
                                </Row>
                                <Row>
                                    <Col className="">
                                        <InputGroup className="mb-3">
                                            <Form.Control onChange={handleChange} name="password" type={passwordType}/>
                                            <InputGroup.Text onClick={togglePassword} className="pointer"
                                                             id="basic-addon1">
                                                {passwordType === "password" ? (
                                                    <BsEyeSlashFill className="color-secondary fs-4"/>
                                                ) : (
                                                    <BsEyeFill className="color-secondary fs-4"/>
                                                )}
                                            </InputGroup.Text>
                                        </InputGroup>

                                    </Col>
                                </Row>

                                <Row className="text-end">
                                    <Col onClick={(e) => getOtp(e)}
                                         className="text-end mt-2 colorprimary text-decoration-underline pointer "> Login
                                        With Otp </Col>
                                </Row>

                                <Row className="mt-3 pb-1">

                                    <Col className="text-center ">
                                        <button onClick={handleSubmit} className={'btn btn-primary1 w-100 my-3'}
                                                disabled={loading}> {loading && <><CircularProgress color="inherit"
                                                                                                    className="mt-1"
                                                                                                    size={16}/> &nbsp;&nbsp;</>} Login
                                        </button>
                                    </Col>
                                </Row>

                            </Container>}

                            {isOtp === true &&
                            <Container className="mt-3 ">
                                <Row className="mt-2">
                                    <Col className="text-center fw-bold h5">
                                        Log/Sign in to your Account
                                    </Col>
                                </Row>
                                <Row className=" ">
                                    <Col className="text-center ">
                                        {/* <small>Book A Repair, purchase parts and get the best offers.</small> */}
                                        <small>Please enter OTP received on our mobile or email address to
                                            login</small>
                                    </Col>
                                </Row>
                                <Row className="mt-3 fw-bold ">
                                    <Col>Mobile</Col>
                                </Row>
                                <Row>
                                    <Col className="">
                                        <Form.Control disabled onChange={handleChange} value={bodydata?.username}
                                                      name="username" type="mobile" maxLength={10} minLength={10}/>
                                    </Col>
                                </Row>
                                {otpInput && <Row className="mt-3 fw-bold">
                                    <Col className="">Otp</Col>
                                </Row>}
                                {otpInput && <Row>
                                    <Col className="">
                                        <Form.Control value={bodydata?.otp || ""} onChange={handleChange} name="otp"
                                                      type="text"/>
                                    </Col>
                                </Row>}
                                {seconds === 0 && minutes === 0 ? <></> : <Row>
                                    <Col className="text-end mt-2  ">
                                        Resend Otp In 0{minutes + ":" + seconds}
                                    </Col>
                                </Row>}

                                {otpButton === 'Get Otp' ? <></> : <>
                                    {seconds === 0 && minutes === 0 ?
                                        <Row>
                                            <Col onClick={ResendOtp}
                                                 className="text-end mt-2 colorprimary text-decoration-underline pointer ">
                                                Resend Otp
                                            </Col>
                                        </Row>
                                        : <></>
                                    }

                                </>
                                }

                                <Row className="mt-4 pb-2">
                                    <Col className="text-center ">
                                        <button onClick={getOtp} className={'btn btn-primary1 w-100 my-3'}
                                                disabled={otpLoading}> {otpLoading && <><CircularProgress
                                            color="inherit"
                                            className="mt-1"
                                            size={16}/> &nbsp;&nbsp;</>} {otpButton}</button>
                                    </Col>
                                </Row>
                            </Container>}


                            <p className={'text-center mb-4'}><small className="">
                                Your registration is not complete <br/> <b onClick={continueProcessNavigate}
                                                                           className={"pointer colorprimary"}>Click
                                Here</b> to resubmit
                                {/*<b>
                                Registration not complete? <br/> or want to continue your
                                registration process? <br/> or want to document
                                resubmit? <br/> <b onClick={continueProcessNavigate} className={"pointer colorprimary"}>Click Here</b>
                            </b>*/}
                            </small>
                            </p>
                        </Form>


                    </Col>
                </Row>
            </Container>

        </>
    )
}
export default LoginModal;