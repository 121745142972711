import React, {useEffect} from 'react'
import SignInForm from './SignInFrom';

export default function AuthSignIn({loginNavigate}) {
    useEffect(() => {
        document.title = 'Login | iService - Innovation Service';
    }, [])


    return (
        <div>
          <section className="">
            <div className="container-fluid px-0">
              <div className="row g-0">
                <div className="col-lg-6 auth-left-div"/>
                <div className="col-lg-6 vh-100 d-flex align-item-center justify-content-center">
                  <SignInForm loginNavigate={loginNavigate} />
                </div>
              </div>
            </div>
          </section>
        </div>
      );
}
