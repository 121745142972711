import React, {useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
// import {FaBell} from "react-icons/fa";
// import {BiLowVision, BiShowAlt} from "react-icons/bi";
import {InputFieldPassword} from "../InputFields/InputFieldHelper";
import {useAuth, useNotification} from "../../hooks";
import { userPasswordUpdate} from "../../api/auth";


const ChangePassword = () => {
    const {authInfo} = useAuth();
    const [btnSubmit, setBtnSubmit] = useState("Save Changes");
    // const [validated, setValidated] = useState(false);
    const {showError, showSuccess} = useNotification();

    const [userProfile, setUserProfile] = useState({});
    const handleChange = (e) => {
        setUserProfile({...userProfile, [e.target.name]: e.target.value});
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setBtnSubmit("Loading....");
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setBtnSubmit("Save Changes");
            e.stopPropagation();
        } else {
            const {data, error, message} = await userPasswordUpdate({
                ...userProfile,
                login_user_id: authInfo?.profile?.id
            });
            // console.log(data);
            // console.log(error);
            // console.log(message);
            setBtnSubmit("Save Changes");
            if (error || data.error) {
                return showError(data?.message || message || "")
            }
            showSuccess(data?.message||message);
        }
        setBtnSubmit("Save Changes");
        setUserProfile({});
    }
    return (

        <>
            <Container className="padding0">
                <Form onSubmit={handleSubmit}>
                    <Row className="h5 ms-1 mt-4">Change Password </Row>
                    <Row className="mt-4">
                        <Col xs={12} md={12}>
                            <InputFieldPassword name={'old_password'} label={'Old Password'}
                                                value={userProfile?.old_password}
                                                onChange={handleChange} minLength={4}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <InputFieldPassword name={'password'} label={'New Password'}
                                                value={userProfile?.password}
                                                onChange={handleChange} minLength={4}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <InputFieldPassword name={'confirm_password'} label={'Confirm New Password'}
                                                value={userProfile?.confirm_password}
                                                onChange={handleChange} minLength={4}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <button className="sign-up-button" type={"submit"}>{btnSubmit}</button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}

export default ChangePassword;