import {React, useEffect, useState} from "react";
import {part_list} from "../../api/general";
import ShopCard from "./ShopCard";
import SkeletonFile from "../OurServices/SkeletonFile";
import {Button} from "react-bootstrap";
import {CircularProgress} from "@mui/material";

export default function AllDisplay({part_type, brand_id, slug_brand, search, modelData, categoryId}) {
    const [product_details, setProduct_details] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const [btnLoadMore, setBtnLoadMore] = useState("Load More");
    const [isMoreData, setIsMoreData] = useState(true);
    const [btnLoading, setbtnLoading] = useState(false);


    const getData = async () => {
        setLoading(true);
        setProduct_details([]);
        setOffset(0);
        const inputData = {
            offset: 0
        }
        if (search) {
            inputData.search = search;
        }
        if (part_type && part_type !== "All" && part_type !== 0) {
            inputData.part_type = part_type;
        }
        if (brand_id) {
            inputData.brand_id = brand_id;
            inputData.brand_true = true;
        }

        if (!brand_id && slug_brand) {
            inputData.slug_brand = slug_brand;
            inputData.slug_brand_true = true;
        }
        if (categoryId) {
            inputData.category_id = categoryId;
        }
        // console.log(inputData);
        const {data} = await part_list(inputData);


        setLoading(false);
        //console.log(data);
        if (!data.error) {
            // setProduct_details((prev) => [...new Set([...prev, ...data.data])]);
            setProduct_details(data.data);
            // console.log(product_details.length);
            if (data.data.length < 12) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
                // setOffset(offset + 1);
            }
        } else {
            setIsMoreData(false);
        }
    };


    useEffect(() => {
        getData();
        // console.log("call search all");
    }, [modelData, brand_id, slug_brand, search, part_type]);

    const nextData = async () => {
        setBtnLoadMore("Loading......")
        setbtnLoading(true);
        setIsLoadingMore(true);
        const inputData = {
            offset: offset || 0
        }
        if (search) {
            inputData.search = search;
        }
        if (part_type && part_type !== "All" && part_type !== 0) {
            inputData.part_type = part_type;
        }
        if (brand_id) {
            inputData.brand_id = brand_id;
            inputData.brand_true = true;
        }

        if (!brand_id && slug_brand) {
            inputData.slug_brand = slug_brand;
            inputData.slug_brand_true = true;
        }
        const {data} = await part_list(inputData);
        // setProduct_details(data.data);
        // console.log(data.data);
        if (!data.error) {
            if (data.data.length < 12) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
                // setOffset(offset + 1);
            }
            if (data.data.length) {
                setProduct_details((prev) => [...new Set([...prev, ...data.data])]);
                // setProduct_details((prev) => [...prev, ...data.data]);
            }
        } else {
            setIsMoreData(false);
        }
        setIsLoadingMore(false);
        setBtnLoadMore("Load More")
        setbtnLoading(false);

        // console.log(product_details);
        // console.log(product_details.length);
    };

    useEffect(() => {
        // console.log(product_details);
        if (offset !== 0) {
            nextData();
        }
    }, [offset]);

    const handelInfiniteScroll = async () => {
        setOffset(offset + 1);
        // try {
        //     if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
        //         setOffset((prev) => prev + 1);
        //     }
        // } catch (error) {
        //     // console.log(error);
        // }
    };

    // useEffect(() => {
    //     window.addEventListener("scroll", handelInfiniteScroll);
    //     return () => window.removeEventListener("scroll", handelInfiniteScroll);
    // }, []);

    return (
        <>
            <div className="row g-4">
                {loading && <SkeletonFile/>}
                {!loading ? (product_details === null || !product_details.length) ? (
                    <div className="text-center fs-3 min-height">No Parts Found</div>
                ) : (
                    !loading &&
                    product_details.map((element, index) => {
                        return (
                            <div key={"all-product-" + (element?.slug || index)}
                                 className="col-sm-6 col-md-4 col-lg-3 col-xl-2 /*my-3*/ d-flex justify-content-center align-item-center">
                                <ShopCard part={element}
                                          image={element?.url_image}
                                          price={element?.amount_price_customer}
                                          title={element.title}
                                          slug={element.slug}
                                />
                            </div>
                        );
                    })
                ) : ""}
                {/*{isLoadingMore && <SkeletonFile/>}*/}
                <div className="text-center">
                    {isMoreData && (
                        <Button disabled={btnLoading} type="submit" variant="" className="btn btn-primary3 my-3 "
                                onClick={handelInfiniteScroll}>{btnLoading &&
                        <CircularProgress size={16} color={"inherit"}/>}&nbsp;  {btnLoadMore}</Button>
                    )}

                </div>
            </div>
        </>
    );
}
