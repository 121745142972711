import React from "react"; 
import { BookingForm } from "./BookingForm"; 
import { DeviceInfoBlock } from "./DeviceInfoDetailBlock"; 

export default function BookingBody({ data, setData, token, handleBack, handeNext, setToken }) {
    return (
        <>
            <div className=" center">
                <h5>Tell us about your device</h5>
                {/* <p>
                    Please fill the following details for book service for you. select category, brand, model and imei
                    number.
                </p>*/}
                {/*<div className="image"></div>*/}

                <DeviceInfoBlock />

                {/*<DragNDrop/>*/}
                <BookingForm data={data} setData={setData} token={token} handleBack={handleBack} handeNext={handeNext} setToken={setToken} />
            </div>
        </>
    );
}
