import {React, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {part_list} from '../../api/general';
import {get_bookings} from "../../api/booking";
import {useAuth, useNotification} from "../../hooks";
import SimilarProducts from './SimilarProducts';
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import {Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {FaCheckDouble, FaTimes} from "react-icons/fa";

export default function OrderBanner() {
    const {state} = useLocation();
    const [loading, setLoading] = useState(true);
    const [part_details, setPart_details] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [orderToken, setOrderToken] = useState((searchParams.get("order")));
    const [order, setOrder] = useState({});
    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const {showError, showSuccess} = useNotification();
    useEffect(() => {
        document.title = 'Order Success | iService - Innovation Service';
    }, [])
    const getOrderDetail = async () => {
        if (orderToken) {
            setLoading(true);
            const {data, error} = await get_bookings({
                id: authInfo.profile?.id,
                user_type: authInfo.profile?.user_type,
                token_order: orderToken
            });
            setOrder(data.data);
            // // console.log(data.data);
            await getData(data?.data?.order_parts[0]?.part?.brand_id);
            setLoading(false);
        } else {
            showError("Undefined token");
            navigate('/');
        }
    }
    const getData = async (brand_id) => {
        const {data} = await part_list({
            brand_id: brand_id || state.id,
            brand_true: true
        });
        setPart_details(data.data)
    };
    useEffect(() => {
        getOrderDetail();
        window.scrollTo(0, 0);
    }, [orderToken]);

    return (
        <div>
            <div className='ourservices'>
                <div className='container'>

                    {loading && <>
                        <Row>
                            <SkeletonFileGeneral height={30} total={1} col={7}/>
                        </Row>
                        <Row>
                            <SkeletonFileGeneral height={20} total={1} col={12}/>
                        </Row>
                    </>}
                    {!loading && (order && (order?.status === 'pending' || order?.status_payment === 'success') ? <>
                        <h3 className='servicesHeading text-success'>
                            <FaCheckDouble color={"success"} size={40}/> Thank you for your order
                        </h3>
                        <p className=' subHeading '>
                            Your order completed successfully and your order number <b
                            className={'text-uppercase'}>#{orderToken}</b>. You also view your order in booking section.
                            or <Link to={"/booking-details/" + orderToken + "?order=" + orderToken}>Click Here</Link>
                        </p>
                    </> : <>
                        <h3 className='servicesHeading text-danger'>
                            <FaTimes color={"danger"} size={50}/> Order unable to complete, Payment failed or cancelled.
                        </h3>
                        <p className=' subHeading '>
                            Your order could not be processed. Payment was either canceled or could not be completed.
                            Please place your order again if you would like to continue
                        </p>
                        <p className=' subHeading '>
                            Please check orders if you payment succeed and this message showing. If Order not showing in
                            you order then contact admin for support.
                        </p>
                    </>)}
                </div>
            </div>
            {loading && <div className="container my-5">
                <div className="row">
                    <SkeletonFileGeneral height={250} total={6} col={2}/>
                </div>
            </div>}
            <SimilarProducts data={part_details}/>
        </div>
    )
}
