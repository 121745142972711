import {Form, InputGroup} from "react-bootstrap";
import {React, useState} from "react";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";

export function InputFieldPassword({onChange, name, label, value, placeholder, minLength}) {
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (<>
        <Form.Group className="mb-3" controlId={"ControlInput" + name}>
            <Form.Label>{label || "Password"}</Form.Label>
            <InputGroup>
                <Form.Control name={name} type={passwordType} placeholder={placeholder || "Password"}
                              onChange={onChange} required={true} minLength={minLength || 6} value={value || ""}
                              aria-label="password" aria-describedby="user-password"/>
                <InputGroup.Text id="user-password" style={{cursor: "pointer"}}
                                 onClick={togglePassword}> {passwordType === "password" ? <FaEyeSlash/> :
                    <FaEye/>}</InputGroup.Text>
            </InputGroup>
        </Form.Group>
    </>);
}

export function InputField({
                               onChange,
                               name,
                               label,
                               type,
                               value,
                               placeholder,
                               min,
                               minLength,
                               max,
                               disabled,
                               required,
                               readOnly,
                               options,
                               appendIcon,
                               appendType,
                               appendUrl,
                               setOtpVisible,
                               multiple
                           }) {
    const [inputType, setInputType] = useState(type || "text");
    const navigate = useNavigate();
    const handleClickEdit = () => {
        navigate(appendUrl);
        setOtpVisible(false);
    }
    return (<>
        <Form.Group className="my-1" controlId={"ControlInput" + (name || "input")}>
            <Form.Label className={"mb-0"} style={{fontWeight: "lighter"}}><strong>{label || ""}</strong>
                {required && required === true ? "*" : ""}
            </Form.Label>
            <InputGroup>
                {(appendType && appendType === 'start') && appendIcon &&
                <InputGroup.Text>{appendIcon}</InputGroup.Text>}

                <Form.Control name={name || 'input'} type={inputType || 'text'}
                              placeholder={placeholder || ""}
                              onChange={onChange} min={min} max={max} minLength={minLength || ""}
                              disabled={disabled || false}
                              readOnly={readOnly || false}
                              required={required || false} value={value || ""}
                              aria-label={name || 'input'} aria-describedby={"input-" + (name || "input")}
                              list={"datalistOptions" + (name || "input")}/>
                {(!appendType || appendType !== 'start') && appendIcon && <InputGroup.Text style={{cursor: "pointer"}}
                                                                                           onClick={handleClickEdit}> {appendIcon}</InputGroup.Text>}

                {options && <datalist id={"datalistOptions" + (name || "input")}>
                    {options && Array.isArray(options) ? options.map((option, index) => {
                        return <option key={index} value={option}/>;
                    }) : ""}
                </datalist>}
            </InputGroup>
        </Form.Group>
    </>);

}

export function InputRadioField({onChange, name, label, value, max, options, appendIcon, appendType}) {
    return (<>
        <FormControl className="mb-3">
            <FormLabel><b className={'text-dark'}>{label}</b></FormLabel>
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name={name} onChange={onChange}
                        value={value}>
                {options && Array.isArray(options) && options.map((option) => {
                    return (<FormControlLabel key={name + "-" + option.value} value={option?.value || option}
                                              control={<Radio/>}
                                              label={option?.label || option}/>);
                })}
            </RadioGroup>
        </FormControl>
    </>);
}
