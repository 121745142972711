import { React, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth, useNotification } from "../../hooks";
import { Col, Form } from "react-bootstrap";
import { InputField, InputFieldPassword } from "../InputFields/InputFieldHelper";
import { FaEdit } from "react-icons/fa";
import CountdownTimer from "../../hooks/CountdownTimer";
import {isAppTempDown, isAppOTP} from "../../api/client";
import { CircularProgress } from "@mui/material";

const validateUserInfo = (mobile, password) => {
    if (!mobile.trim()) return { ok: false, error: "Mobile number is missing!" };
    // if (!isValidEmail(email)) return {ok: false, error: "Invalid email!"};
    if (!isValidMobile(mobile)) return { ok: false, error: "Invalid mobile!" };

    if (!password.trim()) return { ok: false, error: "Password is missing!" };
    if (password.length < 4)
        return { ok: false, error: "Password must be 4 characters long!" };

    return { ok: true };
};


export default function SignInForm({loginNavigate}) {
    const { state } = useLocation();
    // console.log(state)
    const [userInfo, setUserInfo] = useState({
        device_type: "web",
        istoken: "ISABCDEFGH",
        username: state?.username || "",
        email: state?.email || "",
        mobile: state?.mobile || "",
        device_token:  localStorage.getItem('device_token') || "ABCDEFGHIJK",
        password: "",
        device_latitude: "0.00",
        device_longitude: "0.00",
        otp: "",
    });

    const { handleLoginWithPassword, sendUserOtp, authInfo } = useAuth();


    const { showError, showSuccess } = useNotification();
    const [passwordVisible, setPasswordVisible] = useState(true);
    const [otpVisible, setOtpVisible] = useState(false);
    const [btnText, setBtnText] = useState("Get Started");
    const [btnPreText, setPreBtnText] = useState("Get Started");
    const [countTime, setCountTime] = useState((1000 * 60 * 3));
    const [dateCounterEnd, setDateCounterEnd] = useState(Date.now());
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const resendOtp = async (region) => {
        setBtnText("Verifying...");
        setLoading(true)

        const { sendStatus, data } = await sendUserOtp(userInfo, region || null);
        const timeSet = new Date().getTime() + countTime;
        // console.log(data);
        await setDateCounterEnd(timeSet);
        if (isAppOTP) {
            setUserInfo({ ...userInfo, otp: data?.otp });
        }

        await setBtnText("Verify OTP");
        setLoading(false)

        await setPreBtnText("Verify OTP");
        await setOtpVisible(true);
    }

    const handleChange = ({ target }) => {
        const { value, name } = target;
        setUserInfo({ ...userInfo, [name]: value });
    };
    const handleSubmit = async (e) => {
        setBtnText("loading....")
        setLoading(true)

        e.preventDefault();

        if (passwordVisible) {
            const { ok, error } = validateUserInfo(
                userInfo.username,
                userInfo.password
            );

            if (!ok) {
                setBtnText(btnPreText);
        setLoading(false)

                return showError(error);
            }
            // console.log(userInfo);
            await handleLoginWithPassword(userInfo,loginNavigate);
        } else if (otpVisible) {
            await sendUserOtp(userInfo, "login-by-otp");
        } else {
            await resendOtp(userInfo);
            setOtpVisible(true);
            setBtnText("Verify OTP");
        setLoading(false)

            setPreBtnText("Verify OTP");
        }
        // navigate("/");
        setBtnText(btnPreText);
        setLoading(false)

    };
    const handelOTP = (e) => {
        e.preventDefault();
        if (!passwordVisible) {
            setOtpVisible(false)
        }
        setPasswordVisible(!passwordVisible);
        setBtnText("Send OTP")
        setLoading(false)

    };

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setUserInfo({
                    ...userInfo,
                    device_latitude: position.coords.latitude,
                    device_longitude: position.coords.longitude,
                });
                // console.log(position);
            });

        } else {
            // console.log("Geolocation is not available in your browser.");
        }
    }, []);
    return (
        <section className="section">
            <div className="col-12 col-md-8 col-lg-6 col-xl-7 temp2" >
                <div className="card shadow-2-strong auth-card ">
                    <div className="card-body temp">
                        <p className=" text-center auth-heading">Log in to your Account</p>
                        <p className="text-center mb-5">
                            Book A Repair, purchase parts and get the best offers.
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <InputField type={'number'} minLength={10} name={'username'}
                                label={'Mobile Number'} value={userInfo?.username}
                                disabled={state?.username} onChange={handleChange}
                                appendIcon={(passwordVisible || otpVisible) && <FaEdit />}
                                appendUrl={'/auth'} />

                            {passwordVisible && (
                                <>
                                    <InputFieldPassword name={'password'} label={'Password'} value={userInfo?.password}
                                        onChange={handleChange} minLength={4} />
                                    <div className="form-outline my-3">

                                        <div className="d-flex justify-content-between">
                                            <div className=" d-flex justify-content-start">
                                                <a onClick={handelOTP} href="" style={{ color: "#EE610F" }}>
                                                    Login with OTP
                                                </a>
                                            </div>

                                            {/* <div className=" d-flex justify-content-end">
                                                <a href="#" style={{color: "#EE610F"}}>
                                                    Forgot password?
                                                </a>
                                            </div>*/}
                                        </div>
                                    </div>
                                </>


                            )}
                            {otpVisible && (
                                <>
                                    <InputFieldPassword name={'otp'} label={'OTP'} value={userInfo?.otp}
                                        minLength={4}
                                        onChange={handleChange} placeholder={'Valid OTP'} />
                                    <div className="row">
                                        <Col>
                                            <CountdownTimer targetDate={dateCounterEnd} resendOtp={resendOtp} />
                                        </Col>
                                        <Col className={"justify-content-end"}>
                                            <a onClick={handelOTP} href="" style={{ color: "#EE610F" }}>
                                                Login with Password
                                            </a>
                                        </Col>
                                    </div>

                                </>

                            )}

                            <button type="submit" disabled={loading} className="btn btn-primary1 my-3 ">
                            {loading && <CircularProgress size={16} color={"inherit"}/>}&nbsp; Get Started
                                {/* {btnText} */}
                            </button>
                        </Form>
                        <p className="text-center">
                            Don’t have an account?
                            <Link to={"/auth"} style={{ color: "#EE610F" }}> Sign Up</Link>
                        </p>
                        <p className="text-center">
                            Back to home?
                            <Link to={"/"} style={{ color: "#EE610F" }}> Home</Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export const isValidEmail = (email) => {
    const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return isValid.test(email);
};

export const isValidMobile = (mobile) => {
    return mobile.length === 10;
};
