import React, {useEffect, useState} from "react";
import {useAuth, useNotification} from "../../hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {Container} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import {DashboardCard} from "../DealerJobSheetDetail/DashboardCard";
import {FaCogs, FaShoppingCart} from "react-icons/fa";
import Breadcrumbs from "../Breadcrumbs";
import {get_booking_dashboard} from "../../api/booking";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import noitem from "../asset/Comp 2_00000.jpeg"
import {showAdBanners} from "../../api/client";
import {ApiCall} from "../../api/HelpingMethod";

export default function BookingDashboard() {
    const locationTemp = useLocation();
    if ((locationTemp?.pathname + "" + locationTemp?.search) !== localStorage.getItem("prevLocation")) {
        localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
    }
    const [loading, setLoading] = useState(false);
    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const {showError, showSuccess} = useNotification();
    const [countBookingService, setCountBookingService] = useState({
        title: "Booking Services",
        link: "/booking/service?type=service",
        icon: <FaCogs/>,
        count: "0 Booking's"
    });
    const [countBookingPart, setCountBookingPart] = useState({
        title: "Booking Part Orders",
        link: "/booking/part?type=part",
        icon: <FaShoppingCart/>,
        count: "0 Order's"
    });
    const [dataDashboard, setDataDashboard] = useState([countBookingService, countBookingPart]);

    const redirectToBookService = async () => {
        if (authInfo.profile.id) navigate("/book-repair");
        else {
            showError("Please login to book service.");
            navigate("/auth");
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = 'Order Dashboard | iService - Innovation Service';
    }, [])

    const addDashboardApi = async () => {
        setLoading(true);
        const {data, error} = await get_booking_dashboard({
            id: authInfo.profile?.id,
            user_type: authInfo.profile?.user_type
        });
        // console.log(data);
        if (error) {
            setLoading(false);
            return showError(error)
        } else {
            await setCountBookingService({
                // title: "Booking Services",
                link: "/booking/service?type=service",
                icon: <FaCogs/>,
                count: (data?.booking_service?.count || 0) + " Service Booking's"
            });
            await setCountBookingPart({
                // title: "Booking Part Orders",
                link: "/booking/part?type=part",
                icon: <FaShoppingCart/>,
                count: (data?.booking_part?.count || 0) + " Part Order's"
            });
            await setDataDashboard([countBookingService, countBookingPart]);
        }
        setLoading(false);
    };
    useEffect(() => {
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
    }, [])
    useEffect(() => {
        addDashboardApi();
        getBannerAdCall();
    }, [])

    const [bannerBottom, setBannerBottom] = useState(null);

    const getBannerAdCall = async () => {
        await ApiCall('dealer/advertisement', {
            key_advertisement: "order_dashboard_bottom_page"
        }).then((r) => {
            setBannerBottom(r?.data);
        });
    }
    const redirectBanner = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (
        <>
            <section className={'mx-5'}>
                {/*<Backdrop sx={{color: 'var(--primary)', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>*/}
                <div className="mt-3 mt-sm-5 pt-3">
                    <div>
                        <Breadcrumbs menu={{title: "Order", link: '/booking'}}/>
                        <h3 className="mt-sm-5 mt-2 servicesHeading1">Order Dashboard</h3>
                        <p className=" subHeading "/>
                    </div>
                </div>
                <div className={" my-3"} style={{minHeight: "40vh"}}>
                    <Row xs={{cols: 1}} md={{cols: 2}} lg={{cols: 2}} xl={{cols: 3}} className={'g-3'}>
                        {loading ? <SkeletonFileGeneral height={80} total={2} col={6} colLg={4}/> : (
                            <>
                                <Col>
                                    <DashboardCard data={countBookingService} icon={countBookingService?.icon}
                                                   link={countBookingService?.link}/>
                                </Col>
                                <Col>
                                    <DashboardCard data={countBookingPart} icon={countBookingPart?.icon}
                                                   link={countBookingPart?.link}/>
                                </Col>
                            </>
                        )}

                        {/*{!loading && dataDashboard.map((element, index) => {*/}
                        {/*    return (*/}
                        {/*        <Col key={index}>*/}
                        {/*            <DashboardCard data={element} icon={element?.icon}*/}
                        {/*                           link={element?.link}/>*/}
                        {/*        </Col>*/}
                        {/*    );*/}
                        {/*})}*/}

                    </Row>
                </div>
            </section>
            {bannerBottom?.url_image && showAdBanners && <Row className='m-0'>
                <Col className="p-0 pointer">
                    <img onClick={() => redirectBanner(bannerBottom?.url_redirect)} src={bannerBottom?.url_image}
                         className='w-100 banner-image pointer' alt={'no image'}/>
                </Col>
            </Row>}
        </>

    );
}