import React, {useEffect} from 'react'
import ByCard from './ByCard'
import {useLocation} from "react-router-dom";

export default function BuyMain() {
 const locationTemp=useLocation();
    //  if ((locationTemp?.pathname + "" + locationTemp?.search)!==localStorage.getItem("prevLocation")) {
    //        localStorage.setItem('prevLocation', locationTemp?.pathname + "" + locationTemp?.search);
     //   }
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return (
    <div>
           <div className="ourservices">
        <h3 className=" mx-5 servicesHeading">Buy parts for your mobile</h3>
        <p className="mx-5 subHeading">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dictum
          augue ex, a feugiat libero porttitor vel.
        </p>
      </div>
      <div className=" my-5 text-center">
        <div className="container">
          <div className="row gy-5 ">
            <div className="col d-flex justify-content-center align-item-center">
              <ByCard />
            </div>
            <div className="col d-flex justify-content-center align-item-center">
              <ByCard />
            </div>
            <div className="col d-flex justify-content-center align-item-center">
              <ByCard />
            </div>
            <div className="col d-flex justify-content-center align-item-center">
              <ByCard />
            </div>
            <div className="col d-flex justify-content-center align-item-center">
              <ByCard />
            </div>
            <div className="col d-flex justify-content-center align-item-center">
              <ByCard />
            </div>
            <div className="col d-flex justify-content-center align-item-center">
              <ByCard />
            </div>
            <div className="col d-flex justify-content-center align-item-center">
              <ByCard />
            </div>
           
          </div>
        </div>
        
      </div>
    </div>
  )
}
