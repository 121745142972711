import {CCardBody} from "@coreui/react";
import {Card, Col, Row} from "react-bootstrap";
import React from "react";

export function BookingDeviceInfoCard({dataInfo, deviceInfo}) {
    const style = {color: "var(--primary)", fontSize: "1.2em"}
    return (
        <Card className={'shadow  w-100'}>
            <CCardBody>
                <Row xs={{cols: 1}} className={'g-3'}>
                    <Col><p className="clientDetail">Device Details</p></Col>
                </Row>
                <Row className={'g-2 figure-caption'}>
                    <Col xs={4} className={'text-muted'}>Category</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{deviceInfo?.title_category||"-"}</small></Col>

                    <Col xs={4} className={'text-muted'}>Brand</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{deviceInfo?.title_brand||"-"}</small></Col>

                    <Col xs={4} className={'text-muted'}>Model</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{deviceInfo?.title_model||"-"}</small></Col>

                    <Col xs={4} className={'text-muted'}>IMEI/Serial number</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{deviceInfo?.device_serial_imei||"-"}</small></Col>

                    <Col xs={4} className={'text-muted'}>Problem</Col>
                    <Col xs={8}><small
                        className={"float-end fw-bold"}>{deviceInfo.device_problem ? deviceInfo.device_problem : "Other:"} {deviceInfo.device_problem_other ? " / " + deviceInfo.device_problem_other : ""}</small></Col>

                    <Col xs={4} className={'text-muted'}>Service Request Date</Col>
                    <Col xs={8}><small className={"float-end fw-bold"}>{dataInfo?.expected_visit_date||"-"} {dataInfo?.expected_visit_time}</small></Col>

                </Row>

            </CCardBody>
        </Card>
    );
}