import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import {getFirstWordCap} from "../../../hooks/useCustomHelpingMethod";
import {CButton} from "@coreui/react";
import {jobsheet_update} from "../../../api/jobsheet";
import {jobsheet_action} from "../../../api/booking";
import {useAuth, useNotification} from "../../../hooks";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {FaEdit} from "react-icons/fa";
import {getJobsheetStatus} from "../../../api/HelpingMethod";
import {CircularProgress} from "@mui/material";

const options = [
    'pending',
    'open',
    'closed',
    'return',
    'service'
];

function ConfirmationDialogRaw(props) {
    const {onClose, value: valueProp, setValueProp, open, data, setReloadData, ...other} = props;
    const [value, setValue] = React.useState(valueProp);
    const [loading, setLoading] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const {showError, showSuccess} = useNotification();
    const [orderToken, setOrderToken] = useState(searchParams.get("order"))
    const radioGroupRef = React.useRef(null);
    const {authInfo} = useAuth();
    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = async () => {
        setLoading(true);
        const {data, error} = await jobsheet_action({
            login_user_id: authInfo?.profile?.id,
            login_user_type: authInfo?.profile?.user_type || "client",
            token_order: orderToken,
            action_type: "update_status",
            status: value
        });

        if (error || !data || data?.error) {
            setLoading(false);
            return showError(data.message);
        } else {
            showSuccess(data.message)
            setReloadData(true)
        }
        setLoading(false);
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
            maxWidth="xs"
            TransitionProps={{onEntering: handleEntering}}
            open={open}
            {...other}
        >
            <DialogTitle>Status Update</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="status"
                    name="status"
                    value={value}
                    onChange={handleChange}
                >
                    {getJobsheetStatus(value).map((option) => {
                        if (option?.value !== data?.status) {
                            // console.log(option?.value, data?.status);
                            return (
                                <FormControlLabel
                                    value={option?.value || ""}
                                    key={option?.value}
                                    control={<Radio color={'warning'}/>}
                                    label={<>{option?.title} <small
                                        style={{fontSize: "12px"}}> {option?.title_sub && "(" + option?.title_sub + ")"}</small></>}/>
                            )
                        }
                    })}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'inherit'} autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
                <Button disabled={loading} variant={'contained'} color={'warning'} onClick={handleOk}>
                    {loading && <CircularProgress size={16} color={"inherit"}/>}&nbsp;Submit</Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

export default function DialogJobsheetStatusUpdate({dataInfo, setReloadData}) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(dataInfo?.status || "");

    useEffect(() => {
        setValue(dataInfo?.status);
    }, [dataInfo?.status])


    const handleClickListItem = () => {
        if (value !== 'service' && value !== 'closed' && value !== 'return') {
            // console.log("click");
            setOpen(true);
        }
    };

    const handleClose = (newValue) => {
        setOpen(false);
        // console.log(newValue);
        if (newValue) {
            setValue(newValue);
        }
    };

    return (
        <>
            <CButton className={'text-dark shadow float-end'} color="warning" onClick={handleClickListItem}
                     variant="ghost">Status: <b
                className={'color-primary text-capitalize'}>{getFirstWordCap(value || "")}</b>
                {value !== 'service' && value !== 'closed' && value !== 'return' &&
                <FaEdit className={'color-primary mx-1'}/>}</CButton>

            {/*<Button className={'text-dark shadow'} variant={'contained'} color={'inherit'} onClick={handleClickListItem}>
                Status: <b>{value}</b> <FaEdit color={'warning'}/>
            </Button>*/}

            <ConfirmationDialogRaw
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                value={value || ""}
                setValue={setValue}
                data={dataInfo}
                setReloadData={setReloadData}
            />
        </>
        /*<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List component="div" role="group">
                <ListItemButton divider disabled>
                    <ListItemText primary="Interruptions" />
                </ListItemButton>
                <ListItemButton
                    divider
                    aria-haspopup="true"
                    aria-controls="ringtone-menu"
                    aria-label="phone ringtone"
                    onClick={handleClickListItem}
                >
                    <ListItemText primary="Phone ringtone" secondary={value} />
                </ListItemButton>
                <ListItemButton divider disabled>
                    <ListItemText primary="Default notification ringtone" secondary="Tethys" />
                </ListItemButton>
                <ConfirmationDialogRaw
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                />
            </List>
        </Box>*/
    );
}