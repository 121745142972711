import { Autocomplete, Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useAuth, useNotification } from "../../hooks";
import { ApiCall } from "../../api/HelpingMethod";

const PartRequest = () => {
    const { authInfo } = useAuth();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [parts, setParts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { showError, showSuccess, setOpenBackDrop } = useNotification();
    const [selectedBrand, setSelectedBrand] = useState("")
    const [selectedModal, setSelectedModal] = useState("")
    const [selectedPart, setSelectedPart] = useState("")




    const [inputData, setInputData] = useState({
        login_user_id: authInfo?.profile?.id,
        login_user_type: authInfo?.profile?.user_type,
        type: "request",
        token_category: "",
        title_brand: "",
        title_model: "",
        title_part: "",
    });

    const getDataByUrlAndKey = async (url, input) => {
        let inputDa = { ...input, limit: 0, offset: 0 };
        if (url === 'category') {
            setOpenBackDrop(true);
            const response = await ApiCall('/dealer/product/' + url, inputDa, true).then((r) => {
                setCategories(r?.data || [])
                return r;
            });
            setOpenBackDrop(false);

        } else if (url === 'brand') {
            setOpenBackDrop(true);

            const response = await ApiCall('/dealer/product/' + url, inputDa, true).then((r) => {
                setBrands(r?.data || [])
                return r;
            });
            setOpenBackDrop(false);

        } else if (url === 'model') {
            setOpenBackDrop(true);

            const response = await ApiCall('/dealer/product/' + url, inputDa, true).then((r) => {
                setModels(r?.data || []) 
                return r;
            });
            setOpenBackDrop(false);

        } else if (url === 'part') {
            setOpenBackDrop(true);

            const response = await ApiCall('/dealer/product/' + url, inputDa, true).then((r) => {
                setParts(r?.data || [])
                return r;
            });
            setOpenBackDrop(false);

        }

    }

    useEffect(() => {
        getDataByUrlAndKey('category', {}).then(r => r);
    }, [])


    const handleChangeCategory = (e, val) => {
        if (val?.token) {
            getDataByUrlAndKey('brand', { 'token_category': val?.token }).then(r => r);
        }
        setSelectedCategory(val);
        setInputData({ ...inputData, 'token_category': val.token })
        setSelectedBrand("")
        setModels([]);

    }
    const handleChangeBrand = (e, val) => {
        // console.log(val);
        if (val && val.length > 3) {
            // getDataByUrlAndKey('model', { 'title_brand': val }).then(r => r);
            getDataByUrlAndKey('model', { 'title_brand': val, 'token_category': selectedCategory.token }).then(r => r);
            setSelectedBrand(val)
            // console.log("selectedCategory.token---",selectedCategory.token)
        }
        setInputData({ ...inputData, 'title_brand': val })
        setSelectedModal("")
        setParts([])
    }
    const handleChangeModel = (e, val) => {
        if (val && val.length > 3) {
            getDataByUrlAndKey('part', { 'title_model': val }).then(r => r);
        }
        setInputData({ ...inputData, 'title_model': val })
        setSelectedModal(val)
        setSelectedPart("")
    }
    const handleChangePart = (e, val) => {
        // console.log(val, e.target.value);
        setInputData({ ...inputData, 'title_part': val })
        setSelectedPart(val)
    }
    // useEffect(() => {
    //     console.log(inputData)
    // }, [inputData])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSubmit(true);
        const response = await ApiCall('/dealer/part_request_update', inputData, true).then((r) => { 
            if (r?.error) {
                showError(r?.message || "Something went wrong. Please try again.");
            } else {
                setInputData({ ...inputData,...{ token_category: "", title_brand: "", title_model: "", title_part: "",}})
                setSelectedBrand("")
                setSelectedModal("")
                setSelectedPart("")
                setBrands([]);
                setModels([]);
                setParts([]);
                setSelectedCategory(null);
                showSuccess("Thank you! For reaching to us. Your request is successfully submitted, Our executive will reach to you.");
            }
        });

        setLoadingSubmit(false);
    }
    return (
        <>
            <div className="ourservices">
                <h3 className=" mx-lg-5 servicesHeading">Part Request</h3>
                {/* <p className="mx-lg-5 subHeading">
                    Get Spare Parts at low prices on iService. Wide range of Spare Parts. Get Free Shipping & COD options across India
                    iService offers low-cost spare parts. A large selection of spare parts is available. Free shipping
                    and cash-on-delivery (COD) are available in many Indian cities.

                </p>*/}
            </div>
            <Container className="mt-2 mb-5">
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={8} className={'mx-auto'}>
                            <Card className={'shadow w-100 mt-3'}>
                                <Card.Body>
                                    <Row className="g-3 pt-3">
                                        <Col xs={12} className={'text-center mb-4'}>
                                            <h3>Custom Part Request Form</h3>
                                            <small><b>Please submit following detail to request a part</b></small>
                                        </Col>
                                        {/*<hr/>*/}
                                        <Col md={6} xs={12}>
                                            <Autocomplete
                                                required={true}
                                                size="small"
                                                color={"warning"}
                                                id="token_category"
                                                // freeSolo
                                                // name={"token_category"}
                                                options={categories}
                                                getOptionLabel={(option) => option.title}
                                                onChange={handleChangeCategory}
                                                value={(categories.length > 0) ? (selectedCategory || null) : null}
                                                renderInput={(params) => <TextField  {...params} color={"warning"}
                                                    label="Select Part Category" />}
                                            />
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <Autocomplete
                                                required={true}
                                                size="small"
                                                color={"warning"}
                                                id="title_brand"
                                                freeSolo
                                                name={"title_brand"}
                                                // onChange={handleChangeBrand}
                                                onInputChange={handleChangeBrand}
                                                options={brands.map((option) => option.title)}
                                                value={selectedBrand}
                                                // value={inputData?.title_brand || null}
                                                // options={brands.map((option) => option.title)}
                                                renderInput={(params) => <TextField  {...params} color={"warning"}
                                                    label="Enter Part Brand" />}
                                            />
                                        </Col>
                                        <Col md={12} xs={12}>
                                            <Autocomplete
                                                required={true}
                                                size="small"
                                                color={"warning"}
                                                id="title_model"
                                                freeSolo
                                                name={"title_model"}
                                                // onChange={handleChangeModel}
                                                onInputChange={handleChangeModel}
                                                // options={models}
                                                options={models.map((option) => option.title)}
                                                value={selectedModal}
                                                // value={inputData?.title_model || null}
                                                // options={models.map((option) => option.title)}
                                                renderInput={(params) => <TextField  {...params} color={"warning"}
                                                    label="Enter Part Model" />}
                                            />

                                        </Col>
                                        <Col md={12} xs={12}>
                                            <Autocomplete
                                                required={true}
                                                // size="small"
                                                color={"warning"}
                                                id="title_part"
                                                freeSolo
                                                name={"title_part"}
                                                // onChange={handleChangePart}
                                                onInputChange={handleChangePart}
                                                value={selectedPart}
                                                // value={inputData?.title_part || null}
                                                options={parts.map((option) => option.title)}
                                                renderInput={(params) => <TextField  {...params} color={"warning"}
                                                    label="Enter Part Part Name" />}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="text-center mt-3">
                                        <Col>
                                            <Button disabled={loadingSubmit} type={"submit"} variant='contained'
                                                color={'warning'}
                                                className={'sign-up-button px-5'}>{loadingSubmit &&
                                                    <CircularProgress size={16} color={"inherit"} />} Submit</Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </Container>
        </>
    )
}
export default PartRequest;