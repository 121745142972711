import React, { useEffect, useState } from "react";
import { useAuth, useNotification } from "../../hooks";
import { category_list, model, product_model } from "../../api/general";
// import "../ProblemDevice/ppBody.css";
import { sheet_service } from "../../api/jobsheet";
import { CCol, CForm, CFormInput, CFormLabel, CFormSelect, CRow } from "@coreui/react";
import { Col, Container, Row } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function DevicePageDes({ handeNext, data, handleBack, token, setToken, setData, setIemi }) {

    const [category_details, setCategory_details] = useState([]);
    const [selected_categoryId, setSelected_categoryId] = useState();
    const [selected_brandId, setSelected_brandId] = useState();
    const [selected_modelId, setSelected_modelId] = useState();
    const [selected_imei, setSelected_imei] = useState(data?.device_serial_imei || "");
    const [model_details, setModel_details] = useState([]);
    const [brand_details, setBrand_details] = useState([]);
    const { authInfo } = useAuth();
    const { showError } = useNotification()
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Save & Next')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const { setOpenBackDrop } = useNotification();

    useEffect(() => {
        const getData = async () => {
            setOpenBackDrop(true);
            const { data } = await category_list();
            setCategory_details(data.data);
            setOpenBackDrop(false);

        };
        getData();
    }, []);

    useEffect(() => {
        if (data) {
            setSelected_categoryId(data?.category_id || 0);
            setSelected_brandId(data?.brand_id || 0);
            setSelected_modelId(data?.model_id || 0);
            setSelected_imei(data?.device_serial_imei || selected_imei || "")
        }
    }, [data])


    const onCategoryChange = async (event) => {
        setSelected_categoryId(event.target.value);
        setSelected_modelId('')
        setSelected_brandId('')
        setModel_details([]) 
    };

    const onBrandChange = async (event) => {
        setSelected_brandId(event.target.value);
    };

    const onModelChange = async (event) => {
        setSelected_modelId(event.target.value);
        // setModel(event.target.selectedOptions[0].getAttribute('title'))
    };

    const onIChange = async (event) => {
        setSelected_imei(event.target.value);
        setIemi(event.target.value)
    };

    const getBrandList = async () => {
        setOpenBackDrop(true);
        const { data } = await model({ category_id: selected_categoryId });
        setBrand_details(data.data);
        setOpenBackDrop(false);
    }
    const getModelList = async () => {
        setOpenBackDrop(true);
        const { data } = await product_model({ brand_id: selected_brandId });
        // setBrand(event.target.selectedOptions[0].getAttribute('title'))
        setModel_details(data?.data || []);
        setOpenBackDrop(false);
    }

    useEffect(() => {
        if (selected_categoryId) getBrandList().then(r => r);
    }, [selected_categoryId])
    useEffect(() => {
        if (selected_brandId) getModelList().then(r => r);
    }, [selected_brandId])

    const handleSubmit = async (event) => {
        setButtonName("loading...");
        setLoading(true);

        event.preventDefault();
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Save & Next");
            setLoading(false);

            event.stopPropagation();
        } else {
            const inputData = {
                brand_id: selected_brandId,
                category_id: selected_categoryId,
                model_id: selected_modelId,
                token_order: token,
                id: authInfo.profile.id,
                user_type: authInfo.profile.user_type,
                device_serial_imei: selected_imei || "",
                step: 'device-info'
            }
            // console.log(inputData);
            const { data, error } = await sheet_service(inputData);
             
            setToken(data?.data?.token)
            setData(data?.data)
            // return ;
            setButtonName("Save & Next");
            setLoading(false);
            navigate("/job-repair?token=" + data.data.token + "&step=" + data.data.step)
            if (error || data.error) return showError(data.message);
            // showSuccess(data.message);
            handeNext();
        }
        setButtonName("Save & Next");
        setLoading(false);

        setValidated(true);
    };


    return (
        <>
            <div className="center-form">
                <div className="optionContainer">
                    <Container>
                        <CForm className="needs-validation"
                            noValidate validated={validated} onSubmit={handleSubmit}>
                            <CRow className={"g-3"}>
                                <CCol md={6}>
                                    <CFormLabel htmlFor="select-category">Select Category</CFormLabel>
                                    <CFormSelect id={'select-category'} aria-label="Default select example"
                                        onChange={onCategoryChange} value={selected_categoryId || 0}>
                                        <option value="0" disabled={true} selected={true}>Please select Category</option>
                                        {category_details.map((element) => {
                                            return <option value={element.id}>{element.title}</option>;
                                        })}
                                    </CFormSelect>
                                </CCol>
                                <CCol md={6}>
                                    <CFormLabel htmlFor="select-brand">Select brand</CFormLabel>
                                    <CFormSelect id={'select-brand'} aria-label="Default select example"
                                        onChange={onBrandChange} value={selected_brandId || 0}
                                        required>
                                        <option value="0" disabled={true} selected={true}>Please Select Brand</option>
                                        {brand_details === null || !brand_details.length ? (
                                            // <option value="1">Please Select Brand</option>
                                            <></>
                                        ) : (brand_details.map((element) => {
                                            return <option title={element.title}
                                                value={element.id}>{element.title}</option>;
                                        })
                                        )}
                                    </CFormSelect>
                                </CCol>
                                <CCol md={6}>
                                    <CFormLabel htmlFor="select-model">Select Model</CFormLabel>
                                    <CFormSelect id={'select-brand'} aria-label="Default select example"
                                        onChange={onModelChange} value={selected_modelId || 0}
                                        required>
                                        <option value="0" disabled={true} >Please select brand First
                                        </option>
                                        {model_details === null || !model_details.length ? (
                                            // <option value="1">Please Brand Category </option>
                                            <></>
                                        ) : (
                                            model_details.map((element) => {
                                                return <option title={element.title} key={element.id}
                                                    value={element.id}>{element.title}</option>;
                                            })
                                        )}
                                    </CFormSelect>
                                </CCol>
                                <CCol md={6}>
                                    <CFormLabel htmlFor="imei_number">IMEI/Serial number (Optional)</CFormLabel>
                                    <CFormInput id="imei_number" onChange={onIChange} value={selected_imei}
                                        name={'imei_number'} />
                                </CCol>
                                <CCol md={12} className={'my-5'}>
                                    <Row>
                                        <Col>
                                            <button className="button-2 float-left"
                                                onClick={handleBack}>{"Previous"}</button>
                                        </Col>
                                        <Col>
                                            <button disabled={loading} className={"sign-up-button float-end text-end " + (loading && 'disabled-button')}
                                                onClick={handleSubmit}>{loading && <CircularProgress size={16} color={"inherit"} />}&nbsp;{btnName}</button>
                                        </Col>
                                    </Row>


                                </CCol>
                            </CRow>

                        </CForm>
                    </Container>
                </div>
            </div>
        </>
    );
}
