import { CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { cropText, get_date_formatted } from "../../hooks/useCustomHelpingMethod";
import { Link, useNavigate } from "react-router-dom";
import { FaLongArrowAltRight, FaRegUserCircle } from "react-icons/fa";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { Card, Col, Container, Row } from "react-bootstrap";
import React from "react";


export function BlogItemCard({ data }) {
    const navigate = useNavigate();
    return <>
        <Link to={'/blog/' + data.slug} className={'text-decoration-none'}>
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                    component="img"
                    alt={data?.title}
                    height="250"
                    image={data?.media_url}
                />
                <CardContent>
                    <Typography gutterBottom variant="b" component="div"
                        className={'fw-bold'}>
                        {cropText(data?.title || "")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {cropText(data?.description || "", 100)}
                        <br />
                        <div onClick={() => navigate('/blog/' + data.slug)} style={{ fontSize: "17px" }}
                            className="div-as-link float-end">
                            Read More &nbsp;
                            <FaLongArrowAltRight />
                        </div>
                    </Typography>
                </CardContent>
                <CardActions>
                    <Container className="p-0">
                        <Row className="mb-1">
                            <Col xs={6} className=" ">
                                {/* <h6 className="mx-2">{blogItem.count_view}</h6> */}
                                <div className=" fw-medium"> <FaRegUserCircle color="var(--primary)" /> &nbsp; I Service India</div>
                            </Col>
                            <Col xs={6} className="text-end ">

                                <div className="fw-medium">
                                    <BsCalendar2CheckFill color="var(--primary)" />&nbsp;  {get_date_formatted(data.date_schedule)}
                                </div>
                            </Col>
                        </Row>
                    </Container>


                </CardActions>
            </Card>
        </Link>
    </>
}