import React from "react";
// // import "../BookingCase1/mainContainer.css";
// import {BookingForm} from "./JobSheetForm";
import {JobSheetForm} from "./JobSheetForm";
// import DragNDrop from "./DragNDrop";

export default function JobSheetBody({data, token, handeNext, setToken, setData}) {

    return (
        <>

            <div className="center">
                {/*<h4 className={'my-3'}>Tell us About Your Client</h4>*/}
                <h4 className={'my-3'}>Client Detail's</h4>
                {/*<p>Please provide following client data to identify whose jobsheet created and will be inform on given mobile number when work completed</p>*/}
                {/* <div className="image"></div> */}

                {/*<DragNDrop/>*/}
                <JobSheetForm token={token} data={data} handeNext={handeNext} setToken={setToken} setData={setData}/>
            </div>
        </>
    );
}
