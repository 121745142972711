import React, { useState } from "react";
import { BsCheckCircleFill, BsFillEnvelopeFill, BsFillGeoAltFill,  BsPersonFill, BsTelephoneFill } from "react-icons/bs";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { Avatar, Button, CircularProgress } from "@mui/material";
import { useAuth, useNotification } from "../../hooks";
import { get_data_by_url } from "../../api/general";
import { styled, Tooltip, tooltipClasses, Zoom } from "@mui/material";
import ModarProfileAddress from "./ModarProfileAddress";

const AddressCard = ({ address, index ,setReloadData}) => {
    const { authInfo } = useAuth();

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { showError, showSuccess } = useNotification();

    const [bodyData] = useState({
        "login_user_id": authInfo?.profile?.id,
        "login_user_type": authInfo?.profile?.user_type,
        "id": address?.id,
        "values": {
            "is_primary": "yes"
        }
    }) 


    const Confirmation = () => { setShowModal(true) }

    const UpdateAddress = async () => {
        setReloadData(true);
        setLoading(true);
        const response = await get_data_by_url("dealer/address-update", bodyData);

        if (response?.error === false) {
            showSuccess(response?.message)
        } else {
            showError(response?.message)
        }
        setLoading(false);
        setShowModal(false)
        setReloadData(false);

    }

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow TransitionComponent={Zoom} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));


    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)} animation={true} className="login-modal" centered>
                <Modal.Body >
                    <Container>
                        <Row className="fw-bold text-center mt-3">
                            <Col >
                                <BsCheckCircleFill color="#0a9b20" size={100} />
                            </Col>
                        </Row>
                        <Row className="fw-bold mt-4 text-center">
                            <Col>
                                are you sure you want to update
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col className="text-center">
                                <Button onClick={() => setShowModal(false)} variant='outlined' className='me-3' style={{ color: "var(--primary)", borderColor: "var(--primary)" }}>Cancel</Button>

                                <Button variant='contained' color='warning' disabled={loading} className='sign-up-button ps-4 pe-4'
                                    onClick={UpdateAddress}> {loading && <CircularProgress size={16} color={"inherit"} />}&nbsp; make Primary</Button>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>



            <Col className="mb-2" xs={12} md={6} xl={4} key={index}>
                <Card className={address?.is_primary === 'yes' ? "shadow select-address" : "shadow"}>
                    <Card.Body className="pt-2">
                        <Row> <Col ><BsPersonFill
                            color="var(--primary)" />&nbsp;{address?.name} </Col> </Row>
                        <Row> <Col > <BsTelephoneFill
                            color="var(--primary)" />&nbsp;{address?.mobile}</Col> </Row>
                        <Row> <Col ><BsFillEnvelopeFill
                            color="var(--primary)" />&nbsp; {address?.email}</Col> </Row>

                        <Row> <Col className=" "><BsFillGeoAltFill
                            color="var(--primary)" />&nbsp; {address?.address} , {address?.name_city} , {address?.name_state} </Col>
                        </Row>

                       
                        <Row>
                            <Col className=" d-flex justify-content-end ">
                                {address?.is_primary === 'no' && <div>
                                    <LightTooltip title={"Make Primary"} placement="top">
                                        <Avatar sx={{ width: 24, height: 24 }} onClick={() => Confirmation(address)} className="address-make-primary mx-1" >
                                            <BsCheckCircleFill size={14} />
                                        </Avatar>
                                    </LightTooltip>
                                </div>}
                                {/* <div>
                                    <LightTooltip title={"Delete"} placement="top">
                                        <Avatar sx={{ width: 24, height: 24 }} onClick={Confirmation} className="address-delete mx-1" >
                                            <BsTrash size={14} />
                                        </Avatar>
                                    </LightTooltip> 
                                </div> */}


                                <div> <ModarProfileAddress setReloadData={setReloadData} addressData={address}/> </div>





                            </Col>

                        </Row>
                    </Card.Body>
                </Card>
            </Col>

        </>
    )
}
export default AddressCard