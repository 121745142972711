import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";

// import "../mainContainer.css";
import { Location } from "./Location";
import { ProblemPageForm } from "./ProblemPageForm";
import BookingBody from "./BookingBody";
import { NeedService } from "./NeedService";
import { Container } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { StepNavigation } from "./StepNavigation";
import { useAuth, useNotification } from "../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { get_job_sheet } from "../../api/jobsheet";
import { get_bookings } from "../../api/booking";

const steps = ["Device", "Problem", "Schedule", "Location"];

export default function HorizontalLine() {
    const [searchParems] = useSearchParams()

    const [loading, setLoading] = useState(false);
    const { authInfo } = useAuth();
    const navigate = useNavigate();
    const { showError, showSuccess } = useNotification();
    const [activeStep, setActiveStep] = useState(searchParems.get('step-count') || 0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [token, setToken] = useState(searchParems.get('token') || "");
    const [step, setStep] = useState(searchParems.get('step') || "");

    const [data, setData] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
        setStep(searchParems.get('step') || "")

    }, [activeStep])
    // console.log("step----", searchParems.get('step'))

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getBookingData = async (orderToken) => {
        if (orderToken) {
            const { data, error } = await get_bookings({
                id: authInfo.profile?.id,
                user_type: authInfo.profile?.user_type,
                token_order: orderToken
            });
            // console.log("data-------", data)
            setData(data.data)
            if (error) return showError(error)
            updateActiveStep(data?.data?.step || '')
        }
    }
    const updateActiveStep = (step) => {
        if (step === 'device-problem') {
            setActiveStep(1);
        } else if (step === 'visit-schedule') {
            setActiveStep(2);
        } else if (step === 'location-info') {
            setActiveStep(3);
        } else {
            setActiveStep(0);
        }
    }
    useEffect(() => {
        // console.log(token);
        if (token && token !== "") {
            getBookingData(token);
        }
    }, [token])


    return (
        <>
            <Container className={"my-md-5 my-3"}>
                <Row>
                    <Col xs={12} sm={11} md={10} lg={8} className={'mx-auto'}>
                        <Box className=" {/*boxContainer*/}">
                            <StepNavigation loading={loading} setLoading={setLoading} steps={steps}
                                activeStep={activeStep} isStepOptional={isStepOptional}
                                isStepSkipped={isStepSkipped} />
                            <React.Fragment>
                                {activeStep === 0 &&
                                    <BookingBody data={data} setData={setData} handleBack={handleBack} token={token} handeNext={handleNext} setToken={setToken} />}
                                {activeStep === 1 &&
                                    <ProblemPageForm handeNext={handleNext} handleBack={handleBack} setToken={setToken} token={token} data={data} setData={setData} />}
                                {activeStep === 2 &&
                                    <NeedService data={data} setData={setData} token={token} handeNext={handleNext} handleBack={handleBack} />}
                                {activeStep === 3 &&
                                    <Location data={data} setData={setData} token={token} handeNext={handleNext} handleBack={handleBack} />}
                                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                                <Box className="buttonFootercontainer"
                                // sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                                >
                                    <div
                                        className="container  mt-2  d-flex justify-content-center align-items-center">
                                        <div className="btn-container">
                                            <Box />
                                        </div>
                                    </div>
                                </Box>
                            </React.Fragment>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
