import React, { useState} from 'react'

import PartPurchase from './PartPurchase'
import {HelmetCustomInclude} from "../HelmetCustomInclude";

export default function PurchasePage() {
    const [pageTitle] = useState("Mobile Spare Parts Online at Home or Shop | Buy Display, Battery or more");
    // const [metaKeywords, setMetaKeywords] = useState("Mobile Display, Mobile Battery, Laptop Display, Laptop Battery, Keyboard, Charger, mobile repair at home");
    // const [metaDescription, setMetaDescription] = useState("iService offers low-cost spare parts. A large selection of spare parts is available. Free shipping and cash-on-delivery (COD) are available in many Indian cities.");
    const [metaImageUrl] = useState(null);

    return (
        <div>
            <HelmetCustomInclude url={"product"} title={pageTitle} image={metaImageUrl}/>
            <div className="ourservices">
                <h3 className=" mx-lg-5 servicesHeading">Part Purchase</h3>
                <p className="mx-lg-5 subHeading">
                    {/*Get Spare Parts at low prices on iService. Wide range of Spare Parts. Get Free Shipping & COD options across India*/}
                    iService offers low-cost spare parts. A large selection of spare parts is available. Free shipping
                    and cash-on-delivery (COD) are available in many Indian cities.

                </p>
            </div>
            <PartPurchase/>
        </div>
    )
}
