import React, {useEffect} from "react";
import AuthCard from "./AuthCard";
import SignUpForm from "./SignUpForm";

export default function AuthPage() {
  useEffect(() => {
    document.title = 'Register | iService - Innovation Service';
  }, [])
  return (
    <div>
      <section className="">
        <div className="container-fluid px-0">
          <div className="row g-0">
            <div className="col-lg-6 auth-left-div"/>
            <div className="col-lg-6 vh-100" style={{overflow:"auto"}}>
              <SignUpForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
