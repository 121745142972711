import React from 'react'
import {Card, Col, Container, Row} from "react-bootstrap";
import {FaAward, FaHandHoldingHeart, FaInfo, FaInfoCircle, FaMobileAlt, FaTruckMoving} from "react-icons/fa";
import {Link, useNavigate} from "react-router-dom";
import {HiClipboardCheck} from "react-icons/hi";

export default function FeaturedBlocks() {
    const navigate = useNavigate();
    const data = [
        {
            icon: <FaTruckMoving size={30} color="var(--primary)"/>,
            title: "COD Available",
            titleSub: "At your Doorstep.",
        }, {
            icon: <FaAward size={30} color="var(--primary)"/>,
            // title: "90% shipping",
            title: "90% Part Delivery",
            titleSub: "Within 24 hours",
        }, {
            icon: <FaMobileAlt size={30} color="var(--primary)"/>,
            title: "Quality Parts",
            titleSub: "100% parts quality",
        }, {
            icon: <HiClipboardCheck size={35} color="var(--primary)"/>,
            title: "Warranty T&C",
            titleSub: <>Warranty coverage for all products is mentioned <Link to={'/page/terms-warranty?slug=terms-warranty'}
                                                                              style={{color: "#EE610F"}}>Click
                here</Link></>,
        }
    ]
    return (
        <Container fluid className={"my-3 ps-lg-2 ps-0 pe-lg-2 pe-0"}>
            <Card className='shadow'>
                <Card.Body>
                    <Row className={"g-3"} xs={{cols: "1"}} sm={{cols: "1"}}>
                        <Col>
                            <div className='d-flex align-items-center'>
                                <span> <FaHandHoldingHeart size={30} color="var(--primary)"/> </span>
                                <p className='mx-2 my-2' onClick={() => navigate("/contact-us")}
                                   style={{cursor: "pointer"}}>
                                    <span className='h6'>Support</span> <br/>
                                    <small className='text-muted'>Call us on 9713300060 to speak to our sales team
                                        specialist.{/*<span style={{color: "#EE610F"}}>Click here</span>*/}</small>
                                </p>
                            </div>
                        </Col>

                        {data.map((value, index) => {
                            return (
                                <Col key={index}>
                                    <div className='d-flex align-items-center'>
                                        <span>{value?.icon || ""}</span>
                                        <div className='mx-2 my-2'>
                                            <span className='h6'>{value?.title}</span><br/>
                                            <small className='text-muted'>{value?.titleSub}</small>
                                        </div>
                                    </div>
                                </Col>);
                        })}
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}
