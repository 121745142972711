import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, AlertTitle, CircularProgress, Fab, InputAdornment, TextareaAutosize } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { booking_cancel } from "../../../api/booking";
import { useState } from "react";
import { useAuth, useNotification } from "../../../hooks";
import { Col } from "react-bootstrap";

export default function ModalConfirmCancel({ tokenOrder, reloadData, setReloadData }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { showError, showSuccess } = useNotification();
    const { authInfo } = useAuth();
    const [btnCancel, setBtnCancel] = useState('Confirm')
    const [remark, setRemark] = useState('')
    const [messageError, setMessageError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleCancelOrder = async (event) => {
        setLoading(true);

        // setBtnCancel("loading...");
        const { data, error, message } = await booking_cancel({
            id: authInfo.profile.id,
            token_booking: tokenOrder,
            remark: remark
        });
        // console.log(error, data);
        // setBtnCancel("Confirm");
        

        if (error) {
            setMessageError(message);
            setLoading(false);

            return showError(message);
        } else {
            // setMessageError(message);
            // await getUsers();
            showSuccess(message);
            window.location.reload()

        }
        setReloadData(true);
        setLoading(false);

        handleClose();

    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setRemark(value);
        setMessageError("");
    };

    return (
        <div>
            {/* <Button className={'sign-up-button'} onClick={handleClickOpen}>
                Cancel Order
            </Button>*/}
            <button className="sign-up-button float-end"
                onClick={handleClickOpen}>Cancel Order
            </button>
            {/* <Fab variant="extended" size="small" color="primary" onClick={handleClickOpen}>
                <FaTimes sx={{ mr: 3 }} />
                Cancel Order
            </Fab>*/}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Order Cancel Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText className={'mb-3'}>
                        {/* Do you really want to cancel this order? This request cannot be reopened once it has been
                        cancelled. Please also explain why you are cancelling this request.*/}
                        <Alert severity="warning">
                            {/*<AlertTitle>Order Cancel Confirmation</AlertTitle>*/}
                            <small>Do you really want to cancel this order? This request cannot be reopened once it has been
                                cancelled. <b>Please also explain why you are cancelling this request.</b></small>
                        </Alert>

                    </DialogContentText>
                    <TextField color={'warning'}
                        onChange={handleChange}
                        autoFocus
                        margin="dense"
                        id="remark"
                        name={'remark'}
                        label="Reason of cancellation"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />

                    <p>
                        <small className={'text-danger capitalize'}>{messageError}</small>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'inherit'}>Cancel</Button>
                    <Button disabled={loading} variant={'contained'} color={'warning'} className={'sign-up-button'}
                        onClick={handleCancelOrder}>{loading && <CircularProgress size={16} color={"inherit"} />}&nbsp; {btnCancel}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}