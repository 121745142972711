import React from 'react'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './RestPassword'


export default function ResetPage() {
  return (
    <div>
    <section className="">
      <div className="container-fluid px-0">
        <div className="row g-0">
          <div className="col-lg-6 auth-left-div"></div>
          <div className="col-lg-6 vh-100 d-flex align-item-center justify-content-center">
            <ResetPassword/>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}
